import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { for: "name" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { for: "description" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"350px"},
    header: _ctx.t('partner'),
    modal: true,
    class: "w-full product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.t('cancel'),
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.t('save'),
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t("name")), 1),
        _createVNode(_component_InputText, {
          id: "name",
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.name }]),
          modelValue: _ctx.item.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          autofocus: ""
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && !_ctx.item.name)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.t("required")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t("description")), 1),
        _createVNode(_component_InputText, {
          id: "description",
          class: "w-full",
          modelValue: _ctx.item.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.description) = $event)),
          modelModifiers: { trim: true }
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}