
import {
  ITransferOrderFragment,
  ITransferItemFragment,
  useInsertTransferOrderMutation,
  useInsertTransferItemMutation,
  useUpdateTranferItemMutation,
  useTransferFormDataQuery,
  useDeleteTransferItemMutation,
  IProductSearchFragment,
  useUpdateOrderNoteMutation,
} from "@/graphql";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";
import ShowConfirmation, {
  IShowConfirmation,
} from "../common/ShowConfirmation.vue";
import FooterActions from "@/components/orders/FooterActions.vue";
import { validate } from "@/validators";
import OrderNote from "@/components/orders/OrderNote.vue";

type FormMode = "" | "insert" | "update" | "select";

type TransferItem = {
  -readonly [K in keyof ITransferItemFragment]: ITransferItemFragment[K];
};

export type ITransfersForm = {
  showForm(mode: FormMode): void;
};

export default defineComponent({
  name: "TransfersForm",
  components: { ShowConfirmation, FooterActions, OrderNote },
  props: {
    order: Object as PropType<ITransferOrderFragment>,
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const editingRows = ref<any[]>([]);
    const submitted = ref(false);
    const dialog = ref<IShowConfirmation | null>(null);
    const note = ref("");

    const items = ref<TransferItem[]>([]);
    const newItem = ref<TransferItem>({} as TransferItem);

    watch(
      () => props.order,
      () => {
        note.value = props.order?.note || "";
        items.value = JSON.parse(
          JSON.stringify(props.order?.transfer_order_items || [])
        );
      }
    );

    //<!-- Form Data Queries -->
    const { result, refetch } = useTransferFormDataQuery({});
    const destLocation = computed(() => result.value?.stock_location || []);
    const productList = computed(() => result.value?.product || []);

    onMounted(refetch);

    //<!-- Mutations -->
    const { mutate: insertOrder } = useInsertTransferOrderMutation({
      refetchQueries: ["Transfers"],
    });
    const { mutate: insertItem } = useInsertTransferItemMutation({
      refetchQueries: ["Transfers"],
    });
    const { mutate: updateItem } = useUpdateTranferItemMutation({
      refetchQueries: ["Transfers"],
    });
    const { mutate: deleteItem } = useDeleteTransferItemMutation({
      refetchQueries: ["Transfers"],
    });

    const { mutate: updateNote } = useUpdateOrderNoteMutation({});

    //<!-- Handlers -->
    function showForm(_mode: FormMode) {
      open.value = true;
      mode.value = _mode;
    }

    async function hideForm(isOk?: boolean) {
      if (mode.value === "insert" && items.value.length) {
        if (isOk) {
          await handleSave();
        }
        items.value = [];
        newItem.value = {} as TransferItem;
      } else if (mode.value === "update") {
        if (note.value !== props.order?.note) {
          await updateNote({ id: props.order?.id, note: note.value });
        }
      }

      submitted.value = false;
      open.value = false;
      clearInsert();
    }

    async function handleSave() {
      if (mode.value === "insert") {
        const _items = JSON.parse(JSON.stringify(items))?._value;

        for (const i of _items) {
          delete i.product;
        }

        try {
          await insertOrder({
            note: note.value,
            data: _items,
          });
          msg.showInsertSuccess();
        } catch (_) {
          return;
        }
      }
    }

    const productValidator = validate(t, "product", [["required"]]);
    const lotValidator = validate(t, "lot", [["required"]]);
    const locationValidator = validate(t, "source", [["required"]]);
    const quantityValidator = validate(t, "quantity", [["required"]]);
    const unitValidator = validate(t, "unit", [["required"]]);

    function validateInserForm() {
      const item = newItem.value;

      const {
        product,
        lot_id,
        quantity,
        product_uom,
        src_location,
        dest_location,
      } = item;

      if (locationValidator(src_location)) return false;
      if (locationValidator(dest_location)) return false;
      if (productValidator(product)) return false;
      if (product.trackerable && lotValidator(lot_id)) return false;
      if (quantityValidator(quantity)) return false;
      if (unitValidator(product_uom)) return false;

      return true;
    }

    async function handleInsert() {
      const item = newItem.value;
      submitted.value = true;
      item.product_id = item.product?.id || -1;
      if (!validateInserForm()) {
        return;
      }

      if (mode.value === "insert") {
        items.value.push(item);
      }

      if (mode.value === "update") {
        try {
          const _item = JSON.parse(JSON.stringify(item));
          delete _item.product;
          await insertItem({
            order_id: props.order?.id,
            ..._item,
          });
        } catch (_) {
          return;
        }
      }

      newItem.value = {} as TransferItem;
      submitted.value = false;
    }

    function validateUpdateForm(data: TransferItem) {
      //  if (locationValidator(src_location)) return false;
      // if (locationValidator(dest_location)) return false;
      // if (productValidator(product)) return false;
      // if (product.trackerable && lotValidator(lot_id)) return false;
      if (quantityValidator(data.quantity)) return false;
      // if (unitValidator(product_uom)) return false;

      return true;
    }

    async function handleUpdate({ newData: data }: { newData: TransferItem }) {
      if (!validateUpdateForm(data)) {
        editingRows.value.push(data);

        return;
      }

      if (mode.value === "update") {
        try {
          const _item = JSON.parse(JSON.stringify(data));
          delete _item.status, delete _item.dest;
          delete _item.product_id;
          delete _item.__typename;
          delete _item.src;
          delete _item.product;
          await updateItem(_item);
          msg.showUpdateSuccess();
        } catch (_) {
          return;
        }
      }
    }

    async function handleDelete({ id }: TransferItem) {
      const isOk = await dialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!isOk) {
        return;
      }

      if (mode.value === "update") {
        try {
          const res = await deleteItem({ id });
          if (res?.data?.delete_stock_transfer_order_item_by_pk === null) {
            msg.showError();
          }
        } catch (_) {
          return;
        }
      } else {
        const index = items.value.findIndex((i) => i.id === id);
        items.value.splice(index, 1);
      }
    }

    function handleEditCancel(event: { index: number }) {
      if (props.order) {
        items.value[event.index] = JSON.parse(
          JSON.stringify(props.order.transfer_order_items[event.index])
        );
      }
    }

    function clearInsert() {
      newItem.value = {} as TransferItem;
      submitted.value = false;
    }

    function onProductSelect({
      value: p,
    }: {
      value: IProductSearchFragment | any;
    }) {
      newItem.value = { product: newItem.value.product } as any;

      newItem.value.product_uom = p?.unit_of_measure.id;
      newItem.value.quantity = 1;

      const lots =
        p.product_lot?.reduce((items: any, lot: any) => {
          if (!items.some((_lot: any) => _lot.lot_id === lot.lot_id))
            items.push({
              lot_code: lot.lot_code || t("na"),
              lot_id: lot.lot_id,
            });
          return items;
        }, []) || [];

      if (lots.length === 1) {
        newItem.value.lot_id = lots[0].lot_id;
      }

      const srcs = p.product_lot?.filter(
        (lot: any) => newItem.value.lot_id === lot.lot_id
      );

      if (srcs.length === 1) {
        newItem.value.src_location = srcs[0].location_id;
      }
    }

    return {
      t,
      open,
      note,
      mode,
      items,
      handleUpdate,
      newItem,
      submitted,
      hideForm,
      clearInsert,
      destLocation,
      handleInsert,
      handleEditCancel,
      handleDelete,
      productList,
      editingRows,
      dialog,
      onProductSelect,
      showForm,
      productValidator,
      lotValidator,
      locationValidator,
      quantityValidator,
      unitValidator,
    };
  },
});
