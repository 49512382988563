
import { computed, defineComponent, onMounted } from "vue";
import { useI18n } from "vue-i18n";
import Kpi from "@/components/widgets/Kpi.vue";
import {
  useProductsNearToExpireQuery,
  useProductsRunningOutQuery,
  usePurchaseViewQuery,
  useSalesViewQuery,
} from "@/graphql";

export default defineComponent({
  components: { Kpi },
  setup() {
    const { t } = useI18n();

    const { result: nearToExpireResult, refetch: refetchToExpire } =
      useProductsNearToExpireQuery({});

    const { result: productsRunningOutResult, refetch: refetchRunningOut } =
      useProductsRunningOutQuery({});

    const { result: purchaseResult, refetch: refetchPurchase } =
      usePurchaseViewQuery();

    const { result: salesResult, refetch: refetchSales } = useSalesViewQuery();

    onMounted(() => {
      refetchPurchase();
      refetchRunningOut();
      refetchToExpire();
      refetchSales();
    });

    const prodNearToExpire = computed(
      () => nearToExpireResult.value?.data || []
    );

    const prodRunningOut = computed(
      () => productsRunningOutResult.value?.data || []
    );

    const purchaseView = computed(
      () => purchaseResult.value?.opb_purchases_view || []
    );

    const salesView = computed(() => salesResult.value?.opb_sales_view || []);

    function formatCurrency(value: number) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    }

    return {
      t,
      prodNearToExpire,
      prodRunningOut,
      purchaseView,
      salesView,
      formatCurrency,
    };
  },
});
