
import { defineComponent } from "vue";

export default defineComponent({
  name: "appsubmenu",
  props: {
    items: Array as any,
    root: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      activeIndex: null as any,
    };
  },
  methods: {
    onMenuItemClick(event: any, item: any, index: number) {
      if (item.disabled) {
        event.preventDefault();
        return;
      }

      if (!item.to && !item.url) {
        event.preventDefault();
      }

      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item });
      }

      this.activeIndex = index === this.activeIndex ? null : index;

      this.$emit("menuitem-click", {
        originalEvent: event,
        item: item,
      });
    },
    visible(item: any) {
      return typeof item.visible === "function"
        ? item.visible()
        : item.visible !== false;
    },
  },
});
