import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af400f8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-footer-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Button, {
      label: _ctx.t('cancel'),
      icon: "pi pi-times",
      class: "p-button-text",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    }, null, 8, ["label"]),
    _createVNode(_component_Button, {
      label: _ctx.t('save'),
      icon: "pi pi-check",
      class: "p-button-text",
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('save'))),
      disabled: _ctx.disableSave
    }, null, 8, ["label", "disabled"])
  ]))
}