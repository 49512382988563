
import { defineComponent, ref, reactive, watchEffect, PropType } from "vue";
import { useI18n } from "vue-i18n";
import OverlayPanel from "primevue/overlaypanel/OverlayPanel";
import {
  ICategoriesFragment as ICategories,
  useDeleteCategoryMutation,
  useInsertCategoryMutation,
  useUpdateCategoryMutation,
} from "@/graphql";
import { msg } from "@/plugins/message";
import ShowConfirmation, {
  IShowConfirmation,
} from "@/components/common/ShowConfirmation.vue";

const mConf = {
  refetchQueries: ["Categories"],
};

export default defineComponent({
  name: "CategoriesForm",
  components: {
    ShowConfirmation,
  },
  props: {
    categories: Object as PropType<ICategories>,
  },
  setup(props) {
    const catForm = ref<OverlayPanel>();
    const selectedItems = ref<Array<{ id: number }> | null>(null);
    const { t } = useI18n();
    const editingRows = ref([]);
    const newExpanded = ref(false);
    const submitted = ref(false);
    const deleteDialog = ref<IShowConfirmation | null>(null);
    const categoriesBuf = ref<ICategories>(
      JSON.parse(JSON.stringify(props.categories))
    );

    const newCategory = reactive({ name: "", description: "" });

    const { mutate: insertCategory } = useInsertCategoryMutation(mConf);

    const { mutate: updateCategory } = useUpdateCategoryMutation(mConf);

    const { mutate: deleteCategories } = useDeleteCategoryMutation(mConf);

    watchEffect(() => {
      categoriesBuf.value = JSON.parse(JSON.stringify(props.categories));
    });

    function toggle(event: MouseEvent) {
      selectedItems.value = null;
      catForm.value?.toggle(event);
    }

    async function handleSave({ newData: data }: { newData: ICategories }) {
      try {
        const _data = JSON.parse(JSON.stringify(data));
        delete _data.__typename;
        await updateCategory(_data);
        msg.showUpdateSuccess();
      } catch (_) {
        return;
      }
    }

    function toggleCreate() {
      newExpanded.value = !newExpanded.value;
    }

    async function handleDelete() {
      const isOk = await deleteDialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!isOk) {
        return;
      }

      const items = selectedItems.value?.map((i) => i.id) || [];
      if (items.length) {
        try {
          await deleteCategories({ _in: items });
        } catch (_) {
          return;
        }
      }
      selectedItems.value = null;
    }

    async function submit() {
      try {
        await insertCategory(newCategory);
        newCategory.name = "";
        newCategory.description = "";
        submitted.value = false;
        newExpanded.value = false;
      } catch (_) {
        return;
      }
    }

    return {
      t,
      open,
      deleteDialog,
      toggle,
      catForm,
      submitted,
      toggleCreate,
      submit,
      editingRows,
      newCategory,
      newExpanded,
      handleSave,
      handleDelete,
      selectedItems,
      categoriesBuf,
    };
  },
});
