
import {
  ILotFragment,
  IPurchaseItemFragment,
  IPurchaseOrderFragment,
  useDeleteLotMutation,
  useDeletePurchaseOrderItemMutation,
  useInsertLotMutation,
  useInsertPurchaseItemMutation,
  useInsertPurchaseOrderMutation,
  usePurchasesFormDataQuery,
  useUpdateOrderNoteMutation,
  useUpdatePurchaseItemsMutation,
} from "@/graphql";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ShowConfirmation, {
  IShowConfirmation,
} from "../common/ShowConfirmation.vue";
import PurchaseLotForm from "./LotForm.vue";
import FooterActions from "@/components/orders/FooterActions.vue";
import { validate } from "@/validators";
import { msg } from "@/plugins/message";
import OrderNote from "@/components/orders/OrderNote.vue";

type FormMode = "" | "insert" | "update" | "select";

export type PurchaseItem = {
  -readonly [K in keyof IPurchaseItemFragment]: IPurchaseItemFragment[K];
};

export type Lot = {
  -readonly [K in keyof ILotFragment]: ILotFragment[K];
};

export type IPurchaseForm = {
  showForm(mode: FormMode): void;
};

export default defineComponent({
  name: "PurchaseForm",
  components: {
    ShowConfirmation,
    PurchaseLotForm,
    FooterActions,
    OrderNote,
  },
  props: {
    order: Object as PropType<IPurchaseOrderFragment>,
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const editingRows = ref<any[]>([]);
    const submitted = ref(false);
    const dialog = ref<IShowConfirmation | null>(null);
    const note = ref("");

    const items = ref<PurchaseItem[]>([]);
    const newItem = ref<PurchaseItem>({} as PurchaseItem);

    watch(
      () => props.order,
      () => {
        note.value = props.order?.note || "";
        items.value = JSON.parse(
          JSON.stringify(props.order?.purchase_order_items || [])
        );
      }
    );

    //<!-- Form Data Query -->
    const { result: formData, refetch } = usePurchasesFormDataQuery();

    const operations = computed(() => formData.value?.stock_operation || []);
    const partners = computed(() => formData.value?.stock_partner || []);
    const productList = computed(() => formData.value?.product || []);

    onMounted(refetch);

    //<!-- Mutations -->
    const { mutate: insertOrder } = useInsertPurchaseOrderMutation({
      refetchQueries: ["Purchases"],
    });

    const { mutate: insertItem } = useInsertPurchaseItemMutation({
      refetchQueries: ["Purchases"],
    });

    const { mutate: updateItem } = useUpdatePurchaseItemsMutation({
      refetchQueries: ["Purchases"],
    });

    const { mutate: deleteItem } = useDeletePurchaseOrderItemMutation({
      refetchQueries: ["Purchases"],
    });

    const { mutate: insertLot } = useInsertLotMutation({});

    const { mutate: deleteLot } = useDeleteLotMutation({});

    const { mutate: updateNote } = useUpdateOrderNoteMutation({});

    //<!-- Handlers -->

    function showForm(_mode: FormMode) {
      open.value = true;
      mode.value = _mode;
    }

    async function hideForm(isOk?: boolean) {
      if (mode.value === "insert" && items.value.length) {
        if (isOk) {
          await handleSave();
        }
        items.value = [];
        note.value = "";
        newItem.value = {} as PurchaseItem;
      } else if (mode.value === "update") {
        if (note.value !== props.order?.note) {
          await updateNote({ id: props.order?.id, note: note.value });
        }
      }

      submitted.value = false;
      open.value = false;
      clearInsert();
    }

    async function handleInsertLot(lot: Lot): Promise<number | undefined> {
      normalizeLot(lot);
      try {
        return (await insertLot(lot))?.data?.insert_stock_production_lot_one
          ?.id;
      } catch (e) {
        msg.showError(t("msg_error_insert_lot"));
        throw e;
      }
    }

    function normalizeLot(lot: Lot, pid?: number) {
      if (pid) {
        lot.product_id = pid;
      }
      if (!lot?.alert_date) lot.alert_date = null;
      if (!lot?.expiration_date) lot.expiration_date = null;
    }

    async function handleSave() {
      if (mode.value === "insert") {
        const _items = JSON.parse(JSON.stringify(items))?._value;

        for (const i of _items) {
          delete i.product;

          if (i.lot?.[0].code) {
            i.lot_id = await handleInsertLot({
              ...i.lot[0],
              product_id: i.product_id,
            });
          }

          delete i.lot;
        }

        await insertOrder({
          note: note.value,
          data: _items as Pick<
            PurchaseItem,
            Exclude<keyof PurchaseItem, "product">
          >[],
        });
      }
    }

    const productValidator = validate(t, "product", [["required"]]);
    const lotValidator = validate(t, "lot", [["required"]]);
    const operationValidator = validate(t, "operation", [["required"]]);
    const quantityValidator = validate(t, "quantity", [["required"]]);
    const unitValidator = validate(t, "unit", [["required"]]);

    function validateInserForm() {
      const item = newItem.value;
      const { product, lot, operation_id, quantity, price, product_uom } = item;

      if (productValidator(product)) return false;
      if (product.trackerable && lotValidator(lot?.[0]?.code)) return false;
      if (operationValidator(operation_id)) return false;
      if (quantityValidator(quantity)) return false;
      if (!(price >= 0)) return false;
      if (unitValidator(product_uom)) return false;

      return true;
    }

    function onProductSelect(prod: any) {
      const { value } = prod;

      newItem.value = { product: newItem.value.product } as any;

      newItem.value.price = value?.cost;
      newItem.value.product_uom = value?.unit_of_measure.id;
      newItem.value.quantity = 1;

      if (operations.value.length === 1) {
        newItem.value.operation_id = operations.value[0].id;
      }

      if (partners.value.length === 1) {
        newItem.value.partner_provider = partners.value[0].id;
      }

      if (
        newItem.value.product.trackerable &&
        newItem.value.product.expire_on &&
        !newItem.value.lot?.length
      ) {
        const today = new Date();
        const expDate = new Date(
          today.setDate(today.getDate() + newItem.value.product.expire_on)
        );
        const alertDate = newItem.value.product.alert_of_expiration_on
          ? new Date(
              today.setDate(
                today.getDate() - newItem.value.product.alert_of_expiration_on
              )
            )
          : null;

        newItem.value.lot = [
          {
            expiration_date: expDate,
            alert_date: alertDate,
          },
        ] as ILotFragment[];
      }
    }

    async function handleInsert() {
      const item = newItem.value;
      submitted.value = true;

      item.product_id = item.product?.id;
      if (!validateInserForm()) {
        return;
      }

      if (mode.value === "insert") {
        items.value.push(item);
      }

      if (mode.value === "update") {
        try {
          if (item.lot?.[0].code) {
            normalizeLot(item.lot[0], item.product_id);
            item.lot_id = await handleInsertLot(item.lot[0]);
          }
          const _item = JSON.parse(JSON.stringify(item));
          delete _item.lot;
          delete _item.product;
          await insertItem({
            order_id: props.order?.id,
            ..._item,
          });
          msg.showInsertSuccess();
        } catch (_) {
          return;
        }
      }

      newItem.value = {} as PurchaseItem;
      submitted.value = false;
    }

    function validateUpdateItem(data: PurchaseItem) {
      if (quantityValidator(data.quantity)) return false;
      if (!(data.price >= 0)) return false;
      if (data.product.trackerable && lotValidator(data?.lot?.[0]?.code))
        return false;

      return true;
    }

    async function handleUpdate({ newData: data }: { newData: PurchaseItem }) {
      if (!validateUpdateItem(data)) {
        editingRows.value.push(data);
        return;
      }
      if (mode.value === "update") {
        let product_lot: number[] = [];
        if (data.lot_id && !data.lot?.[0]?.code) {
          deleteLot({ id: data.lot_id });
          data.lot_id = null;
          product_lot = [];
        } else if (data.lot_id) {
          product_lot = [data.lot_id];
        } else if (data.lot?.[0]?.code) {
          normalizeLot(data.lot[0], data.product_id);
          data.lot_id = await handleInsertLot(data.lot[0]);
        }
        const _item = JSON.parse(
          JSON.stringify({ ...data, ...(data.lot?.[0] || []), product_lot })
        );

        delete _item.status;
        delete _item.lot;
        delete _item.__typename;
        delete _item.product;

        updateItem(_item);
      }
    }

    async function handleDelete({ id, lot_id }: PurchaseItem) {
      const isOk = await dialog.value?.showConfirmation({
        message: `Está seguro de que desea eliminar el elemento`,
      });

      if (!isOk) {
        return;
      }

      if (mode.value === "update") {
        try {
          const res = await deleteItem({ id, lot: lot_id ? [lot_id] : [] });
          if (res?.data?.delete_stock_purchase_order_item_by_pk === null) {
            msg.showError();
          } else {
            msg.showDeleteSuccess();
          }
        } catch (_) {
          return;
        }
      } else {
        const index = items.value.findIndex((i) => i.id === id);
        items.value.splice(index, 1);
      }
    }

    function handleEditCancel(event: { index: number }) {
      if (props.order) {
        items.value[event.index] = JSON.parse(
          JSON.stringify(props.order.purchase_order_items[event.index])
        ) as PurchaseItem;
      }
    }

    function clearInsert() {
      newItem.value = {} as PurchaseItem;
      submitted.value = false;
    }

    return {
      t,
      open,
      mode,
      note,
      editingRows,
      submitted,
      hideForm,
      handleSave,
      productList,
      partners,
      operations,
      items,
      newItem,
      showForm,
      clearInsert,
      dialog,
      handleInsert,
      handleUpdate,
      handleDelete,
      handleEditCancel,
      productValidator,
      lotValidator,
      operationValidator,
      quantityValidator,
      unitValidator,
      onProductSelect,
    };
  },
});
