
import {
  computed,
  defineComponent,
  onMounted,
  ref,
  watch,
  PropType,
} from "vue";
import {
  IManufactoryOrderFragment,
  IManufactoryItemFragment,
  useInsertManufactoryItemMutation,
  useInsertManufactoryOrderMutation,
  useUpdateManufactoryItemMutation,
  useManufactoryFormDataQuery,
  useDeleteManufactoryItemMutation,
  ILotFragment,
  useInsertLotMutation,
  useDeleteLotMutation,
  IProductSearchFragment,
  useUpdateOrderNoteMutation,
} from "@/graphql";
import { msg } from "@/plugins/message";
import { useI18n } from "vue-i18n";
import ShowConfirmation, {
  IShowConfirmation,
} from "../common/ShowConfirmation.vue";
import ManufactoryProductList, {
  IManufactoryProductList,
} from "@/components/orders/ManufactoryProductList.vue";
import FooterActions from "@/components/orders/FooterActions.vue";
import { validate } from "@/validators";
import LotForm from "@/components/orders/LotForm.vue";
import OrderNote from "@/components/orders/OrderNote.vue";

type FormMode = "insert" | "update" | "select";

type ManufactoryItem = {
  -readonly [K in keyof IManufactoryItemFragment]: IManufactoryItemFragment[K];
};

export type Lot = {
  -readonly [K in keyof ILotFragment]: ILotFragment[K];
};

export type IManufactoryForm = {
  showForm(mode: FormMode): void;
};
export default defineComponent({
  name: "ManufactoryForm",
  components: {
    ShowConfirmation,
    ManufactoryProductList,
    FooterActions,
    LotForm,
    OrderNote,
  },
  props: {
    order: Object as PropType<IManufactoryOrderFragment>,
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("select");
    const editingRows = ref<any[]>([]);
    const submitted = ref(false);
    const dialog = ref<IShowConfirmation | null>(null);
    const list = ref<IManufactoryProductList | null>(null);
    const activeIndex = ref(-1);
    const note = ref("");

    const items = ref<ManufactoryItem[]>([]);
    const newItem = ref<ManufactoryItem>({} as ManufactoryItem);

    watch(
      () => props.order,
      () => {
        note.value = props.order?.note || "";
        items.value = JSON.parse(
          JSON.stringify(props.order?.manufacturing_order_items || [])
        );
      }
    );

    //<!-- Form Data Queries -->

    const { result, refetch } = useManufactoryFormDataQuery({});
    const operations = computed(() => result.value?.stock_operation || []);
    const productList = computed(() => result.value?.product || []);

    onMounted(refetch);

    //<!-- Mutations -->
    const { mutate: insertOrder } = useInsertManufactoryOrderMutation({
      refetchQueries: ["Manufactory"],
    });

    const { mutate: insertItem } = useInsertManufactoryItemMutation({
      refetchQueries: ["Manufactory"],
    });

    const { mutate: updateItem } = useUpdateManufactoryItemMutation({
      refetchQueries: ["Manufactory"],
    });

    const { mutate: deleteItem } = useDeleteManufactoryItemMutation({
      refetchQueries: ["Manufactory"],
    });

    const { mutate: insertLot } = useInsertLotMutation({});

    const { mutate: deleteLot } = useDeleteLotMutation({});

    const { mutate: updateNote } = useUpdateOrderNoteMutation({});

    //<!-- Handlers -->
    function showForm(_mode: FormMode) {
      open.value = true;
      mode.value = _mode;
    }

    async function hideForm(isOk?: boolean) {
      if (mode.value === "insert" && items.value.length) {
        if (isOk) {
          await handleSave();
        }
        items.value = [];
        newItem.value = {} as ManufactoryItem;
      } else if (mode.value === "update") {
        if (note.value !== props.order?.note) {
          await updateNote({ id: props.order?.id, note: note.value });
        }
      }

      submitted.value = false;
      open.value = false;
      clearInsert();
    }

    async function handleInsertLot(lot: Lot): Promise<number | undefined> {
      normalizeLot(lot);
      try {
        return (await insertLot(lot))?.data?.insert_stock_production_lot_one
          ?.id;
      } catch (e) {
        msg.showError(t("msg_error_insert_lot"));
        throw e;
      }
    }

    function normalizeLot(lot: Lot, pid?: number) {
      if (pid) {
        lot.product_id = pid;
      }
      if (!lot?.alert_date) lot.alert_date = null;
      if (!lot?.expiration_date) lot.expiration_date = null;
    }

    async function handleSave() {
      if (mode.value === "insert") {
        const _items = JSON.parse(JSON.stringify(items.value));

        for (const i of _items) {
          delete i.product;

          if (i.lot?.[0].code) {
            i.lot_id = await handleInsertLot({
              ...i.lot[0],
              product_id: i.product_id,
            });
          }

          delete i.lot;
        }

        await insertOrder({
          note: note.value,
          data: _items.map((i: any) => {
            delete i.product;
            delete i.operation;
            return i;
          }) as Omit<
            ManufactoryItem,
            "product" | "source_location" | "destiny_location"
          >[],
        });
        msg.showInsertSuccess();
      }
    }

    function onProductSelect(prod: { value: IProductSearchFragment }) {
      const { value } = prod;
      newItem.value = { product: newItem.value.product } as any;
      newItem.value.product_uom = value?.unit_of_measure.id;
      newItem.value.quantity = 1;

      if (
        newItem.value.product.trackerable &&
        newItem.value.product.expire_on &&
        !newItem.value.lot?.length
      ) {
        const today = new Date();
        const expDate = new Date(
          today.setDate(today.getDate() + newItem.value.product.expire_on)
        );
        const alertDate = newItem.value.product.alert_of_expiration_on
          ? new Date(
              today.setDate(
                today.getDate() - newItem.value.product.alert_of_expiration_on
              )
            )
          : null;

        newItem.value.lot = [
          {
            expiration_date: expDate,
            alert_date: alertDate,
          },
        ] as ILotFragment[];
      }
    }

    const productValidator = validate(t, "product", [["required"]]);
    const quantityValidator = validate(t, "quantity", [["required"]]);

    function validateInserForm() {
      const item = newItem.value;

      const { product, quantity, product_uom } = item;

      if (!(item as any).operation) return false;

      if (productValidator(product)) return false;
      if (product.trackerable && !item.lot?.[0]?.code) return false;
      if (quantityValidator(quantity)) return false;
      if (!product_uom) return false;

      return true;
    }

    async function handleInsert() {
      const item = newItem.value;
      submitted.value = true;

      item.product_id = item.product?.id;
      if (!validateInserForm()) {
        return;
      }

      if (mode.value === "insert") {
        items.value.push(item);
      }

      if (mode.value === "update") {
        try {
          if (item.lot?.[0].code) {
            normalizeLot(item.lot[0], item.product_id);
            item.lot_id = await handleInsertLot(item.lot[0]);
          }
          const _item = JSON.parse(JSON.stringify(item));
          delete _item.product;
          delete _item.operation;
          delete _item.lot;
          await insertItem({ order_id: props.order?.id, ..._item });
          msg.showInsertSuccess();
        } catch (_) {
          return;
        }
      }

      newItem.value = {} as ManufactoryItem;
      submitted.value = false;
    }

    async function handleUpdate({
      newData: data,
    }: {
      newData: ManufactoryItem;
    }) {
      console.log(data);

      if (!data.quantity) {
        editingRows.value.push(data as any);
        return;
      }

      try {
        let product_lot: number[] = [];
        if (data.lot_id && !data.lot?.[0]?.code) {
          deleteLot({ id: data.lot_id });
          data.lot_id = null;
          product_lot = [];
        } else if (data.lot_id) {
          product_lot = [data.lot_id];
        } else if (data.lot?.[0]?.code) {
          normalizeLot(data.lot[0], data.product_id);
          data.lot_id = await handleInsertLot(data.lot[0]);
        }

        const _item = JSON.parse(
          JSON.stringify({ ...data, ...(data.lot?.[0] || []), product_lot })
        );
        delete _item.status, delete _item.lot_id;
        delete _item.product_id;
        delete _item.source_location;
        delete _item.__typename;
        delete _item.destiny_location;
        delete _item.product;
        delete _item.lot;
        await updateItem(_item);
        msg.showUpdateSuccess();
      } catch (_) {
        return;
      }
    }

    async function handleDelete({ id, lot_id }: ManufactoryItem) {
      const isOk = await dialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!isOk) {
        return;
      }

      if (mode.value === "update") {
        try {
          const res = await deleteItem({ id, lot: lot_id ? [lot_id] : [] });
          if (res?.data?.delete_stock_manufacturing_order_item_by_pk === null) {
            msg.showError();
          } else {
            msg.showDeleteSuccess();
          }
        } catch (_) {
          return;
        }
      } else {
        const index = items.value.findIndex((i) => i.id === id);
        items.value.splice(index, 1);
      }
    }

    function handleEditCancel(event: { index: number }) {
      if (props.order) {
        items.value[event.index] = JSON.parse(
          JSON.stringify(props.order.manufacturing_order_items[event.index])
        );
      }
    }

    function handleOpChange(
      { value: id }: { value: number },
      item: ManufactoryItem
    ) {
      const op = operations.value.find((i) => i.id === id);
      if (op) {
        item.src_location = op.default_src;
        item.dest_location = op.default_dest;
      }
    }

    function editMaterials({ id }: ManufactoryItem, mode: "select" | "update") {
      activeIndex.value = items.value.findIndex((i) => i.id === id);
      list.value?.showForm(mode);
    }

    function clearInsert() {
      newItem.value = {} as ManufactoryItem;
      submitted.value = false;
    }

    return {
      t,
      open,
      note,
      mode,
      editingRows,
      submitted,
      hideForm,
      handleSave,
      productList,
      items,
      list,
      newItem,
      showForm,
      operations,
      handleOpChange,
      clearInsert,
      editMaterials,
      dialog,
      onProductSelect,
      activeIndex,
      handleInsert,
      handleUpdate,
      handleDelete,
      handleEditCancel,
      productValidator,
      quantityValidator,
    };
  },
});
