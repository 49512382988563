import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ShowConfirmation = _resolveComponent("ShowConfirmation")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    header: `${_ctx.t('materials_table')}: ${_ctx.name || ''}`,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"800px"},
    modal: true,
    class: "w-full"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm",
        value: _ctx.materialBuf,
        dataKey: "product_id",
        editMode: _ctx.accessors.route?.canWrite ? 'row' : undefined,
        loading: _ctx.loading,
        editingRows: _ctx.editingRows,
        "onUpdate:editingRows": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRows) = $event)),
        onRowEditSave: _ctx.handleUpdate,
        onRowEditCancel: _ctx.handleEditCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.t('name'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.name), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.name), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.product,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newItem.product) = $event)),
                options: _ctx.productList,
                filter: true,
                optionLabel: "name",
                appendTo: "body",
                placeholder: _ctx.t('product')
              }, null, 8, ["modelValue", "options", "placeholder"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "quantity",
            header: _ctx.t('quantity'),
            sortable: true,
            headerStyle: "width:10rem"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.quantity || "N/A"), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_InputNumber, {
                modelValue: data['quantity'],
                "onUpdate:modelValue": ($event: any) => ((data['quantity']) = $event),
                min: 0,
                minFractionDigits: 1,
                maxFractionDigits: 3
              }, null, 8, ["modelValue", "onUpdate:modelValue"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_InputNumber, {
                modelValue: _ctx.newItem.quantity,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem.quantity) = $event)),
                mode: "decimal",
                min: 0,
                minFractionDigits: 1,
                maxFractionDigits: 3
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "product_uom",
            header: _ctx.t('unit'),
            headerStyle: "width:10rem"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.related_uom?.find((i) => i.id === data.product_uom)
              ?.code), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.product_uom,
                "onUpdate:modelValue": ($event: any) => ((data.product_uom) = $event),
                options: 
              data.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                class: "units-selector",
                modelValue: _ctx.newItem.product_uom,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newItem.product_uom) = $event)),
                options: 
              _ctx.newItem.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body"
              }, null, 8, ["modelValue", "options"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            rowEditor: true,
            headerStyle: "width:7rem",
            bodyStyle: "text-align:right"
          }, {
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_Button, {
                  icon: "pi pi-check",
                  iconPos: "right",
                  class: "p-button-rounded",
                  disabled: 
                !(_ctx.newItem.product && _ctx.newItem.quantity && _ctx.newItem.product_uom)
              ,
                  onClick: _ctx.handleInsert
                }, null, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            headerStyle: "width:5rem",
            bodyStyle: "text-align:center",
            footerStyle: "text-align:center"
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                onClick: ($event: any) => (_ctx.handleDelete(slotProps.data))
              }, null, 8, ["onClick"])
            ]),
            editor: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                disabled: true
              })
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-times",
                iconPos: "right",
                class: "p-button-rounded p-button-danger",
                disabled: 
              !(_ctx.newItem.product || _ctx.newItem.quantity || _ctx.newItem.product_uom)
            ,
                onClick: _ctx.clearInsert
              }, null, 8, ["disabled", "onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "editMode", "loading", "editingRows", "onRowEditSave", "onRowEditCancel"]),
      _createVNode(_component_ShowConfirmation, { ref: "deleteDialog" }, null, 512)
    ]),
    _: 1
  }, 8, ["visible", "header", "onUpdate:visible"]))
}