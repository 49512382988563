import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { for: "email" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { for: "password" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Password = _resolveComponent("Password")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"300px"},
    header: _ctx.t('register_user'),
    modal: true,
    class: "w-full product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancelar",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Guardar",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.registerUser
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t("email")), 1),
        _createVNode(_component_InputText, {
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && _ctx.emailV(_ctx.item.email) }]),
          modelValue: _ctx.item.email,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.email) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          autofocus: ""
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && _ctx.emailV(_ctx.item.email))
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.emailV(_ctx.item.email)), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t("password")), 1),
        _createVNode(_component_Password, {
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && _ctx.passwordV(_ctx.item.password) }]),
          inputClass: "w-full",
          modelValue: _ctx.item.password,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.password) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          toggleMask: "",
          appendTo: "body",
          feedback: false
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && _ctx.passwordV(_ctx.item.password))
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.passwordV(_ctx.item.password)), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}