
import { msg } from "@/plugins/message";
import { validate } from "@/validators";
import { defineComponent, reactive, ref } from "vue";
import { useI18n } from "vue-i18n";
import { AuthService } from "@/plugins/authService";

const authService = new AuthService();

export type INewUserForm = {
  showForm(): Promise<string>;
};

export default defineComponent({
  name: "NewUserForm",
  emits: ["close"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const open = ref(false);
    const submitted = ref(false);
    const item = reactive({
      email: "",
      password: "",
    });

    const emailV = validate(t, "email", [["required"], ["email"]]);
    const passwordV = validate(t, "password", [["required"], ["minLenght", 8]]);

    //<!-- Handlers -->
    function showForm() {
      open.value = true;
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
      item.email = "";
      item.password = "";
    }

    async function registerUser() {
      submitted.value = true;

      if (!validateForm()) {
        return;
      }

      await authService
        .register({
          email: item.email,
          password: item.password,
        })
        .then(() => {
          msg.showSuccess(t("msg_user_inserted"), t("completed"), {
            life: 6000,
          });
          hideForm();
          emit("close");
        })
        .catch((e) => {
          msg.showError(t(e.response?.data?.message || e.message));
        });
    }

    function validateForm() {
      const { email, password } = item;

      if (!emailV(email) && !passwordV(password)) {
        return true;
      }
    }

    return {
      t,
      open,
      submitted,
      hideForm,
      item,
      emailV,
      passwordV,
      showForm,
      registerUser,
    };
  },
});
