import { onError } from "@apollo/client/link/error";
import { useAuth } from "./auth";
import { msg, t } from "./message";

const { invalidateToken } = useAuth();

export const errorLink = onError(
  //{ graphQLErrors, networkError, operation, response, forward }
  ({ graphQLErrors, networkError, operation, forward }) => {
    //<!-- Handle token rejected by server -->
    if (
      graphQLErrors?.some(
        ({ extensions }) => extensions?.code === "invalid-jwt"
      )
    ) {
      invalidateToken();
      msg.showInfo("Refreshing Token and retrying");
      return forward(operation);
    }

    //<!-- Handle Network Error -->
    if (networkError) console.log(`[network error]: ${networkError}`);

    //<!-- Handle GraphqlErrors Error -->
    if (graphQLErrors) {
      //{ message, locations, path, extensions }
      graphQLErrors.map(({ message, extensions }) => {
        console.info(`[error]: Operation: ${operation.operationName}`);

        const op = operation.operationName.toLowerCase();
        if (op.includes("insert") || op.includes("update")) {
          if (message.includes("Uniqueness violation")) {
            msg.showError(t("error_element_duplicated_key"));
            return;
          }

          if (extensions?.code === "validation-failed") {
            msg.showError(t("error_validation"));
            return;
          }
        }

        if (op.includes("delete")) {
          if (message.includes("Foreign key violation")) {
            msg.showError(t("error_delete_referenced"));
            return;
          }
        }

        // Forced puntual solution
        if (operation.operationName === "DeleteOperation") {
          msg.showError(t("error_delete_referenced"));
          return;
        }

        msg.showError(message);
      });
    }
  }
);
