
import {
  IProductFragment as Product,
  useInsertProductMutation,
  useUpdateProductMutation,
  InsertProductMutationVariables,
  UpdateProductMutationVariables,
  useCategoriesQuery,
} from "@/graphql";
import CategoriesForm from "@/components/categories/CategoriesForm.vue";
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";
import { validate } from "@/validators";

type FormMode = "" | "insert" | "update";

export type IProductForm = {
  showForm(mode: FormMode, item: Product): void;
};

export default defineComponent({
  name: "ProductForm",
  components: {
    CategoriesForm,
  },
  props: {
    units: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const submitted = ref(false);
    const item = ref({} as Product);

    const { result } = useCategoriesQuery();
    const categories = computed(() => result.value?.product_category || []);

    const { mutate: insertProduct } = useInsertProductMutation({
      refetchQueries: ["Products", "SearchProduct"],
    });
    const { mutate: updateProduct } = useUpdateProductMutation({
      refetchQueries: ["Products"],
    });

    function showForm(_mode: FormMode, _item: Product) {
      open.value = true;
      mode.value = _mode;
      item.value = JSON.parse(JSON.stringify(_item));
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
    }

    const nameValidator = validate(t, "name", [
      ["required"],
      ["maxLenght", 64],
    ]);

    const skuValidator = validate(t, "sku", [["maxLenght", 12]]);

    const unitValidator = validate(t, "unit", [["required"]]);

    function validateForm() {
      const { name, sku, product_uom } = (item.value as Product) || {};

      if (nameValidator(name)) return false;
      if (skuValidator(sku)) return false;
      if (unitValidator(product_uom)) return false;

      return true;
    }

    async function saveItem() {
      submitted.value = true;

      if (validateForm()) {
        try {
          if (mode.value === "insert") {
            await insertProduct(item.value as InsertProductMutationVariables);
            msg.showInsertSuccess();
          }
          if (mode.value === "update") {
            const _item = JSON.parse(JSON.stringify(item.value));
            delete _item.unit_of_measure;
            delete _item.manufacturable;
            delete _item.category;
            delete _item.product_lot;
            delete _item.quantity_on_hand;
            delete _item.__typename;
            await updateProduct(_item as UpdateProductMutationVariables);
            msg.showUpdateSuccess();
          }
        } catch (_) {
          return;
        }

        hideForm();
      }
    }

    return {
      t,
      open,
      mode,
      submitted,
      hideForm,
      saveItem,
      item,
      showForm,
      categories,
      nameValidator,
      skuValidator,
      unitValidator,
    };
  },
});
