import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { for: "code" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = { for: "description" }
const _hoisted_6 = { class: "formgrid grid" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = { for: "expiration_date" }
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = { for: "alert_date" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Calendar = _resolveComponent("Calendar")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", {
      view: _ctx.view,
      edit: _ctx.edit
    }),
    _createVNode(_component_OverlayPanel, {
      appendTo: "body",
      ref: "panel",
      style: {"max-width":"450px"},
      class: "w-full product-form"
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.t("code")), 1),
            _createVNode(_component_InputText, {
              id: "code",
              class: "w-full",
              modelValue: _ctx.item[0].code,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item[0].code) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              autofocus: "",
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t("description")), 1),
            _createVNode(_component_InputText, {
              id: "description",
              class: "w-full",
              modelValue: _ctx.item[0].description,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item[0].description) = $event)),
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "disabled"])
          ])
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("label", _hoisted_8, _toDisplayString(_ctx.t("expiration")), 1),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.item[0].expiration_date,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item[0].expiration_date) = $event)),
              class: "w-full",
              dateFormat: "yy-mm-dd",
              monthNavigator: true,
              yearNavigator: true,
              yearRange: "2020:2040",
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.t("alert")), 1),
            _createVNode(_component_Calendar, {
              modelValue: _ctx.item[0].alert_date,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item[0].alert_date) = $event)),
              dateFormat: "yy-mm-dd",
              class: "w-full",
              monthNavigator: true,
              yearNavigator: true,
              yearRange: "2020:2040",
              disabled: _ctx.disabled
            }, null, 8, ["modelValue", "disabled"])
          ])
        ])
      ]),
      _: 1
    }, 512)
  ], 64))
}