
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import OverlayPanel from "primevue/overlaypanel/OverlayPanel.vue";
import IOverlayPanel from "primevue/overlaypanel/OverlayPanel";
import { PurchaseItem as OrderItem } from "./PurchaseForm.vue";

export type ILotForm = {
  showForm(): Promise<boolean>;
};

export default defineComponent({
  name: "LotForm",
  components: {
    OverlayPanel,
  },
  setup() {
    const panel = ref<IOverlayPanel | null>(null);
    const { t } = useI18n();
    const disabled = ref(true);
    const order = ref<OrderItem>({} as OrderItem);
    const item = ref([{} as unknown] as OrderItem["lot"]);

    function view(event: MouseEvent, item: OrderItem) {
      disabled.value = true;
      toggle(event, item);
    }

    function edit(event: MouseEvent, item: OrderItem) {
      disabled.value = false;
      toggle(event, item);
    }

    function toggle(event: MouseEvent, _item: OrderItem) {
      if (!_item.lot?.length) {
        _item.lot = [{} as unknown] as OrderItem["lot"];
      }
      order.value = _item;
      item.value = _item.lot;
      panel.value?.toggle(event);
    }

    return {
      t,
      open,
      item,
      order,
      disabled,
      view,
      edit,
      panel,
    };
  },
});
