import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid mt-2" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "field col" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 1 }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "field-checkbox" }
const _hoisted_10 = { for: "checkOption1" }
const _hoisted_11 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"500px","margin-right":"-3%"},
    header: _ctx.t('conversions'),
    modal: true,
    class: "w-full product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancelar",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Guardar",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_Button, {
            label: `${_ctx.from.description} (${_ctx.from.code})`,
            class: "p-button-link w-full",
            disabled: "",
            style: {"color":"black"},
            icon: "pi pi-arrow-right",
            iconPos: "right"
          }, null, 8, ["label"])
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_Dropdown, {
            appendTo: "body",
            modelValue: _ctx.conversion.to,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.conversion.to) = $event)),
            options: _ctx.unitsList,
            disabled: _ctx.mode === 'update',
            class: _normalizeClass(["w-full", {
            'p-invalid': _ctx.submitted && !_ctx.conversion.to_um,
          }]),
            onChange: _cache[1] || (_cache[1] = 
            ({ value }: any) => {
              _ctx.conversion.to_um = value.id;
            }
          )
          }, {
            value: _withCtx(({ value }) => [
              value
                ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                    _createElementVNode("span", null, _toDisplayString(`${value.description} (${value.code})`), 1)
                  ]))
                : (_openBlock(), _createElementBlock("span", _hoisted_5, " Select one "))
            ]),
            option: _withCtx(({ option }) => [
              _createTextVNode(_toDisplayString(`${option.description} (${option.code})`), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "options", "disabled", "class"]),
          (_ctx.submitted && !_ctx.conversion.to_um)
            ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_InputNumber, {
            inputClass: "w-full",
            mode: "decimal",
            modelValue: _ctx.conversion.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.conversion.value) = $event)),
            placeholder: _ctx.t('value'),
            minFractionDigits: 1,
            maxFractionDigits: 10,
            class: _normalizeClass({
            'p-invalid': _ctx.submitted && !_ctx.conversion.value,
          } as any)
          }, null, 8, ["modelValue", "placeholder", "class"]),
          (_ctx.submitted && !_ctx.conversion.value)
            ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("h5", null, _toDisplayString(_ctx.t("details")) + ":", 1),
      _createElementVNode("p", null, " 1 " + _toDisplayString(`${_ctx.from.description} (${_ctx.from.code})`) + " = " + _toDisplayString(_ctx.conversion.value || "? - ") + " " + _toDisplayString(_ctx.conversion.to
          ? `${_ctx.conversion.to.description} (${_ctx.conversion.to.code})`
          : "???"), 1),
      _createElementVNode("div", _hoisted_9, [
        _createVNode(_component_Checkbox, {
          id: "inverse",
          name: "inverse",
          binary: true,
          modelValue: _ctx.inverse,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.inverse) = $event))
        }, null, 8, ["modelValue"]),
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.t(_ctx.mode) + " " + _ctx.t("inverse_conversion")), 1)
      ]),
      (_ctx.inverse)
        ? (_openBlock(), _createElementBlock("p", _hoisted_11, " 1 " + _toDisplayString(_ctx.conversion.to
          ? `${_ctx.conversion.to.description} (${_ctx.conversion.to.code})`
          : "???") + " = " + _toDisplayString(1 / _ctx.conversion.value || "? - ") + " " + _toDisplayString(`${_ctx.from.description} (${_ctx.from.code})`), 1))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}