
import {
  Modules_Enum,
  PermissionsFragment,
  useEditPermissionsMutation,
  UserFragment,
} from "@/graphql";
import { accessors } from "@/router";
import { defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

export type Permissions = {
  -readonly [K in keyof PermissionsFragment]: PermissionsFragment[K];
};

export default defineComponent({
  name: "PermissionsForm",
  props: {
    user: Object as PropType<UserFragment>,
    open: Boolean,
  },
  emits: ["update:open"],
  setup(props, { emit }) {
    const { t } = useI18n();
    const permissions = ref<Permissions[]>([]);

    watch(
      () => props.open,
      (v) => {
        if (v) {
          let p: Permissions[] = JSON.parse(
            JSON.stringify(props.user?.permissions || [])
          );

          Object.values(Modules_Enum).forEach((v) => {
            const index = p.findIndex((i) => i.module_name === v);

            if (index === -1) {
              p.push({
                module_name: v as Modules_Enum,
                user_id: props.user?.id,
                can_read: false,
                can_write: false,
                can_delete: false,
              });
            }
          });

          permissions.value = p;
        } else {
          permissions.value = [];
        }
      }
    );

    //<!-- Form Data Query -->
    const { mutate, loading } = useEditPermissionsMutation({
      refetchQueries: ["Users"],
    });

    //<!-- Handlers -->
    function save() {
      permissions.value.forEach((i) => {
        delete i.__typename;
      });
      mutate({ objects: permissions.value }).then(close);
    }

    function close() {
      emit("update:open", false);
    }

    return { t, permissions, save, close, loading, accessors };
  },
});
