import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Message = _resolveComponent("Message")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    header: _ctx.t('errors'),
    visible: _ctx.open,
    "onUpdate:visible": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:open', false))),
    style: { width: '50vw' },
    modal: true
  }, {
    default: _withCtx(() => [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.mappedErrors, (e) => {
        return (_openBlock(), _createBlock(_component_Message, {
          key: e.key,
          severity: "error",
          closable: false
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(e.message), 1)
          ]),
          _: 2
        }, 1024))
      }), 128))
    ]),
    _: 1
  }, 8, ["header", "visible"]))
}