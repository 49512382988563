import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { for: "name" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "formgrid grid" }
const _hoisted_5 = { class: "field col" }
const _hoisted_6 = { for: "code" }
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = { class: "field col" }
const _hoisted_9 = { class: "field" }
const _hoisted_10 = { for: "external_id" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"450px"},
    header: _ctx.t('location'),
    modal: true,
    class: "w-full product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.t('cancel'),
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.t('save'),
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t("name")), 1),
        _createVNode(_component_InputText, {
          id: "name",
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.name }]),
          modelValue: _ctx.item.name,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          autofocus: ""
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && !_ctx.item.name)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.t("name") + _ctx.t("is_required")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", _hoisted_6, _toDisplayString(_ctx.t("code")), 1),
          _createVNode(_component_InputText, {
            id: "code",
            class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.code }]),
            modelValue: _ctx.item.code,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.code) = $event)),
            modelModifiers: { trim: true },
            required: "true"
          }, null, 8, ["modelValue", "class"]),
          (_ctx.submitted && !_ctx.item.code)
            ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.t("code") + _ctx.t("is_required")), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_8, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("type")), 1),
          _createVNode(_component_Dropdown, {
            class: "w-full",
            modelValue: _ctx.item.location_type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.location_type) = $event)),
            options: ['Internal', 'Virtual']
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.t("external_product_id")), 1),
        _createVNode(_component_InputText, {
          id: "external_id",
          class: "w-full",
          modelValue: _ctx.item.external_id,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.external_id) = $event)),
          modelModifiers: { trim: true }
        }, null, 8, ["modelValue"])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}