import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "grid w-full dashboard" }
const _hoisted_2 = { class: "col-3" }
const _hoisted_3 = { class: "text-500" }
const _hoisted_4 = { class: "text-green-500 font-medium" }
const _hoisted_5 = { class: "col-3" }
const _hoisted_6 = { class: "text-500" }
const _hoisted_7 = { class: "text-green-500 font-medium" }
const _hoisted_8 = { class: "col-3" }
const _hoisted_9 = { class: "text-500" }
const _hoisted_10 = { class: "text-green-500 font-medium" }
const _hoisted_11 = { class: "col-3" }
const _hoisted_12 = { class: "text-500" }
const _hoisted_13 = { class: "text-green-500 font-medium" }
const _hoisted_14 = { class: "col-6" }
const _hoisted_15 = { class: "task-list" }
const _hoisted_16 = { class: "task-name text-left col-4" }
const _hoisted_17 = { class: "task-name text-right col-4" }
const _hoisted_18 = { class: "text-left" }
const _hoisted_19 = { class: "task-name text-left col-4" }
const _hoisted_20 = { class: "col-6" }
const _hoisted_21 = { class: "task-list" }
const _hoisted_22 = { class: "task-name text-left col-6" }
const _hoisted_23 = { class: "task-name text-right col-6" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Kpi = _resolveComponent("Kpi")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('purchases'),
        content: (_ctx.purchaseView[0]?.purchases || 0).toString(),
        color: "blue",
        icon: "pi-shopping-bag"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.t("number_of_purchases")) + _toDisplayString(" "), 1),
          _createElementVNode("span", _hoisted_4, _toDisplayString((_ctx.purchaseView[0]?.purchases || 0).toString()), 1)
        ]),
        _: 1
      }, 8, ["title", "content"])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('cost'),
        content: _ctx.formatCurrency(_ctx.purchaseView[0]?.cost || 0),
        color: "yellow",
        icon: "pi-dollar"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.t("purchases_cost")) + _toDisplayString(" "), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(_ctx.formatCurrency(_ctx.purchaseView[0]?.cost || 0)), 1)
        ]),
        _: 1
      }, 8, ["title", "content"])
    ]),
    _createElementVNode("div", _hoisted_8, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('sales'),
        content: (_ctx.salesView[0]?.sales || 0).toString(),
        color: "purple",
        icon: "pi-shopping-cart"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_9, _toDisplayString(_ctx.t("number_of_sales")) + _toDisplayString(" "), 1),
          _createElementVNode("span", _hoisted_10, _toDisplayString((_ctx.salesView[0]?.sales || 0).toString()), 1)
        ]),
        _: 1
      }, 8, ["title", "content"])
    ]),
    _createElementVNode("div", _hoisted_11, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('revenue'),
        content: _ctx.formatCurrency(_ctx.salesView[0]?.revenue || 0),
        color: "green",
        icon: "pi-wallet"
      }, {
        footer: _withCtx(() => [
          _createElementVNode("span", _hoisted_12, _toDisplayString(_ctx.t("sales_revenue")) + _toDisplayString(" "), 1),
          _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.formatCurrency(_ctx.salesView[0]?.revenue || 0)), 1)
        ]),
        _: 1
      }, 8, ["title", "content"])
    ]),
    _createElementVNode("div", _hoisted_14, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('toexpire'),
        color: "orange",
        icon: "pi-calendar"
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_15, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prodNearToExpire, (row, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "grid",
                key: index
              }, [
                _createElementVNode("div", _hoisted_16, _toDisplayString(row.product_name), 1),
                _createElementVNode("div", _hoisted_17, [
                  _createElementVNode("div", _hoisted_18, [
                    _createElementVNode("span", null, _toDisplayString(_ctx.t("lot")), 1),
                    _createTextVNode(": " + _toDisplayString(row.lot_code), 1)
                  ])
                ]),
                _createElementVNode("div", _hoisted_19, _toDisplayString(row.days_to_expire) + " " + _toDisplayString(_ctx.t("days")), 1)
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["title"])
    ]),
    _createElementVNode("div", _hoisted_20, [
      _createVNode(_component_Kpi, {
        title: _ctx.t('products_running_out'),
        color: "orange",
        icon: "pi-exclamation-triangle"
      }, {
        default: _withCtx(() => [
          _createElementVNode("ul", _hoisted_21, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.prodRunningOut, (row, index) => {
              return (_openBlock(), _createElementBlock("li", {
                class: "grid",
                key: index
              }, [
                _createElementVNode("div", _hoisted_22, _toDisplayString(row.product_name), 1),
                _createElementVNode("div", _hoisted_23, [
                  _createElementVNode("span", null, _toDisplayString(_ctx.t("quantity")), 1),
                  _createTextVNode(": " + _toDisplayString(row.quantity && parseFloat(row.quantity.toFixed(3))), 1)
                ])
              ]))
            }), 128))
          ])
        ]),
        _: 1
      }, 8, ["title"])
    ])
  ]))
}