import { createI18n } from "vue-i18n";
import { messages } from "@/locales";
import { useStoredRef } from "@/utils/storedRef";
import { watch } from "vue";

export const locale = useStoredRef(navigator.language.split("-")[0], "locale");

export const i18n = createI18n({
  legacy: false,
  locale: locale.value,
  fallbackLocale: "en",
  messages,
});

watch(locale, () => {
  i18n.global.locale.value = locale.value;
});
