
import {
  IuConversionFragment,
  IUnitFragment,
  useDeleteConversionMutation,
  useDeleteUnitMutation,
  useUnitsQuery,
} from "@/graphql";
import UnitsForm, { IUnitsForm } from "@/components/units/UnitsForm.vue";
import ConversionForm, {
  IConversionsForm,
} from "@/components/units/ConversionForm.vue";

import ShowConfirmation, {
  IShowConfirmation,
} from "@/components/common/ShowConfirmation.vue";

import { computed, defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";
import { accessors } from "@/router";

export default defineComponent({
  components: {
    UnitsForm,
    ConversionForm,
    ShowConfirmation,
  },
  data: () => ({
    selectedKey: null,
    selectedCity: null,
  }),
  methods: {
    onSelect({ data }: { data: { id: number } }) {
      this.activeId = data.id;
    },
  },
  setup() {
    const { t } = useI18n();
    const activeId = ref(-1);
    const unitsForm = ref<IUnitsForm | null>(null);
    const conversionForm = ref<IConversionsForm | null>(null);
    const dialog = ref<IShowConfirmation | null>(null);

    //<!-- Queries -->
    const { result: unitsResult, refetch } = useUnitsQuery({});

    onMounted(refetch);

    const units = computed(() => unitsResult.value?.unit_of_measure || []);
    const categories = computed(
      () => unitsResult.value?.unit_of_measure_category || []
    );

    //<!-- Mutations -->
    const { mutate: deleteUnitMutation } = useDeleteUnitMutation({
      refetchQueries: ["Units"],
    });
    const { mutate: deleteConversionMutation } = useDeleteConversionMutation({
      refetchQueries: ["Units"],
    });

    //<!-- Handlers -->
    const items = computed(() => {
      return categories.value.map((i) => {
        const children = units.value
          .filter((u) => u.category.id === i.id)
          .map((u) => ({
            key: u.id,
            label: `${u.description} (${u.code})`,
            type: "unit",
            styleClass: "unit-box",
            icon: "pi pi-fw pi-circle-on",
            data: u,
          }));
        return {
          key: "cat-" + i.id,
          label: t(i.value),
          style: "text-transform: uppercase;font-weight: bold;",
          data: i,
          selectable: false,
          children,
        };
      });
    });

    const conversions = computed(
      () =>
        units.value
          .find((i) => i.id === activeId.value)
          ?.conversions.map((i) => ({
            ...i,
            title: `${i.to.description} (${i.to.code})`,
          })) || []
    );

    function insertUnit() {
      unitsForm.value?.showForm("insert");
    }

    function editUnit(item: IUnitFragment) {
      unitsForm.value?.showForm("update", item);
    }

    async function deleteUnit(item: IUnitFragment) {
      const ok = await dialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!ok) return;

      await deleteUnitMutation({ id: item.id }).catch();
      msg.showDeleteSuccess();
    }

    function insertConversion() {
      conversionForm.value?.showForm("insert");
    }

    function editConversion(item: IuConversionFragment) {
      conversionForm.value?.showForm("update", item);
    }

    async function deleteConversion(item: IUnitFragment["conversions"][0]) {
      const ok = await dialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!ok) return;

      deleteConversionMutation({
        from_um: item.from_um,
        to_um: item.to_um,
      })
        .then(() => msg.showDeleteSuccess())
        .catch();
    }

    return {
      t,
      items,
      categories,
      activeId,
      dialog,
      units,
      unitsForm,
      conversionForm,
      conversions,
      insertUnit,
      insertConversion,
      editUnit,
      editConversion,
      deleteUnit,
      deleteConversion,
      accessors,
    };
  },
});
