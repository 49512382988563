
import { locale } from "@/plugins/i18n";
import { defineComponent } from "vue";
import ProfilePanel from "@/components/user/ProfilePanel.vue";

export default defineComponent({
  components: { ProfilePanel },
  methods: {
    onMenuToggle(event: Event) {
      this.$emit("menu-toggle", event);
    },
  },
  setup() {
    function switchLocale() {
      if (locale.value === "es") {
        locale.value = "en";
      } else {
        locale.value = "es";
      }
    }

    return {
      locale,
      switchLocale,
    };
  },
});
