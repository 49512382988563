
import { ExecutionErrorsFragment } from "@/graphql";
import { msg } from "@/plugins/message";
import { computed, defineComponent, PropType } from "vue";
import { useI18n } from "vue-i18n";

interface OrderError {
  id: number;
  message: string;
  order_id: number;
  product_id: number;
  product_uom: string;
  product_name: string;
  missing_quantity: number;
  order_item_id: number;
  quantity_on_hand: number;
  reference_order_id: number;
  reference_order_item_id: number;
  requred_quantity_on_product_uom: number;
}

export function buildOrderErrors(
  errors: ExecutionErrorsFragment[],
  long = false
) {
  return errors.map((i) => {
    const data: OrderError = i.errors && JSON.parse(i.errors)?.error;

    return {
      key: i.id,
      message:
        (long || !data.product_name ? msg.t(i.message) : "") +
        (!data.missing_quantity
          ? " "
          : ` - ${msg.t("missing")} ${data.product_name}: ${parseFloat(
              data.missing_quantity.toFixed(3)
            )} (${data.product_uom})`),
    };
  });
}

export default defineComponent({
  name: "ErrorDialog",
  props: {
    open: Boolean,
    errors: {
      type: Array as PropType<ExecutionErrorsFragment[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();

    const mappedErrors = computed(() => buildOrderErrors(props.errors, true));

    return { t, mappedErrors };
  },
});
