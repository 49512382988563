
import { defineComponent, onMounted, ref, watch } from "vue";
import { useAuth } from "@/plugins/auth";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { ROUTES } from "@/router";
import { useFindUserByEmailQuery } from "@/graphql";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const expanded = ref(false);
    const { user, logout } = useAuth();
    const router = useRouter();

    //<!-- Queries -->
    const { result, refetch } = useFindUserByEmailQuery(
      { email: user.value?.email || "" },
      { notifyOnNetworkStatusChange: true }
    );

    watch(result, () => {
      const a = result.value?.users || [];
      if (!user.value?.email && a.length !== 1) {
        return null;
      } else if (user.value) {
        user.value.userData = a[0];
      }
    });

    onMounted(
      () => user.value?.email && refetch({ email: user.value?.email })?.catch()
    );

    function onClick(event: Event) {
      expanded.value = !expanded.value;
      event.preventDefault();
    }

    function handleLogout() {
      logout();
      router.push(ROUTES.Login);
    }

    return { t, expanded, onClick, user, handleLogout };
  },
});
