import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Toast = _resolveComponent("Toast")!
  const _component_ConfirmDialog = _resolveComponent("ConfirmDialog")!
  const _component_AppLayout = _resolveComponent("AppLayout")!

  return (_openBlock(), _createBlock(_component_AppLayout, null, {
    default: _withCtx(() => [
      _createVNode(_component_router_view),
      _createVNode(_component_Toast, { baseZIndex: 90000 }),
      _createVNode(_component_ConfirmDialog)
    ]),
    _: 1
  }))
}