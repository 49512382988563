import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withCtx as _withCtx, createBlock as _createBlock, withDirectives as _withDirectives, vShow as _vShow, createVNode as _createVNode, Transition as _Transition } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 0,
  class: "arrow"
}
const _hoisted_3 = {
  key: 0,
  class: "pi pi-fw pi-angle-down menuitem-toggle-icon"
}
const _hoisted_4 = {
  key: 1,
  class: "menuitem-badge"
}
const _hoisted_5 = ["href", "onClick", "target"]
const _hoisted_6 = {
  key: 0,
  class: "pi pi-fw pi-angle-down menuitem-toggle-icon"
}
const _hoisted_7 = {
  key: 1,
  class: "menuitem-badge"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_appsubmenu = _resolveComponent("appsubmenu")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_ctx.items)
    ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, i) => {
          return (_openBlock(), _createElementBlock(_Fragment, null, [
            (_ctx.visible(item) && !item.separator)
              ? (_openBlock(), _createElementBlock("li", {
                  key: i,
                  class: _normalizeClass([
          {
            'active-menuitem': _ctx.activeIndex === i && !item.to && !item.disabled,
          },
        ]),
                  role: "none"
                }, [
                  (item.items && _ctx.root === true)
                    ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                    : _createCommentVNode("", true),
                  (item.to)
                    ? _withDirectives((_openBlock(), _createBlock(_component_router_link, {
                        key: 1,
                        to: item.to,
                        class: _normalizeClass([
            item.class,
            'p-ripple',
            { 'active-route': _ctx.activeIndex === i, 'p-disabled': item.disabled },
          ]),
                        style: _normalizeStyle(item.style),
                        onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                        target: item.target,
                        exact: "",
                        role: "menuitem"
                      }, {
                        default: _withCtx(() => [
                          _createElementVNode("i", {
                            class: _normalizeClass(item.icon)
                          }, null, 2),
                          _createElementVNode("span", null, _toDisplayString(item.label), 1),
                          (item.items)
                            ? (_openBlock(), _createElementBlock("i", _hoisted_3))
                            : _createCommentVNode("", true),
                          (item.badge)
                            ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(item.badge), 1))
                            : _createCommentVNode("", true)
                        ]),
                        _: 2
                      }, 1032, ["to", "class", "style", "onClick", "target"])), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  (!item.to)
                    ? _withDirectives((_openBlock(), _createElementBlock("a", {
                        key: 2,
                        href: item.url || '#',
                        style: _normalizeStyle(item.style),
                        class: _normalizeClass([item.class, 'p-ripple', { 'p-disabled': item.disabled }]),
                        onClick: ($event: any) => (_ctx.onMenuItemClick($event, item, i)),
                        target: item.target,
                        role: "menuitem"
                      }, [
                        _createElementVNode("i", {
                          class: _normalizeClass(item.icon)
                        }, null, 2),
                        _createElementVNode("span", null, _toDisplayString(item.label), 1),
                        (item.items)
                          ? (_openBlock(), _createElementBlock("i", _hoisted_6))
                          : _createCommentVNode("", true),
                        (item.badge)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(item.badge), 1))
                          : _createCommentVNode("", true)
                      ], 14, _hoisted_5)), [
                        [_directive_ripple]
                      ])
                    : _createCommentVNode("", true),
                  _createVNode(_Transition, { name: "layout-submenu-wrapper" }, {
                    default: _withCtx(() => [
                      _withDirectives(_createVNode(_component_appsubmenu, {
                        items: _ctx.visible(item) && item.items,
                        onMenuitemClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('menuitem-click', $event)))
                      }, null, 8, ["items"]), [
                        [_vShow, _ctx.activeIndex === i]
                      ])
                    ]),
                    _: 2
                  }, 1024)
                ], 2))
              : _createCommentVNode("", true),
            (_ctx.visible(item) && item.separator)
              ? (_openBlock(), _createElementBlock("li", {
                  class: "p-menu-separator",
                  style: _normalizeStyle(item.style),
                  key: 'separator' + i,
                  role: "separator"
                }, null, 4))
              : _createCommentVNode("", true)
          ], 64))
        }), 256))
      ]))
    : _createCommentVNode("", true)
}