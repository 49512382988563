
import AppSubmenu from "./AppSubmenu.vue";
import { accessors, ROUTES } from "@/router";
import { useI18n } from "vue-i18n";
import { computed, defineComponent } from "vue";
import { Modules_Enum } from "@/graphql";

export default defineComponent({
  methods: {
    onMenuItemClick(event: Event): void {
      this.$emit("menuitem-click", event);
    },
  },
  setup() {
    const { t } = useI18n();

    const menu = computed(() =>
      [
        { label: t("dashboard"), icon: "pi pi-fw pi-home", to: "/" },
        {
          label: t("products"),
          icon: "pi pi-fw pi-tags",
          to: ROUTES.Products,
          accessor: Modules_Enum.Products,
        },
        {
          label: t("orders"),
          icon: "pi pi-fw pi-shopping-cart",
          items: [
            {
              label: t("purchases"),
              icon: "pi pi-fw pi-shopping-bag",
              to: ROUTES.OrdersBuy,
              accessor: Modules_Enum.PurchaseOrder,
            },
            {
              label: t("transfers"),
              icon: "pi pi-fw pi-sort-alt",
              to: ROUTES.OrdersTransfer,
              accessor: Modules_Enum.TransferOrder,
            },
            {
              label: t("manufactory"),
              icon: "pi pi-fw pi-box",
              to: ROUTES.OrdersManufactory,
              accessor: Modules_Enum.ManufacturingOrder,
            },
            {
              label: t("sales"),
              icon: "pi pi-fw pi-shopping-cart",
              to: ROUTES.OrdersSell,
              accessor: Modules_Enum.SalesOrder,
            },
          ],
        },
        {
          label: t("reports"),
          icon: "pi pi-fw pi-list",
          items: [
            {
              label: t("ipv"),
              icon: "pi pi-fw pi-book",
              to: ROUTES.ReportIPV,
              accessor: Modules_Enum.IpvReport,
            },
            {
              label: t("existence"),
              icon: "pi pi-fw pi-database",
              to: ROUTES.ReportExistence,
              accessor: Modules_Enum.ProductExistenceReport,
            },
            {
              label: t("lot"),
              icon: "pi pi-fw pi-table",
              to: ROUTES.ReportLot,
              accessor: Modules_Enum.LotProductExistenceReport,
            },
            {
              label: t("toexpire"),
              icon: "pi pi-fw pi-calendar",
              to: ROUTES.ReportProductNearToExpire,
              accessor: Modules_Enum.ProductNearToExpireReport,
            },
            {
              label: t("products_running_out"),
              icon: "pi pi-fw pi-exclamation-triangle",
              to: ROUTES.ReportProductsRunningOut,
              accessor: Modules_Enum.ProductMinQuantityAlertReport,
            },
            {
              label: t("profit"),
              icon: "pi pi-fw pi-dollar",
              to: ROUTES.Profit,
              accessor: Modules_Enum.ProfitReport,
            },
          ],
        },
        {
          label: t("settings"),
          icon: "pi pi-fw pi-cog",
          items: [
            {
              label: t("operations"),
              icon: "pi pi-fw pi-briefcase",
              to: ROUTES.Operations,
              accessor: Modules_Enum.Operation,
            },
            {
              label: t("locations"),
              icon: "pi pi-fw pi-globe",
              to: ROUTES.Locations,
              accessor: Modules_Enum.Location,
            },
            {
              label: t("partners"),
              icon: "pi pi-fw pi-users",
              to: ROUTES.Partners,
              accessor: Modules_Enum.Partner,
            },
            {
              label: t("measures"),
              icon: "pi pi-fw pi-th-large",
              to: ROUTES.Units,
              accessor: Modules_Enum.UnitOfMeasure,
            },
            {
              label: t("users"),
              icon: "pi pi-fw pi-user",
              to: ROUTES.Users,
              accessor: Modules_Enum.Users,
            },
          ],
        },
      ]
        .map((i) => {
          if (!i.items) {
            return i;
          }

          const childs = i.items.filter((c) => {
            return !c.accessor || accessors.value[c.accessor]?.canRead;
          });

          return {
            ...i,
            items: childs,
          };
        })
        .filter((i) => {
          if (i.items && !i.items.length) return false;
          if (i.accessor && !accessors.value[i.accessor]?.canRead) return false;
          return true;
        })
    );

    return {
      menu,
    };
  },
  components: {
    AppSubmenu,
  },
});
