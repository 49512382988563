import gql from 'graphql-tag';
import * as VueApolloComposable from '@vue/apollo-composable';
import * as VueCompositionApi from 'vue';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type ReactiveFunction<TParam> = () => TParam;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** expression to compare columns of type Boolean. All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']>;
  _gt?: InputMaybe<Scalars['Boolean']>;
  _gte?: InputMaybe<Scalars['Boolean']>;
  _in?: InputMaybe<Array<Scalars['Boolean']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Boolean']>;
  _lte?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Scalars['Boolean']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** expression to compare columns of type Int. All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']>;
  _gt?: InputMaybe<Scalars['Int']>;
  _gte?: InputMaybe<Scalars['Int']>;
  _in?: InputMaybe<Array<Scalars['Int']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['Int']>;
  _lte?: InputMaybe<Scalars['Int']>;
  _neq?: InputMaybe<Scalars['Int']>;
  _nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** expression to compare columns of type String. All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']>;
  _gt?: InputMaybe<Scalars['String']>;
  _gte?: InputMaybe<Scalars['String']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['String']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['String']>;
  _lte?: InputMaybe<Scalars['String']>;
  _neq?: InputMaybe<Scalars['String']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['String']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "auth.account_providers" */
export type Auth_Account_Providers = {
  __typename?: 'auth_account_providers';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Auth_Providers;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate = {
  __typename?: 'auth_account_providers_aggregate';
  aggregate?: Maybe<Auth_Account_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Account_Providers>;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Fields = {
  __typename?: 'auth_account_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Providers_Max_Fields>;
  min?: Maybe<Auth_Account_Providers_Min_Fields>;
};


/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Account_Providers_Max_Order_By>;
  min?: InputMaybe<Auth_Account_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type Auth_Account_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Account_Providers_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Account_Providers_Bool_Exp>>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  auth_provider?: InputMaybe<String_Comparison_Exp>;
  auth_provider_unique_id?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  provider?: InputMaybe<Auth_Providers_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum Auth_Account_Providers_Constraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type Auth_Account_Providers_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Scalars['String']>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  provider?: InputMaybe<Auth_Providers_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Account_Providers_Max_Fields = {
  __typename?: 'auth_account_providers_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Providers_Min_Fields = {
  __typename?: 'auth_account_providers_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.account_providers" */
export type Auth_Account_Providers_Mutation_Response = {
  __typename?: 'auth_account_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Providers>;
};

/** input type for inserting object relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Account_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.account_providers" */
export type Auth_Account_Providers_On_Conflict = {
  constraint: Auth_Account_Providers_Constraint;
  update_columns: Array<Auth_Account_Providers_Update_Column>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_providers" */
export type Auth_Account_Providers_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  auth_provider?: InputMaybe<Order_By>;
  auth_provider_unique_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  provider?: InputMaybe<Auth_Providers_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "auth.account_providers" */
export type Auth_Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type Auth_Account_Providers_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  auth_provider?: InputMaybe<Scalars['String']>;
  auth_provider_unique_id?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type Auth_Account_Roles = {
  __typename?: 'auth_account_roles';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: Auth_Roles;
};

/** aggregated selection of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate = {
  __typename?: 'auth_account_roles_aggregate';
  aggregate?: Maybe<Auth_Account_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Account_Roles>;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Fields = {
  __typename?: 'auth_account_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Account_Roles_Max_Fields>;
  min?: Maybe<Auth_Account_Roles_Min_Fields>;
};


/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Account_Roles_Max_Order_By>;
  min?: InputMaybe<Auth_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type Auth_Account_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Account_Roles_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Account_Roles_Bool_Exp>>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
  roleByRole?: InputMaybe<Auth_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type Auth_Account_Roles_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
  roleByRole?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Account_Roles_Max_Fields = {
  __typename?: 'auth_account_roles_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Roles_Min_Fields = {
  __typename?: 'auth_account_roles_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.account_roles" */
export type Auth_Account_Roles_Mutation_Response = {
  __typename?: 'auth_account_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Account_Roles>;
};

/** input type for inserting object relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Account_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.account_roles" */
export type Auth_Account_Roles_On_Conflict = {
  constraint: Auth_Account_Roles_Constraint;
  update_columns: Array<Auth_Account_Roles_Update_Column>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.account_roles" */
export type Auth_Account_Roles_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  role?: InputMaybe<Order_By>;
  roleByRole?: InputMaybe<Auth_Roles_Order_By>;
};

/** primary key columns input for table: "auth.account_roles" */
export type Auth_Account_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type Auth_Account_Roles_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['uuid']>;
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type Auth_Accounts = {
  __typename?: 'auth_accounts';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  active: Scalars['Boolean'];
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregated array relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsCustom_Register_DataArgs = {
  path?: InputMaybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.accounts" */
export type Auth_Accounts_Aggregate = {
  __typename?: 'auth_accounts_aggregate';
  aggregate?: Maybe<Auth_Accounts_Aggregate_Fields>;
  nodes: Array<Auth_Accounts>;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_Fields = {
  __typename?: 'auth_accounts_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Accounts_Max_Fields>;
  min?: Maybe<Auth_Accounts_Min_Fields>;
};


/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type Auth_Accounts_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Accounts_Max_Order_By>;
  min?: InputMaybe<Auth_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Append_Input = {
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type Auth_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type Auth_Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Accounts_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Accounts_Bool_Exp>>>;
  account_providers?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  custom_register_data?: InputMaybe<Jsonb_Comparison_Exp>;
  default_role?: InputMaybe<String_Comparison_Exp>;
  email?: InputMaybe<Citext_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  is_anonymous?: InputMaybe<Boolean_Comparison_Exp>;
  mfa_enabled?: InputMaybe<Boolean_Comparison_Exp>;
  new_email?: InputMaybe<Citext_Comparison_Exp>;
  otp_secret?: InputMaybe<String_Comparison_Exp>;
  password_hash?: InputMaybe<String_Comparison_Exp>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  role?: InputMaybe<Auth_Roles_Bool_Exp>;
  ticket?: InputMaybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Accounts_Delete_At_Path_Input = {
  custom_register_data?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Accounts_Delete_Elem_Input = {
  custom_register_data?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Accounts_Delete_Key_Input = {
  custom_register_data?: InputMaybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type Auth_Accounts_Insert_Input = {
  account_providers?: InputMaybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  new_email?: InputMaybe<Scalars['citext']>;
  otp_secret?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  refresh_tokens?: InputMaybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: InputMaybe<Scalars['uuid']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Accounts_Max_Fields = {
  __typename?: 'auth_accounts_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type Auth_Accounts_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Accounts_Min_Fields = {
  __typename?: 'auth_accounts_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type Auth_Accounts_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.accounts" */
export type Auth_Accounts_Mutation_Response = {
  __typename?: 'auth_accounts_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Accounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type Auth_Accounts_Obj_Rel_Insert_Input = {
  data: Auth_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type Auth_Accounts_On_Conflict = {
  constraint: Auth_Accounts_Constraint;
  update_columns: Array<Auth_Accounts_Update_Column>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

/** ordering options when selecting data from "auth.accounts" */
export type Auth_Accounts_Order_By = {
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Order_By>;
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  custom_register_data?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_anonymous?: InputMaybe<Order_By>;
  mfa_enabled?: InputMaybe<Order_By>;
  new_email?: InputMaybe<Order_By>;
  otp_secret?: InputMaybe<Order_By>;
  password_hash?: InputMaybe<Order_By>;
  refresh_tokens_aggregate?: InputMaybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: InputMaybe<Auth_Roles_Order_By>;
  ticket?: InputMaybe<Order_By>;
  ticket_expires_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "auth.accounts" */
export type Auth_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Prepend_Input = {
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type Auth_Accounts_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  custom_register_data?: InputMaybe<Scalars['jsonb']>;
  default_role?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['citext']>;
  id?: InputMaybe<Scalars['uuid']>;
  is_anonymous?: InputMaybe<Scalars['Boolean']>;
  mfa_enabled?: InputMaybe<Scalars['Boolean']>;
  new_email?: InputMaybe<Scalars['citext']>;
  otp_secret?: InputMaybe<Scalars['String']>;
  password_hash?: InputMaybe<Scalars['String']>;
  ticket?: InputMaybe<Scalars['uuid']>;
  ticket_expires_at?: InputMaybe<Scalars['timestamptz']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.providers" */
export type Auth_Providers = {
  __typename?: 'auth_providers';
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregated array relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  provider: Scalars['String'];
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type Auth_Providers_Aggregate = {
  __typename?: 'auth_providers_aggregate';
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_Fields = {
  __typename?: 'auth_providers_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.providers" */
export type Auth_Providers_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Providers_Max_Order_By>;
  min?: InputMaybe<Auth_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.providers" */
export type Auth_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Providers_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Providers_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Providers_Bool_Exp>>>;
  account_providers?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
  provider?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type Auth_Providers_Insert_Input = {
  account_providers?: InputMaybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  provider?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  __typename?: 'auth_providers_max_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.providers" */
export type Auth_Providers_Max_Order_By = {
  provider?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  __typename?: 'auth_providers_min_fields';
  provider?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.providers" */
export type Auth_Providers_Min_Order_By = {
  provider?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.providers" */
export type Auth_Providers_Mutation_Response = {
  __typename?: 'auth_providers_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns: Array<Auth_Providers_Update_Column>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};

/** ordering options when selecting data from "auth.providers" */
export type Auth_Providers_Order_By = {
  account_providers_aggregate?: InputMaybe<Auth_Account_Providers_Aggregate_Order_By>;
  provider?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "auth.providers" */
export type Auth_Providers_Pk_Columns_Input = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type Auth_Providers_Set_Input = {
  provider?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens = {
  __typename?: 'auth_refresh_tokens';
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  __typename?: 'auth_refresh_tokens_aggregate';
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  __typename?: 'auth_refresh_tokens_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: InputMaybe<Auth_Refresh_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Refresh_Tokens_Bool_Exp>>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  account_id?: InputMaybe<Uuid_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  refresh_token?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  __typename?: 'auth_refresh_tokens_max_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  __typename?: 'auth_refresh_tokens_min_fields';
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  __typename?: 'auth_refresh_tokens_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** input type for inserting object relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Obj_Rel_Insert_Input = {
  data: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** ordering options when selecting data from "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  refresh_token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  account_id?: InputMaybe<Scalars['uuid']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expires_at?: InputMaybe<Scalars['timestamptz']>;
  refresh_token?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type Auth_Roles = {
  __typename?: 'auth_roles';
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregated array relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** An array relationship */
  accounts: Array<Auth_Accounts>;
  /** An aggregated array relationship */
  accounts_aggregate: Auth_Accounts_Aggregate;
  role: Scalars['String'];
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type Auth_Roles_Aggregate = {
  __typename?: 'auth_roles_aggregate';
  aggregate?: Maybe<Auth_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Roles>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_Fields = {
  __typename?: 'auth_roles_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Auth_Roles_Max_Fields>;
  min?: Maybe<Auth_Roles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.roles" */
export type Auth_Roles_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Auth_Roles_Max_Order_By>;
  min?: InputMaybe<Auth_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.roles" */
export type Auth_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type Auth_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Auth_Roles_Bool_Exp>>>;
  _not?: InputMaybe<Auth_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Auth_Roles_Bool_Exp>>>;
  account_roles?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
  accounts?: InputMaybe<Auth_Accounts_Bool_Exp>;
  role?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type Auth_Roles_Insert_Input = {
  account_roles?: InputMaybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  role?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Roles_Max_Fields = {
  __typename?: 'auth_roles_max_fields';
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.roles" */
export type Auth_Roles_Max_Order_By = {
  role?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Roles_Min_Fields = {
  __typename?: 'auth_roles_min_fields';
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.roles" */
export type Auth_Roles_Min_Order_By = {
  role?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "auth.roles" */
export type Auth_Roles_Mutation_Response = {
  __typename?: 'auth_roles_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Auth_Roles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type Auth_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.roles" */
export type Auth_Roles_On_Conflict = {
  constraint: Auth_Roles_Constraint;
  update_columns: Array<Auth_Roles_Update_Column>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};

/** ordering options when selecting data from "auth.roles" */
export type Auth_Roles_Order_By = {
  account_roles_aggregate?: InputMaybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Auth_Accounts_Aggregate_Order_By>;
  role?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "auth.roles" */
export type Auth_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type Auth_Roles_Set_Input = {
  role?: InputMaybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Role = 'role'
}

/** expression to compare columns of type bigint. All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']>;
  _gt?: InputMaybe<Scalars['bigint']>;
  _gte?: InputMaybe<Scalars['bigint']>;
  _in?: InputMaybe<Array<Scalars['bigint']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['bigint']>;
  _lte?: InputMaybe<Scalars['bigint']>;
  _neq?: InputMaybe<Scalars['bigint']>;
  _nin?: InputMaybe<Array<Scalars['bigint']>>;
};

/** expression to compare columns of type citext. All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['citext']>;
  _gt?: InputMaybe<Scalars['citext']>;
  _gte?: InputMaybe<Scalars['citext']>;
  _ilike?: InputMaybe<Scalars['String']>;
  _in?: InputMaybe<Array<Scalars['citext']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _like?: InputMaybe<Scalars['String']>;
  _lt?: InputMaybe<Scalars['citext']>;
  _lte?: InputMaybe<Scalars['citext']>;
  _neq?: InputMaybe<Scalars['citext']>;
  _nilike?: InputMaybe<Scalars['String']>;
  _nin?: InputMaybe<Array<Scalars['citext']>>;
  _nlike?: InputMaybe<Scalars['String']>;
  _nsimilar?: InputMaybe<Scalars['String']>;
  _similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "daily_existence_report" */
export type Daily_Existence_Report = {
  __typename?: 'daily_existence_report';
  date_time?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  location_name: Scalars['String'];
  lot_code?: Maybe<Scalars['String']>;
  manufacturable?: Maybe<Scalars['Boolean']>;
  product_code?: Maybe<Scalars['String']>;
  product_id: Scalars['Int'];
  product_name: Scalars['String'];
  purchasable?: Maybe<Scalars['Boolean']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  sellable?: Maybe<Scalars['Boolean']>;
  storable?: Maybe<Scalars['Boolean']>;
  unit_of_measure: Scalars['String'];
  unit_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "daily_existence_report" */
export type Daily_Existence_Report_Aggregate = {
  __typename?: 'daily_existence_report_aggregate';
  aggregate?: Maybe<Daily_Existence_Report_Aggregate_Fields>;
  nodes: Array<Daily_Existence_Report>;
};

/** aggregate fields of "daily_existence_report" */
export type Daily_Existence_Report_Aggregate_Fields = {
  __typename?: 'daily_existence_report_aggregate_fields';
  avg?: Maybe<Daily_Existence_Report_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Daily_Existence_Report_Max_Fields>;
  min?: Maybe<Daily_Existence_Report_Min_Fields>;
  stddev?: Maybe<Daily_Existence_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Daily_Existence_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Daily_Existence_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Daily_Existence_Report_Sum_Fields>;
  var_pop?: Maybe<Daily_Existence_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Daily_Existence_Report_Var_Samp_Fields>;
  variance?: Maybe<Daily_Existence_Report_Variance_Fields>;
};


/** aggregate fields of "daily_existence_report" */
export type Daily_Existence_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "daily_existence_report" */
export type Daily_Existence_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Daily_Existence_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Daily_Existence_Report_Max_Order_By>;
  min?: InputMaybe<Daily_Existence_Report_Min_Order_By>;
  stddev?: InputMaybe<Daily_Existence_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Daily_Existence_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Daily_Existence_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Daily_Existence_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Daily_Existence_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Daily_Existence_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Daily_Existence_Report_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "daily_existence_report" */
export type Daily_Existence_Report_Arr_Rel_Insert_Input = {
  data: Array<Daily_Existence_Report_Insert_Input>;
  on_conflict?: InputMaybe<Daily_Existence_Report_On_Conflict>;
};

/** aggregate avg on columns */
export type Daily_Existence_Report_Avg_Fields = {
  __typename?: 'daily_existence_report_avg_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "daily_existence_report". All fields are combined with a logical 'AND'. */
export type Daily_Existence_Report_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Daily_Existence_Report_Bool_Exp>>>;
  _not?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Daily_Existence_Report_Bool_Exp>>>;
  date_time?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  lot_code?: InputMaybe<String_Comparison_Exp>;
  manufacturable?: InputMaybe<Boolean_Comparison_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  purchasable?: InputMaybe<Boolean_Comparison_Exp>;
  quantity_on_hand?: InputMaybe<Numeric_Comparison_Exp>;
  sellable?: InputMaybe<Boolean_Comparison_Exp>;
  storable?: InputMaybe<Boolean_Comparison_Exp>;
  unit_of_measure?: InputMaybe<String_Comparison_Exp>;
  unit_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "daily_existence_report" */
export enum Daily_Existence_Report_Constraint {
  /** unique or primary key constraint */
  DailyExistenceReportPkey = 'daily_existence_report_pkey'
}

/** input type for incrementing integer column in table "daily_existence_report" */
export type Daily_Existence_Report_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  quantity_on_hand?: InputMaybe<Scalars['numeric']>;
  unit_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "daily_existence_report" */
export type Daily_Existence_Report_Insert_Input = {
  date_time?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  lot_code?: InputMaybe<Scalars['String']>;
  manufacturable?: InputMaybe<Scalars['Boolean']>;
  product_code?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_name?: InputMaybe<Scalars['String']>;
  purchasable?: InputMaybe<Scalars['Boolean']>;
  quantity_on_hand?: InputMaybe<Scalars['numeric']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  storable?: InputMaybe<Scalars['Boolean']>;
  unit_of_measure?: InputMaybe<Scalars['String']>;
  unit_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Daily_Existence_Report_Max_Fields = {
  __typename?: 'daily_existence_report_max_fields';
  date_time?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Max_Order_By = {
  date_time?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Daily_Existence_Report_Min_Fields = {
  __typename?: 'daily_existence_report_min_fields';
  date_time?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Min_Order_By = {
  date_time?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "daily_existence_report" */
export type Daily_Existence_Report_Mutation_Response = {
  __typename?: 'daily_existence_report_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Daily_Existence_Report>;
};

/** input type for inserting object relation for remote table "daily_existence_report" */
export type Daily_Existence_Report_Obj_Rel_Insert_Input = {
  data: Daily_Existence_Report_Insert_Input;
  on_conflict?: InputMaybe<Daily_Existence_Report_On_Conflict>;
};

/** on conflict condition type for table "daily_existence_report" */
export type Daily_Existence_Report_On_Conflict = {
  constraint: Daily_Existence_Report_Constraint;
  update_columns: Array<Daily_Existence_Report_Update_Column>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};

/** ordering options when selecting data from "daily_existence_report" */
export type Daily_Existence_Report_Order_By = {
  date_time?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  manufacturable?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  purchasable?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  sellable?: InputMaybe<Order_By>;
  storable?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "daily_existence_report" */
export type Daily_Existence_Report_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "daily_existence_report" */
export enum Daily_Existence_Report_Select_Column {
  /** column name */
  DateTime = 'date_time',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  LotCode = 'lot_code',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  QuantityOnHand = 'quantity_on_hand',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Storable = 'storable',
  /** column name */
  UnitOfMeasure = 'unit_of_measure',
  /** column name */
  UnitPrice = 'unit_price'
}

/** input type for updating data in table "daily_existence_report" */
export type Daily_Existence_Report_Set_Input = {
  date_time?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  location_name?: InputMaybe<Scalars['String']>;
  lot_code?: InputMaybe<Scalars['String']>;
  manufacturable?: InputMaybe<Scalars['Boolean']>;
  product_code?: InputMaybe<Scalars['String']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_name?: InputMaybe<Scalars['String']>;
  purchasable?: InputMaybe<Scalars['Boolean']>;
  quantity_on_hand?: InputMaybe<Scalars['numeric']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  storable?: InputMaybe<Scalars['Boolean']>;
  unit_of_measure?: InputMaybe<Scalars['String']>;
  unit_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Daily_Existence_Report_Stddev_Fields = {
  __typename?: 'daily_existence_report_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Daily_Existence_Report_Stddev_Pop_Fields = {
  __typename?: 'daily_existence_report_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Daily_Existence_Report_Stddev_Samp_Fields = {
  __typename?: 'daily_existence_report_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Daily_Existence_Report_Sum_Fields = {
  __typename?: 'daily_existence_report_sum_fields';
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** update columns of table "daily_existence_report" */
export enum Daily_Existence_Report_Update_Column {
  /** column name */
  DateTime = 'date_time',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  LotCode = 'lot_code',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  QuantityOnHand = 'quantity_on_hand',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Storable = 'storable',
  /** column name */
  UnitOfMeasure = 'unit_of_measure',
  /** column name */
  UnitPrice = 'unit_price'
}

/** aggregate var_pop on columns */
export type Daily_Existence_Report_Var_Pop_Fields = {
  __typename?: 'daily_existence_report_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Daily_Existence_Report_Var_Samp_Fields = {
  __typename?: 'daily_existence_report_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Daily_Existence_Report_Variance_Fields = {
  __typename?: 'daily_existence_report_variance_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "daily_existence_report" */
export type Daily_Existence_Report_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** expression to compare columns of type date. All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']>;
  _gt?: InputMaybe<Scalars['date']>;
  _gte?: InputMaybe<Scalars['date']>;
  _in?: InputMaybe<Array<Scalars['date']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['date']>;
  _lte?: InputMaybe<Scalars['date']>;
  _neq?: InputMaybe<Scalars['date']>;
  _nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "import_sales_order" */
export type Import_Sales_Order = {
  __typename?: 'import_sales_order';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  imported_order: Scalars['jsonb'];
  order_id: Scalars['bigint'];
};


/** columns and relationships of "import_sales_order" */
export type Import_Sales_OrderImported_OrderArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "import_sales_order" */
export type Import_Sales_Order_Aggregate = {
  __typename?: 'import_sales_order_aggregate';
  aggregate?: Maybe<Import_Sales_Order_Aggregate_Fields>;
  nodes: Array<Import_Sales_Order>;
};

/** aggregate fields of "import_sales_order" */
export type Import_Sales_Order_Aggregate_Fields = {
  __typename?: 'import_sales_order_aggregate_fields';
  avg?: Maybe<Import_Sales_Order_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Import_Sales_Order_Max_Fields>;
  min?: Maybe<Import_Sales_Order_Min_Fields>;
  stddev?: Maybe<Import_Sales_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Import_Sales_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Import_Sales_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Import_Sales_Order_Sum_Fields>;
  var_pop?: Maybe<Import_Sales_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Import_Sales_Order_Var_Samp_Fields>;
  variance?: Maybe<Import_Sales_Order_Variance_Fields>;
};


/** aggregate fields of "import_sales_order" */
export type Import_Sales_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Import_Sales_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "import_sales_order" */
export type Import_Sales_Order_Aggregate_Order_By = {
  avg?: InputMaybe<Import_Sales_Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Import_Sales_Order_Max_Order_By>;
  min?: InputMaybe<Import_Sales_Order_Min_Order_By>;
  stddev?: InputMaybe<Import_Sales_Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Import_Sales_Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Import_Sales_Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Import_Sales_Order_Sum_Order_By>;
  var_pop?: InputMaybe<Import_Sales_Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Import_Sales_Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Import_Sales_Order_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Import_Sales_Order_Append_Input = {
  imported_order?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "import_sales_order" */
export type Import_Sales_Order_Arr_Rel_Insert_Input = {
  data: Array<Import_Sales_Order_Insert_Input>;
  on_conflict?: InputMaybe<Import_Sales_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Import_Sales_Order_Avg_Fields = {
  __typename?: 'import_sales_order_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "import_sales_order" */
export type Import_Sales_Order_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "import_sales_order". All fields are combined with a logical 'AND'. */
export type Import_Sales_Order_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Import_Sales_Order_Bool_Exp>>>;
  _not?: InputMaybe<Import_Sales_Order_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Import_Sales_Order_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  imported_order?: InputMaybe<Jsonb_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "import_sales_order" */
export enum Import_Sales_Order_Constraint {
  /** unique or primary key constraint */
  ImportSalesOrderPkey = 'import_sales_order_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Import_Sales_Order_Delete_At_Path_Input = {
  imported_order?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Import_Sales_Order_Delete_Elem_Input = {
  imported_order?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Import_Sales_Order_Delete_Key_Input = {
  imported_order?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "import_sales_order" */
export type Import_Sales_Order_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "import_sales_order" */
export type Import_Sales_Order_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imported_order?: InputMaybe<Scalars['jsonb']>;
  order_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Import_Sales_Order_Max_Fields = {
  __typename?: 'import_sales_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "import_sales_order" */
export type Import_Sales_Order_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Import_Sales_Order_Min_Fields = {
  __typename?: 'import_sales_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "import_sales_order" */
export type Import_Sales_Order_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "import_sales_order" */
export type Import_Sales_Order_Mutation_Response = {
  __typename?: 'import_sales_order_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Import_Sales_Order>;
};

/** input type for inserting object relation for remote table "import_sales_order" */
export type Import_Sales_Order_Obj_Rel_Insert_Input = {
  data: Import_Sales_Order_Insert_Input;
  on_conflict?: InputMaybe<Import_Sales_Order_On_Conflict>;
};

/** on conflict condition type for table "import_sales_order" */
export type Import_Sales_Order_On_Conflict = {
  constraint: Import_Sales_Order_Constraint;
  update_columns: Array<Import_Sales_Order_Update_Column>;
  where?: InputMaybe<Import_Sales_Order_Bool_Exp>;
};

/** ordering options when selecting data from "import_sales_order" */
export type Import_Sales_Order_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  imported_order?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "import_sales_order" */
export type Import_Sales_Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Import_Sales_Order_Prepend_Input = {
  imported_order?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "import_sales_order" */
export enum Import_Sales_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedOrder = 'imported_order',
  /** column name */
  OrderId = 'order_id'
}

/** input type for updating data in table "import_sales_order" */
export type Import_Sales_Order_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  id?: InputMaybe<Scalars['Int']>;
  imported_order?: InputMaybe<Scalars['jsonb']>;
  order_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Import_Sales_Order_Stddev_Fields = {
  __typename?: 'import_sales_order_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "import_sales_order" */
export type Import_Sales_Order_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Import_Sales_Order_Stddev_Pop_Fields = {
  __typename?: 'import_sales_order_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "import_sales_order" */
export type Import_Sales_Order_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Import_Sales_Order_Stddev_Samp_Fields = {
  __typename?: 'import_sales_order_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "import_sales_order" */
export type Import_Sales_Order_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Import_Sales_Order_Sum_Fields = {
  __typename?: 'import_sales_order_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "import_sales_order" */
export type Import_Sales_Order_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** update columns of table "import_sales_order" */
export enum Import_Sales_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ImportedOrder = 'imported_order',
  /** column name */
  OrderId = 'order_id'
}

/** aggregate var_pop on columns */
export type Import_Sales_Order_Var_Pop_Fields = {
  __typename?: 'import_sales_order_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "import_sales_order" */
export type Import_Sales_Order_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Import_Sales_Order_Var_Samp_Fields = {
  __typename?: 'import_sales_order_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "import_sales_order" */
export type Import_Sales_Order_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Import_Sales_Order_Variance_Fields = {
  __typename?: 'import_sales_order_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "import_sales_order" */
export type Import_Sales_Order_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
};

/** expression to compare columns of type jsonb. All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']>;
  _eq?: InputMaybe<Scalars['jsonb']>;
  _gt?: InputMaybe<Scalars['jsonb']>;
  _gte?: InputMaybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['jsonb']>;
  _lte?: InputMaybe<Scalars['jsonb']>;
  _neq?: InputMaybe<Scalars['jsonb']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "modules" */
export type Modules = {
  __typename?: 'modules';
  module_description: Scalars['String'];
  module_name: Scalars['String'];
};

/** aggregated selection of "modules" */
export type Modules_Aggregate = {
  __typename?: 'modules_aggregate';
  aggregate?: Maybe<Modules_Aggregate_Fields>;
  nodes: Array<Modules>;
};

/** aggregate fields of "modules" */
export type Modules_Aggregate_Fields = {
  __typename?: 'modules_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Modules_Max_Fields>;
  min?: Maybe<Modules_Min_Fields>;
};


/** aggregate fields of "modules" */
export type Modules_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Modules_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "modules" */
export type Modules_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Modules_Max_Order_By>;
  min?: InputMaybe<Modules_Min_Order_By>;
};

/** input type for inserting array relation for remote table "modules" */
export type Modules_Arr_Rel_Insert_Input = {
  data: Array<Modules_Insert_Input>;
  on_conflict?: InputMaybe<Modules_On_Conflict>;
};

/** Boolean expression to filter rows from the table "modules". All fields are combined with a logical 'AND'. */
export type Modules_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Modules_Bool_Exp>>>;
  _not?: InputMaybe<Modules_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Modules_Bool_Exp>>>;
  module_description?: InputMaybe<String_Comparison_Exp>;
  module_name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "modules" */
export enum Modules_Constraint {
  /** unique or primary key constraint */
  ModulesPkey = 'modules_pkey'
}

export enum Modules_Enum {
  IpvReport = 'ipv_report',
  Location = 'location',
  LotProductExistenceReport = 'lot_product_existence_report',
  ManufacturingOrder = 'manufacturing_order',
  Operation = 'operation',
  Partner = 'partner',
  ProductExistenceReport = 'product_existence_report',
  ProductMinQuantityAlertReport = 'product_min_quantity_alert_report',
  ProductNearToExpireReport = 'product_near_to_expire_report',
  Products = 'products',
  ProfitReport = 'profit_report',
  PurchaseOrder = 'purchase_order',
  SalesOrder = 'sales_order',
  TransferOrder = 'transfer_order',
  UnitOfMeasure = 'unit_of_measure',
  Users = 'users'
}

/** expression to compare columns of type modules_enum. All fields are combined with logical 'AND'. */
export type Modules_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Modules_Enum>;
  _in?: InputMaybe<Array<Modules_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Modules_Enum>;
  _nin?: InputMaybe<Array<Modules_Enum>>;
};

/** input type for inserting data into table "modules" */
export type Modules_Insert_Input = {
  module_description?: InputMaybe<Scalars['String']>;
  module_name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Modules_Max_Fields = {
  __typename?: 'modules_max_fields';
  module_description?: Maybe<Scalars['String']>;
  module_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "modules" */
export type Modules_Max_Order_By = {
  module_description?: InputMaybe<Order_By>;
  module_name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Modules_Min_Fields = {
  __typename?: 'modules_min_fields';
  module_description?: Maybe<Scalars['String']>;
  module_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "modules" */
export type Modules_Min_Order_By = {
  module_description?: InputMaybe<Order_By>;
  module_name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "modules" */
export type Modules_Mutation_Response = {
  __typename?: 'modules_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Modules>;
};

/** input type for inserting object relation for remote table "modules" */
export type Modules_Obj_Rel_Insert_Input = {
  data: Modules_Insert_Input;
  on_conflict?: InputMaybe<Modules_On_Conflict>;
};

/** on conflict condition type for table "modules" */
export type Modules_On_Conflict = {
  constraint: Modules_Constraint;
  update_columns: Array<Modules_Update_Column>;
  where?: InputMaybe<Modules_Bool_Exp>;
};

/** ordering options when selecting data from "modules" */
export type Modules_Order_By = {
  module_description?: InputMaybe<Order_By>;
  module_name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "modules" */
export type Modules_Pk_Columns_Input = {
  module_name: Scalars['String'];
};

/** select columns of table "modules" */
export enum Modules_Select_Column {
  /** column name */
  ModuleDescription = 'module_description',
  /** column name */
  ModuleName = 'module_name'
}

/** input type for updating data in table "modules" */
export type Modules_Set_Input = {
  module_description?: InputMaybe<Scalars['String']>;
  module_name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "modules" */
export enum Modules_Update_Column {
  /** column name */
  ModuleDescription = 'module_description',
  /** column name */
  ModuleName = 'module_name'
}

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** delete data from the table: "daily_existence_report" */
  delete_daily_existence_report?: Maybe<Daily_Existence_Report_Mutation_Response>;
  /** delete single row from the table: "daily_existence_report" */
  delete_daily_existence_report_by_pk?: Maybe<Daily_Existence_Report>;
  /** delete data from the table: "import_sales_order" */
  delete_import_sales_order?: Maybe<Import_Sales_Order_Mutation_Response>;
  /** delete single row from the table: "import_sales_order" */
  delete_import_sales_order_by_pk?: Maybe<Import_Sales_Order>;
  /** delete data from the table: "modules" */
  delete_modules?: Maybe<Modules_Mutation_Response>;
  /** delete single row from the table: "modules" */
  delete_modules_by_pk?: Maybe<Modules>;
  /** delete data from the table: "opb_profit_report_template" */
  delete_opb_profit_report_template?: Maybe<Opb_Profit_Report_Template_Mutation_Response>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete data from the table: "product_bill_of_material_items" */
  delete_product_bill_of_material_items?: Maybe<Product_Bill_Of_Material_Items_Mutation_Response>;
  /** delete single row from the table: "product_bill_of_material_items" */
  delete_product_bill_of_material_items_by_pk?: Maybe<Product_Bill_Of_Material_Items>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_category" */
  delete_product_category?: Maybe<Product_Category_Mutation_Response>;
  /** delete single row from the table: "product_category" */
  delete_product_category_by_pk?: Maybe<Product_Category>;
  /** delete data from the table: "quantity_on_hand" */
  delete_quantity_on_hand?: Maybe<Quantity_On_Hand_Mutation_Response>;
  /** delete data from the table: "stock_deliver_status" */
  delete_stock_deliver_status?: Maybe<Stock_Deliver_Status_Mutation_Response>;
  /** delete single row from the table: "stock_deliver_status" */
  delete_stock_deliver_status_by_pk?: Maybe<Stock_Deliver_Status>;
  /** delete data from the table: "stock_location" */
  delete_stock_location?: Maybe<Stock_Location_Mutation_Response>;
  /** delete single row from the table: "stock_location" */
  delete_stock_location_by_pk?: Maybe<Stock_Location>;
  /** delete data from the table: "stock_location_type" */
  delete_stock_location_type?: Maybe<Stock_Location_Type_Mutation_Response>;
  /** delete single row from the table: "stock_location_type" */
  delete_stock_location_type_by_pk?: Maybe<Stock_Location_Type>;
  /** delete data from the table: "stock_manufacturing_order_item" */
  delete_stock_manufacturing_order_item?: Maybe<Stock_Manufacturing_Order_Item_Mutation_Response>;
  /** delete single row from the table: "stock_manufacturing_order_item" */
  delete_stock_manufacturing_order_item_by_pk?: Maybe<Stock_Manufacturing_Order_Item>;
  /** delete data from the table: "stock_operation" */
  delete_stock_operation?: Maybe<Stock_Operation_Mutation_Response>;
  /** delete single row from the table: "stock_operation" */
  delete_stock_operation_by_pk?: Maybe<Stock_Operation>;
  /** delete data from the table: "stock_operation_type" */
  delete_stock_operation_type?: Maybe<Stock_Operation_Type_Mutation_Response>;
  /** delete single row from the table: "stock_operation_type" */
  delete_stock_operation_type_by_pk?: Maybe<Stock_Operation_Type>;
  /** delete data from the table: "stock_order" */
  delete_stock_order?: Maybe<Stock_Order_Mutation_Response>;
  /** delete single row from the table: "stock_order" */
  delete_stock_order_by_pk?: Maybe<Stock_Order>;
  /** delete data from the table: "stock_order_execution_errors" */
  delete_stock_order_execution_errors?: Maybe<Stock_Order_Execution_Errors_Mutation_Response>;
  /** delete single row from the table: "stock_order_execution_errors" */
  delete_stock_order_execution_errors_by_pk?: Maybe<Stock_Order_Execution_Errors>;
  /** delete data from the table: "stock_order_status" */
  delete_stock_order_status?: Maybe<Stock_Order_Status_Mutation_Response>;
  /** delete single row from the table: "stock_order_status" */
  delete_stock_order_status_by_pk?: Maybe<Stock_Order_Status>;
  /** delete data from the table: "stock_order_type" */
  delete_stock_order_type?: Maybe<Stock_Order_Type_Mutation_Response>;
  /** delete single row from the table: "stock_order_type" */
  delete_stock_order_type_by_pk?: Maybe<Stock_Order_Type>;
  /** delete data from the table: "stock_partner" */
  delete_stock_partner?: Maybe<Stock_Partner_Mutation_Response>;
  /** delete single row from the table: "stock_partner" */
  delete_stock_partner_by_pk?: Maybe<Stock_Partner>;
  /** delete data from the table: "stock_production_lot" */
  delete_stock_production_lot?: Maybe<Stock_Production_Lot_Mutation_Response>;
  /** delete single row from the table: "stock_production_lot" */
  delete_stock_production_lot_by_pk?: Maybe<Stock_Production_Lot>;
  /** delete data from the table: "stock_purchase_order_item" */
  delete_stock_purchase_order_item?: Maybe<Stock_Purchase_Order_Item_Mutation_Response>;
  /** delete single row from the table: "stock_purchase_order_item" */
  delete_stock_purchase_order_item_by_pk?: Maybe<Stock_Purchase_Order_Item>;
  /** delete data from the table: "stock_quantity" */
  delete_stock_quantity?: Maybe<Stock_Quantity_Mutation_Response>;
  /** delete single row from the table: "stock_quantity" */
  delete_stock_quantity_by_pk?: Maybe<Stock_Quantity>;
  /** delete data from the table: "stock_receipts_order_item" */
  delete_stock_receipts_order_item?: Maybe<Stock_Receipts_Order_Item_Mutation_Response>;
  /** delete single row from the table: "stock_receipts_order_item" */
  delete_stock_receipts_order_item_by_pk?: Maybe<Stock_Receipts_Order_Item>;
  /** delete data from the table: "stock_sales_order_item" */
  delete_stock_sales_order_item?: Maybe<Stock_Sales_Order_Item_Mutation_Response>;
  /** delete single row from the table: "stock_sales_order_item" */
  delete_stock_sales_order_item_by_pk?: Maybe<Stock_Sales_Order_Item>;
  /** delete data from the table: "stock_transfer_order_item" */
  delete_stock_transfer_order_item?: Maybe<Stock_Transfer_Order_Item_Mutation_Response>;
  /** delete single row from the table: "stock_transfer_order_item" */
  delete_stock_transfer_order_item_by_pk?: Maybe<Stock_Transfer_Order_Item>;
  /** delete data from the table: "unit_of_measure" */
  delete_unit_of_measure?: Maybe<Unit_Of_Measure_Mutation_Response>;
  /** delete single row from the table: "unit_of_measure" */
  delete_unit_of_measure_by_pk?: Maybe<Unit_Of_Measure>;
  /** delete data from the table: "unit_of_measure_category" */
  delete_unit_of_measure_category?: Maybe<Unit_Of_Measure_Category_Mutation_Response>;
  /** delete single row from the table: "unit_of_measure_category" */
  delete_unit_of_measure_category_by_pk?: Maybe<Unit_Of_Measure_Category>;
  /** delete data from the table: "unit_of_measure_conversion" */
  delete_unit_of_measure_conversion?: Maybe<Unit_Of_Measure_Conversion_Mutation_Response>;
  /** delete single row from the table: "unit_of_measure_conversion" */
  delete_unit_of_measure_conversion_by_pk?: Maybe<Unit_Of_Measure_Conversion>;
  /** delete data from the table: "user_permission" */
  delete_user_permission?: Maybe<User_Permission_Mutation_Response>;
  /** delete single row from the table: "user_permission" */
  delete_user_permission_by_pk?: Maybe<User_Permission>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<Auth_Account_Providers>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<Auth_Account_Roles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<Auth_Accounts>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<Auth_Roles>;
  /** insert data into the table: "daily_existence_report" */
  insert_daily_existence_report?: Maybe<Daily_Existence_Report_Mutation_Response>;
  /** insert a single row into the table: "daily_existence_report" */
  insert_daily_existence_report_one?: Maybe<Daily_Existence_Report>;
  /** insert data into the table: "import_sales_order" */
  insert_import_sales_order?: Maybe<Import_Sales_Order_Mutation_Response>;
  /** insert a single row into the table: "import_sales_order" */
  insert_import_sales_order_one?: Maybe<Import_Sales_Order>;
  /** insert data into the table: "modules" */
  insert_modules?: Maybe<Modules_Mutation_Response>;
  /** insert a single row into the table: "modules" */
  insert_modules_one?: Maybe<Modules>;
  /** insert data into the table: "opb_profit_report_template" */
  insert_opb_profit_report_template?: Maybe<Opb_Profit_Report_Template_Mutation_Response>;
  /** insert a single row into the table: "opb_profit_report_template" */
  insert_opb_profit_report_template_one?: Maybe<Opb_Profit_Report_Template>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert data into the table: "product_bill_of_material_items" */
  insert_product_bill_of_material_items?: Maybe<Product_Bill_Of_Material_Items_Mutation_Response>;
  /** insert a single row into the table: "product_bill_of_material_items" */
  insert_product_bill_of_material_items_one?: Maybe<Product_Bill_Of_Material_Items>;
  /** insert data into the table: "product_category" */
  insert_product_category?: Maybe<Product_Category_Mutation_Response>;
  /** insert a single row into the table: "product_category" */
  insert_product_category_one?: Maybe<Product_Category>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "quantity_on_hand" */
  insert_quantity_on_hand?: Maybe<Quantity_On_Hand_Mutation_Response>;
  /** insert a single row into the table: "quantity_on_hand" */
  insert_quantity_on_hand_one?: Maybe<Quantity_On_Hand>;
  /** insert data into the table: "stock_deliver_status" */
  insert_stock_deliver_status?: Maybe<Stock_Deliver_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_deliver_status" */
  insert_stock_deliver_status_one?: Maybe<Stock_Deliver_Status>;
  /** insert data into the table: "stock_location" */
  insert_stock_location?: Maybe<Stock_Location_Mutation_Response>;
  /** insert a single row into the table: "stock_location" */
  insert_stock_location_one?: Maybe<Stock_Location>;
  /** insert data into the table: "stock_location_type" */
  insert_stock_location_type?: Maybe<Stock_Location_Type_Mutation_Response>;
  /** insert a single row into the table: "stock_location_type" */
  insert_stock_location_type_one?: Maybe<Stock_Location_Type>;
  /** insert data into the table: "stock_manufacturing_order_item" */
  insert_stock_manufacturing_order_item?: Maybe<Stock_Manufacturing_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "stock_manufacturing_order_item" */
  insert_stock_manufacturing_order_item_one?: Maybe<Stock_Manufacturing_Order_Item>;
  /** insert data into the table: "stock_operation" */
  insert_stock_operation?: Maybe<Stock_Operation_Mutation_Response>;
  /** insert a single row into the table: "stock_operation" */
  insert_stock_operation_one?: Maybe<Stock_Operation>;
  /** insert data into the table: "stock_operation_type" */
  insert_stock_operation_type?: Maybe<Stock_Operation_Type_Mutation_Response>;
  /** insert a single row into the table: "stock_operation_type" */
  insert_stock_operation_type_one?: Maybe<Stock_Operation_Type>;
  /** insert data into the table: "stock_order" */
  insert_stock_order?: Maybe<Stock_Order_Mutation_Response>;
  /** insert data into the table: "stock_order_execution_errors" */
  insert_stock_order_execution_errors?: Maybe<Stock_Order_Execution_Errors_Mutation_Response>;
  /** insert a single row into the table: "stock_order_execution_errors" */
  insert_stock_order_execution_errors_one?: Maybe<Stock_Order_Execution_Errors>;
  /** insert a single row into the table: "stock_order" */
  insert_stock_order_one?: Maybe<Stock_Order>;
  /** insert data into the table: "stock_order_status" */
  insert_stock_order_status?: Maybe<Stock_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_order_status" */
  insert_stock_order_status_one?: Maybe<Stock_Order_Status>;
  /** insert data into the table: "stock_order_type" */
  insert_stock_order_type?: Maybe<Stock_Order_Type_Mutation_Response>;
  /** insert a single row into the table: "stock_order_type" */
  insert_stock_order_type_one?: Maybe<Stock_Order_Type>;
  /** insert data into the table: "stock_partner" */
  insert_stock_partner?: Maybe<Stock_Partner_Mutation_Response>;
  /** insert a single row into the table: "stock_partner" */
  insert_stock_partner_one?: Maybe<Stock_Partner>;
  /** insert data into the table: "stock_production_lot" */
  insert_stock_production_lot?: Maybe<Stock_Production_Lot_Mutation_Response>;
  /** insert a single row into the table: "stock_production_lot" */
  insert_stock_production_lot_one?: Maybe<Stock_Production_Lot>;
  /** insert data into the table: "stock_purchase_order_item" */
  insert_stock_purchase_order_item?: Maybe<Stock_Purchase_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "stock_purchase_order_item" */
  insert_stock_purchase_order_item_one?: Maybe<Stock_Purchase_Order_Item>;
  /** insert data into the table: "stock_quantity" */
  insert_stock_quantity?: Maybe<Stock_Quantity_Mutation_Response>;
  /** insert a single row into the table: "stock_quantity" */
  insert_stock_quantity_one?: Maybe<Stock_Quantity>;
  /** insert data into the table: "stock_receipts_order_item" */
  insert_stock_receipts_order_item?: Maybe<Stock_Receipts_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "stock_receipts_order_item" */
  insert_stock_receipts_order_item_one?: Maybe<Stock_Receipts_Order_Item>;
  /** insert data into the table: "stock_sales_order_item" */
  insert_stock_sales_order_item?: Maybe<Stock_Sales_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "stock_sales_order_item" */
  insert_stock_sales_order_item_one?: Maybe<Stock_Sales_Order_Item>;
  /** insert data into the table: "stock_transfer_order_item" */
  insert_stock_transfer_order_item?: Maybe<Stock_Transfer_Order_Item_Mutation_Response>;
  /** insert a single row into the table: "stock_transfer_order_item" */
  insert_stock_transfer_order_item_one?: Maybe<Stock_Transfer_Order_Item>;
  /** insert data into the table: "unit_of_measure" */
  insert_unit_of_measure?: Maybe<Unit_Of_Measure_Mutation_Response>;
  /** insert data into the table: "unit_of_measure_category" */
  insert_unit_of_measure_category?: Maybe<Unit_Of_Measure_Category_Mutation_Response>;
  /** insert a single row into the table: "unit_of_measure_category" */
  insert_unit_of_measure_category_one?: Maybe<Unit_Of_Measure_Category>;
  /** insert data into the table: "unit_of_measure_conversion" */
  insert_unit_of_measure_conversion?: Maybe<Unit_Of_Measure_Conversion_Mutation_Response>;
  /** insert a single row into the table: "unit_of_measure_conversion" */
  insert_unit_of_measure_conversion_one?: Maybe<Unit_Of_Measure_Conversion>;
  /** insert a single row into the table: "unit_of_measure" */
  insert_unit_of_measure_one?: Maybe<Unit_Of_Measure>;
  /** insert data into the table: "user_permission" */
  insert_user_permission?: Maybe<User_Permission_Mutation_Response>;
  /** insert a single row into the table: "user_permission" */
  insert_user_permission_one?: Maybe<User_Permission>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** update data of the table: "daily_existence_report" */
  update_daily_existence_report?: Maybe<Daily_Existence_Report_Mutation_Response>;
  /** update single row of the table: "daily_existence_report" */
  update_daily_existence_report_by_pk?: Maybe<Daily_Existence_Report>;
  /** update data of the table: "import_sales_order" */
  update_import_sales_order?: Maybe<Import_Sales_Order_Mutation_Response>;
  /** update single row of the table: "import_sales_order" */
  update_import_sales_order_by_pk?: Maybe<Import_Sales_Order>;
  /** update data of the table: "modules" */
  update_modules?: Maybe<Modules_Mutation_Response>;
  /** update single row of the table: "modules" */
  update_modules_by_pk?: Maybe<Modules>;
  /** update data of the table: "opb_profit_report_template" */
  update_opb_profit_report_template?: Maybe<Opb_Profit_Report_Template_Mutation_Response>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update data of the table: "product_bill_of_material_items" */
  update_product_bill_of_material_items?: Maybe<Product_Bill_Of_Material_Items_Mutation_Response>;
  /** update single row of the table: "product_bill_of_material_items" */
  update_product_bill_of_material_items_by_pk?: Maybe<Product_Bill_Of_Material_Items>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update data of the table: "product_category" */
  update_product_category?: Maybe<Product_Category_Mutation_Response>;
  /** update single row of the table: "product_category" */
  update_product_category_by_pk?: Maybe<Product_Category>;
  /** update data of the table: "quantity_on_hand" */
  update_quantity_on_hand?: Maybe<Quantity_On_Hand_Mutation_Response>;
  /** update data of the table: "stock_deliver_status" */
  update_stock_deliver_status?: Maybe<Stock_Deliver_Status_Mutation_Response>;
  /** update single row of the table: "stock_deliver_status" */
  update_stock_deliver_status_by_pk?: Maybe<Stock_Deliver_Status>;
  /** update data of the table: "stock_location" */
  update_stock_location?: Maybe<Stock_Location_Mutation_Response>;
  /** update single row of the table: "stock_location" */
  update_stock_location_by_pk?: Maybe<Stock_Location>;
  /** update data of the table: "stock_location_type" */
  update_stock_location_type?: Maybe<Stock_Location_Type_Mutation_Response>;
  /** update single row of the table: "stock_location_type" */
  update_stock_location_type_by_pk?: Maybe<Stock_Location_Type>;
  /** update data of the table: "stock_manufacturing_order_item" */
  update_stock_manufacturing_order_item?: Maybe<Stock_Manufacturing_Order_Item_Mutation_Response>;
  /** update single row of the table: "stock_manufacturing_order_item" */
  update_stock_manufacturing_order_item_by_pk?: Maybe<Stock_Manufacturing_Order_Item>;
  /** update data of the table: "stock_operation" */
  update_stock_operation?: Maybe<Stock_Operation_Mutation_Response>;
  /** update single row of the table: "stock_operation" */
  update_stock_operation_by_pk?: Maybe<Stock_Operation>;
  /** update data of the table: "stock_operation_type" */
  update_stock_operation_type?: Maybe<Stock_Operation_Type_Mutation_Response>;
  /** update single row of the table: "stock_operation_type" */
  update_stock_operation_type_by_pk?: Maybe<Stock_Operation_Type>;
  /** update data of the table: "stock_order" */
  update_stock_order?: Maybe<Stock_Order_Mutation_Response>;
  /** update single row of the table: "stock_order" */
  update_stock_order_by_pk?: Maybe<Stock_Order>;
  /** update data of the table: "stock_order_execution_errors" */
  update_stock_order_execution_errors?: Maybe<Stock_Order_Execution_Errors_Mutation_Response>;
  /** update single row of the table: "stock_order_execution_errors" */
  update_stock_order_execution_errors_by_pk?: Maybe<Stock_Order_Execution_Errors>;
  /** update data of the table: "stock_order_status" */
  update_stock_order_status?: Maybe<Stock_Order_Status_Mutation_Response>;
  /** update single row of the table: "stock_order_status" */
  update_stock_order_status_by_pk?: Maybe<Stock_Order_Status>;
  /** update data of the table: "stock_order_type" */
  update_stock_order_type?: Maybe<Stock_Order_Type_Mutation_Response>;
  /** update single row of the table: "stock_order_type" */
  update_stock_order_type_by_pk?: Maybe<Stock_Order_Type>;
  /** update data of the table: "stock_partner" */
  update_stock_partner?: Maybe<Stock_Partner_Mutation_Response>;
  /** update single row of the table: "stock_partner" */
  update_stock_partner_by_pk?: Maybe<Stock_Partner>;
  /** update data of the table: "stock_production_lot" */
  update_stock_production_lot?: Maybe<Stock_Production_Lot_Mutation_Response>;
  /** update single row of the table: "stock_production_lot" */
  update_stock_production_lot_by_pk?: Maybe<Stock_Production_Lot>;
  /** update data of the table: "stock_purchase_order_item" */
  update_stock_purchase_order_item?: Maybe<Stock_Purchase_Order_Item_Mutation_Response>;
  /** update single row of the table: "stock_purchase_order_item" */
  update_stock_purchase_order_item_by_pk?: Maybe<Stock_Purchase_Order_Item>;
  /** update data of the table: "stock_quantity" */
  update_stock_quantity?: Maybe<Stock_Quantity_Mutation_Response>;
  /** update single row of the table: "stock_quantity" */
  update_stock_quantity_by_pk?: Maybe<Stock_Quantity>;
  /** update data of the table: "stock_receipts_order_item" */
  update_stock_receipts_order_item?: Maybe<Stock_Receipts_Order_Item_Mutation_Response>;
  /** update single row of the table: "stock_receipts_order_item" */
  update_stock_receipts_order_item_by_pk?: Maybe<Stock_Receipts_Order_Item>;
  /** update data of the table: "stock_sales_order_item" */
  update_stock_sales_order_item?: Maybe<Stock_Sales_Order_Item_Mutation_Response>;
  /** update single row of the table: "stock_sales_order_item" */
  update_stock_sales_order_item_by_pk?: Maybe<Stock_Sales_Order_Item>;
  /** update data of the table: "stock_transfer_order_item" */
  update_stock_transfer_order_item?: Maybe<Stock_Transfer_Order_Item_Mutation_Response>;
  /** update single row of the table: "stock_transfer_order_item" */
  update_stock_transfer_order_item_by_pk?: Maybe<Stock_Transfer_Order_Item>;
  /** update data of the table: "unit_of_measure" */
  update_unit_of_measure?: Maybe<Unit_Of_Measure_Mutation_Response>;
  /** update single row of the table: "unit_of_measure" */
  update_unit_of_measure_by_pk?: Maybe<Unit_Of_Measure>;
  /** update data of the table: "unit_of_measure_category" */
  update_unit_of_measure_category?: Maybe<Unit_Of_Measure_Category_Mutation_Response>;
  /** update single row of the table: "unit_of_measure_category" */
  update_unit_of_measure_category_by_pk?: Maybe<Unit_Of_Measure_Category>;
  /** update data of the table: "unit_of_measure_conversion" */
  update_unit_of_measure_conversion?: Maybe<Unit_Of_Measure_Conversion_Mutation_Response>;
  /** update single row of the table: "unit_of_measure_conversion" */
  update_unit_of_measure_conversion_by_pk?: Maybe<Unit_Of_Measure_Conversion>;
  /** update data of the table: "user_permission" */
  update_user_permission?: Maybe<User_Permission_Mutation_Response>;
  /** update single row of the table: "user_permission" */
  update_user_permission_by_pk?: Maybe<User_Permission>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_ProvidersArgs = {
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_RolesArgs = {
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_AccountsArgs = {
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_RolesArgs = {
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Daily_Existence_ReportArgs = {
  where: Daily_Existence_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Daily_Existence_Report_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Import_Sales_OrderArgs = {
  where: Import_Sales_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Import_Sales_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ModulesArgs = {
  where: Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Modules_By_PkArgs = {
  module_name: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Opb_Profit_Report_TemplateArgs = {
  where: Opb_Profit_Report_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Bill_Of_Material_ItemsArgs = {
  where: Product_Bill_Of_Material_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Bill_Of_Material_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Product_CategoryArgs = {
  where: Product_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Quantity_On_HandArgs = {
  where: Quantity_On_Hand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Deliver_StatusArgs = {
  where: Stock_Deliver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Deliver_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_LocationArgs = {
  where: Stock_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Location_TypeArgs = {
  where: Stock_Location_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Location_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Manufacturing_Order_ItemArgs = {
  where: Stock_Manufacturing_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Manufacturing_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_OperationArgs = {
  where: Stock_Operation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Operation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Operation_TypeArgs = {
  where: Stock_Operation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Operation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_OrderArgs = {
  where: Stock_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_Execution_ErrorsArgs = {
  where: Stock_Order_Execution_Errors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_Execution_Errors_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_StatusArgs = {
  where: Stock_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_TypeArgs = {
  where: Stock_Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Order_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_PartnerArgs = {
  where: Stock_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Partner_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Production_LotArgs = {
  where: Stock_Production_Lot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Production_Lot_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Purchase_Order_ItemArgs = {
  where: Stock_Purchase_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Purchase_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_QuantityArgs = {
  where: Stock_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Quantity_By_PkArgs = {
  location_id: Scalars['Int'];
  lot_id: Scalars['Int'];
  product_id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Receipts_Order_ItemArgs = {
  where: Stock_Receipts_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Receipts_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Sales_Order_ItemArgs = {
  where: Stock_Sales_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Sales_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Transfer_Order_ItemArgs = {
  where: Stock_Transfer_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Transfer_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_MeasureArgs = {
  where: Unit_Of_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_Measure_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_Measure_CategoryArgs = {
  where: Unit_Of_Measure_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_Measure_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_Measure_ConversionArgs = {
  where: Unit_Of_Measure_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Unit_Of_Measure_Conversion_By_PkArgs = {
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_User_PermissionArgs = {
  where: User_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Permission_By_PkArgs = {
  module_name: Modules_Enum;
  user_id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_ProvidersArgs = {
  objects: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Providers_OneArgs = {
  object: Auth_Account_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_RolesArgs = {
  objects: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Roles_OneArgs = {
  object: Auth_Account_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_AccountsArgs = {
  objects: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Accounts_OneArgs = {
  object: Auth_Accounts_Insert_Input;
  on_conflict?: InputMaybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: InputMaybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_RolesArgs = {
  objects: Array<Auth_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Roles_OneArgs = {
  object: Auth_Roles_Insert_Input;
  on_conflict?: InputMaybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Existence_ReportArgs = {
  objects: Array<Daily_Existence_Report_Insert_Input>;
  on_conflict?: InputMaybe<Daily_Existence_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Daily_Existence_Report_OneArgs = {
  object: Daily_Existence_Report_Insert_Input;
  on_conflict?: InputMaybe<Daily_Existence_Report_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Import_Sales_OrderArgs = {
  objects: Array<Import_Sales_Order_Insert_Input>;
  on_conflict?: InputMaybe<Import_Sales_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Import_Sales_Order_OneArgs = {
  object: Import_Sales_Order_Insert_Input;
  on_conflict?: InputMaybe<Import_Sales_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ModulesArgs = {
  objects: Array<Modules_Insert_Input>;
  on_conflict?: InputMaybe<Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Modules_OneArgs = {
  object: Modules_Insert_Input;
  on_conflict?: InputMaybe<Modules_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Opb_Profit_Report_TemplateArgs = {
  objects: Array<Opb_Profit_Report_Template_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Opb_Profit_Report_Template_OneArgs = {
  object: Opb_Profit_Report_Template_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Bill_Of_Material_ItemsArgs = {
  objects: Array<Product_Bill_Of_Material_Items_Insert_Input>;
  on_conflict?: InputMaybe<Product_Bill_Of_Material_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Bill_Of_Material_Items_OneArgs = {
  object: Product_Bill_Of_Material_Items_Insert_Input;
  on_conflict?: InputMaybe<Product_Bill_Of_Material_Items_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_CategoryArgs = {
  objects: Array<Product_Category_Insert_Input>;
  on_conflict?: InputMaybe<Product_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Category_OneArgs = {
  object: Product_Category_Insert_Input;
  on_conflict?: InputMaybe<Product_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Quantity_On_HandArgs = {
  objects: Array<Quantity_On_Hand_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Quantity_On_Hand_OneArgs = {
  object: Quantity_On_Hand_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Deliver_StatusArgs = {
  objects: Array<Stock_Deliver_Status_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Deliver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Deliver_Status_OneArgs = {
  object: Stock_Deliver_Status_Insert_Input;
  on_conflict?: InputMaybe<Stock_Deliver_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_LocationArgs = {
  objects: Array<Stock_Location_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Location_OneArgs = {
  object: Stock_Location_Insert_Input;
  on_conflict?: InputMaybe<Stock_Location_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Location_TypeArgs = {
  objects: Array<Stock_Location_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Location_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Location_Type_OneArgs = {
  object: Stock_Location_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Location_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Manufacturing_Order_ItemArgs = {
  objects: Array<Stock_Manufacturing_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Manufacturing_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Manufacturing_Order_Item_OneArgs = {
  object: Stock_Manufacturing_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Manufacturing_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_OperationArgs = {
  objects: Array<Stock_Operation_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Operation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Operation_OneArgs = {
  object: Stock_Operation_Insert_Input;
  on_conflict?: InputMaybe<Stock_Operation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Operation_TypeArgs = {
  objects: Array<Stock_Operation_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Operation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Operation_Type_OneArgs = {
  object: Stock_Operation_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Operation_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_OrderArgs = {
  objects: Array<Stock_Order_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_Execution_ErrorsArgs = {
  objects: Array<Stock_Order_Execution_Errors_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Execution_Errors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_Execution_Errors_OneArgs = {
  object: Stock_Order_Execution_Errors_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Execution_Errors_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_OneArgs = {
  object: Stock_Order_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_StatusArgs = {
  objects: Array<Stock_Order_Status_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_Status_OneArgs = {
  object: Stock_Order_Status_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_TypeArgs = {
  objects: Array<Stock_Order_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Order_Type_OneArgs = {
  object: Stock_Order_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_PartnerArgs = {
  objects: Array<Stock_Partner_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Partner_OneArgs = {
  object: Stock_Partner_Insert_Input;
  on_conflict?: InputMaybe<Stock_Partner_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Production_LotArgs = {
  objects: Array<Stock_Production_Lot_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Production_Lot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Production_Lot_OneArgs = {
  object: Stock_Production_Lot_Insert_Input;
  on_conflict?: InputMaybe<Stock_Production_Lot_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Purchase_Order_ItemArgs = {
  objects: Array<Stock_Purchase_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Purchase_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Purchase_Order_Item_OneArgs = {
  object: Stock_Purchase_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Purchase_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_QuantityArgs = {
  objects: Array<Stock_Quantity_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Quantity_OneArgs = {
  object: Stock_Quantity_Insert_Input;
  on_conflict?: InputMaybe<Stock_Quantity_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Receipts_Order_ItemArgs = {
  objects: Array<Stock_Receipts_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Receipts_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Receipts_Order_Item_OneArgs = {
  object: Stock_Receipts_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Receipts_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Sales_Order_ItemArgs = {
  objects: Array<Stock_Sales_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Sales_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Sales_Order_Item_OneArgs = {
  object: Stock_Sales_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Sales_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Transfer_Order_ItemArgs = {
  objects: Array<Stock_Transfer_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Transfer_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Transfer_Order_Item_OneArgs = {
  object: Stock_Transfer_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Transfer_Order_Item_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_MeasureArgs = {
  objects: Array<Unit_Of_Measure_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_Measure_CategoryArgs = {
  objects: Array<Unit_Of_Measure_Category_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_Measure_Category_OneArgs = {
  object: Unit_Of_Measure_Category_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_Measure_ConversionArgs = {
  objects: Array<Unit_Of_Measure_Conversion_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_Measure_Conversion_OneArgs = {
  object: Unit_Of_Measure_Conversion_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_Conversion_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Unit_Of_Measure_OneArgs = {
  object: Unit_Of_Measure_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_PermissionArgs = {
  objects: Array<User_Permission_Insert_Input>;
  on_conflict?: InputMaybe<User_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Permission_OneArgs = {
  object: User_Permission_Insert_Input;
  on_conflict?: InputMaybe<User_Permission_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_ProvidersArgs = {
  _set?: InputMaybe<Auth_Account_Providers_Set_Input>;
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Account_Providers_Set_Input>;
  pk_columns: Auth_Account_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_RolesArgs = {
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_By_PkArgs = {
  _set?: InputMaybe<Auth_Account_Roles_Set_Input>;
  pk_columns: Auth_Account_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_AccountsArgs = {
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_By_PkArgs = {
  _append?: InputMaybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: InputMaybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: InputMaybe<Auth_Accounts_Prepend_Input>;
  _set?: InputMaybe<Auth_Accounts_Set_Input>;
  pk_columns: Auth_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: InputMaybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _set?: InputMaybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_RolesArgs = {
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_By_PkArgs = {
  _set?: InputMaybe<Auth_Roles_Set_Input>;
  pk_columns: Auth_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Existence_ReportArgs = {
  _inc?: InputMaybe<Daily_Existence_Report_Inc_Input>;
  _set?: InputMaybe<Daily_Existence_Report_Set_Input>;
  where: Daily_Existence_Report_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Daily_Existence_Report_By_PkArgs = {
  _inc?: InputMaybe<Daily_Existence_Report_Inc_Input>;
  _set?: InputMaybe<Daily_Existence_Report_Set_Input>;
  pk_columns: Daily_Existence_Report_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Import_Sales_OrderArgs = {
  _append?: InputMaybe<Import_Sales_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Import_Sales_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Import_Sales_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Import_Sales_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Import_Sales_Order_Inc_Input>;
  _prepend?: InputMaybe<Import_Sales_Order_Prepend_Input>;
  _set?: InputMaybe<Import_Sales_Order_Set_Input>;
  where: Import_Sales_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Import_Sales_Order_By_PkArgs = {
  _append?: InputMaybe<Import_Sales_Order_Append_Input>;
  _delete_at_path?: InputMaybe<Import_Sales_Order_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Import_Sales_Order_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Import_Sales_Order_Delete_Key_Input>;
  _inc?: InputMaybe<Import_Sales_Order_Inc_Input>;
  _prepend?: InputMaybe<Import_Sales_Order_Prepend_Input>;
  _set?: InputMaybe<Import_Sales_Order_Set_Input>;
  pk_columns: Import_Sales_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_ModulesArgs = {
  _set?: InputMaybe<Modules_Set_Input>;
  where: Modules_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Modules_By_PkArgs = {
  _set?: InputMaybe<Modules_Set_Input>;
  pk_columns: Modules_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Opb_Profit_Report_TemplateArgs = {
  _inc?: InputMaybe<Opb_Profit_Report_Template_Inc_Input>;
  _set?: InputMaybe<Opb_Profit_Report_Template_Set_Input>;
  where: Opb_Profit_Report_Template_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Bill_Of_Material_ItemsArgs = {
  _inc?: InputMaybe<Product_Bill_Of_Material_Items_Inc_Input>;
  _set?: InputMaybe<Product_Bill_Of_Material_Items_Set_Input>;
  where: Product_Bill_Of_Material_Items_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Bill_Of_Material_Items_By_PkArgs = {
  _inc?: InputMaybe<Product_Bill_Of_Material_Items_Inc_Input>;
  _set?: InputMaybe<Product_Bill_Of_Material_Items_Set_Input>;
  pk_columns: Product_Bill_Of_Material_Items_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: InputMaybe<Product_Inc_Input>;
  _set?: InputMaybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_CategoryArgs = {
  _inc?: InputMaybe<Product_Category_Inc_Input>;
  _set?: InputMaybe<Product_Category_Set_Input>;
  where: Product_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Category_By_PkArgs = {
  _inc?: InputMaybe<Product_Category_Inc_Input>;
  _set?: InputMaybe<Product_Category_Set_Input>;
  pk_columns: Product_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Quantity_On_HandArgs = {
  _inc?: InputMaybe<Quantity_On_Hand_Inc_Input>;
  _set?: InputMaybe<Quantity_On_Hand_Set_Input>;
  where: Quantity_On_Hand_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Deliver_StatusArgs = {
  _set?: InputMaybe<Stock_Deliver_Status_Set_Input>;
  where: Stock_Deliver_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Deliver_Status_By_PkArgs = {
  _set?: InputMaybe<Stock_Deliver_Status_Set_Input>;
  pk_columns: Stock_Deliver_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_LocationArgs = {
  _inc?: InputMaybe<Stock_Location_Inc_Input>;
  _set?: InputMaybe<Stock_Location_Set_Input>;
  where: Stock_Location_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Location_By_PkArgs = {
  _inc?: InputMaybe<Stock_Location_Inc_Input>;
  _set?: InputMaybe<Stock_Location_Set_Input>;
  pk_columns: Stock_Location_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Location_TypeArgs = {
  _set?: InputMaybe<Stock_Location_Type_Set_Input>;
  where: Stock_Location_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Location_Type_By_PkArgs = {
  _set?: InputMaybe<Stock_Location_Type_Set_Input>;
  pk_columns: Stock_Location_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Manufacturing_Order_ItemArgs = {
  _append?: InputMaybe<Stock_Manufacturing_Order_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Manufacturing_Order_Item_Inc_Input>;
  _prepend?: InputMaybe<Stock_Manufacturing_Order_Item_Prepend_Input>;
  _set?: InputMaybe<Stock_Manufacturing_Order_Item_Set_Input>;
  where: Stock_Manufacturing_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Manufacturing_Order_Item_By_PkArgs = {
  _append?: InputMaybe<Stock_Manufacturing_Order_Item_Append_Input>;
  _delete_at_path?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stock_Manufacturing_Order_Item_Delete_Key_Input>;
  _inc?: InputMaybe<Stock_Manufacturing_Order_Item_Inc_Input>;
  _prepend?: InputMaybe<Stock_Manufacturing_Order_Item_Prepend_Input>;
  _set?: InputMaybe<Stock_Manufacturing_Order_Item_Set_Input>;
  pk_columns: Stock_Manufacturing_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_OperationArgs = {
  _inc?: InputMaybe<Stock_Operation_Inc_Input>;
  _set?: InputMaybe<Stock_Operation_Set_Input>;
  where: Stock_Operation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Operation_By_PkArgs = {
  _inc?: InputMaybe<Stock_Operation_Inc_Input>;
  _set?: InputMaybe<Stock_Operation_Set_Input>;
  pk_columns: Stock_Operation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Operation_TypeArgs = {
  _set?: InputMaybe<Stock_Operation_Type_Set_Input>;
  where: Stock_Operation_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Operation_Type_By_PkArgs = {
  _set?: InputMaybe<Stock_Operation_Type_Set_Input>;
  pk_columns: Stock_Operation_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_OrderArgs = {
  _inc?: InputMaybe<Stock_Order_Inc_Input>;
  _set?: InputMaybe<Stock_Order_Set_Input>;
  where: Stock_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_By_PkArgs = {
  _inc?: InputMaybe<Stock_Order_Inc_Input>;
  _set?: InputMaybe<Stock_Order_Set_Input>;
  pk_columns: Stock_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_Execution_ErrorsArgs = {
  _inc?: InputMaybe<Stock_Order_Execution_Errors_Inc_Input>;
  _set?: InputMaybe<Stock_Order_Execution_Errors_Set_Input>;
  where: Stock_Order_Execution_Errors_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_Execution_Errors_By_PkArgs = {
  _inc?: InputMaybe<Stock_Order_Execution_Errors_Inc_Input>;
  _set?: InputMaybe<Stock_Order_Execution_Errors_Set_Input>;
  pk_columns: Stock_Order_Execution_Errors_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_StatusArgs = {
  _set?: InputMaybe<Stock_Order_Status_Set_Input>;
  where: Stock_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_Status_By_PkArgs = {
  _set?: InputMaybe<Stock_Order_Status_Set_Input>;
  pk_columns: Stock_Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_TypeArgs = {
  _set?: InputMaybe<Stock_Order_Type_Set_Input>;
  where: Stock_Order_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Order_Type_By_PkArgs = {
  _set?: InputMaybe<Stock_Order_Type_Set_Input>;
  pk_columns: Stock_Order_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_PartnerArgs = {
  _inc?: InputMaybe<Stock_Partner_Inc_Input>;
  _set?: InputMaybe<Stock_Partner_Set_Input>;
  where: Stock_Partner_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Partner_By_PkArgs = {
  _inc?: InputMaybe<Stock_Partner_Inc_Input>;
  _set?: InputMaybe<Stock_Partner_Set_Input>;
  pk_columns: Stock_Partner_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Production_LotArgs = {
  _inc?: InputMaybe<Stock_Production_Lot_Inc_Input>;
  _set?: InputMaybe<Stock_Production_Lot_Set_Input>;
  where: Stock_Production_Lot_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Production_Lot_By_PkArgs = {
  _inc?: InputMaybe<Stock_Production_Lot_Inc_Input>;
  _set?: InputMaybe<Stock_Production_Lot_Set_Input>;
  pk_columns: Stock_Production_Lot_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Purchase_Order_ItemArgs = {
  _inc?: InputMaybe<Stock_Purchase_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Purchase_Order_Item_Set_Input>;
  where: Stock_Purchase_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Purchase_Order_Item_By_PkArgs = {
  _inc?: InputMaybe<Stock_Purchase_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Purchase_Order_Item_Set_Input>;
  pk_columns: Stock_Purchase_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_QuantityArgs = {
  _inc?: InputMaybe<Stock_Quantity_Inc_Input>;
  _set?: InputMaybe<Stock_Quantity_Set_Input>;
  where: Stock_Quantity_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Quantity_By_PkArgs = {
  _inc?: InputMaybe<Stock_Quantity_Inc_Input>;
  _set?: InputMaybe<Stock_Quantity_Set_Input>;
  pk_columns: Stock_Quantity_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Receipts_Order_ItemArgs = {
  _inc?: InputMaybe<Stock_Receipts_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Receipts_Order_Item_Set_Input>;
  where: Stock_Receipts_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Receipts_Order_Item_By_PkArgs = {
  _inc?: InputMaybe<Stock_Receipts_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Receipts_Order_Item_Set_Input>;
  pk_columns: Stock_Receipts_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Sales_Order_ItemArgs = {
  _inc?: InputMaybe<Stock_Sales_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Sales_Order_Item_Set_Input>;
  where: Stock_Sales_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Sales_Order_Item_By_PkArgs = {
  _inc?: InputMaybe<Stock_Sales_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Sales_Order_Item_Set_Input>;
  pk_columns: Stock_Sales_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Transfer_Order_ItemArgs = {
  _inc?: InputMaybe<Stock_Transfer_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Transfer_Order_Item_Set_Input>;
  where: Stock_Transfer_Order_Item_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Transfer_Order_Item_By_PkArgs = {
  _inc?: InputMaybe<Stock_Transfer_Order_Item_Inc_Input>;
  _set?: InputMaybe<Stock_Transfer_Order_Item_Set_Input>;
  pk_columns: Stock_Transfer_Order_Item_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_MeasureArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Set_Input>;
  where: Unit_Of_Measure_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_Measure_By_PkArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Set_Input>;
  pk_columns: Unit_Of_Measure_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_Measure_CategoryArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Category_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Category_Set_Input>;
  where: Unit_Of_Measure_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_Measure_Category_By_PkArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Category_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Category_Set_Input>;
  pk_columns: Unit_Of_Measure_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_Measure_ConversionArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Conversion_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Conversion_Set_Input>;
  where: Unit_Of_Measure_Conversion_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Unit_Of_Measure_Conversion_By_PkArgs = {
  _inc?: InputMaybe<Unit_Of_Measure_Conversion_Inc_Input>;
  _set?: InputMaybe<Unit_Of_Measure_Conversion_Set_Input>;
  pk_columns: Unit_Of_Measure_Conversion_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_PermissionArgs = {
  _set?: InputMaybe<User_Permission_Set_Input>;
  where: User_Permission_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Permission_By_PkArgs = {
  _set?: InputMaybe<User_Permission_Set_Input>;
  pk_columns: User_Permission_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** expression to compare columns of type numeric. All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['numeric']>;
  _gt?: InputMaybe<Scalars['numeric']>;
  _gte?: InputMaybe<Scalars['numeric']>;
  _in?: InputMaybe<Array<Scalars['numeric']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['numeric']>;
  _lte?: InputMaybe<Scalars['numeric']>;
  _neq?: InputMaybe<Scalars['numeric']>;
  _nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "opb_existence" */
export type Opb_Existence = {
  __typename?: 'opb_existence';
  location_name?: Maybe<Scalars['String']>;
  manufacturable?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  purchasable?: Maybe<Scalars['Boolean']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  sellable?: Maybe<Scalars['Boolean']>;
  storable?: Maybe<Scalars['Boolean']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "opb_existence" */
export type Opb_Existence_Aggregate = {
  __typename?: 'opb_existence_aggregate';
  aggregate?: Maybe<Opb_Existence_Aggregate_Fields>;
  nodes: Array<Opb_Existence>;
};

/** aggregate fields of "opb_existence" */
export type Opb_Existence_Aggregate_Fields = {
  __typename?: 'opb_existence_aggregate_fields';
  avg?: Maybe<Opb_Existence_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Existence_Max_Fields>;
  min?: Maybe<Opb_Existence_Min_Fields>;
  stddev?: Maybe<Opb_Existence_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Existence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Existence_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Existence_Sum_Fields>;
  var_pop?: Maybe<Opb_Existence_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Existence_Var_Samp_Fields>;
  variance?: Maybe<Opb_Existence_Variance_Fields>;
};


/** aggregate fields of "opb_existence" */
export type Opb_Existence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Existence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_existence" */
export type Opb_Existence_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Existence_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Existence_Max_Order_By>;
  min?: InputMaybe<Opb_Existence_Min_Order_By>;
  stddev?: InputMaybe<Opb_Existence_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Existence_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Existence_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Existence_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Existence_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Existence_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Existence_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Existence_Avg_Fields = {
  __typename?: 'opb_existence_avg_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_existence" */
export type Opb_Existence_Avg_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_existence". All fields are combined with a logical 'AND'. */
export type Opb_Existence_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Existence_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Existence_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Existence_Bool_Exp>>>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  manufacturable?: InputMaybe<Boolean_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  purchasable?: InputMaybe<Boolean_Comparison_Exp>;
  quantity_on_hand?: InputMaybe<Numeric_Comparison_Exp>;
  sellable?: InputMaybe<Boolean_Comparison_Exp>;
  storable?: InputMaybe<Boolean_Comparison_Exp>;
  unit_of_measure?: InputMaybe<String_Comparison_Exp>;
  unit_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Existence_Max_Fields = {
  __typename?: 'opb_existence_max_fields';
  location_name?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "opb_existence" */
export type Opb_Existence_Max_Order_By = {
  location_name?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Existence_Min_Fields = {
  __typename?: 'opb_existence_min_fields';
  location_name?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "opb_existence" */
export type Opb_Existence_Min_Order_By = {
  location_name?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_existence" */
export type Opb_Existence_Order_By = {
  location_name?: InputMaybe<Order_By>;
  manufacturable?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  purchasable?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  sellable?: InputMaybe<Order_By>;
  storable?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** select columns of table "opb_existence" */
export enum Opb_Existence_Select_Column {
  /** column name */
  LocationName = 'location_name',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  QuantityOnHand = 'quantity_on_hand',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Storable = 'storable',
  /** column name */
  UnitOfMeasure = 'unit_of_measure',
  /** column name */
  UnitPrice = 'unit_price'
}

/** aggregate stddev on columns */
export type Opb_Existence_Stddev_Fields = {
  __typename?: 'opb_existence_stddev_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_existence" */
export type Opb_Existence_Stddev_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Existence_Stddev_Pop_Fields = {
  __typename?: 'opb_existence_stddev_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_existence" */
export type Opb_Existence_Stddev_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Existence_Stddev_Samp_Fields = {
  __typename?: 'opb_existence_stddev_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_existence" */
export type Opb_Existence_Stddev_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Existence_Sum_Fields = {
  __typename?: 'opb_existence_sum_fields';
  product_id?: Maybe<Scalars['Int']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_existence" */
export type Opb_Existence_Sum_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Existence_Var_Pop_Fields = {
  __typename?: 'opb_existence_var_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_existence" */
export type Opb_Existence_Var_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Existence_Var_Samp_Fields = {
  __typename?: 'opb_existence_var_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_existence" */
export type Opb_Existence_Var_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Existence_Variance_Fields = {
  __typename?: 'opb_existence_variance_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_existence" */
export type Opb_Existence_Variance_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_ipv_report" */
export type Opb_Ipv_Report = {
  __typename?: 'opb_ipv_report';
  date?: Maybe<Scalars['date']>;
  end_quantity?: Maybe<Scalars['numeric']>;
  end_value?: Maybe<Scalars['numeric']>;
  in_quantity?: Maybe<Scalars['numeric']>;
  location_name?: Maybe<Scalars['String']>;
  out_quantity?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  start_quantity?: Maybe<Scalars['numeric']>;
  start_value?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** aggregated selection of "opb_ipv_report" */
export type Opb_Ipv_Report_Aggregate = {
  __typename?: 'opb_ipv_report_aggregate';
  aggregate?: Maybe<Opb_Ipv_Report_Aggregate_Fields>;
  nodes: Array<Opb_Ipv_Report>;
};

/** aggregate fields of "opb_ipv_report" */
export type Opb_Ipv_Report_Aggregate_Fields = {
  __typename?: 'opb_ipv_report_aggregate_fields';
  avg?: Maybe<Opb_Ipv_Report_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Ipv_Report_Max_Fields>;
  min?: Maybe<Opb_Ipv_Report_Min_Fields>;
  stddev?: Maybe<Opb_Ipv_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Ipv_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Ipv_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Ipv_Report_Sum_Fields>;
  var_pop?: Maybe<Opb_Ipv_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Ipv_Report_Var_Samp_Fields>;
  variance?: Maybe<Opb_Ipv_Report_Variance_Fields>;
};


/** aggregate fields of "opb_ipv_report" */
export type Opb_Ipv_Report_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Ipv_Report_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_ipv_report" */
export type Opb_Ipv_Report_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Ipv_Report_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Ipv_Report_Max_Order_By>;
  min?: InputMaybe<Opb_Ipv_Report_Min_Order_By>;
  stddev?: InputMaybe<Opb_Ipv_Report_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Ipv_Report_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Ipv_Report_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Ipv_Report_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Ipv_Report_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Ipv_Report_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Ipv_Report_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Ipv_Report_Avg_Fields = {
  __typename?: 'opb_ipv_report_avg_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Avg_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_ipv_report". All fields are combined with a logical 'AND'. */
export type Opb_Ipv_Report_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Ipv_Report_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Ipv_Report_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Ipv_Report_Bool_Exp>>>;
  date?: InputMaybe<Date_Comparison_Exp>;
  end_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  end_value?: InputMaybe<Numeric_Comparison_Exp>;
  in_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  out_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  start_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  start_value?: InputMaybe<Numeric_Comparison_Exp>;
  unit_of_measure?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Ipv_Report_Max_Fields = {
  __typename?: 'opb_ipv_report_max_fields';
  date?: Maybe<Scalars['date']>;
  end_quantity?: Maybe<Scalars['numeric']>;
  end_value?: Maybe<Scalars['numeric']>;
  in_quantity?: Maybe<Scalars['numeric']>;
  location_name?: Maybe<Scalars['String']>;
  out_quantity?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  start_quantity?: Maybe<Scalars['numeric']>;
  start_value?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Max_Order_By = {
  date?: InputMaybe<Order_By>;
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Ipv_Report_Min_Fields = {
  __typename?: 'opb_ipv_report_min_fields';
  date?: Maybe<Scalars['date']>;
  end_quantity?: Maybe<Scalars['numeric']>;
  end_value?: Maybe<Scalars['numeric']>;
  in_quantity?: Maybe<Scalars['numeric']>;
  location_name?: Maybe<Scalars['String']>;
  out_quantity?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  start_quantity?: Maybe<Scalars['numeric']>;
  start_value?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Min_Order_By = {
  date?: InputMaybe<Order_By>;
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_ipv_report" */
export type Opb_Ipv_Report_Order_By = {
  date?: InputMaybe<Order_By>;
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** select columns of table "opb_ipv_report" */
export enum Opb_Ipv_Report_Select_Column {
  /** column name */
  Date = 'date',
  /** column name */
  EndQuantity = 'end_quantity',
  /** column name */
  EndValue = 'end_value',
  /** column name */
  InQuantity = 'in_quantity',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  OutQuantity = 'out_quantity',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  StartQuantity = 'start_quantity',
  /** column name */
  StartValue = 'start_value',
  /** column name */
  UnitOfMeasure = 'unit_of_measure'
}

/** aggregate stddev on columns */
export type Opb_Ipv_Report_Stddev_Fields = {
  __typename?: 'opb_ipv_report_stddev_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Stddev_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Ipv_Report_Stddev_Pop_Fields = {
  __typename?: 'opb_ipv_report_stddev_pop_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Stddev_Pop_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Ipv_Report_Stddev_Samp_Fields = {
  __typename?: 'opb_ipv_report_stddev_samp_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Stddev_Samp_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Ipv_Report_Sum_Fields = {
  __typename?: 'opb_ipv_report_sum_fields';
  end_quantity?: Maybe<Scalars['numeric']>;
  end_value?: Maybe<Scalars['numeric']>;
  in_quantity?: Maybe<Scalars['numeric']>;
  out_quantity?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  start_quantity?: Maybe<Scalars['numeric']>;
  start_value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Sum_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Ipv_Report_Var_Pop_Fields = {
  __typename?: 'opb_ipv_report_var_pop_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Var_Pop_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Ipv_Report_Var_Samp_Fields = {
  __typename?: 'opb_ipv_report_var_samp_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Var_Samp_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Ipv_Report_Variance_Fields = {
  __typename?: 'opb_ipv_report_variance_fields';
  end_quantity?: Maybe<Scalars['Float']>;
  end_value?: Maybe<Scalars['Float']>;
  in_quantity?: Maybe<Scalars['Float']>;
  out_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  start_quantity?: Maybe<Scalars['Float']>;
  start_value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_ipv_report" */
export type Opb_Ipv_Report_Variance_Order_By = {
  end_quantity?: InputMaybe<Order_By>;
  end_value?: InputMaybe<Order_By>;
  in_quantity?: InputMaybe<Order_By>;
  out_quantity?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  start_quantity?: InputMaybe<Order_By>;
  start_value?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_product_existence" */
export type Opb_Product_Existence = {
  __typename?: 'opb_product_existence';
  expiration_date?: Maybe<Scalars['date']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  manufacturable?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  purchasable?: Maybe<Scalars['Boolean']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  sellable?: Maybe<Scalars['Boolean']>;
  storable?: Maybe<Scalars['Boolean']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "opb_product_existence" */
export type Opb_Product_Existence_Aggregate = {
  __typename?: 'opb_product_existence_aggregate';
  aggregate?: Maybe<Opb_Product_Existence_Aggregate_Fields>;
  nodes: Array<Opb_Product_Existence>;
};

/** aggregate fields of "opb_product_existence" */
export type Opb_Product_Existence_Aggregate_Fields = {
  __typename?: 'opb_product_existence_aggregate_fields';
  avg?: Maybe<Opb_Product_Existence_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Product_Existence_Max_Fields>;
  min?: Maybe<Opb_Product_Existence_Min_Fields>;
  stddev?: Maybe<Opb_Product_Existence_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Product_Existence_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Product_Existence_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Product_Existence_Sum_Fields>;
  var_pop?: Maybe<Opb_Product_Existence_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Product_Existence_Var_Samp_Fields>;
  variance?: Maybe<Opb_Product_Existence_Variance_Fields>;
};


/** aggregate fields of "opb_product_existence" */
export type Opb_Product_Existence_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Product_Existence_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_product_existence" */
export type Opb_Product_Existence_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Product_Existence_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Product_Existence_Max_Order_By>;
  min?: InputMaybe<Opb_Product_Existence_Min_Order_By>;
  stddev?: InputMaybe<Opb_Product_Existence_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Product_Existence_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Product_Existence_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Product_Existence_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Product_Existence_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Product_Existence_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Product_Existence_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Product_Existence_Avg_Fields = {
  __typename?: 'opb_product_existence_avg_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Avg_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_product_existence". All fields are combined with a logical 'AND'. */
export type Opb_Product_Existence_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Product_Existence_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Product_Existence_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Product_Existence_Bool_Exp>>>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  lot_code?: InputMaybe<String_Comparison_Exp>;
  manufacturable?: InputMaybe<Boolean_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_code?: InputMaybe<String_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  purchasable?: InputMaybe<Boolean_Comparison_Exp>;
  quantity_on_hand?: InputMaybe<Numeric_Comparison_Exp>;
  sellable?: InputMaybe<Boolean_Comparison_Exp>;
  storable?: InputMaybe<Boolean_Comparison_Exp>;
  unit_of_measure?: InputMaybe<String_Comparison_Exp>;
  unit_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Product_Existence_Max_Fields = {
  __typename?: 'opb_product_existence_max_fields';
  expiration_date?: Maybe<Scalars['date']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Max_Order_By = {
  expiration_date?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Product_Existence_Min_Fields = {
  __typename?: 'opb_product_existence_min_fields';
  expiration_date?: Maybe<Scalars['date']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  product_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Min_Order_By = {
  expiration_date?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_product_existence" */
export type Opb_Product_Existence_Order_By = {
  expiration_date?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  manufacturable?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  purchasable?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  sellable?: InputMaybe<Order_By>;
  storable?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** select columns of table "opb_product_existence" */
export enum Opb_Product_Existence_Select_Column {
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  LotCode = 'lot_code',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  ProductCode = 'product_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  QuantityOnHand = 'quantity_on_hand',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Storable = 'storable',
  /** column name */
  UnitOfMeasure = 'unit_of_measure',
  /** column name */
  UnitPrice = 'unit_price'
}

/** aggregate stddev on columns */
export type Opb_Product_Existence_Stddev_Fields = {
  __typename?: 'opb_product_existence_stddev_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Stddev_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Product_Existence_Stddev_Pop_Fields = {
  __typename?: 'opb_product_existence_stddev_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Stddev_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Product_Existence_Stddev_Samp_Fields = {
  __typename?: 'opb_product_existence_stddev_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Stddev_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Product_Existence_Sum_Fields = {
  __typename?: 'opb_product_existence_sum_fields';
  product_id?: Maybe<Scalars['Int']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  unit_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Sum_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Product_Existence_Var_Pop_Fields = {
  __typename?: 'opb_product_existence_var_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Var_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Product_Existence_Var_Samp_Fields = {
  __typename?: 'opb_product_existence_var_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Var_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Product_Existence_Variance_Fields = {
  __typename?: 'opb_product_existence_variance_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
  unit_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_product_existence" */
export type Opb_Product_Existence_Variance_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
  unit_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_product_lot" */
export type Opb_Product_Lot = {
  __typename?: 'opb_product_lot';
  code?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "opb_product_lot" */
export type Opb_Product_Lot_Aggregate = {
  __typename?: 'opb_product_lot_aggregate';
  aggregate?: Maybe<Opb_Product_Lot_Aggregate_Fields>;
  nodes: Array<Opb_Product_Lot>;
};

/** aggregate fields of "opb_product_lot" */
export type Opb_Product_Lot_Aggregate_Fields = {
  __typename?: 'opb_product_lot_aggregate_fields';
  avg?: Maybe<Opb_Product_Lot_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Product_Lot_Max_Fields>;
  min?: Maybe<Opb_Product_Lot_Min_Fields>;
  stddev?: Maybe<Opb_Product_Lot_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Product_Lot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Product_Lot_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Product_Lot_Sum_Fields>;
  var_pop?: Maybe<Opb_Product_Lot_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Product_Lot_Var_Samp_Fields>;
  variance?: Maybe<Opb_Product_Lot_Variance_Fields>;
};


/** aggregate fields of "opb_product_lot" */
export type Opb_Product_Lot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_product_lot" */
export type Opb_Product_Lot_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Product_Lot_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Product_Lot_Max_Order_By>;
  min?: InputMaybe<Opb_Product_Lot_Min_Order_By>;
  stddev?: InputMaybe<Opb_Product_Lot_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Product_Lot_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Product_Lot_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Product_Lot_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Product_Lot_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Product_Lot_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Product_Lot_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Product_Lot_Avg_Fields = {
  __typename?: 'opb_product_lot_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Avg_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_product_lot". All fields are combined with a logical 'AND'. */
export type Opb_Product_Lot_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Product_Lot_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Product_Lot_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  location_name?: InputMaybe<String_Comparison_Exp>;
  lot_code?: InputMaybe<String_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Product_Lot_Max_Fields = {
  __typename?: 'opb_product_lot_max_fields';
  code?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Product_Lot_Min_Fields = {
  __typename?: 'opb_product_lot_min_fields';
  code?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  location_name?: Maybe<Scalars['String']>;
  lot_code?: Maybe<Scalars['String']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_product_lot" */
export type Opb_Product_Lot_Order_By = {
  code?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  location_name?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** select columns of table "opb_product_lot" */
export enum Opb_Product_Lot_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LocationName = 'location_name',
  /** column name */
  LotCode = 'lot_code',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity'
}

/** aggregate stddev on columns */
export type Opb_Product_Lot_Stddev_Fields = {
  __typename?: 'opb_product_lot_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Stddev_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Product_Lot_Stddev_Pop_Fields = {
  __typename?: 'opb_product_lot_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Stddev_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Product_Lot_Stddev_Samp_Fields = {
  __typename?: 'opb_product_lot_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Stddev_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Product_Lot_Sum_Fields = {
  __typename?: 'opb_product_lot_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Sum_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Product_Lot_Var_Pop_Fields = {
  __typename?: 'opb_product_lot_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Var_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Product_Lot_Var_Samp_Fields = {
  __typename?: 'opb_product_lot_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Var_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Product_Lot_Variance_Fields = {
  __typename?: 'opb_product_lot_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_product_lot" */
export type Opb_Product_Lot_Variance_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert = {
  __typename?: 'opb_product_min_quantity_alert';
  /** An object relationship */
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** aggregated selection of "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Aggregate = {
  __typename?: 'opb_product_min_quantity_alert_aggregate';
  aggregate?: Maybe<Opb_Product_Min_Quantity_Alert_Aggregate_Fields>;
  nodes: Array<Opb_Product_Min_Quantity_Alert>;
};

/** aggregate fields of "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Aggregate_Fields = {
  __typename?: 'opb_product_min_quantity_alert_aggregate_fields';
  avg?: Maybe<Opb_Product_Min_Quantity_Alert_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Product_Min_Quantity_Alert_Max_Fields>;
  min?: Maybe<Opb_Product_Min_Quantity_Alert_Min_Fields>;
  stddev?: Maybe<Opb_Product_Min_Quantity_Alert_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Product_Min_Quantity_Alert_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Product_Min_Quantity_Alert_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Product_Min_Quantity_Alert_Sum_Fields>;
  var_pop?: Maybe<Opb_Product_Min_Quantity_Alert_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Product_Min_Quantity_Alert_Var_Samp_Fields>;
  variance?: Maybe<Opb_Product_Min_Quantity_Alert_Variance_Fields>;
};


/** aggregate fields of "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Product_Min_Quantity_Alert_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Product_Min_Quantity_Alert_Max_Order_By>;
  min?: InputMaybe<Opb_Product_Min_Quantity_Alert_Min_Order_By>;
  stddev?: InputMaybe<Opb_Product_Min_Quantity_Alert_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Product_Min_Quantity_Alert_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Product_Min_Quantity_Alert_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Product_Min_Quantity_Alert_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Product_Min_Quantity_Alert_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Product_Min_Quantity_Alert_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Product_Min_Quantity_Alert_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Product_Min_Quantity_Alert_Avg_Fields = {
  __typename?: 'opb_product_min_quantity_alert_avg_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Avg_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_product_min_quantity_alert". All fields are combined with a logical 'AND'. */
export type Opb_Product_Min_Quantity_Alert_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>>>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  unit_of_measure?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Product_Min_Quantity_Alert_Max_Fields = {
  __typename?: 'opb_product_min_quantity_alert_max_fields';
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Max_Order_By = {
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Product_Min_Quantity_Alert_Min_Fields = {
  __typename?: 'opb_product_min_quantity_alert_min_fields';
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['numeric']>;
  unit_of_measure?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Min_Order_By = {
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Order_By = {
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Order_By>;
};

/** select columns of table "opb_product_min_quantity_alert" */
export enum Opb_Product_Min_Quantity_Alert_Select_Column {
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UnitOfMeasure = 'unit_of_measure'
}

/** aggregate stddev on columns */
export type Opb_Product_Min_Quantity_Alert_Stddev_Fields = {
  __typename?: 'opb_product_min_quantity_alert_stddev_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Stddev_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Product_Min_Quantity_Alert_Stddev_Pop_Fields = {
  __typename?: 'opb_product_min_quantity_alert_stddev_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Stddev_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Product_Min_Quantity_Alert_Stddev_Samp_Fields = {
  __typename?: 'opb_product_min_quantity_alert_stddev_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Stddev_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Product_Min_Quantity_Alert_Sum_Fields = {
  __typename?: 'opb_product_min_quantity_alert_sum_fields';
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Sum_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Product_Min_Quantity_Alert_Var_Pop_Fields = {
  __typename?: 'opb_product_min_quantity_alert_var_pop_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Var_Pop_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Product_Min_Quantity_Alert_Var_Samp_Fields = {
  __typename?: 'opb_product_min_quantity_alert_var_samp_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Var_Samp_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Product_Min_Quantity_Alert_Variance_Fields = {
  __typename?: 'opb_product_min_quantity_alert_variance_fields';
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_product_min_quantity_alert" */
export type Opb_Product_Min_Quantity_Alert_Variance_Order_By = {
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire = {
  __typename?: 'opb_product_near_to_expire';
  days_to_expire?: Maybe<Scalars['Int']>;
  expiration_date?: Maybe<Scalars['date']>;
  lot_code?: Maybe<Scalars['String']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Aggregate = {
  __typename?: 'opb_product_near_to_expire_aggregate';
  aggregate?: Maybe<Opb_Product_Near_To_Expire_Aggregate_Fields>;
  nodes: Array<Opb_Product_Near_To_Expire>;
};

/** aggregate fields of "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Aggregate_Fields = {
  __typename?: 'opb_product_near_to_expire_aggregate_fields';
  avg?: Maybe<Opb_Product_Near_To_Expire_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Product_Near_To_Expire_Max_Fields>;
  min?: Maybe<Opb_Product_Near_To_Expire_Min_Fields>;
  stddev?: Maybe<Opb_Product_Near_To_Expire_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Product_Near_To_Expire_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Product_Near_To_Expire_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Product_Near_To_Expire_Sum_Fields>;
  var_pop?: Maybe<Opb_Product_Near_To_Expire_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Product_Near_To_Expire_Var_Samp_Fields>;
  variance?: Maybe<Opb_Product_Near_To_Expire_Variance_Fields>;
};


/** aggregate fields of "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Product_Near_To_Expire_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Product_Near_To_Expire_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Product_Near_To_Expire_Max_Order_By>;
  min?: InputMaybe<Opb_Product_Near_To_Expire_Min_Order_By>;
  stddev?: InputMaybe<Opb_Product_Near_To_Expire_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Product_Near_To_Expire_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Product_Near_To_Expire_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Product_Near_To_Expire_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Product_Near_To_Expire_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Product_Near_To_Expire_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Product_Near_To_Expire_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Product_Near_To_Expire_Avg_Fields = {
  __typename?: 'opb_product_near_to_expire_avg_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Avg_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_product_near_to_expire". All fields are combined with a logical 'AND'. */
export type Opb_Product_Near_To_Expire_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>>>;
  days_to_expire?: InputMaybe<Int_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  lot_code?: InputMaybe<String_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  quantity_on_hand?: InputMaybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Product_Near_To_Expire_Max_Fields = {
  __typename?: 'opb_product_near_to_expire_max_fields';
  days_to_expire?: Maybe<Scalars['Int']>;
  expiration_date?: Maybe<Scalars['date']>;
  lot_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Max_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Product_Near_To_Expire_Min_Fields = {
  __typename?: 'opb_product_near_to_expire_min_fields';
  days_to_expire?: Maybe<Scalars['Int']>;
  expiration_date?: Maybe<Scalars['date']>;
  lot_code?: Maybe<Scalars['String']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Min_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  lot_code?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** select columns of table "opb_product_near_to_expire" */
export enum Opb_Product_Near_To_Expire_Select_Column {
  /** column name */
  DaysToExpire = 'days_to_expire',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LotCode = 'lot_code',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  QuantityOnHand = 'quantity_on_hand'
}

/** aggregate stddev on columns */
export type Opb_Product_Near_To_Expire_Stddev_Fields = {
  __typename?: 'opb_product_near_to_expire_stddev_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Stddev_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Product_Near_To_Expire_Stddev_Pop_Fields = {
  __typename?: 'opb_product_near_to_expire_stddev_pop_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Stddev_Pop_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Product_Near_To_Expire_Stddev_Samp_Fields = {
  __typename?: 'opb_product_near_to_expire_stddev_samp_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Stddev_Samp_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Product_Near_To_Expire_Sum_Fields = {
  __typename?: 'opb_product_near_to_expire_sum_fields';
  days_to_expire?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity_on_hand?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Sum_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Product_Near_To_Expire_Var_Pop_Fields = {
  __typename?: 'opb_product_near_to_expire_var_pop_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Var_Pop_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Product_Near_To_Expire_Var_Samp_Fields = {
  __typename?: 'opb_product_near_to_expire_var_samp_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Var_Samp_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Product_Near_To_Expire_Variance_Fields = {
  __typename?: 'opb_product_near_to_expire_variance_fields';
  days_to_expire?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity_on_hand?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_product_near_to_expire" */
export type Opb_Product_Near_To_Expire_Variance_Order_By = {
  days_to_expire?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity_on_hand?: InputMaybe<Order_By>;
};

export type Opb_Profit_Report_Args = {
  end_date?: InputMaybe<Scalars['date']>;
  start_date?: InputMaybe<Scalars['date']>;
};

/** columns and relationships of "opb_profit_report_template" */
export type Opb_Profit_Report_Template = {
  __typename?: 'opb_profit_report_template';
  margin?: Maybe<Scalars['numeric']>;
  /** An object relationship */
  product?: Maybe<Product>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  sales_price?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Aggregate = {
  __typename?: 'opb_profit_report_template_aggregate';
  aggregate?: Maybe<Opb_Profit_Report_Template_Aggregate_Fields>;
  nodes: Array<Opb_Profit_Report_Template>;
};

/** aggregate fields of "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Aggregate_Fields = {
  __typename?: 'opb_profit_report_template_aggregate_fields';
  avg?: Maybe<Opb_Profit_Report_Template_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Profit_Report_Template_Max_Fields>;
  min?: Maybe<Opb_Profit_Report_Template_Min_Fields>;
  stddev?: Maybe<Opb_Profit_Report_Template_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Profit_Report_Template_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Profit_Report_Template_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Profit_Report_Template_Sum_Fields>;
  var_pop?: Maybe<Opb_Profit_Report_Template_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Profit_Report_Template_Var_Samp_Fields>;
  variance?: Maybe<Opb_Profit_Report_Template_Variance_Fields>;
};


/** aggregate fields of "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Profit_Report_Template_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Profit_Report_Template_Max_Order_By>;
  min?: InputMaybe<Opb_Profit_Report_Template_Min_Order_By>;
  stddev?: InputMaybe<Opb_Profit_Report_Template_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Profit_Report_Template_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Profit_Report_Template_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Profit_Report_Template_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Profit_Report_Template_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Profit_Report_Template_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Profit_Report_Template_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Arr_Rel_Insert_Input = {
  data: Array<Opb_Profit_Report_Template_Insert_Input>;
};

/** aggregate avg on columns */
export type Opb_Profit_Report_Template_Avg_Fields = {
  __typename?: 'opb_profit_report_template_avg_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Avg_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_profit_report_template". All fields are combined with a logical 'AND'. */
export type Opb_Profit_Report_Template_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Profit_Report_Template_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Profit_Report_Template_Bool_Exp>>>;
  margin?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_name?: InputMaybe<String_Comparison_Exp>;
  profit?: InputMaybe<Numeric_Comparison_Exp>;
  purchase_price?: InputMaybe<Numeric_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  sales_price?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for incrementing integer column in table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Inc_Input = {
  margin?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  profit?: InputMaybe<Scalars['numeric']>;
  purchase_price?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sales_price?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Insert_Input = {
  margin?: InputMaybe<Scalars['numeric']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_name?: InputMaybe<Scalars['String']>;
  profit?: InputMaybe<Scalars['numeric']>;
  purchase_price?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sales_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Opb_Profit_Report_Template_Max_Fields = {
  __typename?: 'opb_profit_report_template_max_fields';
  margin?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  sales_price?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Max_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Profit_Report_Template_Min_Fields = {
  __typename?: 'opb_profit_report_template_min_fields';
  margin?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  profit?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  sales_price?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Min_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Mutation_Response = {
  __typename?: 'opb_profit_report_template_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Opb_Profit_Report_Template>;
};

/** input type for inserting object relation for remote table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Obj_Rel_Insert_Input = {
  data: Opb_Profit_Report_Template_Insert_Input;
};

/** ordering options when selecting data from "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Order_By = {
  margin?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_name?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** select columns of table "opb_profit_report_template" */
export enum Opb_Profit_Report_Template_Select_Column {
  /** column name */
  Margin = 'margin',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  Profit = 'profit',
  /** column name */
  PurchasePrice = 'purchase_price',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SalesPrice = 'sales_price'
}

/** input type for updating data in table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Set_Input = {
  margin?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_name?: InputMaybe<Scalars['String']>;
  profit?: InputMaybe<Scalars['numeric']>;
  purchase_price?: InputMaybe<Scalars['numeric']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  sales_price?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Opb_Profit_Report_Template_Stddev_Fields = {
  __typename?: 'opb_profit_report_template_stddev_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Stddev_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Profit_Report_Template_Stddev_Pop_Fields = {
  __typename?: 'opb_profit_report_template_stddev_pop_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Stddev_Pop_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Profit_Report_Template_Stddev_Samp_Fields = {
  __typename?: 'opb_profit_report_template_stddev_samp_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Stddev_Samp_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Profit_Report_Template_Sum_Fields = {
  __typename?: 'opb_profit_report_template_sum_fields';
  margin?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  profit?: Maybe<Scalars['numeric']>;
  purchase_price?: Maybe<Scalars['numeric']>;
  quantity?: Maybe<Scalars['numeric']>;
  sales_price?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Sum_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Profit_Report_Template_Var_Pop_Fields = {
  __typename?: 'opb_profit_report_template_var_pop_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Var_Pop_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Profit_Report_Template_Var_Samp_Fields = {
  __typename?: 'opb_profit_report_template_var_samp_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Var_Samp_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Profit_Report_Template_Variance_Fields = {
  __typename?: 'opb_profit_report_template_variance_fields';
  margin?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  profit?: Maybe<Scalars['Float']>;
  purchase_price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  sales_price?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_profit_report_template" */
export type Opb_Profit_Report_Template_Variance_Order_By = {
  margin?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  profit?: InputMaybe<Order_By>;
  purchase_price?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  sales_price?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_purchases_view" */
export type Opb_Purchases_View = {
  __typename?: 'opb_purchases_view';
  cost?: Maybe<Scalars['numeric']>;
  purchases?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "opb_purchases_view" */
export type Opb_Purchases_View_Aggregate = {
  __typename?: 'opb_purchases_view_aggregate';
  aggregate?: Maybe<Opb_Purchases_View_Aggregate_Fields>;
  nodes: Array<Opb_Purchases_View>;
};

/** aggregate fields of "opb_purchases_view" */
export type Opb_Purchases_View_Aggregate_Fields = {
  __typename?: 'opb_purchases_view_aggregate_fields';
  avg?: Maybe<Opb_Purchases_View_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Purchases_View_Max_Fields>;
  min?: Maybe<Opb_Purchases_View_Min_Fields>;
  stddev?: Maybe<Opb_Purchases_View_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Purchases_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Purchases_View_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Purchases_View_Sum_Fields>;
  var_pop?: Maybe<Opb_Purchases_View_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Purchases_View_Var_Samp_Fields>;
  variance?: Maybe<Opb_Purchases_View_Variance_Fields>;
};


/** aggregate fields of "opb_purchases_view" */
export type Opb_Purchases_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Purchases_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_purchases_view" */
export type Opb_Purchases_View_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Purchases_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Purchases_View_Max_Order_By>;
  min?: InputMaybe<Opb_Purchases_View_Min_Order_By>;
  stddev?: InputMaybe<Opb_Purchases_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Purchases_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Purchases_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Purchases_View_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Purchases_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Purchases_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Purchases_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Purchases_View_Avg_Fields = {
  __typename?: 'opb_purchases_view_avg_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Avg_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_purchases_view". All fields are combined with a logical 'AND'. */
export type Opb_Purchases_View_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Purchases_View_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Purchases_View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Purchases_View_Bool_Exp>>>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  purchases?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Purchases_View_Max_Fields = {
  __typename?: 'opb_purchases_view_max_fields';
  cost?: Maybe<Scalars['numeric']>;
  purchases?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Max_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Purchases_View_Min_Fields = {
  __typename?: 'opb_purchases_view_min_fields';
  cost?: Maybe<Scalars['numeric']>;
  purchases?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Min_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_purchases_view" */
export type Opb_Purchases_View_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** select columns of table "opb_purchases_view" */
export enum Opb_Purchases_View_Select_Column {
  /** column name */
  Cost = 'cost',
  /** column name */
  Purchases = 'purchases'
}

/** aggregate stddev on columns */
export type Opb_Purchases_View_Stddev_Fields = {
  __typename?: 'opb_purchases_view_stddev_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Stddev_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Purchases_View_Stddev_Pop_Fields = {
  __typename?: 'opb_purchases_view_stddev_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Stddev_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Purchases_View_Stddev_Samp_Fields = {
  __typename?: 'opb_purchases_view_stddev_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Stddev_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Purchases_View_Sum_Fields = {
  __typename?: 'opb_purchases_view_sum_fields';
  cost?: Maybe<Scalars['numeric']>;
  purchases?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Sum_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Purchases_View_Var_Pop_Fields = {
  __typename?: 'opb_purchases_view_var_pop_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Var_Pop_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Purchases_View_Var_Samp_Fields = {
  __typename?: 'opb_purchases_view_var_samp_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Var_Samp_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Purchases_View_Variance_Fields = {
  __typename?: 'opb_purchases_view_variance_fields';
  cost?: Maybe<Scalars['Float']>;
  purchases?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_purchases_view" */
export type Opb_Purchases_View_Variance_Order_By = {
  cost?: InputMaybe<Order_By>;
  purchases?: InputMaybe<Order_By>;
};

/** columns and relationships of "opb_sales_view" */
export type Opb_Sales_View = {
  __typename?: 'opb_sales_view';
  revenue?: Maybe<Scalars['numeric']>;
  sales?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "opb_sales_view" */
export type Opb_Sales_View_Aggregate = {
  __typename?: 'opb_sales_view_aggregate';
  aggregate?: Maybe<Opb_Sales_View_Aggregate_Fields>;
  nodes: Array<Opb_Sales_View>;
};

/** aggregate fields of "opb_sales_view" */
export type Opb_Sales_View_Aggregate_Fields = {
  __typename?: 'opb_sales_view_aggregate_fields';
  avg?: Maybe<Opb_Sales_View_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Opb_Sales_View_Max_Fields>;
  min?: Maybe<Opb_Sales_View_Min_Fields>;
  stddev?: Maybe<Opb_Sales_View_Stddev_Fields>;
  stddev_pop?: Maybe<Opb_Sales_View_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Opb_Sales_View_Stddev_Samp_Fields>;
  sum?: Maybe<Opb_Sales_View_Sum_Fields>;
  var_pop?: Maybe<Opb_Sales_View_Var_Pop_Fields>;
  var_samp?: Maybe<Opb_Sales_View_Var_Samp_Fields>;
  variance?: Maybe<Opb_Sales_View_Variance_Fields>;
};


/** aggregate fields of "opb_sales_view" */
export type Opb_Sales_View_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Opb_Sales_View_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "opb_sales_view" */
export type Opb_Sales_View_Aggregate_Order_By = {
  avg?: InputMaybe<Opb_Sales_View_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Opb_Sales_View_Max_Order_By>;
  min?: InputMaybe<Opb_Sales_View_Min_Order_By>;
  stddev?: InputMaybe<Opb_Sales_View_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Opb_Sales_View_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Opb_Sales_View_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Opb_Sales_View_Sum_Order_By>;
  var_pop?: InputMaybe<Opb_Sales_View_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Opb_Sales_View_Var_Samp_Order_By>;
  variance?: InputMaybe<Opb_Sales_View_Variance_Order_By>;
};

/** aggregate avg on columns */
export type Opb_Sales_View_Avg_Fields = {
  __typename?: 'opb_sales_view_avg_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Avg_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "opb_sales_view". All fields are combined with a logical 'AND'. */
export type Opb_Sales_View_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Opb_Sales_View_Bool_Exp>>>;
  _not?: InputMaybe<Opb_Sales_View_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Opb_Sales_View_Bool_Exp>>>;
  revenue?: InputMaybe<Numeric_Comparison_Exp>;
  sales?: InputMaybe<Bigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type Opb_Sales_View_Max_Fields = {
  __typename?: 'opb_sales_view_max_fields';
  revenue?: Maybe<Scalars['numeric']>;
  sales?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Max_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Opb_Sales_View_Min_Fields = {
  __typename?: 'opb_sales_view_min_fields';
  revenue?: Maybe<Scalars['numeric']>;
  sales?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Min_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** ordering options when selecting data from "opb_sales_view" */
export type Opb_Sales_View_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** select columns of table "opb_sales_view" */
export enum Opb_Sales_View_Select_Column {
  /** column name */
  Revenue = 'revenue',
  /** column name */
  Sales = 'sales'
}

/** aggregate stddev on columns */
export type Opb_Sales_View_Stddev_Fields = {
  __typename?: 'opb_sales_view_stddev_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Stddev_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Opb_Sales_View_Stddev_Pop_Fields = {
  __typename?: 'opb_sales_view_stddev_pop_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Stddev_Pop_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Opb_Sales_View_Stddev_Samp_Fields = {
  __typename?: 'opb_sales_view_stddev_samp_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Stddev_Samp_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Opb_Sales_View_Sum_Fields = {
  __typename?: 'opb_sales_view_sum_fields';
  revenue?: Maybe<Scalars['numeric']>;
  sales?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Sum_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Opb_Sales_View_Var_Pop_Fields = {
  __typename?: 'opb_sales_view_var_pop_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Var_Pop_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Opb_Sales_View_Var_Samp_Fields = {
  __typename?: 'opb_sales_view_var_samp_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Var_Samp_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Opb_Sales_View_Variance_Fields = {
  __typename?: 'opb_sales_view_variance_fields';
  revenue?: Maybe<Scalars['Float']>;
  sales?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "opb_sales_view" */
export type Opb_Sales_View_Variance_Order_By = {
  revenue?: InputMaybe<Order_By>;
  sales?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in the ascending order, nulls last */
  Asc = 'asc',
  /** in the ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in the ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in the descending order, nulls first */
  Desc = 'desc',
  /** in the descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in the descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  alert_of_expiration_on?: Maybe<Scalars['Int']>;
  allowed_min_quantity?: Maybe<Scalars['numeric']>;
  /** An array relationship */
  bill_of_material_items: Array<Product_Bill_Of_Material_Items>;
  /** An aggregated array relationship */
  bill_of_material_items_aggregate: Product_Bill_Of_Material_Items_Aggregate;
  /** An object relationship */
  category?: Maybe<Product_Category>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expirable: Scalars['Boolean'];
  expire_on?: Maybe<Scalars['Int']>;
  external_product_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  manufacturable: Scalars['Boolean'];
  name: Scalars['String'];
  price?: Maybe<Scalars['numeric']>;
  product_category?: Maybe<Scalars['Int']>;
  /** An array relationship */
  product_lot: Array<Opb_Product_Lot>;
  /** An aggregated array relationship */
  product_lot_aggregate: Opb_Product_Lot_Aggregate;
  product_uom: Scalars['Int'];
  purchasable: Scalars['Boolean'];
  /** A computed field, executes function "opb_product_quantity_on_hand" */
  quantity_on_hand?: Maybe<Scalars['numeric']>;
  /** A computed field, executes function "opb_related_uom" */
  related_uom?: Maybe<Array<Unit_Of_Measure>>;
  sellable: Scalars['Boolean'];
  sku?: Maybe<Scalars['String']>;
  storable: Scalars['Boolean'];
  trackerable: Scalars['Boolean'];
  /** An object relationship */
  unit_of_measure: Unit_Of_Measure;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "product" */
export type ProductBill_Of_Material_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductBill_Of_Material_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductProduct_LotArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductProduct_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductRelated_UomArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Max_Order_By>;
  min?: InputMaybe<Product_Min_Order_By>;
  stddev?: InputMaybe<Product_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** columns and relationships of "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items = {
  __typename?: 'product_bill_of_material_items';
  bill_of_material_id: Scalars['Int'];
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Aggregate = {
  __typename?: 'product_bill_of_material_items_aggregate';
  aggregate?: Maybe<Product_Bill_Of_Material_Items_Aggregate_Fields>;
  nodes: Array<Product_Bill_Of_Material_Items>;
};

/** aggregate fields of "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Aggregate_Fields = {
  __typename?: 'product_bill_of_material_items_aggregate_fields';
  avg?: Maybe<Product_Bill_Of_Material_Items_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Bill_Of_Material_Items_Max_Fields>;
  min?: Maybe<Product_Bill_Of_Material_Items_Min_Fields>;
  stddev?: Maybe<Product_Bill_Of_Material_Items_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Bill_Of_Material_Items_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Bill_Of_Material_Items_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Bill_Of_Material_Items_Sum_Fields>;
  var_pop?: Maybe<Product_Bill_Of_Material_Items_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Bill_Of_Material_Items_Var_Samp_Fields>;
  variance?: Maybe<Product_Bill_Of_Material_Items_Variance_Fields>;
};


/** aggregate fields of "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Bill_Of_Material_Items_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Bill_Of_Material_Items_Max_Order_By>;
  min?: InputMaybe<Product_Bill_Of_Material_Items_Min_Order_By>;
  stddev?: InputMaybe<Product_Bill_Of_Material_Items_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Bill_Of_Material_Items_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Bill_Of_Material_Items_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Bill_Of_Material_Items_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Bill_Of_Material_Items_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Bill_Of_Material_Items_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Bill_Of_Material_Items_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Arr_Rel_Insert_Input = {
  data: Array<Product_Bill_Of_Material_Items_Insert_Input>;
  on_conflict?: InputMaybe<Product_Bill_Of_Material_Items_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Bill_Of_Material_Items_Avg_Fields = {
  __typename?: 'product_bill_of_material_items_avg_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Avg_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_bill_of_material_items". All fields are combined with a logical 'AND'. */
export type Product_Bill_Of_Material_Items_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>>>;
  _not?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>>>;
  bill_of_material_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_bill_of_material_items" */
export enum Product_Bill_Of_Material_Items_Constraint {
  /** unique or primary key constraint */
  ProductBillOfMaterialItemsPkey = 'product_bill_of_material_items_pkey'
}

/** input type for incrementing integer column in table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Inc_Input = {
  bill_of_material_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Insert_Input = {
  bill_of_material_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Bill_Of_Material_Items_Max_Fields = {
  __typename?: 'product_bill_of_material_items_max_fields';
  bill_of_material_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Max_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Bill_Of_Material_Items_Min_Fields = {
  __typename?: 'product_bill_of_material_items_min_fields';
  bill_of_material_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Min_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Mutation_Response = {
  __typename?: 'product_bill_of_material_items_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product_Bill_Of_Material_Items>;
};

/** input type for inserting object relation for remote table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Obj_Rel_Insert_Input = {
  data: Product_Bill_Of_Material_Items_Insert_Input;
  on_conflict?: InputMaybe<Product_Bill_Of_Material_Items_On_Conflict>;
};

/** on conflict condition type for table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_On_Conflict = {
  constraint: Product_Bill_Of_Material_Items_Constraint;
  update_columns: Array<Product_Bill_Of_Material_Items_Update_Column>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};

/** ordering options when selecting data from "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product_bill_of_material_items" */
export enum Product_Bill_Of_Material_Items_Select_Column {
  /** column name */
  BillOfMaterialId = 'bill_of_material_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Set_Input = {
  bill_of_material_id?: InputMaybe<Scalars['Int']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Bill_Of_Material_Items_Stddev_Fields = {
  __typename?: 'product_bill_of_material_items_stddev_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Stddev_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Bill_Of_Material_Items_Stddev_Pop_Fields = {
  __typename?: 'product_bill_of_material_items_stddev_pop_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Stddev_Pop_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Bill_Of_Material_Items_Stddev_Samp_Fields = {
  __typename?: 'product_bill_of_material_items_stddev_samp_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Stddev_Samp_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Bill_Of_Material_Items_Sum_Fields = {
  __typename?: 'product_bill_of_material_items_sum_fields';
  bill_of_material_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Sum_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** update columns of table "product_bill_of_material_items" */
export enum Product_Bill_Of_Material_Items_Update_Column {
  /** column name */
  BillOfMaterialId = 'bill_of_material_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Product_Bill_Of_Material_Items_Var_Pop_Fields = {
  __typename?: 'product_bill_of_material_items_var_pop_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Var_Pop_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Bill_Of_Material_Items_Var_Samp_Fields = {
  __typename?: 'product_bill_of_material_items_var_samp_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Var_Samp_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Bill_Of_Material_Items_Variance_Fields = {
  __typename?: 'product_bill_of_material_items_variance_fields';
  bill_of_material_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_bill_of_material_items" */
export type Product_Bill_Of_Material_Items_Variance_Order_By = {
  bill_of_material_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Product_Bool_Exp>>>;
  _not?: InputMaybe<Product_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Product_Bool_Exp>>>;
  alert_of_expiration_on?: InputMaybe<Int_Comparison_Exp>;
  allowed_min_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  bill_of_material_items?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
  category?: InputMaybe<Product_Category_Bool_Exp>;
  cost?: InputMaybe<Numeric_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expirable?: InputMaybe<Boolean_Comparison_Exp>;
  expire_on?: InputMaybe<Int_Comparison_Exp>;
  external_product_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  manufacturable?: InputMaybe<Boolean_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product_category?: InputMaybe<Int_Comparison_Exp>;
  product_lot?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  purchasable?: InputMaybe<Boolean_Comparison_Exp>;
  sellable?: InputMaybe<Boolean_Comparison_Exp>;
  sku?: InputMaybe<String_Comparison_Exp>;
  storable?: InputMaybe<Boolean_Comparison_Exp>;
  trackerable?: InputMaybe<Boolean_Comparison_Exp>;
  unit_of_measure?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** columns and relationships of "product_category" */
export type Product_Category = {
  __typename?: 'product_category';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "product_category" */
export type Product_Category_Aggregate = {
  __typename?: 'product_category_aggregate';
  aggregate?: Maybe<Product_Category_Aggregate_Fields>;
  nodes: Array<Product_Category>;
};

/** aggregate fields of "product_category" */
export type Product_Category_Aggregate_Fields = {
  __typename?: 'product_category_aggregate_fields';
  avg?: Maybe<Product_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Product_Category_Max_Fields>;
  min?: Maybe<Product_Category_Min_Fields>;
  stddev?: Maybe<Product_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Category_Sum_Fields>;
  var_pop?: Maybe<Product_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Category_Var_Samp_Fields>;
  variance?: Maybe<Product_Category_Variance_Fields>;
};


/** aggregate fields of "product_category" */
export type Product_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Product_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_category" */
export type Product_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Product_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Product_Category_Max_Order_By>;
  min?: InputMaybe<Product_Category_Min_Order_By>;
  stddev?: InputMaybe<Product_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Product_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Product_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Product_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Product_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Product_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Product_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_category" */
export type Product_Category_Arr_Rel_Insert_Input = {
  data: Array<Product_Category_Insert_Input>;
  on_conflict?: InputMaybe<Product_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Category_Avg_Fields = {
  __typename?: 'product_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_category" */
export type Product_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_category". All fields are combined with a logical 'AND'. */
export type Product_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Product_Category_Bool_Exp>>>;
  _not?: InputMaybe<Product_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Product_Category_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_category" */
export enum Product_Category_Constraint {
  /** unique or primary key constraint */
  ProductCategoryNameKey = 'product_category_name_key',
  /** unique or primary key constraint */
  ProductCategoryPkey = 'product_category_pkey'
}

/** input type for incrementing integer column in table "product_category" */
export type Product_Category_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "product_category" */
export type Product_Category_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Category_Max_Fields = {
  __typename?: 'product_category_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product_category" */
export type Product_Category_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Category_Min_Fields = {
  __typename?: 'product_category_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product_category" */
export type Product_Category_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product_category" */
export type Product_Category_Mutation_Response = {
  __typename?: 'product_category_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product_Category>;
};

/** input type for inserting object relation for remote table "product_category" */
export type Product_Category_Obj_Rel_Insert_Input = {
  data: Product_Category_Insert_Input;
  on_conflict?: InputMaybe<Product_Category_On_Conflict>;
};

/** on conflict condition type for table "product_category" */
export type Product_Category_On_Conflict = {
  constraint: Product_Category_Constraint;
  update_columns: Array<Product_Category_Update_Column>;
  where?: InputMaybe<Product_Category_Bool_Exp>;
};

/** ordering options when selecting data from "product_category" */
export type Product_Category_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "product_category" */
export type Product_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product_category" */
export enum Product_Category_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "product_category" */
export type Product_Category_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Category_Stddev_Fields = {
  __typename?: 'product_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_category" */
export type Product_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Category_Stddev_Pop_Fields = {
  __typename?: 'product_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_category" */
export type Product_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Category_Stddev_Samp_Fields = {
  __typename?: 'product_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_category" */
export type Product_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Category_Sum_Fields = {
  __typename?: 'product_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product_category" */
export type Product_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "product_category" */
export enum Product_Category_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Product_Category_Var_Pop_Fields = {
  __typename?: 'product_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_category" */
export type Product_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Category_Var_Samp_Fields = {
  __typename?: 'product_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_category" */
export type Product_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Category_Variance_Fields = {
  __typename?: 'product_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_category" */
export type Product_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint */
  ProductNameKey = 'product_name_key',
  /** unique or primary key constraint */
  ProductPkey = 'product_pkey',
  /** unique or primary key constraint */
  ProductSkuKey = 'product_sku_key'
}

/** input type for incrementing integer column in table "product" */
export type Product_Inc_Input = {
  alert_of_expiration_on?: InputMaybe<Scalars['Int']>;
  allowed_min_quantity?: InputMaybe<Scalars['numeric']>;
  cost?: InputMaybe<Scalars['numeric']>;
  expire_on?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_category?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  alert_of_expiration_on?: InputMaybe<Scalars['Int']>;
  allowed_min_quantity?: InputMaybe<Scalars['numeric']>;
  bill_of_material_items?: InputMaybe<Product_Bill_Of_Material_Items_Arr_Rel_Insert_Input>;
  category?: InputMaybe<Product_Category_Obj_Rel_Insert_Input>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expirable?: InputMaybe<Scalars['Boolean']>;
  expire_on?: InputMaybe<Scalars['Int']>;
  external_product_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  manufacturable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_category?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  purchasable?: InputMaybe<Scalars['Boolean']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  storable?: InputMaybe<Scalars['Boolean']>;
  trackerable?: InputMaybe<Scalars['Boolean']>;
  unit_of_measure?: InputMaybe<Unit_Of_Measure_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  alert_of_expiration_on?: Maybe<Scalars['Int']>;
  allowed_min_quantity?: Maybe<Scalars['numeric']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expire_on?: Maybe<Scalars['Int']>;
  external_product_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  product_category?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  external_product_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  alert_of_expiration_on?: Maybe<Scalars['Int']>;
  allowed_min_quantity?: Maybe<Scalars['numeric']>;
  cost?: Maybe<Scalars['numeric']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  expire_on?: Maybe<Scalars['Int']>;
  external_product_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['numeric']>;
  product_category?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  sku?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  external_product_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  on_conflict?: InputMaybe<Product_On_Conflict>;
};

/** on conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns: Array<Product_Update_Column>;
  where?: InputMaybe<Product_Bool_Exp>;
};

/** ordering options when selecting data from "product" */
export type Product_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  bill_of_material_items_aggregate?: InputMaybe<Product_Bill_Of_Material_Items_Aggregate_Order_By>;
  category?: InputMaybe<Product_Category_Order_By>;
  cost?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expirable?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  external_product_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturable?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_lot_aggregate?: InputMaybe<Opb_Product_Lot_Aggregate_Order_By>;
  product_uom?: InputMaybe<Order_By>;
  purchasable?: InputMaybe<Order_By>;
  sellable?: InputMaybe<Order_By>;
  sku?: InputMaybe<Order_By>;
  storable?: InputMaybe<Order_By>;
  trackerable?: InputMaybe<Order_By>;
  unit_of_measure?: InputMaybe<Unit_Of_Measure_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "product" */
export type Product_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  AlertOfExpirationOn = 'alert_of_expiration_on',
  /** column name */
  AllowedMinQuantity = 'allowed_min_quantity',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Expirable = 'expirable',
  /** column name */
  ExpireOn = 'expire_on',
  /** column name */
  ExternalProductId = 'external_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ProductCategory = 'product_category',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Sku = 'sku',
  /** column name */
  Storable = 'storable',
  /** column name */
  Trackerable = 'trackerable',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  alert_of_expiration_on?: InputMaybe<Scalars['Int']>;
  allowed_min_quantity?: InputMaybe<Scalars['numeric']>;
  cost?: InputMaybe<Scalars['numeric']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expirable?: InputMaybe<Scalars['Boolean']>;
  expire_on?: InputMaybe<Scalars['Int']>;
  external_product_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  manufacturable?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<Scalars['String']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_category?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  purchasable?: InputMaybe<Scalars['Boolean']>;
  sellable?: InputMaybe<Scalars['Boolean']>;
  sku?: InputMaybe<Scalars['String']>;
  storable?: InputMaybe<Scalars['Boolean']>;
  trackerable?: InputMaybe<Scalars['Boolean']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  alert_of_expiration_on?: Maybe<Scalars['Int']>;
  allowed_min_quantity?: Maybe<Scalars['numeric']>;
  cost?: Maybe<Scalars['numeric']>;
  expire_on?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_category?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  AlertOfExpirationOn = 'alert_of_expiration_on',
  /** column name */
  AllowedMinQuantity = 'allowed_min_quantity',
  /** column name */
  Cost = 'cost',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Expirable = 'expirable',
  /** column name */
  ExpireOn = 'expire_on',
  /** column name */
  ExternalProductId = 'external_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  Manufacturable = 'manufacturable',
  /** column name */
  Name = 'name',
  /** column name */
  Price = 'price',
  /** column name */
  ProductCategory = 'product_category',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Purchasable = 'purchasable',
  /** column name */
  Sellable = 'sellable',
  /** column name */
  Sku = 'sku',
  /** column name */
  Storable = 'storable',
  /** column name */
  Trackerable = 'trackerable',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  alert_of_expiration_on?: Maybe<Scalars['Float']>;
  allowed_min_quantity?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  expire_on?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_category?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  alert_of_expiration_on?: InputMaybe<Order_By>;
  allowed_min_quantity?: InputMaybe<Order_By>;
  cost?: InputMaybe<Order_By>;
  expire_on?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_category?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
};

/** columns and relationships of "quantity_on_hand" */
export type Quantity_On_Hand = {
  __typename?: 'quantity_on_hand';
  sum?: Maybe<Scalars['numeric']>;
};

/** aggregated selection of "quantity_on_hand" */
export type Quantity_On_Hand_Aggregate = {
  __typename?: 'quantity_on_hand_aggregate';
  aggregate?: Maybe<Quantity_On_Hand_Aggregate_Fields>;
  nodes: Array<Quantity_On_Hand>;
};

/** aggregate fields of "quantity_on_hand" */
export type Quantity_On_Hand_Aggregate_Fields = {
  __typename?: 'quantity_on_hand_aggregate_fields';
  avg?: Maybe<Quantity_On_Hand_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Quantity_On_Hand_Max_Fields>;
  min?: Maybe<Quantity_On_Hand_Min_Fields>;
  stddev?: Maybe<Quantity_On_Hand_Stddev_Fields>;
  stddev_pop?: Maybe<Quantity_On_Hand_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Quantity_On_Hand_Stddev_Samp_Fields>;
  sum?: Maybe<Quantity_On_Hand_Sum_Fields>;
  var_pop?: Maybe<Quantity_On_Hand_Var_Pop_Fields>;
  var_samp?: Maybe<Quantity_On_Hand_Var_Samp_Fields>;
  variance?: Maybe<Quantity_On_Hand_Variance_Fields>;
};


/** aggregate fields of "quantity_on_hand" */
export type Quantity_On_Hand_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Quantity_On_Hand_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "quantity_on_hand" */
export type Quantity_On_Hand_Aggregate_Order_By = {
  avg?: InputMaybe<Quantity_On_Hand_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Quantity_On_Hand_Max_Order_By>;
  min?: InputMaybe<Quantity_On_Hand_Min_Order_By>;
  stddev?: InputMaybe<Quantity_On_Hand_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Quantity_On_Hand_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Quantity_On_Hand_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Quantity_On_Hand_Sum_Order_By>;
  var_pop?: InputMaybe<Quantity_On_Hand_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Quantity_On_Hand_Var_Samp_Order_By>;
  variance?: InputMaybe<Quantity_On_Hand_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "quantity_on_hand" */
export type Quantity_On_Hand_Arr_Rel_Insert_Input = {
  data: Array<Quantity_On_Hand_Insert_Input>;
};

/** aggregate avg on columns */
export type Quantity_On_Hand_Avg_Fields = {
  __typename?: 'quantity_on_hand_avg_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Avg_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "quantity_on_hand". All fields are combined with a logical 'AND'. */
export type Quantity_On_Hand_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Quantity_On_Hand_Bool_Exp>>>;
  _not?: InputMaybe<Quantity_On_Hand_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Quantity_On_Hand_Bool_Exp>>>;
  sum?: InputMaybe<Numeric_Comparison_Exp>;
};

/** input type for incrementing integer column in table "quantity_on_hand" */
export type Quantity_On_Hand_Inc_Input = {
  sum?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "quantity_on_hand" */
export type Quantity_On_Hand_Insert_Input = {
  sum?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Quantity_On_Hand_Max_Fields = {
  __typename?: 'quantity_on_hand_max_fields';
  sum?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Max_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Quantity_On_Hand_Min_Fields = {
  __typename?: 'quantity_on_hand_min_fields';
  sum?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Min_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "quantity_on_hand" */
export type Quantity_On_Hand_Mutation_Response = {
  __typename?: 'quantity_on_hand_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Quantity_On_Hand>;
};

/** input type for inserting object relation for remote table "quantity_on_hand" */
export type Quantity_On_Hand_Obj_Rel_Insert_Input = {
  data: Quantity_On_Hand_Insert_Input;
};

/** ordering options when selecting data from "quantity_on_hand" */
export type Quantity_On_Hand_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** select columns of table "quantity_on_hand" */
export enum Quantity_On_Hand_Select_Column {
  /** column name */
  Sum = 'sum'
}

/** input type for updating data in table "quantity_on_hand" */
export type Quantity_On_Hand_Set_Input = {
  sum?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Quantity_On_Hand_Stddev_Fields = {
  __typename?: 'quantity_on_hand_stddev_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Stddev_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Quantity_On_Hand_Stddev_Pop_Fields = {
  __typename?: 'quantity_on_hand_stddev_pop_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Stddev_Pop_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Quantity_On_Hand_Stddev_Samp_Fields = {
  __typename?: 'quantity_on_hand_stddev_samp_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Stddev_Samp_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Quantity_On_Hand_Sum_Fields = {
  __typename?: 'quantity_on_hand_sum_fields';
  sum?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Sum_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Quantity_On_Hand_Var_Pop_Fields = {
  __typename?: 'quantity_on_hand_var_pop_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Var_Pop_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Quantity_On_Hand_Var_Samp_Fields = {
  __typename?: 'quantity_on_hand_var_samp_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Var_Samp_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Quantity_On_Hand_Variance_Fields = {
  __typename?: 'quantity_on_hand_variance_fields';
  sum?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "quantity_on_hand" */
export type Quantity_On_Hand_Variance_Order_By = {
  sum?: InputMaybe<Order_By>;
};

/** query root */
export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "daily_existence_report" */
  daily_existence_report: Array<Daily_Existence_Report>;
  /** fetch aggregated fields from the table: "daily_existence_report" */
  daily_existence_report_aggregate: Daily_Existence_Report_Aggregate;
  /** fetch data from the table: "daily_existence_report" using primary key columns */
  daily_existence_report_by_pk?: Maybe<Daily_Existence_Report>;
  /** fetch data from the table: "import_sales_order" */
  import_sales_order: Array<Import_Sales_Order>;
  /** fetch aggregated fields from the table: "import_sales_order" */
  import_sales_order_aggregate: Import_Sales_Order_Aggregate;
  /** fetch data from the table: "import_sales_order" using primary key columns */
  import_sales_order_by_pk?: Maybe<Import_Sales_Order>;
  /** fetch data from the table: "modules" */
  modules: Array<Modules>;
  /** fetch aggregated fields from the table: "modules" */
  modules_aggregate: Modules_Aggregate;
  /** fetch data from the table: "modules" using primary key columns */
  modules_by_pk?: Maybe<Modules>;
  /** execute function "opb_execute_daily_report" which returns "daily_existence_report" */
  opb_execute_daily_report: Array<Daily_Existence_Report>;
  /** execute function "opb_execute_daily_report" and query aggregates on result of table type "daily_existence_report" */
  opb_execute_daily_report_aggregate: Daily_Existence_Report_Aggregate;
  /** fetch data from the table: "opb_existence" */
  opb_existence: Array<Opb_Existence>;
  /** fetch aggregated fields from the table: "opb_existence" */
  opb_existence_aggregate: Opb_Existence_Aggregate;
  /** fetch data from the table: "opb_ipv_report" */
  opb_ipv_report: Array<Opb_Ipv_Report>;
  /** fetch aggregated fields from the table: "opb_ipv_report" */
  opb_ipv_report_aggregate: Opb_Ipv_Report_Aggregate;
  /** fetch data from the table: "opb_product_existence" */
  opb_product_existence: Array<Opb_Product_Existence>;
  /** fetch aggregated fields from the table: "opb_product_existence" */
  opb_product_existence_aggregate: Opb_Product_Existence_Aggregate;
  /** fetch data from the table: "opb_product_lot" */
  opb_product_lot: Array<Opb_Product_Lot>;
  /** fetch aggregated fields from the table: "opb_product_lot" */
  opb_product_lot_aggregate: Opb_Product_Lot_Aggregate;
  /** fetch data from the table: "opb_product_min_quantity_alert" */
  opb_product_min_quantity_alert: Array<Opb_Product_Min_Quantity_Alert>;
  /** fetch aggregated fields from the table: "opb_product_min_quantity_alert" */
  opb_product_min_quantity_alert_aggregate: Opb_Product_Min_Quantity_Alert_Aggregate;
  /** fetch data from the table: "opb_product_near_to_expire" */
  opb_product_near_to_expire: Array<Opb_Product_Near_To_Expire>;
  /** fetch aggregated fields from the table: "opb_product_near_to_expire" */
  opb_product_near_to_expire_aggregate: Opb_Product_Near_To_Expire_Aggregate;
  /** execute function "opb_profit_report" which returns "opb_profit_report_template" */
  opb_profit_report: Array<Opb_Profit_Report_Template>;
  /** execute function "opb_profit_report" and query aggregates on result of table type "opb_profit_report_template" */
  opb_profit_report_aggregate: Opb_Profit_Report_Template_Aggregate;
  /** fetch data from the table: "opb_profit_report_template" */
  opb_profit_report_template: Array<Opb_Profit_Report_Template>;
  /** fetch aggregated fields from the table: "opb_profit_report_template" */
  opb_profit_report_template_aggregate: Opb_Profit_Report_Template_Aggregate;
  /** fetch data from the table: "opb_purchases_view" */
  opb_purchases_view: Array<Opb_Purchases_View>;
  /** fetch aggregated fields from the table: "opb_purchases_view" */
  opb_purchases_view_aggregate: Opb_Purchases_View_Aggregate;
  /** fetch data from the table: "opb_sales_view" */
  opb_sales_view: Array<Opb_Sales_View>;
  /** fetch aggregated fields from the table: "opb_sales_view" */
  opb_sales_view_aggregate: Opb_Sales_View_Aggregate;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product_bill_of_material_items" */
  product_bill_of_material_items: Array<Product_Bill_Of_Material_Items>;
  /** fetch aggregated fields from the table: "product_bill_of_material_items" */
  product_bill_of_material_items_aggregate: Product_Bill_Of_Material_Items_Aggregate;
  /** fetch data from the table: "product_bill_of_material_items" using primary key columns */
  product_bill_of_material_items_by_pk?: Maybe<Product_Bill_Of_Material_Items>;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_category" */
  product_category: Array<Product_Category>;
  /** fetch aggregated fields from the table: "product_category" */
  product_category_aggregate: Product_Category_Aggregate;
  /** fetch data from the table: "product_category" using primary key columns */
  product_category_by_pk?: Maybe<Product_Category>;
  /** fetch data from the table: "quantity_on_hand" */
  quantity_on_hand: Array<Quantity_On_Hand>;
  /** fetch aggregated fields from the table: "quantity_on_hand" */
  quantity_on_hand_aggregate: Quantity_On_Hand_Aggregate;
  /** fetch data from the table: "stock_deliver_status" */
  stock_deliver_status: Array<Stock_Deliver_Status>;
  /** fetch aggregated fields from the table: "stock_deliver_status" */
  stock_deliver_status_aggregate: Stock_Deliver_Status_Aggregate;
  /** fetch data from the table: "stock_deliver_status" using primary key columns */
  stock_deliver_status_by_pk?: Maybe<Stock_Deliver_Status>;
  /** fetch data from the table: "stock_location" */
  stock_location: Array<Stock_Location>;
  /** fetch aggregated fields from the table: "stock_location" */
  stock_location_aggregate: Stock_Location_Aggregate;
  /** fetch data from the table: "stock_location" using primary key columns */
  stock_location_by_pk?: Maybe<Stock_Location>;
  /** fetch data from the table: "stock_location_type" */
  stock_location_type: Array<Stock_Location_Type>;
  /** fetch aggregated fields from the table: "stock_location_type" */
  stock_location_type_aggregate: Stock_Location_Type_Aggregate;
  /** fetch data from the table: "stock_location_type" using primary key columns */
  stock_location_type_by_pk?: Maybe<Stock_Location_Type>;
  /** fetch data from the table: "stock_manufacturing_order_item" */
  stock_manufacturing_order_item: Array<Stock_Manufacturing_Order_Item>;
  /** fetch aggregated fields from the table: "stock_manufacturing_order_item" */
  stock_manufacturing_order_item_aggregate: Stock_Manufacturing_Order_Item_Aggregate;
  /** fetch data from the table: "stock_manufacturing_order_item" using primary key columns */
  stock_manufacturing_order_item_by_pk?: Maybe<Stock_Manufacturing_Order_Item>;
  /** fetch data from the table: "stock_operation" */
  stock_operation: Array<Stock_Operation>;
  /** fetch aggregated fields from the table: "stock_operation" */
  stock_operation_aggregate: Stock_Operation_Aggregate;
  /** fetch data from the table: "stock_operation" using primary key columns */
  stock_operation_by_pk?: Maybe<Stock_Operation>;
  /** fetch data from the table: "stock_operation_type" */
  stock_operation_type: Array<Stock_Operation_Type>;
  /** fetch aggregated fields from the table: "stock_operation_type" */
  stock_operation_type_aggregate: Stock_Operation_Type_Aggregate;
  /** fetch data from the table: "stock_operation_type" using primary key columns */
  stock_operation_type_by_pk?: Maybe<Stock_Operation_Type>;
  /** fetch data from the table: "stock_order" */
  stock_order: Array<Stock_Order>;
  /** fetch aggregated fields from the table: "stock_order" */
  stock_order_aggregate: Stock_Order_Aggregate;
  /** fetch data from the table: "stock_order" using primary key columns */
  stock_order_by_pk?: Maybe<Stock_Order>;
  /** fetch data from the table: "stock_order_execution_errors" */
  stock_order_execution_errors: Array<Stock_Order_Execution_Errors>;
  /** fetch aggregated fields from the table: "stock_order_execution_errors" */
  stock_order_execution_errors_aggregate: Stock_Order_Execution_Errors_Aggregate;
  /** fetch data from the table: "stock_order_execution_errors" using primary key columns */
  stock_order_execution_errors_by_pk?: Maybe<Stock_Order_Execution_Errors>;
  /** fetch data from the table: "stock_order_status" */
  stock_order_status: Array<Stock_Order_Status>;
  /** fetch aggregated fields from the table: "stock_order_status" */
  stock_order_status_aggregate: Stock_Order_Status_Aggregate;
  /** fetch data from the table: "stock_order_status" using primary key columns */
  stock_order_status_by_pk?: Maybe<Stock_Order_Status>;
  /** fetch data from the table: "stock_order_type" */
  stock_order_type: Array<Stock_Order_Type>;
  /** fetch aggregated fields from the table: "stock_order_type" */
  stock_order_type_aggregate: Stock_Order_Type_Aggregate;
  /** fetch data from the table: "stock_order_type" using primary key columns */
  stock_order_type_by_pk?: Maybe<Stock_Order_Type>;
  /** fetch data from the table: "stock_partner" */
  stock_partner: Array<Stock_Partner>;
  /** fetch aggregated fields from the table: "stock_partner" */
  stock_partner_aggregate: Stock_Partner_Aggregate;
  /** fetch data from the table: "stock_partner" using primary key columns */
  stock_partner_by_pk?: Maybe<Stock_Partner>;
  /** fetch data from the table: "stock_production_lot" */
  stock_production_lot: Array<Stock_Production_Lot>;
  /** fetch aggregated fields from the table: "stock_production_lot" */
  stock_production_lot_aggregate: Stock_Production_Lot_Aggregate;
  /** fetch data from the table: "stock_production_lot" using primary key columns */
  stock_production_lot_by_pk?: Maybe<Stock_Production_Lot>;
  /** fetch data from the table: "stock_purchase_order_item" */
  stock_purchase_order_item: Array<Stock_Purchase_Order_Item>;
  /** fetch aggregated fields from the table: "stock_purchase_order_item" */
  stock_purchase_order_item_aggregate: Stock_Purchase_Order_Item_Aggregate;
  /** fetch data from the table: "stock_purchase_order_item" using primary key columns */
  stock_purchase_order_item_by_pk?: Maybe<Stock_Purchase_Order_Item>;
  /** fetch data from the table: "stock_quantity" */
  stock_quantity: Array<Stock_Quantity>;
  /** fetch aggregated fields from the table: "stock_quantity" */
  stock_quantity_aggregate: Stock_Quantity_Aggregate;
  /** fetch data from the table: "stock_quantity" using primary key columns */
  stock_quantity_by_pk?: Maybe<Stock_Quantity>;
  /** fetch data from the table: "stock_receipts_order_item" */
  stock_receipts_order_item: Array<Stock_Receipts_Order_Item>;
  /** fetch aggregated fields from the table: "stock_receipts_order_item" */
  stock_receipts_order_item_aggregate: Stock_Receipts_Order_Item_Aggregate;
  /** fetch data from the table: "stock_receipts_order_item" using primary key columns */
  stock_receipts_order_item_by_pk?: Maybe<Stock_Receipts_Order_Item>;
  /** fetch data from the table: "stock_sales_order_item" */
  stock_sales_order_item: Array<Stock_Sales_Order_Item>;
  /** fetch aggregated fields from the table: "stock_sales_order_item" */
  stock_sales_order_item_aggregate: Stock_Sales_Order_Item_Aggregate;
  /** fetch data from the table: "stock_sales_order_item" using primary key columns */
  stock_sales_order_item_by_pk?: Maybe<Stock_Sales_Order_Item>;
  /** fetch data from the table: "stock_transfer_order_item" */
  stock_transfer_order_item: Array<Stock_Transfer_Order_Item>;
  /** fetch aggregated fields from the table: "stock_transfer_order_item" */
  stock_transfer_order_item_aggregate: Stock_Transfer_Order_Item_Aggregate;
  /** fetch data from the table: "stock_transfer_order_item" using primary key columns */
  stock_transfer_order_item_by_pk?: Maybe<Stock_Transfer_Order_Item>;
  /** fetch data from the table: "unit_of_measure" */
  unit_of_measure: Array<Unit_Of_Measure>;
  /** fetch aggregated fields from the table: "unit_of_measure" */
  unit_of_measure_aggregate: Unit_Of_Measure_Aggregate;
  /** fetch data from the table: "unit_of_measure" using primary key columns */
  unit_of_measure_by_pk?: Maybe<Unit_Of_Measure>;
  /** fetch data from the table: "unit_of_measure_category" */
  unit_of_measure_category: Array<Unit_Of_Measure_Category>;
  /** fetch aggregated fields from the table: "unit_of_measure_category" */
  unit_of_measure_category_aggregate: Unit_Of_Measure_Category_Aggregate;
  /** fetch data from the table: "unit_of_measure_category" using primary key columns */
  unit_of_measure_category_by_pk?: Maybe<Unit_Of_Measure_Category>;
  /** fetch data from the table: "unit_of_measure_conversion" */
  unit_of_measure_conversion: Array<Unit_Of_Measure_Conversion>;
  /** fetch aggregated fields from the table: "unit_of_measure_conversion" */
  unit_of_measure_conversion_aggregate: Unit_Of_Measure_Conversion_Aggregate;
  /** fetch data from the table: "unit_of_measure_conversion" using primary key columns */
  unit_of_measure_conversion_by_pk?: Maybe<Unit_Of_Measure_Conversion>;
  /** fetch data from the table: "user_permission" */
  user_permission: Array<User_Permission>;
  /** fetch aggregated fields from the table: "user_permission" */
  user_permission_aggregate: User_Permission_Aggregate;
  /** fetch data from the table: "user_permission" using primary key columns */
  user_permission_by_pk?: Maybe<User_Permission>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** query root */
export type Query_RootAuth_Account_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** query root */
export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** query root */
export type Query_RootAuth_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};


/** query root */
export type Query_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** query root */
export type Query_RootDaily_Existence_ReportArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** query root */
export type Query_RootDaily_Existence_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** query root */
export type Query_RootDaily_Existence_Report_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootImport_Sales_OrderArgs = {
  distinct_on?: InputMaybe<Array<Import_Sales_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Import_Sales_Order_Order_By>>;
  where?: InputMaybe<Import_Sales_Order_Bool_Exp>;
};


/** query root */
export type Query_RootImport_Sales_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Sales_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Import_Sales_Order_Order_By>>;
  where?: InputMaybe<Import_Sales_Order_Bool_Exp>;
};


/** query root */
export type Query_RootImport_Sales_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootModulesArgs = {
  distinct_on?: InputMaybe<Array<Modules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Modules_Order_By>>;
  where?: InputMaybe<Modules_Bool_Exp>;
};


/** query root */
export type Query_RootModules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Modules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Modules_Order_By>>;
  where?: InputMaybe<Modules_Bool_Exp>;
};


/** query root */
export type Query_RootModules_By_PkArgs = {
  module_name: Scalars['String'];
};


/** query root */
export type Query_RootOpb_Execute_Daily_ReportArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Execute_Daily_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_ExistenceArgs = {
  distinct_on?: InputMaybe<Array<Opb_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Existence_Order_By>>;
  where?: InputMaybe<Opb_Existence_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Existence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Existence_Order_By>>;
  where?: InputMaybe<Opb_Existence_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Ipv_ReportArgs = {
  distinct_on?: InputMaybe<Array<Opb_Ipv_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Ipv_Report_Order_By>>;
  where?: InputMaybe<Opb_Ipv_Report_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Ipv_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Ipv_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Ipv_Report_Order_By>>;
  where?: InputMaybe<Opb_Ipv_Report_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_ExistenceArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Existence_Order_By>>;
  where?: InputMaybe<Opb_Product_Existence_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Existence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Existence_Order_By>>;
  where?: InputMaybe<Opb_Product_Existence_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_LotArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Min_Quantity_AlertArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Order_By>>;
  where?: InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Min_Quantity_Alert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Order_By>>;
  where?: InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Near_To_ExpireArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Near_To_Expire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Near_To_Expire_Order_By>>;
  where?: InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Product_Near_To_Expire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Near_To_Expire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Near_To_Expire_Order_By>>;
  where?: InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Profit_ReportArgs = {
  args: Opb_Profit_Report_Args;
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Profit_Report_AggregateArgs = {
  args: Opb_Profit_Report_Args;
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Profit_Report_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Profit_Report_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Purchases_ViewArgs = {
  distinct_on?: InputMaybe<Array<Opb_Purchases_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Purchases_View_Order_By>>;
  where?: InputMaybe<Opb_Purchases_View_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Purchases_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Purchases_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Purchases_View_Order_By>>;
  where?: InputMaybe<Opb_Purchases_View_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Sales_ViewArgs = {
  distinct_on?: InputMaybe<Array<Opb_Sales_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Sales_View_Order_By>>;
  where?: InputMaybe<Opb_Sales_View_Bool_Exp>;
};


/** query root */
export type Query_RootOpb_Sales_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Sales_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Sales_View_Order_By>>;
  where?: InputMaybe<Opb_Sales_View_Bool_Exp>;
};


/** query root */
export type Query_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_Bill_Of_Material_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_Bill_Of_Material_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_Bill_Of_Material_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootProduct_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Category_Order_By>>;
  where?: InputMaybe<Product_Category_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Category_Order_By>>;
  where?: InputMaybe<Product_Category_Bool_Exp>;
};


/** query root */
export type Query_RootProduct_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootQuantity_On_HandArgs = {
  distinct_on?: InputMaybe<Array<Quantity_On_Hand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quantity_On_Hand_Order_By>>;
  where?: InputMaybe<Quantity_On_Hand_Bool_Exp>;
};


/** query root */
export type Query_RootQuantity_On_Hand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quantity_On_Hand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quantity_On_Hand_Order_By>>;
  where?: InputMaybe<Quantity_On_Hand_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Deliver_StatusArgs = {
  distinct_on?: InputMaybe<Array<Stock_Deliver_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Deliver_Status_Order_By>>;
  where?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Deliver_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Deliver_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Deliver_Status_Order_By>>;
  where?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Deliver_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** query root */
export type Query_RootStock_LocationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Order_By>>;
  where?: InputMaybe<Stock_Location_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Order_By>>;
  where?: InputMaybe<Stock_Location_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Location_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Type_Order_By>>;
  where?: InputMaybe<Stock_Location_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Location_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Type_Order_By>>;
  where?: InputMaybe<Stock_Location_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Location_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootStock_Manufacturing_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Manufacturing_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Manufacturing_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStock_OperationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Order_By>>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Operation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Order_By>>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Operation_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Operation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Type_Order_By>>;
  where?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Operation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Type_Order_By>>;
  where?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Operation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** query root */
export type Query_RootStock_OrderArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By>>;
  where?: InputMaybe<Stock_Order_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By>>;
  where?: InputMaybe<Stock_Order_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStock_Order_Execution_ErrorsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Execution_Errors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Execution_Errors_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Order_StatusArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Status_Order_By>>;
  where?: InputMaybe<Stock_Order_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Status_Order_By>>;
  where?: InputMaybe<Stock_Order_Status_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** query root */
export type Query_RootStock_Order_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Type_Order_By>>;
  where?: InputMaybe<Stock_Order_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Type_Order_By>>;
  where?: InputMaybe<Stock_Order_Type_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Order_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** query root */
export type Query_RootStock_PartnerArgs = {
  distinct_on?: InputMaybe<Array<Stock_Partner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Partner_Order_By>>;
  where?: InputMaybe<Stock_Partner_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Partner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Partner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Partner_Order_By>>;
  where?: InputMaybe<Stock_Partner_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Partner_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Production_LotArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Production_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Production_Lot_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Purchase_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Purchase_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Purchase_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStock_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Stock_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Quantity_Order_By>>;
  where?: InputMaybe<Stock_Quantity_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Quantity_Order_By>>;
  where?: InputMaybe<Stock_Quantity_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Quantity_By_PkArgs = {
  location_id: Scalars['Int'];
  lot_id: Scalars['Int'];
  product_id: Scalars['Int'];
};


/** query root */
export type Query_RootStock_Receipts_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Receipts_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Receipts_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStock_Sales_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Sales_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Sales_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootStock_Transfer_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Transfer_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};


/** query root */
export type Query_RootStock_Transfer_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** query root */
export type Query_RootUnit_Of_MeasureArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUnit_Of_Measure_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Category_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Category_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** query root */
export type Query_RootUnit_Of_Measure_ConversionArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_Conversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};


/** query root */
export type Query_RootUnit_Of_Measure_Conversion_By_PkArgs = {
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
};


/** query root */
export type Query_RootUser_PermissionArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Permission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};


/** query root */
export type Query_RootUser_Permission_By_PkArgs = {
  module_name: Modules_Enum;
  user_id: Scalars['uuid'];
};


/** query root */
export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** query root */
export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** columns and relationships of "stock_deliver_status" */
export type Stock_Deliver_Status = {
  __typename?: 'stock_deliver_status';
  description?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** aggregated selection of "stock_deliver_status" */
export type Stock_Deliver_Status_Aggregate = {
  __typename?: 'stock_deliver_status_aggregate';
  aggregate?: Maybe<Stock_Deliver_Status_Aggregate_Fields>;
  nodes: Array<Stock_Deliver_Status>;
};

/** aggregate fields of "stock_deliver_status" */
export type Stock_Deliver_Status_Aggregate_Fields = {
  __typename?: 'stock_deliver_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Deliver_Status_Max_Fields>;
  min?: Maybe<Stock_Deliver_Status_Min_Fields>;
};


/** aggregate fields of "stock_deliver_status" */
export type Stock_Deliver_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Deliver_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_deliver_status" */
export type Stock_Deliver_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Deliver_Status_Max_Order_By>;
  min?: InputMaybe<Stock_Deliver_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stock_deliver_status" */
export type Stock_Deliver_Status_Arr_Rel_Insert_Input = {
  data: Array<Stock_Deliver_Status_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Deliver_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stock_deliver_status". All fields are combined with a logical 'AND'. */
export type Stock_Deliver_Status_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Deliver_Status_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Deliver_Status_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_deliver_status" */
export enum Stock_Deliver_Status_Constraint {
  /** unique or primary key constraint */
  StockMoveStatusPkey = 'stock_move_status_pkey'
}

export enum Stock_Deliver_Status_Enum {
  Done = 'DONE',
  /** the move can not be done */
  Fail = 'FAIL',
  /** Reverted order */
  Reverted = 'REVERTED',
  ToDo = 'TO_DO',
  Validated = 'VALIDATED'
}

/** expression to compare columns of type stock_deliver_status_enum. All fields are combined with logical 'AND'. */
export type Stock_Deliver_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Deliver_Status_Enum>;
  _in?: InputMaybe<Array<Stock_Deliver_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Deliver_Status_Enum>;
  _nin?: InputMaybe<Array<Stock_Deliver_Status_Enum>>;
};

/** input type for inserting data into table "stock_deliver_status" */
export type Stock_Deliver_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Deliver_Status_Max_Fields = {
  __typename?: 'stock_deliver_status_max_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_deliver_status" */
export type Stock_Deliver_Status_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Deliver_Status_Min_Fields = {
  __typename?: 'stock_deliver_status_min_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_deliver_status" */
export type Stock_Deliver_Status_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_deliver_status" */
export type Stock_Deliver_Status_Mutation_Response = {
  __typename?: 'stock_deliver_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Deliver_Status>;
};

/** input type for inserting object relation for remote table "stock_deliver_status" */
export type Stock_Deliver_Status_Obj_Rel_Insert_Input = {
  data: Stock_Deliver_Status_Insert_Input;
  on_conflict?: InputMaybe<Stock_Deliver_Status_On_Conflict>;
};

/** on conflict condition type for table "stock_deliver_status" */
export type Stock_Deliver_Status_On_Conflict = {
  constraint: Stock_Deliver_Status_Constraint;
  update_columns: Array<Stock_Deliver_Status_Update_Column>;
  where?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
};

/** ordering options when selecting data from "stock_deliver_status" */
export type Stock_Deliver_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_deliver_status" */
export type Stock_Deliver_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "stock_deliver_status" */
export enum Stock_Deliver_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "stock_deliver_status" */
export type Stock_Deliver_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stock_deliver_status" */
export enum Stock_Deliver_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

/** columns and relationships of "stock_location" */
export type Stock_Location = {
  __typename?: 'stock_location';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  location_type: Stock_Location_Type_Enum;
  name: Scalars['String'];
  name_path_location?: Maybe<Scalars['String']>;
  parent_location?: Maybe<Scalars['Int']>;
  path_location: Scalars['String'];
  sub_type?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_location" */
export type Stock_Location_Aggregate = {
  __typename?: 'stock_location_aggregate';
  aggregate?: Maybe<Stock_Location_Aggregate_Fields>;
  nodes: Array<Stock_Location>;
};

/** aggregate fields of "stock_location" */
export type Stock_Location_Aggregate_Fields = {
  __typename?: 'stock_location_aggregate_fields';
  avg?: Maybe<Stock_Location_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Location_Max_Fields>;
  min?: Maybe<Stock_Location_Min_Fields>;
  stddev?: Maybe<Stock_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Location_Sum_Fields>;
  var_pop?: Maybe<Stock_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Location_Var_Samp_Fields>;
  variance?: Maybe<Stock_Location_Variance_Fields>;
};


/** aggregate fields of "stock_location" */
export type Stock_Location_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Location_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_location" */
export type Stock_Location_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Location_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Location_Max_Order_By>;
  min?: InputMaybe<Stock_Location_Min_Order_By>;
  stddev?: InputMaybe<Stock_Location_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Location_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Location_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Location_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Location_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Location_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_location" */
export type Stock_Location_Arr_Rel_Insert_Input = {
  data: Array<Stock_Location_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Location_Avg_Fields = {
  __typename?: 'stock_location_avg_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_location" */
export type Stock_Location_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_location". All fields are combined with a logical 'AND'. */
export type Stock_Location_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Location_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Location_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Location_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  location_type?: InputMaybe<Stock_Location_Type_Enum_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  name_path_location?: InputMaybe<String_Comparison_Exp>;
  parent_location?: InputMaybe<Int_Comparison_Exp>;
  path_location?: InputMaybe<String_Comparison_Exp>;
  sub_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_location" */
export enum Stock_Location_Constraint {
  /** unique or primary key constraint */
  StockLocationCodeKey = 'stock_location_code_key',
  /** unique or primary key constraint */
  StockLocationPkey = 'stock_location_pkey',
  /** unique or primary key constraint */
  UniqueLocationName = 'unique_location_name'
}

/** input type for incrementing integer column in table "stock_location" */
export type Stock_Location_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  parent_location?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_location" */
export type Stock_Location_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location_type?: InputMaybe<Stock_Location_Type_Enum>;
  name?: InputMaybe<Scalars['String']>;
  name_path_location?: InputMaybe<Scalars['String']>;
  parent_location?: InputMaybe<Scalars['Int']>;
  path_location?: InputMaybe<Scalars['String']>;
  sub_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Location_Max_Fields = {
  __typename?: 'stock_location_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_path_location?: Maybe<Scalars['String']>;
  parent_location?: Maybe<Scalars['Int']>;
  path_location?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_location" */
export type Stock_Location_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_path_location?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
  path_location?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Location_Min_Fields = {
  __typename?: 'stock_location_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  external_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  name_path_location?: Maybe<Scalars['String']>;
  parent_location?: Maybe<Scalars['Int']>;
  path_location?: Maybe<Scalars['String']>;
  sub_type?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_location" */
export type Stock_Location_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_path_location?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
  path_location?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_location" */
export type Stock_Location_Mutation_Response = {
  __typename?: 'stock_location_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Location>;
};

/** input type for inserting object relation for remote table "stock_location" */
export type Stock_Location_Obj_Rel_Insert_Input = {
  data: Stock_Location_Insert_Input;
  on_conflict?: InputMaybe<Stock_Location_On_Conflict>;
};

/** on conflict condition type for table "stock_location" */
export type Stock_Location_On_Conflict = {
  constraint: Stock_Location_Constraint;
  update_columns: Array<Stock_Location_Update_Column>;
  where?: InputMaybe<Stock_Location_Bool_Exp>;
};

/** ordering options when selecting data from "stock_location" */
export type Stock_Location_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  location_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  name_path_location?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
  path_location?: InputMaybe<Order_By>;
  sub_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_location" */
export type Stock_Location_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_location" */
export enum Stock_Location_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Name = 'name',
  /** column name */
  NamePathLocation = 'name_path_location',
  /** column name */
  ParentLocation = 'parent_location',
  /** column name */
  PathLocation = 'path_location',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_location" */
export type Stock_Location_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  external_id?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  location_type?: InputMaybe<Stock_Location_Type_Enum>;
  name?: InputMaybe<Scalars['String']>;
  name_path_location?: InputMaybe<Scalars['String']>;
  parent_location?: InputMaybe<Scalars['Int']>;
  path_location?: InputMaybe<Scalars['String']>;
  sub_type?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Location_Stddev_Fields = {
  __typename?: 'stock_location_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_location" */
export type Stock_Location_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Location_Stddev_Pop_Fields = {
  __typename?: 'stock_location_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_location" */
export type Stock_Location_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Location_Stddev_Samp_Fields = {
  __typename?: 'stock_location_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_location" */
export type Stock_Location_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Location_Sum_Fields = {
  __typename?: 'stock_location_sum_fields';
  id?: Maybe<Scalars['Int']>;
  parent_location?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_location" */
export type Stock_Location_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_location_type" */
export type Stock_Location_Type = {
  __typename?: 'stock_location_type';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "stock_location_type" */
export type Stock_Location_Type_Aggregate = {
  __typename?: 'stock_location_type_aggregate';
  aggregate?: Maybe<Stock_Location_Type_Aggregate_Fields>;
  nodes: Array<Stock_Location_Type>;
};

/** aggregate fields of "stock_location_type" */
export type Stock_Location_Type_Aggregate_Fields = {
  __typename?: 'stock_location_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Location_Type_Max_Fields>;
  min?: Maybe<Stock_Location_Type_Min_Fields>;
};


/** aggregate fields of "stock_location_type" */
export type Stock_Location_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Location_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_location_type" */
export type Stock_Location_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Location_Type_Max_Order_By>;
  min?: InputMaybe<Stock_Location_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stock_location_type" */
export type Stock_Location_Type_Arr_Rel_Insert_Input = {
  data: Array<Stock_Location_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Location_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stock_location_type". All fields are combined with a logical 'AND'. */
export type Stock_Location_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Location_Type_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Location_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Location_Type_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_location_type" */
export enum Stock_Location_Type_Constraint {
  /** unique or primary key constraint */
  StockLocationTypePkey = 'stock_location_type_pkey'
}

export enum Stock_Location_Type_Enum {
  /** Represent an internal location */
  Internal = 'Internal',
  /** Represent a virtual location for suppliers or customers */
  Virtual = 'Virtual'
}

/** expression to compare columns of type stock_location_type_enum. All fields are combined with logical 'AND'. */
export type Stock_Location_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Location_Type_Enum>;
  _in?: InputMaybe<Array<Stock_Location_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Location_Type_Enum>;
  _nin?: InputMaybe<Array<Stock_Location_Type_Enum>>;
};

/** input type for inserting data into table "stock_location_type" */
export type Stock_Location_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Location_Type_Max_Fields = {
  __typename?: 'stock_location_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_location_type" */
export type Stock_Location_Type_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Location_Type_Min_Fields = {
  __typename?: 'stock_location_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_location_type" */
export type Stock_Location_Type_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_location_type" */
export type Stock_Location_Type_Mutation_Response = {
  __typename?: 'stock_location_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Location_Type>;
};

/** input type for inserting object relation for remote table "stock_location_type" */
export type Stock_Location_Type_Obj_Rel_Insert_Input = {
  data: Stock_Location_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Location_Type_On_Conflict>;
};

/** on conflict condition type for table "stock_location_type" */
export type Stock_Location_Type_On_Conflict = {
  constraint: Stock_Location_Type_Constraint;
  update_columns: Array<Stock_Location_Type_Update_Column>;
  where?: InputMaybe<Stock_Location_Type_Bool_Exp>;
};

/** ordering options when selecting data from "stock_location_type" */
export type Stock_Location_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_location_type" */
export type Stock_Location_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "stock_location_type" */
export enum Stock_Location_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "stock_location_type" */
export type Stock_Location_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stock_location_type" */
export enum Stock_Location_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "stock_location" */
export enum Stock_Location_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  LocationType = 'location_type',
  /** column name */
  Name = 'name',
  /** column name */
  NamePathLocation = 'name_path_location',
  /** column name */
  ParentLocation = 'parent_location',
  /** column name */
  PathLocation = 'path_location',
  /** column name */
  SubType = 'sub_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Location_Var_Pop_Fields = {
  __typename?: 'stock_location_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_location" */
export type Stock_Location_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Location_Var_Samp_Fields = {
  __typename?: 'stock_location_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_location" */
export type Stock_Location_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Location_Variance_Fields = {
  __typename?: 'stock_location_variance_fields';
  id?: Maybe<Scalars['Float']>;
  parent_location?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_location" */
export type Stock_Location_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  parent_location?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item = {
  __typename?: 'stock_manufacturing_order_item';
  created_by?: Maybe<Scalars['String']>;
  dest_location: Scalars['Int'];
  /** An object relationship */
  destiny_location: Stock_Location;
  id: Scalars['bigint'];
  /** A computed field, executes function "opb_related_product_mfa_lot" */
  lot?: Maybe<Array<Stock_Production_Lot>>;
  lot_id?: Maybe<Scalars['Int']>;
  material_items?: Maybe<Scalars['jsonb']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id: Scalars['bigint'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom: Scalars['numeric'];
  /** An object relationship */
  source_location: Stock_Location;
  src_location: Scalars['Int'];
  status?: Maybe<Stock_Deliver_Status_Enum>;
  /** An object relationship */
  stock_order: Stock_Order;
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_ItemLotArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};


/** columns and relationships of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_ItemMaterial_ItemsArgs = {
  path?: InputMaybe<Scalars['String']>;
};

/** aggregated selection of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Aggregate = {
  __typename?: 'stock_manufacturing_order_item_aggregate';
  aggregate?: Maybe<Stock_Manufacturing_Order_Item_Aggregate_Fields>;
  nodes: Array<Stock_Manufacturing_Order_Item>;
};

/** aggregate fields of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Aggregate_Fields = {
  __typename?: 'stock_manufacturing_order_item_aggregate_fields';
  avg?: Maybe<Stock_Manufacturing_Order_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Manufacturing_Order_Item_Max_Fields>;
  min?: Maybe<Stock_Manufacturing_Order_Item_Min_Fields>;
  stddev?: Maybe<Stock_Manufacturing_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Manufacturing_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Manufacturing_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Manufacturing_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Stock_Manufacturing_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Manufacturing_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Stock_Manufacturing_Order_Item_Variance_Fields>;
};


/** aggregate fields of "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Manufacturing_Order_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Manufacturing_Order_Item_Max_Order_By>;
  min?: InputMaybe<Stock_Manufacturing_Order_Item_Min_Order_By>;
  stddev?: InputMaybe<Stock_Manufacturing_Order_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Manufacturing_Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Manufacturing_Order_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Manufacturing_Order_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Manufacturing_Order_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Manufacturing_Order_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Manufacturing_Order_Item_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stock_Manufacturing_Order_Item_Append_Input = {
  material_items?: InputMaybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Stock_Manufacturing_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Manufacturing_Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Manufacturing_Order_Item_Avg_Fields = {
  __typename?: 'stock_manufacturing_order_item_avg_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Avg_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_manufacturing_order_item". All fields are combined with a logical 'AND'. */
export type Stock_Manufacturing_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  dest_location?: InputMaybe<Int_Comparison_Exp>;
  destiny_location?: InputMaybe<Stock_Location_Bool_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  material_items?: InputMaybe<Jsonb_Comparison_Exp>;
  missing_amount?: InputMaybe<Numeric_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  source_location?: InputMaybe<Stock_Location_Bool_Exp>;
  src_location?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Stock_Deliver_Status_Enum_Comparison_Exp>;
  stock_order?: InputMaybe<Stock_Order_Bool_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_manufacturing_order_item" */
export enum Stock_Manufacturing_Order_Item_Constraint {
  /** unique or primary key constraint */
  StockManufacturingOrderItemPkey = 'stock_manufacturing_order_item_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stock_Manufacturing_Order_Item_Delete_At_Path_Input = {
  material_items?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stock_Manufacturing_Order_Item_Delete_Elem_Input = {
  material_items?: InputMaybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stock_Manufacturing_Order_Item_Delete_Key_Input = {
  material_items?: InputMaybe<Scalars['String']>;
};

/** input type for incrementing integer column in table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Inc_Input = {
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  destiny_location?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  material_items?: InputMaybe<Scalars['jsonb']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  source_location?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  stock_order?: InputMaybe<Stock_Order_Obj_Rel_Insert_Input>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Manufacturing_Order_Item_Max_Fields = {
  __typename?: 'stock_manufacturing_order_item_max_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Manufacturing_Order_Item_Min_Fields = {
  __typename?: 'stock_manufacturing_order_item_min_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Mutation_Response = {
  __typename?: 'stock_manufacturing_order_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Manufacturing_Order_Item>;
};

/** input type for inserting object relation for remote table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Obj_Rel_Insert_Input = {
  data: Stock_Manufacturing_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Manufacturing_Order_Item_On_Conflict>;
};

/** on conflict condition type for table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_On_Conflict = {
  constraint: Stock_Manufacturing_Order_Item_Constraint;
  update_columns: Array<Stock_Manufacturing_Order_Item_Update_Column>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};

/** ordering options when selecting data from "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  destiny_location?: InputMaybe<Stock_Location_Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  material_items?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  source_location?: InputMaybe<Stock_Location_Order_By>;
  src_location?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  stock_order?: InputMaybe<Stock_Order_Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stock_Manufacturing_Order_Item_Prepend_Input = {
  material_items?: InputMaybe<Scalars['jsonb']>;
};

/** select columns of table "stock_manufacturing_order_item" */
export enum Stock_Manufacturing_Order_Item_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  MaterialItems = 'material_items',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  material_items?: InputMaybe<Scalars['jsonb']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Manufacturing_Order_Item_Stddev_Fields = {
  __typename?: 'stock_manufacturing_order_item_stddev_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Stddev_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Manufacturing_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'stock_manufacturing_order_item_stddev_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Stddev_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Manufacturing_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'stock_manufacturing_order_item_stddev_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Stddev_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Manufacturing_Order_Item_Sum_Fields = {
  __typename?: 'stock_manufacturing_order_item_sum_fields';
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Sum_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** update columns of table "stock_manufacturing_order_item" */
export enum Stock_Manufacturing_Order_Item_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  MaterialItems = 'material_items',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Manufacturing_Order_Item_Var_Pop_Fields = {
  __typename?: 'stock_manufacturing_order_item_var_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Var_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Manufacturing_Order_Item_Var_Samp_Fields = {
  __typename?: 'stock_manufacturing_order_item_var_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Var_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Manufacturing_Order_Item_Variance_Fields = {
  __typename?: 'stock_manufacturing_order_item_variance_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_manufacturing_order_item" */
export type Stock_Manufacturing_Order_Item_Variance_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_operation" */
export type Stock_Operation = {
  __typename?: 'stock_operation';
  code: Scalars['String'];
  created_by?: Maybe<Scalars['String']>;
  default_dest: Scalars['Int'];
  default_src: Scalars['Int'];
  /** An object relationship */
  destination: Stock_Location;
  id: Scalars['Int'];
  name: Scalars['String'];
  operation_type: Stock_Operation_Type_Enum;
  order_type: Stock_Order_Type_Enum;
  return_operation?: Maybe<Scalars['Int']>;
  /** An object relationship */
  source: Stock_Location;
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_operation" */
export type Stock_Operation_Aggregate = {
  __typename?: 'stock_operation_aggregate';
  aggregate?: Maybe<Stock_Operation_Aggregate_Fields>;
  nodes: Array<Stock_Operation>;
};

/** aggregate fields of "stock_operation" */
export type Stock_Operation_Aggregate_Fields = {
  __typename?: 'stock_operation_aggregate_fields';
  avg?: Maybe<Stock_Operation_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Operation_Max_Fields>;
  min?: Maybe<Stock_Operation_Min_Fields>;
  stddev?: Maybe<Stock_Operation_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Operation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Operation_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Operation_Sum_Fields>;
  var_pop?: Maybe<Stock_Operation_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Operation_Var_Samp_Fields>;
  variance?: Maybe<Stock_Operation_Variance_Fields>;
};


/** aggregate fields of "stock_operation" */
export type Stock_Operation_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_operation" */
export type Stock_Operation_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Operation_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Operation_Max_Order_By>;
  min?: InputMaybe<Stock_Operation_Min_Order_By>;
  stddev?: InputMaybe<Stock_Operation_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Operation_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Operation_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Operation_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Operation_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Operation_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Operation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_operation" */
export type Stock_Operation_Arr_Rel_Insert_Input = {
  data: Array<Stock_Operation_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Operation_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Operation_Avg_Fields = {
  __typename?: 'stock_operation_avg_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_operation" */
export type Stock_Operation_Avg_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_operation". All fields are combined with a logical 'AND'. */
export type Stock_Operation_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Operation_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Operation_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Operation_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  default_dest?: InputMaybe<Int_Comparison_Exp>;
  default_src?: InputMaybe<Int_Comparison_Exp>;
  destination?: InputMaybe<Stock_Location_Bool_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  operation_type?: InputMaybe<Stock_Operation_Type_Enum_Comparison_Exp>;
  order_type?: InputMaybe<Stock_Order_Type_Enum_Comparison_Exp>;
  return_operation?: InputMaybe<Int_Comparison_Exp>;
  source?: InputMaybe<Stock_Location_Bool_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_operation" */
export enum Stock_Operation_Constraint {
  /** unique or primary key constraint */
  StockOperationCodeKey = 'stock_operation_code_key',
  /** unique or primary key constraint */
  StockOperationPkey = 'stock_operation_pkey'
}

/** input type for incrementing integer column in table "stock_operation" */
export type Stock_Operation_Inc_Input = {
  default_dest?: InputMaybe<Scalars['Int']>;
  default_src?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  return_operation?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_operation" */
export type Stock_Operation_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['String']>;
  default_dest?: InputMaybe<Scalars['Int']>;
  default_src?: InputMaybe<Scalars['Int']>;
  destination?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operation_type?: InputMaybe<Stock_Operation_Type_Enum>;
  order_type?: InputMaybe<Stock_Order_Type_Enum>;
  return_operation?: InputMaybe<Scalars['Int']>;
  source?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Operation_Max_Fields = {
  __typename?: 'stock_operation_max_fields';
  code?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  default_dest?: Maybe<Scalars['Int']>;
  default_src?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  return_operation?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_operation" */
export type Stock_Operation_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Operation_Min_Fields = {
  __typename?: 'stock_operation_min_fields';
  code?: Maybe<Scalars['String']>;
  created_by?: Maybe<Scalars['String']>;
  default_dest?: Maybe<Scalars['Int']>;
  default_src?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  return_operation?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_operation" */
export type Stock_Operation_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_operation" */
export type Stock_Operation_Mutation_Response = {
  __typename?: 'stock_operation_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Operation>;
};

/** input type for inserting object relation for remote table "stock_operation" */
export type Stock_Operation_Obj_Rel_Insert_Input = {
  data: Stock_Operation_Insert_Input;
  on_conflict?: InputMaybe<Stock_Operation_On_Conflict>;
};

/** on conflict condition type for table "stock_operation" */
export type Stock_Operation_On_Conflict = {
  constraint: Stock_Operation_Constraint;
  update_columns: Array<Stock_Operation_Update_Column>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};

/** ordering options when selecting data from "stock_operation" */
export type Stock_Operation_Order_By = {
  code?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  destination?: InputMaybe<Stock_Location_Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  operation_type?: InputMaybe<Order_By>;
  order_type?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
  source?: InputMaybe<Stock_Location_Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_operation" */
export type Stock_Operation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_operation" */
export enum Stock_Operation_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultDest = 'default_dest',
  /** column name */
  DefaultSrc = 'default_src',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  ReturnOperation = 'return_operation',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_operation" */
export type Stock_Operation_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_by?: InputMaybe<Scalars['String']>;
  default_dest?: InputMaybe<Scalars['Int']>;
  default_src?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  operation_type?: InputMaybe<Stock_Operation_Type_Enum>;
  order_type?: InputMaybe<Stock_Order_Type_Enum>;
  return_operation?: InputMaybe<Scalars['Int']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Operation_Stddev_Fields = {
  __typename?: 'stock_operation_stddev_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_operation" */
export type Stock_Operation_Stddev_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Operation_Stddev_Pop_Fields = {
  __typename?: 'stock_operation_stddev_pop_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_operation" */
export type Stock_Operation_Stddev_Pop_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Operation_Stddev_Samp_Fields = {
  __typename?: 'stock_operation_stddev_samp_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_operation" */
export type Stock_Operation_Stddev_Samp_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Operation_Sum_Fields = {
  __typename?: 'stock_operation_sum_fields';
  default_dest?: Maybe<Scalars['Int']>;
  default_src?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  return_operation?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_operation" */
export type Stock_Operation_Sum_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_operation_type" */
export type Stock_Operation_Type = {
  __typename?: 'stock_operation_type';
  description?: Maybe<Scalars['String']>;
  value: Scalars['String'];
};

/** aggregated selection of "stock_operation_type" */
export type Stock_Operation_Type_Aggregate = {
  __typename?: 'stock_operation_type_aggregate';
  aggregate?: Maybe<Stock_Operation_Type_Aggregate_Fields>;
  nodes: Array<Stock_Operation_Type>;
};

/** aggregate fields of "stock_operation_type" */
export type Stock_Operation_Type_Aggregate_Fields = {
  __typename?: 'stock_operation_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Operation_Type_Max_Fields>;
  min?: Maybe<Stock_Operation_Type_Min_Fields>;
};


/** aggregate fields of "stock_operation_type" */
export type Stock_Operation_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Operation_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_operation_type" */
export type Stock_Operation_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Operation_Type_Max_Order_By>;
  min?: InputMaybe<Stock_Operation_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stock_operation_type" */
export type Stock_Operation_Type_Arr_Rel_Insert_Input = {
  data: Array<Stock_Operation_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Operation_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stock_operation_type". All fields are combined with a logical 'AND'. */
export type Stock_Operation_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Operation_Type_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Operation_Type_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_operation_type" */
export enum Stock_Operation_Type_Constraint {
  /** unique or primary key constraint */
  StockOperationTypePkey = 'stock_operation_type_pkey'
}

export enum Stock_Operation_Type_Enum {
  In = 'IN',
  /** Manufacturing operations */
  Mfa = 'MFA',
  Out = 'OUT',
  /** Transfer between locations */
  Tran = 'TRAN'
}

/** expression to compare columns of type stock_operation_type_enum. All fields are combined with logical 'AND'. */
export type Stock_Operation_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Operation_Type_Enum>;
  _in?: InputMaybe<Array<Stock_Operation_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Operation_Type_Enum>;
  _nin?: InputMaybe<Array<Stock_Operation_Type_Enum>>;
};

/** input type for inserting data into table "stock_operation_type" */
export type Stock_Operation_Type_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Operation_Type_Max_Fields = {
  __typename?: 'stock_operation_type_max_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_operation_type" */
export type Stock_Operation_Type_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Operation_Type_Min_Fields = {
  __typename?: 'stock_operation_type_min_fields';
  description?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_operation_type" */
export type Stock_Operation_Type_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_operation_type" */
export type Stock_Operation_Type_Mutation_Response = {
  __typename?: 'stock_operation_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Operation_Type>;
};

/** input type for inserting object relation for remote table "stock_operation_type" */
export type Stock_Operation_Type_Obj_Rel_Insert_Input = {
  data: Stock_Operation_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Operation_Type_On_Conflict>;
};

/** on conflict condition type for table "stock_operation_type" */
export type Stock_Operation_Type_On_Conflict = {
  constraint: Stock_Operation_Type_Constraint;
  update_columns: Array<Stock_Operation_Type_Update_Column>;
  where?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
};

/** ordering options when selecting data from "stock_operation_type" */
export type Stock_Operation_Type_Order_By = {
  description?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_operation_type" */
export type Stock_Operation_Type_Pk_Columns_Input = {
  value: Scalars['String'];
};

/** select columns of table "stock_operation_type" */
export enum Stock_Operation_Type_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "stock_operation_type" */
export type Stock_Operation_Type_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  value?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stock_operation_type" */
export enum Stock_Operation_Type_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Value = 'value'
}

/** update columns of table "stock_operation" */
export enum Stock_Operation_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DefaultDest = 'default_dest',
  /** column name */
  DefaultSrc = 'default_src',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OperationType = 'operation_type',
  /** column name */
  OrderType = 'order_type',
  /** column name */
  ReturnOperation = 'return_operation',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Operation_Var_Pop_Fields = {
  __typename?: 'stock_operation_var_pop_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_operation" */
export type Stock_Operation_Var_Pop_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Operation_Var_Samp_Fields = {
  __typename?: 'stock_operation_var_samp_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_operation" */
export type Stock_Operation_Var_Samp_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Operation_Variance_Fields = {
  __typename?: 'stock_operation_variance_fields';
  default_dest?: Maybe<Scalars['Float']>;
  default_src?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  return_operation?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_operation" */
export type Stock_Operation_Variance_Order_By = {
  default_dest?: InputMaybe<Order_By>;
  default_src?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  return_operation?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_order" */
export type Stock_Order = {
  __typename?: 'stock_order';
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  /** A computed field, executes function "opb_erros_message" */
  execution_errors?: Maybe<Array<Stock_Order_Execution_Errors>>;
  /** A computed field, executes function "opb_reference_error" */
  execution_errors_by_reference?: Maybe<Array<Stock_Order_Execution_Errors>>;
  id: Scalars['bigint'];
  /** An array relationship */
  manufacturing_order_items: Array<Stock_Manufacturing_Order_Item>;
  /** An aggregated array relationship */
  manufacturing_order_items_aggregate: Stock_Manufacturing_Order_Item_Aggregate;
  note?: Maybe<Scalars['String']>;
  /** A computed field, executes function "opb_available_operations" */
  operations?: Maybe<Array<Stock_Operation>>;
  order_date?: Maybe<Scalars['timestamp']>;
  /** An array relationship */
  purchase_order_items: Array<Stock_Purchase_Order_Item>;
  /** An aggregated array relationship */
  purchase_order_items_aggregate: Stock_Purchase_Order_Item_Aggregate;
  /** An array relationship */
  receipts_order_items: Array<Stock_Receipts_Order_Item>;
  /** An aggregated array relationship */
  receipts_order_items_aggregate: Stock_Receipts_Order_Item_Aggregate;
  /** An array relationship */
  sales_order_items: Array<Stock_Sales_Order_Item>;
  /** An aggregated array relationship */
  sales_order_items_aggregate: Stock_Sales_Order_Item_Aggregate;
  source_item_reference?: Maybe<Scalars['bigint']>;
  source_reference?: Maybe<Scalars['bigint']>;
  status: Stock_Order_Status_Enum;
  /** An array relationship */
  transfer_order_items: Array<Stock_Transfer_Order_Item>;
  /** An aggregated array relationship */
  transfer_order_items_aggregate: Stock_Transfer_Order_Item_Aggregate;
  type: Stock_Order_Type_Enum;
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderExecution_ErrorsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderExecution_Errors_By_ReferenceArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderManufacturing_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderManufacturing_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderOperationsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Order_By>>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderPurchase_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderPurchase_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderReceipts_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderReceipts_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderSales_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderSales_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderTransfer_Order_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order" */
export type Stock_OrderTransfer_Order_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};

/** aggregated selection of "stock_order" */
export type Stock_Order_Aggregate = {
  __typename?: 'stock_order_aggregate';
  aggregate?: Maybe<Stock_Order_Aggregate_Fields>;
  nodes: Array<Stock_Order>;
};

/** aggregate fields of "stock_order" */
export type Stock_Order_Aggregate_Fields = {
  __typename?: 'stock_order_aggregate_fields';
  avg?: Maybe<Stock_Order_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Order_Max_Fields>;
  min?: Maybe<Stock_Order_Min_Fields>;
  stddev?: Maybe<Stock_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Order_Sum_Fields>;
  var_pop?: Maybe<Stock_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Order_Var_Samp_Fields>;
  variance?: Maybe<Stock_Order_Variance_Fields>;
};


/** aggregate fields of "stock_order" */
export type Stock_Order_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Order_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_order" */
export type Stock_Order_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Order_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Order_Max_Order_By>;
  min?: InputMaybe<Stock_Order_Min_Order_By>;
  stddev?: InputMaybe<Stock_Order_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Order_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Order_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Order_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Order_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Order_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_order" */
export type Stock_Order_Arr_Rel_Insert_Input = {
  data: Array<Stock_Order_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Order_Avg_Fields = {
  __typename?: 'stock_order_avg_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_order" */
export type Stock_Order_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_order". All fields are combined with a logical 'AND'. */
export type Stock_Order_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Order_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Order_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Order_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  manufacturing_order_items?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
  note?: InputMaybe<String_Comparison_Exp>;
  order_date?: InputMaybe<Timestamp_Comparison_Exp>;
  purchase_order_items?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
  receipts_order_items?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
  sales_order_items?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
  source_item_reference?: InputMaybe<Bigint_Comparison_Exp>;
  source_reference?: InputMaybe<Bigint_Comparison_Exp>;
  status?: InputMaybe<Stock_Order_Status_Enum_Comparison_Exp>;
  transfer_order_items?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
  type?: InputMaybe<Stock_Order_Type_Enum_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_order" */
export enum Stock_Order_Constraint {
  /** unique or primary key constraint */
  StockOrderCodeKey = 'stock_order_code_key',
  /** unique or primary key constraint */
  StockOrderPkey = 'stock_order_pkey'
}

/** columns and relationships of "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors = {
  __typename?: 'stock_order_execution_errors';
  error: Scalars['Boolean'];
  errors?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  message: Scalars['String'];
  /** A computed field, executes function "opb_get_mfa_error_record" */
  mfa_order_item?: Maybe<Array<Stock_Manufacturing_Order_Item>>;
  order_id: Scalars['bigint'];
  order_item_id: Scalars['bigint'];
  reference_order_id?: Maybe<Scalars['bigint']>;
  reference_order_item_id?: Maybe<Scalars['bigint']>;
  /** A computed field, executes function "opb_get_sales_error_record" */
  sales_order_item?: Maybe<Array<Stock_Sales_Order_Item>>;
};


/** columns and relationships of "stock_order_execution_errors" */
export type Stock_Order_Execution_ErrorsMfa_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** columns and relationships of "stock_order_execution_errors" */
export type Stock_Order_Execution_ErrorsSales_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};

/** aggregated selection of "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Aggregate = {
  __typename?: 'stock_order_execution_errors_aggregate';
  aggregate?: Maybe<Stock_Order_Execution_Errors_Aggregate_Fields>;
  nodes: Array<Stock_Order_Execution_Errors>;
};

/** aggregate fields of "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Aggregate_Fields = {
  __typename?: 'stock_order_execution_errors_aggregate_fields';
  avg?: Maybe<Stock_Order_Execution_Errors_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Order_Execution_Errors_Max_Fields>;
  min?: Maybe<Stock_Order_Execution_Errors_Min_Fields>;
  stddev?: Maybe<Stock_Order_Execution_Errors_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Order_Execution_Errors_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Order_Execution_Errors_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Order_Execution_Errors_Sum_Fields>;
  var_pop?: Maybe<Stock_Order_Execution_Errors_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Order_Execution_Errors_Var_Samp_Fields>;
  variance?: Maybe<Stock_Order_Execution_Errors_Variance_Fields>;
};


/** aggregate fields of "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Order_Execution_Errors_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Order_Execution_Errors_Max_Order_By>;
  min?: InputMaybe<Stock_Order_Execution_Errors_Min_Order_By>;
  stddev?: InputMaybe<Stock_Order_Execution_Errors_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Order_Execution_Errors_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Order_Execution_Errors_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Order_Execution_Errors_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Order_Execution_Errors_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Order_Execution_Errors_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Order_Execution_Errors_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Arr_Rel_Insert_Input = {
  data: Array<Stock_Order_Execution_Errors_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Execution_Errors_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Order_Execution_Errors_Avg_Fields = {
  __typename?: 'stock_order_execution_errors_avg_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_order_execution_errors". All fields are combined with a logical 'AND'. */
export type Stock_Order_Execution_Errors_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>>>;
  error?: InputMaybe<Boolean_Comparison_Exp>;
  errors?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  order_item_id?: InputMaybe<Bigint_Comparison_Exp>;
  reference_order_id?: InputMaybe<Bigint_Comparison_Exp>;
  reference_order_item_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_order_execution_errors" */
export enum Stock_Order_Execution_Errors_Constraint {
  /** unique or primary key constraint */
  StockOrderExecutionErrorsOrderIdOrderItemIdKey = 'stock_order_execution_errors_order_id_order_item_id_key',
  /** unique or primary key constraint */
  StockOrderExecutionErrorsPkey = 'stock_order_execution_errors_pkey'
}

/** input type for incrementing integer column in table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  order_item_id?: InputMaybe<Scalars['bigint']>;
  reference_order_id?: InputMaybe<Scalars['bigint']>;
  reference_order_item_id?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Insert_Input = {
  error?: InputMaybe<Scalars['Boolean']>;
  errors?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  order_item_id?: InputMaybe<Scalars['bigint']>;
  reference_order_id?: InputMaybe<Scalars['bigint']>;
  reference_order_item_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate max on columns */
export type Stock_Order_Execution_Errors_Max_Fields = {
  __typename?: 'stock_order_execution_errors_max_fields';
  errors?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['bigint']>;
  order_item_id?: Maybe<Scalars['bigint']>;
  reference_order_id?: Maybe<Scalars['bigint']>;
  reference_order_item_id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Max_Order_By = {
  errors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Order_Execution_Errors_Min_Fields = {
  __typename?: 'stock_order_execution_errors_min_fields';
  errors?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
  order_id?: Maybe<Scalars['bigint']>;
  order_item_id?: Maybe<Scalars['bigint']>;
  reference_order_id?: Maybe<Scalars['bigint']>;
  reference_order_item_id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Min_Order_By = {
  errors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Mutation_Response = {
  __typename?: 'stock_order_execution_errors_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Order_Execution_Errors>;
};

/** input type for inserting object relation for remote table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Obj_Rel_Insert_Input = {
  data: Stock_Order_Execution_Errors_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Execution_Errors_On_Conflict>;
};

/** on conflict condition type for table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_On_Conflict = {
  constraint: Stock_Order_Execution_Errors_Constraint;
  update_columns: Array<Stock_Order_Execution_Errors_Update_Column>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};

/** ordering options when selecting data from "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Order_By = {
  error?: InputMaybe<Order_By>;
  errors?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_order_execution_errors" */
export enum Stock_Order_Execution_Errors_Select_Column {
  /** column name */
  Error = 'error',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  ReferenceOrderId = 'reference_order_id',
  /** column name */
  ReferenceOrderItemId = 'reference_order_item_id'
}

/** input type for updating data in table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Set_Input = {
  error?: InputMaybe<Scalars['Boolean']>;
  errors?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  message?: InputMaybe<Scalars['String']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  order_item_id?: InputMaybe<Scalars['bigint']>;
  reference_order_id?: InputMaybe<Scalars['bigint']>;
  reference_order_item_id?: InputMaybe<Scalars['bigint']>;
};

/** aggregate stddev on columns */
export type Stock_Order_Execution_Errors_Stddev_Fields = {
  __typename?: 'stock_order_execution_errors_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Order_Execution_Errors_Stddev_Pop_Fields = {
  __typename?: 'stock_order_execution_errors_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Order_Execution_Errors_Stddev_Samp_Fields = {
  __typename?: 'stock_order_execution_errors_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Order_Execution_Errors_Sum_Fields = {
  __typename?: 'stock_order_execution_errors_sum_fields';
  id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  order_item_id?: Maybe<Scalars['bigint']>;
  reference_order_id?: Maybe<Scalars['bigint']>;
  reference_order_item_id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** update columns of table "stock_order_execution_errors" */
export enum Stock_Order_Execution_Errors_Update_Column {
  /** column name */
  Error = 'error',
  /** column name */
  Errors = 'errors',
  /** column name */
  Id = 'id',
  /** column name */
  Message = 'message',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  OrderItemId = 'order_item_id',
  /** column name */
  ReferenceOrderId = 'reference_order_id',
  /** column name */
  ReferenceOrderItemId = 'reference_order_item_id'
}

/** aggregate var_pop on columns */
export type Stock_Order_Execution_Errors_Var_Pop_Fields = {
  __typename?: 'stock_order_execution_errors_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Order_Execution_Errors_Var_Samp_Fields = {
  __typename?: 'stock_order_execution_errors_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Order_Execution_Errors_Variance_Fields = {
  __typename?: 'stock_order_execution_errors_variance_fields';
  id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  order_item_id?: Maybe<Scalars['Float']>;
  reference_order_id?: Maybe<Scalars['Float']>;
  reference_order_item_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_order_execution_errors" */
export type Stock_Order_Execution_Errors_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  order_item_id?: InputMaybe<Order_By>;
  reference_order_id?: InputMaybe<Order_By>;
  reference_order_item_id?: InputMaybe<Order_By>;
};

/** input type for incrementing integer column in table "stock_order" */
export type Stock_Order_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  source_item_reference?: InputMaybe<Scalars['bigint']>;
  source_reference?: InputMaybe<Scalars['bigint']>;
};

/** input type for inserting data into table "stock_order" */
export type Stock_Order_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  manufacturing_order_items?: InputMaybe<Stock_Manufacturing_Order_Item_Arr_Rel_Insert_Input>;
  note?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['timestamp']>;
  purchase_order_items?: InputMaybe<Stock_Purchase_Order_Item_Arr_Rel_Insert_Input>;
  receipts_order_items?: InputMaybe<Stock_Receipts_Order_Item_Arr_Rel_Insert_Input>;
  sales_order_items?: InputMaybe<Stock_Sales_Order_Item_Arr_Rel_Insert_Input>;
  source_item_reference?: InputMaybe<Scalars['bigint']>;
  source_reference?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Stock_Order_Status_Enum>;
  transfer_order_items?: InputMaybe<Stock_Transfer_Order_Item_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Stock_Order_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Order_Max_Fields = {
  __typename?: 'stock_order_max_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  note?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['timestamp']>;
  source_item_reference?: Maybe<Scalars['bigint']>;
  source_reference?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_order" */
export type Stock_Order_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Order_Min_Fields = {
  __typename?: 'stock_order_min_fields';
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  note?: Maybe<Scalars['String']>;
  order_date?: Maybe<Scalars['timestamp']>;
  source_item_reference?: Maybe<Scalars['bigint']>;
  source_reference?: Maybe<Scalars['bigint']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_order" */
export type Stock_Order_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  note?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_order" */
export type Stock_Order_Mutation_Response = {
  __typename?: 'stock_order_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Order>;
};

/** input type for inserting object relation for remote table "stock_order" */
export type Stock_Order_Obj_Rel_Insert_Input = {
  data: Stock_Order_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_On_Conflict>;
};

/** on conflict condition type for table "stock_order" */
export type Stock_Order_On_Conflict = {
  constraint: Stock_Order_Constraint;
  update_columns: Array<Stock_Order_Update_Column>;
  where?: InputMaybe<Stock_Order_Bool_Exp>;
};

/** ordering options when selecting data from "stock_order" */
export type Stock_Order_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  manufacturing_order_items_aggregate?: InputMaybe<Stock_Manufacturing_Order_Item_Aggregate_Order_By>;
  note?: InputMaybe<Order_By>;
  order_date?: InputMaybe<Order_By>;
  purchase_order_items_aggregate?: InputMaybe<Stock_Purchase_Order_Item_Aggregate_Order_By>;
  receipts_order_items_aggregate?: InputMaybe<Stock_Receipts_Order_Item_Aggregate_Order_By>;
  sales_order_items_aggregate?: InputMaybe<Stock_Sales_Order_Item_Aggregate_Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  transfer_order_items_aggregate?: InputMaybe<Stock_Transfer_Order_Item_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_order" */
export type Stock_Order_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "stock_order" */
export enum Stock_Order_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  SourceItemReference = 'source_item_reference',
  /** column name */
  SourceReference = 'source_reference',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_order" */
export type Stock_Order_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  note?: InputMaybe<Scalars['String']>;
  order_date?: InputMaybe<Scalars['timestamp']>;
  source_item_reference?: InputMaybe<Scalars['bigint']>;
  source_reference?: InputMaybe<Scalars['bigint']>;
  status?: InputMaybe<Stock_Order_Status_Enum>;
  type?: InputMaybe<Stock_Order_Type_Enum>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "stock_order_status" */
export type Stock_Order_Status = {
  __typename?: 'stock_order_status';
  description?: Maybe<Scalars['String']>;
  status: Scalars['String'];
};

/** aggregated selection of "stock_order_status" */
export type Stock_Order_Status_Aggregate = {
  __typename?: 'stock_order_status_aggregate';
  aggregate?: Maybe<Stock_Order_Status_Aggregate_Fields>;
  nodes: Array<Stock_Order_Status>;
};

/** aggregate fields of "stock_order_status" */
export type Stock_Order_Status_Aggregate_Fields = {
  __typename?: 'stock_order_status_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Order_Status_Max_Fields>;
  min?: Maybe<Stock_Order_Status_Min_Fields>;
};


/** aggregate fields of "stock_order_status" */
export type Stock_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Order_Status_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_order_status" */
export type Stock_Order_Status_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Order_Status_Max_Order_By>;
  min?: InputMaybe<Stock_Order_Status_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stock_order_status" */
export type Stock_Order_Status_Arr_Rel_Insert_Input = {
  data: Array<Stock_Order_Status_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Status_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stock_order_status". All fields are combined with a logical 'AND'. */
export type Stock_Order_Status_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Order_Status_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Order_Status_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Order_Status_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_order_status" */
export enum Stock_Order_Status_Constraint {
  /** unique or primary key constraint */
  OrderStatusPkey = 'order_status_pkey'
}

export enum Stock_Order_Status_Enum {
  Approved = 'APPROVED',
  InProgress = 'IN_PROGRESS',
  Rejected = 'REJECTED',
  /** Reverted order */
  Reverted = 'REVERTED',
  Started = 'STARTED',
  Validated = 'VALIDATED',
  Waiting = 'WAITING'
}

/** expression to compare columns of type stock_order_status_enum. All fields are combined with logical 'AND'. */
export type Stock_Order_Status_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Order_Status_Enum>;
  _in?: InputMaybe<Array<Stock_Order_Status_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Order_Status_Enum>;
  _nin?: InputMaybe<Array<Stock_Order_Status_Enum>>;
};

/** input type for inserting data into table "stock_order_status" */
export type Stock_Order_Status_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Order_Status_Max_Fields = {
  __typename?: 'stock_order_status_max_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_order_status" */
export type Stock_Order_Status_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Order_Status_Min_Fields = {
  __typename?: 'stock_order_status_min_fields';
  description?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_order_status" */
export type Stock_Order_Status_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_order_status" */
export type Stock_Order_Status_Mutation_Response = {
  __typename?: 'stock_order_status_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Order_Status>;
};

/** input type for inserting object relation for remote table "stock_order_status" */
export type Stock_Order_Status_Obj_Rel_Insert_Input = {
  data: Stock_Order_Status_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Status_On_Conflict>;
};

/** on conflict condition type for table "stock_order_status" */
export type Stock_Order_Status_On_Conflict = {
  constraint: Stock_Order_Status_Constraint;
  update_columns: Array<Stock_Order_Status_Update_Column>;
  where?: InputMaybe<Stock_Order_Status_Bool_Exp>;
};

/** ordering options when selecting data from "stock_order_status" */
export type Stock_Order_Status_Order_By = {
  description?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_order_status" */
export type Stock_Order_Status_Pk_Columns_Input = {
  status: Scalars['String'];
};

/** select columns of table "stock_order_status" */
export enum Stock_Order_Status_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

/** input type for updating data in table "stock_order_status" */
export type Stock_Order_Status_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  status?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stock_order_status" */
export enum Stock_Order_Status_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Status = 'status'
}

/** aggregate stddev on columns */
export type Stock_Order_Stddev_Fields = {
  __typename?: 'stock_order_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_order" */
export type Stock_Order_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Order_Stddev_Pop_Fields = {
  __typename?: 'stock_order_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_order" */
export type Stock_Order_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Order_Stddev_Samp_Fields = {
  __typename?: 'stock_order_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_order" */
export type Stock_Order_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Order_Sum_Fields = {
  __typename?: 'stock_order_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  source_item_reference?: Maybe<Scalars['bigint']>;
  source_reference?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "stock_order" */
export type Stock_Order_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_order_type" */
export type Stock_Order_Type = {
  __typename?: 'stock_order_type';
  code: Scalars['String'];
  description: Scalars['String'];
};

/** aggregated selection of "stock_order_type" */
export type Stock_Order_Type_Aggregate = {
  __typename?: 'stock_order_type_aggregate';
  aggregate?: Maybe<Stock_Order_Type_Aggregate_Fields>;
  nodes: Array<Stock_Order_Type>;
};

/** aggregate fields of "stock_order_type" */
export type Stock_Order_Type_Aggregate_Fields = {
  __typename?: 'stock_order_type_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Order_Type_Max_Fields>;
  min?: Maybe<Stock_Order_Type_Min_Fields>;
};


/** aggregate fields of "stock_order_type" */
export type Stock_Order_Type_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Order_Type_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_order_type" */
export type Stock_Order_Type_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Order_Type_Max_Order_By>;
  min?: InputMaybe<Stock_Order_Type_Min_Order_By>;
};

/** input type for inserting array relation for remote table "stock_order_type" */
export type Stock_Order_Type_Arr_Rel_Insert_Input = {
  data: Array<Stock_Order_Type_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Order_Type_On_Conflict>;
};

/** Boolean expression to filter rows from the table "stock_order_type". All fields are combined with a logical 'AND'. */
export type Stock_Order_Type_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Order_Type_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Order_Type_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Order_Type_Bool_Exp>>>;
  code?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_order_type" */
export enum Stock_Order_Type_Constraint {
  /** unique or primary key constraint */
  OrdenTypePkey = 'orden_type_pkey'
}

export enum Stock_Order_Type_Enum {
  /** Manufacturing  */
  Mfa = 'MFA',
  /** Purcher Order */
  Po = 'PO',
  /** Receipts */
  Rc = 'RC',
  /** Sales Order */
  So = 'SO',
  /** Transfer Order */
  To = 'TO'
}

/** expression to compare columns of type stock_order_type_enum. All fields are combined with logical 'AND'. */
export type Stock_Order_Type_Enum_Comparison_Exp = {
  _eq?: InputMaybe<Stock_Order_Type_Enum>;
  _in?: InputMaybe<Array<Stock_Order_Type_Enum>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _neq?: InputMaybe<Stock_Order_Type_Enum>;
  _nin?: InputMaybe<Array<Stock_Order_Type_Enum>>;
};

/** input type for inserting data into table "stock_order_type" */
export type Stock_Order_Type_Insert_Input = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Order_Type_Max_Fields = {
  __typename?: 'stock_order_type_max_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_order_type" */
export type Stock_Order_Type_Max_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Order_Type_Min_Fields = {
  __typename?: 'stock_order_type_min_fields';
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_order_type" */
export type Stock_Order_Type_Min_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_order_type" */
export type Stock_Order_Type_Mutation_Response = {
  __typename?: 'stock_order_type_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Order_Type>;
};

/** input type for inserting object relation for remote table "stock_order_type" */
export type Stock_Order_Type_Obj_Rel_Insert_Input = {
  data: Stock_Order_Type_Insert_Input;
  on_conflict?: InputMaybe<Stock_Order_Type_On_Conflict>;
};

/** on conflict condition type for table "stock_order_type" */
export type Stock_Order_Type_On_Conflict = {
  constraint: Stock_Order_Type_Constraint;
  update_columns: Array<Stock_Order_Type_Update_Column>;
  where?: InputMaybe<Stock_Order_Type_Bool_Exp>;
};

/** ordering options when selecting data from "stock_order_type" */
export type Stock_Order_Type_Order_By = {
  code?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_order_type" */
export type Stock_Order_Type_Pk_Columns_Input = {
  code: Scalars['String'];
};

/** select columns of table "stock_order_type" */
export enum Stock_Order_Type_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description'
}

/** input type for updating data in table "stock_order_type" */
export type Stock_Order_Type_Set_Input = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
};

/** update columns of table "stock_order_type" */
export enum Stock_Order_Type_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  Description = 'description'
}

/** update columns of table "stock_order" */
export enum Stock_Order_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  Note = 'note',
  /** column name */
  OrderDate = 'order_date',
  /** column name */
  SourceItemReference = 'source_item_reference',
  /** column name */
  SourceReference = 'source_reference',
  /** column name */
  Status = 'status',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Order_Var_Pop_Fields = {
  __typename?: 'stock_order_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_order" */
export type Stock_Order_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Order_Var_Samp_Fields = {
  __typename?: 'stock_order_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_order" */
export type Stock_Order_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Order_Variance_Fields = {
  __typename?: 'stock_order_variance_fields';
  id?: Maybe<Scalars['Float']>;
  source_item_reference?: Maybe<Scalars['Float']>;
  source_reference?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_order" */
export type Stock_Order_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  source_item_reference?: InputMaybe<Order_By>;
  source_reference?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_partner" */
export type Stock_Partner = {
  __typename?: 'stock_partner';
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_partner" */
export type Stock_Partner_Aggregate = {
  __typename?: 'stock_partner_aggregate';
  aggregate?: Maybe<Stock_Partner_Aggregate_Fields>;
  nodes: Array<Stock_Partner>;
};

/** aggregate fields of "stock_partner" */
export type Stock_Partner_Aggregate_Fields = {
  __typename?: 'stock_partner_aggregate_fields';
  avg?: Maybe<Stock_Partner_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Partner_Max_Fields>;
  min?: Maybe<Stock_Partner_Min_Fields>;
  stddev?: Maybe<Stock_Partner_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Partner_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Partner_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Partner_Sum_Fields>;
  var_pop?: Maybe<Stock_Partner_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Partner_Var_Samp_Fields>;
  variance?: Maybe<Stock_Partner_Variance_Fields>;
};


/** aggregate fields of "stock_partner" */
export type Stock_Partner_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Partner_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_partner" */
export type Stock_Partner_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Partner_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Partner_Max_Order_By>;
  min?: InputMaybe<Stock_Partner_Min_Order_By>;
  stddev?: InputMaybe<Stock_Partner_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Partner_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Partner_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Partner_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Partner_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Partner_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Partner_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_partner" */
export type Stock_Partner_Arr_Rel_Insert_Input = {
  data: Array<Stock_Partner_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Partner_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Partner_Avg_Fields = {
  __typename?: 'stock_partner_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_partner" */
export type Stock_Partner_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_partner". All fields are combined with a logical 'AND'. */
export type Stock_Partner_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Partner_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Partner_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Partner_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_partner" */
export enum Stock_Partner_Constraint {
  /** unique or primary key constraint */
  StockPartnerPkey = 'stock_partner_pkey'
}

/** input type for incrementing integer column in table "stock_partner" */
export type Stock_Partner_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_partner" */
export type Stock_Partner_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Partner_Max_Fields = {
  __typename?: 'stock_partner_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_partner" */
export type Stock_Partner_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Partner_Min_Fields = {
  __typename?: 'stock_partner_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_partner" */
export type Stock_Partner_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_partner" */
export type Stock_Partner_Mutation_Response = {
  __typename?: 'stock_partner_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Partner>;
};

/** input type for inserting object relation for remote table "stock_partner" */
export type Stock_Partner_Obj_Rel_Insert_Input = {
  data: Stock_Partner_Insert_Input;
  on_conflict?: InputMaybe<Stock_Partner_On_Conflict>;
};

/** on conflict condition type for table "stock_partner" */
export type Stock_Partner_On_Conflict = {
  constraint: Stock_Partner_Constraint;
  update_columns: Array<Stock_Partner_Update_Column>;
  where?: InputMaybe<Stock_Partner_Bool_Exp>;
};

/** ordering options when selecting data from "stock_partner" */
export type Stock_Partner_Order_By = {
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_partner" */
export type Stock_Partner_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_partner" */
export enum Stock_Partner_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_partner" */
export type Stock_Partner_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Partner_Stddev_Fields = {
  __typename?: 'stock_partner_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_partner" */
export type Stock_Partner_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Partner_Stddev_Pop_Fields = {
  __typename?: 'stock_partner_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_partner" */
export type Stock_Partner_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Partner_Stddev_Samp_Fields = {
  __typename?: 'stock_partner_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_partner" */
export type Stock_Partner_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Partner_Sum_Fields = {
  __typename?: 'stock_partner_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_partner" */
export type Stock_Partner_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "stock_partner" */
export enum Stock_Partner_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Partner_Var_Pop_Fields = {
  __typename?: 'stock_partner_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_partner" */
export type Stock_Partner_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Partner_Var_Samp_Fields = {
  __typename?: 'stock_partner_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_partner" */
export type Stock_Partner_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Partner_Variance_Fields = {
  __typename?: 'stock_partner_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_partner" */
export type Stock_Partner_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_production_lot" */
export type Stock_Production_Lot = {
  __typename?: 'stock_production_lot';
  alert_date?: Maybe<Scalars['date']>;
  code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  created_by?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  id: Scalars['Int'];
  product_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_production_lot" */
export type Stock_Production_Lot_Aggregate = {
  __typename?: 'stock_production_lot_aggregate';
  aggregate?: Maybe<Stock_Production_Lot_Aggregate_Fields>;
  nodes: Array<Stock_Production_Lot>;
};

/** aggregate fields of "stock_production_lot" */
export type Stock_Production_Lot_Aggregate_Fields = {
  __typename?: 'stock_production_lot_aggregate_fields';
  avg?: Maybe<Stock_Production_Lot_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Production_Lot_Max_Fields>;
  min?: Maybe<Stock_Production_Lot_Min_Fields>;
  stddev?: Maybe<Stock_Production_Lot_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Production_Lot_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Production_Lot_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Production_Lot_Sum_Fields>;
  var_pop?: Maybe<Stock_Production_Lot_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Production_Lot_Var_Samp_Fields>;
  variance?: Maybe<Stock_Production_Lot_Variance_Fields>;
};


/** aggregate fields of "stock_production_lot" */
export type Stock_Production_Lot_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_production_lot" */
export type Stock_Production_Lot_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Production_Lot_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Production_Lot_Max_Order_By>;
  min?: InputMaybe<Stock_Production_Lot_Min_Order_By>;
  stddev?: InputMaybe<Stock_Production_Lot_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Production_Lot_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Production_Lot_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Production_Lot_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Production_Lot_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Production_Lot_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Production_Lot_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_production_lot" */
export type Stock_Production_Lot_Arr_Rel_Insert_Input = {
  data: Array<Stock_Production_Lot_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Production_Lot_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Production_Lot_Avg_Fields = {
  __typename?: 'stock_production_lot_avg_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_production_lot". All fields are combined with a logical 'AND'. */
export type Stock_Production_Lot_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Production_Lot_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Production_Lot_Bool_Exp>>>;
  alert_date?: InputMaybe<Date_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  date?: InputMaybe<Date_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_production_lot" */
export enum Stock_Production_Lot_Constraint {
  /** unique or primary key constraint */
  LotCode = 'lot_code',
  /** unique or primary key constraint */
  StockProductionLotPkey = 'stock_production_lot_pkey'
}

/** input type for incrementing integer column in table "stock_production_lot" */
export type Stock_Production_Lot_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_production_lot" */
export type Stock_Production_Lot_Insert_Input = {
  alert_date?: InputMaybe<Scalars['date']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Production_Lot_Max_Fields = {
  __typename?: 'stock_production_lot_max_fields';
  alert_date?: Maybe<Scalars['date']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Max_Order_By = {
  alert_date?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Production_Lot_Min_Fields = {
  __typename?: 'stock_production_lot_min_fields';
  alert_date?: Maybe<Scalars['date']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  created_by?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['date']>;
  description?: Maybe<Scalars['String']>;
  expiration_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Min_Order_By = {
  alert_date?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_production_lot" */
export type Stock_Production_Lot_Mutation_Response = {
  __typename?: 'stock_production_lot_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Production_Lot>;
};

/** input type for inserting object relation for remote table "stock_production_lot" */
export type Stock_Production_Lot_Obj_Rel_Insert_Input = {
  data: Stock_Production_Lot_Insert_Input;
  on_conflict?: InputMaybe<Stock_Production_Lot_On_Conflict>;
};

/** on conflict condition type for table "stock_production_lot" */
export type Stock_Production_Lot_On_Conflict = {
  constraint: Stock_Production_Lot_Constraint;
  update_columns: Array<Stock_Production_Lot_Update_Column>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};

/** ordering options when selecting data from "stock_production_lot" */
export type Stock_Production_Lot_Order_By = {
  alert_date?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by?: InputMaybe<Order_By>;
  date?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_production_lot" */
export type Stock_Production_Lot_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_production_lot" */
export enum Stock_Production_Lot_Select_Column {
  /** column name */
  AlertDate = 'alert_date',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_production_lot" */
export type Stock_Production_Lot_Set_Input = {
  alert_date?: InputMaybe<Scalars['date']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  created_by?: InputMaybe<Scalars['String']>;
  date?: InputMaybe<Scalars['date']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Production_Lot_Stddev_Fields = {
  __typename?: 'stock_production_lot_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Production_Lot_Stddev_Pop_Fields = {
  __typename?: 'stock_production_lot_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Production_Lot_Stddev_Samp_Fields = {
  __typename?: 'stock_production_lot_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Production_Lot_Sum_Fields = {
  __typename?: 'stock_production_lot_sum_fields';
  id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** update columns of table "stock_production_lot" */
export enum Stock_Production_Lot_Update_Column {
  /** column name */
  AlertDate = 'alert_date',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Date = 'date',
  /** column name */
  Description = 'description',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  Id = 'id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Production_Lot_Var_Pop_Fields = {
  __typename?: 'stock_production_lot_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Production_Lot_Var_Samp_Fields = {
  __typename?: 'stock_production_lot_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Production_Lot_Variance_Fields = {
  __typename?: 'stock_production_lot_variance_fields';
  id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_production_lot" */
export type Stock_Production_Lot_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item = {
  __typename?: 'stock_purchase_order_item';
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** A computed field, executes function "opb_related_product_lot" */
  lot?: Maybe<Array<Stock_Production_Lot>>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id: Scalars['Int'];
  order_id: Scalars['bigint'];
  partner_provider?: Maybe<Scalars['Int']>;
  price: Scalars['numeric'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom: Scalars['numeric'];
  status: Stock_Deliver_Status_Enum;
  updated_by?: Maybe<Scalars['String']>;
};


/** columns and relationships of "stock_purchase_order_item" */
export type Stock_Purchase_Order_ItemLotArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};

/** aggregated selection of "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Aggregate = {
  __typename?: 'stock_purchase_order_item_aggregate';
  aggregate?: Maybe<Stock_Purchase_Order_Item_Aggregate_Fields>;
  nodes: Array<Stock_Purchase_Order_Item>;
};

/** aggregate fields of "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Aggregate_Fields = {
  __typename?: 'stock_purchase_order_item_aggregate_fields';
  avg?: Maybe<Stock_Purchase_Order_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Purchase_Order_Item_Max_Fields>;
  min?: Maybe<Stock_Purchase_Order_Item_Min_Fields>;
  stddev?: Maybe<Stock_Purchase_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Purchase_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Purchase_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Purchase_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Stock_Purchase_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Purchase_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Stock_Purchase_Order_Item_Variance_Fields>;
};


/** aggregate fields of "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Purchase_Order_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Purchase_Order_Item_Max_Order_By>;
  min?: InputMaybe<Stock_Purchase_Order_Item_Min_Order_By>;
  stddev?: InputMaybe<Stock_Purchase_Order_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Purchase_Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Purchase_Order_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Purchase_Order_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Purchase_Order_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Purchase_Order_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Purchase_Order_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Stock_Purchase_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Purchase_Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Purchase_Order_Item_Avg_Fields = {
  __typename?: 'stock_purchase_order_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_purchase_order_item". All fields are combined with a logical 'AND'. */
export type Stock_Purchase_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  operation_id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  partner_provider?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<Stock_Deliver_Status_Enum_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_purchase_order_item" */
export enum Stock_Purchase_Order_Item_Constraint {
  /** unique or primary key constraint */
  StockPurchaseOrderItemsPkey = 'stock_purchase_order_items_pkey'
}

/** input type for incrementing integer column in table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_provider?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_provider?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Purchase_Order_Item_Max_Fields = {
  __typename?: 'stock_purchase_order_item_max_fields';
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_provider?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Purchase_Order_Item_Min_Fields = {
  __typename?: 'stock_purchase_order_item_min_fields';
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_provider?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Mutation_Response = {
  __typename?: 'stock_purchase_order_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Purchase_Order_Item>;
};

/** input type for inserting object relation for remote table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Obj_Rel_Insert_Input = {
  data: Stock_Purchase_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Purchase_Order_Item_On_Conflict>;
};

/** on conflict condition type for table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_On_Conflict = {
  constraint: Stock_Purchase_Order_Item_Constraint;
  update_columns: Array<Stock_Purchase_Order_Item_Update_Column>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};

/** ordering options when selecting data from "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "stock_purchase_order_item" */
export enum Stock_Purchase_Order_Item_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerProvider = 'partner_provider',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_provider?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Purchase_Order_Item_Stddev_Fields = {
  __typename?: 'stock_purchase_order_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Purchase_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'stock_purchase_order_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Purchase_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'stock_purchase_order_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Purchase_Order_Item_Sum_Fields = {
  __typename?: 'stock_purchase_order_item_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_provider?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** update columns of table "stock_purchase_order_item" */
export enum Stock_Purchase_Order_Item_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerProvider = 'partner_provider',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Purchase_Order_Item_Var_Pop_Fields = {
  __typename?: 'stock_purchase_order_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Purchase_Order_Item_Var_Samp_Fields = {
  __typename?: 'stock_purchase_order_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Purchase_Order_Item_Variance_Fields = {
  __typename?: 'stock_purchase_order_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_provider?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_purchase_order_item" */
export type Stock_Purchase_Order_Item_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_provider?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_quantity" */
export type Stock_Quantity = {
  __typename?: 'stock_quantity';
  created_at: Scalars['timestamptz'];
  expiration_date?: Maybe<Scalars['date']>;
  location_id: Scalars['Int'];
  lot_id: Scalars['Int'];
  product_id: Scalars['Int'];
  quantity: Scalars['numeric'];
  reserved_quantity?: Maybe<Scalars['numeric']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "stock_quantity" */
export type Stock_Quantity_Aggregate = {
  __typename?: 'stock_quantity_aggregate';
  aggregate?: Maybe<Stock_Quantity_Aggregate_Fields>;
  nodes: Array<Stock_Quantity>;
};

/** aggregate fields of "stock_quantity" */
export type Stock_Quantity_Aggregate_Fields = {
  __typename?: 'stock_quantity_aggregate_fields';
  avg?: Maybe<Stock_Quantity_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Quantity_Max_Fields>;
  min?: Maybe<Stock_Quantity_Min_Fields>;
  stddev?: Maybe<Stock_Quantity_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Quantity_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Quantity_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Quantity_Sum_Fields>;
  var_pop?: Maybe<Stock_Quantity_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Quantity_Var_Samp_Fields>;
  variance?: Maybe<Stock_Quantity_Variance_Fields>;
};


/** aggregate fields of "stock_quantity" */
export type Stock_Quantity_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Quantity_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_quantity" */
export type Stock_Quantity_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Quantity_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Quantity_Max_Order_By>;
  min?: InputMaybe<Stock_Quantity_Min_Order_By>;
  stddev?: InputMaybe<Stock_Quantity_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Quantity_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Quantity_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Quantity_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Quantity_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Quantity_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Quantity_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_quantity" */
export type Stock_Quantity_Arr_Rel_Insert_Input = {
  data: Array<Stock_Quantity_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Quantity_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Quantity_Avg_Fields = {
  __typename?: 'stock_quantity_avg_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_quantity" */
export type Stock_Quantity_Avg_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_quantity". All fields are combined with a logical 'AND'. */
export type Stock_Quantity_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Quantity_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Quantity_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Quantity_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  expiration_date?: InputMaybe<Date_Comparison_Exp>;
  location_id?: InputMaybe<Int_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  reserved_quantity?: InputMaybe<Numeric_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_quantity" */
export enum Stock_Quantity_Constraint {
  /** unique or primary key constraint */
  StockQuantityPkey = 'stock_quantity_pkey',
  /** unique or primary key constraint */
  UniqueRowConst = 'unique_row_const'
}

/** input type for incrementing integer column in table "stock_quantity" */
export type Stock_Quantity_Inc_Input = {
  location_id?: InputMaybe<Scalars['Int']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  reserved_quantity?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "stock_quantity" */
export type Stock_Quantity_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  location_id?: InputMaybe<Scalars['Int']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  reserved_quantity?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Stock_Quantity_Max_Fields = {
  __typename?: 'stock_quantity_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  reserved_quantity?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "stock_quantity" */
export type Stock_Quantity_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Quantity_Min_Fields = {
  __typename?: 'stock_quantity_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expiration_date?: Maybe<Scalars['date']>;
  location_id?: Maybe<Scalars['Int']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  reserved_quantity?: Maybe<Scalars['numeric']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "stock_quantity" */
export type Stock_Quantity_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_quantity" */
export type Stock_Quantity_Mutation_Response = {
  __typename?: 'stock_quantity_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Quantity>;
};

/** input type for inserting object relation for remote table "stock_quantity" */
export type Stock_Quantity_Obj_Rel_Insert_Input = {
  data: Stock_Quantity_Insert_Input;
  on_conflict?: InputMaybe<Stock_Quantity_On_Conflict>;
};

/** on conflict condition type for table "stock_quantity" */
export type Stock_Quantity_On_Conflict = {
  constraint: Stock_Quantity_Constraint;
  update_columns: Array<Stock_Quantity_Update_Column>;
  where?: InputMaybe<Stock_Quantity_Bool_Exp>;
};

/** ordering options when selecting data from "stock_quantity" */
export type Stock_Quantity_Order_By = {
  created_at?: InputMaybe<Order_By>;
  expiration_date?: InputMaybe<Order_By>;
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_quantity" */
export type Stock_Quantity_Pk_Columns_Input = {
  location_id: Scalars['Int'];
  lot_id: Scalars['Int'];
  product_id: Scalars['Int'];
};

/** select columns of table "stock_quantity" */
export enum Stock_Quantity_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReservedQuantity = 'reserved_quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stock_quantity" */
export type Stock_Quantity_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  location_id?: InputMaybe<Scalars['Int']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  reserved_quantity?: InputMaybe<Scalars['numeric']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stock_Quantity_Stddev_Fields = {
  __typename?: 'stock_quantity_stddev_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_quantity" */
export type Stock_Quantity_Stddev_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Quantity_Stddev_Pop_Fields = {
  __typename?: 'stock_quantity_stddev_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_quantity" */
export type Stock_Quantity_Stddev_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Quantity_Stddev_Samp_Fields = {
  __typename?: 'stock_quantity_stddev_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_quantity" */
export type Stock_Quantity_Stddev_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Quantity_Sum_Fields = {
  __typename?: 'stock_quantity_sum_fields';
  location_id?: Maybe<Scalars['Int']>;
  lot_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  reserved_quantity?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stock_quantity" */
export type Stock_Quantity_Sum_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** update columns of table "stock_quantity" */
export enum Stock_Quantity_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpirationDate = 'expiration_date',
  /** column name */
  LocationId = 'location_id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReservedQuantity = 'reserved_quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Stock_Quantity_Var_Pop_Fields = {
  __typename?: 'stock_quantity_var_pop_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_quantity" */
export type Stock_Quantity_Var_Pop_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Quantity_Var_Samp_Fields = {
  __typename?: 'stock_quantity_var_samp_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_quantity" */
export type Stock_Quantity_Var_Samp_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Quantity_Variance_Fields = {
  __typename?: 'stock_quantity_variance_fields';
  location_id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  reserved_quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_quantity" */
export type Stock_Quantity_Variance_Order_By = {
  location_id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  reserved_quantity?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item = {
  __typename?: 'stock_receipts_order_item';
  created_by?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  lot_id: Scalars['Int'];
  operation_id: Scalars['Int'];
  order_id: Scalars['bigint'];
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom: Scalars['numeric'];
  status: Scalars['String'];
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Aggregate = {
  __typename?: 'stock_receipts_order_item_aggregate';
  aggregate?: Maybe<Stock_Receipts_Order_Item_Aggregate_Fields>;
  nodes: Array<Stock_Receipts_Order_Item>;
};

/** aggregate fields of "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Aggregate_Fields = {
  __typename?: 'stock_receipts_order_item_aggregate_fields';
  avg?: Maybe<Stock_Receipts_Order_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Receipts_Order_Item_Max_Fields>;
  min?: Maybe<Stock_Receipts_Order_Item_Min_Fields>;
  stddev?: Maybe<Stock_Receipts_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Receipts_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Receipts_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Receipts_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Stock_Receipts_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Receipts_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Stock_Receipts_Order_Item_Variance_Fields>;
};


/** aggregate fields of "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Receipts_Order_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Receipts_Order_Item_Max_Order_By>;
  min?: InputMaybe<Stock_Receipts_Order_Item_Min_Order_By>;
  stddev?: InputMaybe<Stock_Receipts_Order_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Receipts_Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Receipts_Order_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Receipts_Order_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Receipts_Order_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Receipts_Order_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Receipts_Order_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Stock_Receipts_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Receipts_Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Receipts_Order_Item_Avg_Fields = {
  __typename?: 'stock_receipts_order_item_avg_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_receipts_order_item". All fields are combined with a logical 'AND'. */
export type Stock_Receipts_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  operation_id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_receipts_order_item" */
export enum Stock_Receipts_Order_Item_Constraint {
  /** unique or primary key constraint */
  ReceiptOrderItemPkey = 'receipt_order_item_pkey'
}

/** input type for incrementing integer column in table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Receipts_Order_Item_Max_Fields = {
  __typename?: 'stock_receipts_order_item_max_fields';
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Receipts_Order_Item_Min_Fields = {
  __typename?: 'stock_receipts_order_item_min_fields';
  created_by?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  status?: Maybe<Scalars['String']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Mutation_Response = {
  __typename?: 'stock_receipts_order_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Receipts_Order_Item>;
};

/** input type for inserting object relation for remote table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Obj_Rel_Insert_Input = {
  data: Stock_Receipts_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Receipts_Order_Item_On_Conflict>;
};

/** on conflict condition type for table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_On_Conflict = {
  constraint: Stock_Receipts_Order_Item_Constraint;
  update_columns: Array<Stock_Receipts_Order_Item_Update_Column>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};

/** ordering options when selecting data from "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Order_By = {
  created_by?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "stock_receipts_order_item" */
export enum Stock_Receipts_Order_Item_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  status?: InputMaybe<Scalars['String']>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Receipts_Order_Item_Stddev_Fields = {
  __typename?: 'stock_receipts_order_item_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Receipts_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'stock_receipts_order_item_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Receipts_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'stock_receipts_order_item_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Receipts_Order_Item_Sum_Fields = {
  __typename?: 'stock_receipts_order_item_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** update columns of table "stock_receipts_order_item" */
export enum Stock_Receipts_Order_Item_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Receipts_Order_Item_Var_Pop_Fields = {
  __typename?: 'stock_receipts_order_item_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Receipts_Order_Item_Var_Samp_Fields = {
  __typename?: 'stock_receipts_order_item_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Receipts_Order_Item_Variance_Fields = {
  __typename?: 'stock_receipts_order_item_variance_fields';
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_receipts_order_item" */
export type Stock_Receipts_Order_Item_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_sales_order_item" */
export type Stock_Sales_Order_Item = {
  __typename?: 'stock_sales_order_item';
  created_by?: Maybe<Scalars['String']>;
  dest_location: Scalars['Int'];
  id: Scalars['bigint'];
  missing_amount?: Maybe<Scalars['numeric']>;
  operation_id: Scalars['Int'];
  order_id: Scalars['bigint'];
  partner_customer?: Maybe<Scalars['Int']>;
  price: Scalars['numeric'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom: Scalars['numeric'];
  src_location: Scalars['Int'];
  status: Stock_Deliver_Status_Enum;
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Aggregate = {
  __typename?: 'stock_sales_order_item_aggregate';
  aggregate?: Maybe<Stock_Sales_Order_Item_Aggregate_Fields>;
  nodes: Array<Stock_Sales_Order_Item>;
};

/** aggregate fields of "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Aggregate_Fields = {
  __typename?: 'stock_sales_order_item_aggregate_fields';
  avg?: Maybe<Stock_Sales_Order_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Sales_Order_Item_Max_Fields>;
  min?: Maybe<Stock_Sales_Order_Item_Min_Fields>;
  stddev?: Maybe<Stock_Sales_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Sales_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Sales_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Sales_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Stock_Sales_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Sales_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Stock_Sales_Order_Item_Variance_Fields>;
};


/** aggregate fields of "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Sales_Order_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Sales_Order_Item_Max_Order_By>;
  min?: InputMaybe<Stock_Sales_Order_Item_Min_Order_By>;
  stddev?: InputMaybe<Stock_Sales_Order_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Sales_Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Sales_Order_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Sales_Order_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Sales_Order_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Sales_Order_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Sales_Order_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Stock_Sales_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Sales_Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Sales_Order_Item_Avg_Fields = {
  __typename?: 'stock_sales_order_item_avg_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Avg_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_sales_order_item". All fields are combined with a logical 'AND'. */
export type Stock_Sales_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Sales_Order_Item_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Sales_Order_Item_Bool_Exp>>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  dest_location?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  missing_amount?: InputMaybe<Numeric_Comparison_Exp>;
  operation_id?: InputMaybe<Int_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  partner_customer?: InputMaybe<Int_Comparison_Exp>;
  price?: InputMaybe<Numeric_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  src_location?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Stock_Deliver_Status_Enum_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_sales_order_item" */
export enum Stock_Sales_Order_Item_Constraint {
  /** unique or primary key constraint */
  StockSaleOrderItemPkey = 'stock_sale_order_item_pkey'
}

/** input type for incrementing integer column in table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Inc_Input = {
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_customer?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_customer?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Sales_Order_Item_Max_Fields = {
  __typename?: 'stock_sales_order_item_max_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_customer?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Sales_Order_Item_Min_Fields = {
  __typename?: 'stock_sales_order_item_min_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_customer?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Mutation_Response = {
  __typename?: 'stock_sales_order_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Sales_Order_Item>;
};

/** input type for inserting object relation for remote table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Obj_Rel_Insert_Input = {
  data: Stock_Sales_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Sales_Order_Item_On_Conflict>;
};

/** on conflict condition type for table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_On_Conflict = {
  constraint: Stock_Sales_Order_Item_Constraint;
  update_columns: Array<Stock_Sales_Order_Item_Update_Column>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};

/** ordering options when selecting data from "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "stock_sales_order_item" */
export enum Stock_Sales_Order_Item_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerCustomer = 'partner_customer',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  operation_id?: InputMaybe<Scalars['Int']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  partner_customer?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Sales_Order_Item_Stddev_Fields = {
  __typename?: 'stock_sales_order_item_stddev_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Stddev_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Sales_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'stock_sales_order_item_stddev_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Stddev_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Sales_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'stock_sales_order_item_stddev_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Stddev_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Sales_Order_Item_Sum_Fields = {
  __typename?: 'stock_sales_order_item_sum_fields';
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  operation_id?: Maybe<Scalars['Int']>;
  order_id?: Maybe<Scalars['bigint']>;
  partner_customer?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['numeric']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Sum_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** update columns of table "stock_sales_order_item" */
export enum Stock_Sales_Order_Item_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OperationId = 'operation_id',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  PartnerCustomer = 'partner_customer',
  /** column name */
  Price = 'price',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Sales_Order_Item_Var_Pop_Fields = {
  __typename?: 'stock_sales_order_item_var_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Var_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Sales_Order_Item_Var_Samp_Fields = {
  __typename?: 'stock_sales_order_item_var_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Var_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Sales_Order_Item_Variance_Fields = {
  __typename?: 'stock_sales_order_item_variance_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  operation_id?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  partner_customer?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_sales_order_item" */
export type Stock_Sales_Order_Item_Variance_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  operation_id?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  partner_customer?: InputMaybe<Order_By>;
  price?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** columns and relationships of "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item = {
  __typename?: 'stock_transfer_order_item';
  created_by?: Maybe<Scalars['String']>;
  /** An object relationship */
  dest: Stock_Location;
  dest_location: Scalars['Int'];
  id: Scalars['bigint'];
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id: Scalars['bigint'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  quantity_on_product_uom: Scalars['numeric'];
  /** An object relationship */
  src: Stock_Location;
  src_location: Scalars['Int'];
  status: Stock_Deliver_Status_Enum;
  updated_by?: Maybe<Scalars['String']>;
};

/** aggregated selection of "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Aggregate = {
  __typename?: 'stock_transfer_order_item_aggregate';
  aggregate?: Maybe<Stock_Transfer_Order_Item_Aggregate_Fields>;
  nodes: Array<Stock_Transfer_Order_Item>;
};

/** aggregate fields of "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Aggregate_Fields = {
  __typename?: 'stock_transfer_order_item_aggregate_fields';
  avg?: Maybe<Stock_Transfer_Order_Item_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Stock_Transfer_Order_Item_Max_Fields>;
  min?: Maybe<Stock_Transfer_Order_Item_Min_Fields>;
  stddev?: Maybe<Stock_Transfer_Order_Item_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Transfer_Order_Item_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Transfer_Order_Item_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Transfer_Order_Item_Sum_Fields>;
  var_pop?: Maybe<Stock_Transfer_Order_Item_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Transfer_Order_Item_Var_Samp_Fields>;
  variance?: Maybe<Stock_Transfer_Order_Item_Variance_Fields>;
};


/** aggregate fields of "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Aggregate_Order_By = {
  avg?: InputMaybe<Stock_Transfer_Order_Item_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Stock_Transfer_Order_Item_Max_Order_By>;
  min?: InputMaybe<Stock_Transfer_Order_Item_Min_Order_By>;
  stddev?: InputMaybe<Stock_Transfer_Order_Item_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Stock_Transfer_Order_Item_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Stock_Transfer_Order_Item_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Stock_Transfer_Order_Item_Sum_Order_By>;
  var_pop?: InputMaybe<Stock_Transfer_Order_Item_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Stock_Transfer_Order_Item_Var_Samp_Order_By>;
  variance?: InputMaybe<Stock_Transfer_Order_Item_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Arr_Rel_Insert_Input = {
  data: Array<Stock_Transfer_Order_Item_Insert_Input>;
  on_conflict?: InputMaybe<Stock_Transfer_Order_Item_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Transfer_Order_Item_Avg_Fields = {
  __typename?: 'stock_transfer_order_item_avg_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Avg_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_transfer_order_item". All fields are combined with a logical 'AND'. */
export type Stock_Transfer_Order_Item_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>>>;
  _not?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>>>;
  created_by?: InputMaybe<String_Comparison_Exp>;
  dest?: InputMaybe<Stock_Location_Bool_Exp>;
  dest_location?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  lot_id?: InputMaybe<Int_Comparison_Exp>;
  missing_amount?: InputMaybe<Numeric_Comparison_Exp>;
  order_id?: InputMaybe<Bigint_Comparison_Exp>;
  product?: InputMaybe<Product_Bool_Exp>;
  product_id?: InputMaybe<Int_Comparison_Exp>;
  product_uom?: InputMaybe<Int_Comparison_Exp>;
  quantity?: InputMaybe<Numeric_Comparison_Exp>;
  quantity_on_product_uom?: InputMaybe<Numeric_Comparison_Exp>;
  src?: InputMaybe<Stock_Location_Bool_Exp>;
  src_location?: InputMaybe<Int_Comparison_Exp>;
  status?: InputMaybe<Stock_Deliver_Status_Enum_Comparison_Exp>;
  updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_transfer_order_item" */
export enum Stock_Transfer_Order_Item_Constraint {
  /** unique or primary key constraint */
  StockRansferOrderItemtPkey = 'stock_ransfer_order_itemt_pkey'
}

/** input type for incrementing integer column in table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Inc_Input = {
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Insert_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product?: InputMaybe<Product_Obj_Rel_Insert_Input>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src?: InputMaybe<Stock_Location_Obj_Rel_Insert_Input>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Stock_Transfer_Order_Item_Max_Fields = {
  __typename?: 'stock_transfer_order_item_max_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Max_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Transfer_Order_Item_Min_Fields = {
  __typename?: 'stock_transfer_order_item_min_fields';
  created_by?: Maybe<Scalars['String']>;
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
  updated_by?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Min_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Mutation_Response = {
  __typename?: 'stock_transfer_order_item_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Stock_Transfer_Order_Item>;
};

/** input type for inserting object relation for remote table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Obj_Rel_Insert_Input = {
  data: Stock_Transfer_Order_Item_Insert_Input;
  on_conflict?: InputMaybe<Stock_Transfer_Order_Item_On_Conflict>;
};

/** on conflict condition type for table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_On_Conflict = {
  constraint: Stock_Transfer_Order_Item_Constraint;
  update_columns: Array<Stock_Transfer_Order_Item_Update_Column>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};

/** ordering options when selecting data from "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Order_By = {
  created_by?: InputMaybe<Order_By>;
  dest?: InputMaybe<Stock_Location_Order_By>;
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product?: InputMaybe<Product_Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src?: InputMaybe<Stock_Location_Order_By>;
  src_location?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_by?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Pk_Columns_Input = {
  id: Scalars['bigint'];
};

/** select columns of table "stock_transfer_order_item" */
export enum Stock_Transfer_Order_Item_Select_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** input type for updating data in table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Set_Input = {
  created_by?: InputMaybe<Scalars['String']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['bigint']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  missing_amount?: InputMaybe<Scalars['numeric']>;
  order_id?: InputMaybe<Scalars['bigint']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  quantity?: InputMaybe<Scalars['numeric']>;
  quantity_on_product_uom?: InputMaybe<Scalars['numeric']>;
  src_location?: InputMaybe<Scalars['Int']>;
  status?: InputMaybe<Stock_Deliver_Status_Enum>;
  updated_by?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Stock_Transfer_Order_Item_Stddev_Fields = {
  __typename?: 'stock_transfer_order_item_stddev_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Stddev_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Transfer_Order_Item_Stddev_Pop_Fields = {
  __typename?: 'stock_transfer_order_item_stddev_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Stddev_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Transfer_Order_Item_Stddev_Samp_Fields = {
  __typename?: 'stock_transfer_order_item_stddev_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Stddev_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Stock_Transfer_Order_Item_Sum_Fields = {
  __typename?: 'stock_transfer_order_item_sum_fields';
  dest_location?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  lot_id?: Maybe<Scalars['Int']>;
  missing_amount?: Maybe<Scalars['numeric']>;
  order_id?: Maybe<Scalars['bigint']>;
  product_id?: Maybe<Scalars['Int']>;
  product_uom?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['numeric']>;
  quantity_on_product_uom?: Maybe<Scalars['numeric']>;
  src_location?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Sum_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** update columns of table "stock_transfer_order_item" */
export enum Stock_Transfer_Order_Item_Update_Column {
  /** column name */
  CreatedBy = 'created_by',
  /** column name */
  DestLocation = 'dest_location',
  /** column name */
  Id = 'id',
  /** column name */
  LotId = 'lot_id',
  /** column name */
  MissingAmount = 'missing_amount',
  /** column name */
  OrderId = 'order_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  ProductUom = 'product_uom',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  QuantityOnProductUom = 'quantity_on_product_uom',
  /** column name */
  SrcLocation = 'src_location',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedBy = 'updated_by'
}

/** aggregate var_pop on columns */
export type Stock_Transfer_Order_Item_Var_Pop_Fields = {
  __typename?: 'stock_transfer_order_item_var_pop_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Var_Pop_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Transfer_Order_Item_Var_Samp_Fields = {
  __typename?: 'stock_transfer_order_item_var_samp_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Var_Samp_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Transfer_Order_Item_Variance_Fields = {
  __typename?: 'stock_transfer_order_item_variance_fields';
  dest_location?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  lot_id?: Maybe<Scalars['Float']>;
  missing_amount?: Maybe<Scalars['Float']>;
  order_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  product_uom?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  quantity_on_product_uom?: Maybe<Scalars['Float']>;
  src_location?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_transfer_order_item" */
export type Stock_Transfer_Order_Item_Variance_Order_By = {
  dest_location?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lot_id?: InputMaybe<Order_By>;
  missing_amount?: InputMaybe<Order_By>;
  order_id?: InputMaybe<Order_By>;
  product_id?: InputMaybe<Order_By>;
  product_uom?: InputMaybe<Order_By>;
  quantity?: InputMaybe<Order_By>;
  quantity_on_product_uom?: InputMaybe<Order_By>;
  src_location?: InputMaybe<Order_By>;
};

/** subscription root */
export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "daily_existence_report" */
  daily_existence_report: Array<Daily_Existence_Report>;
  /** fetch aggregated fields from the table: "daily_existence_report" */
  daily_existence_report_aggregate: Daily_Existence_Report_Aggregate;
  /** fetch data from the table: "daily_existence_report" using primary key columns */
  daily_existence_report_by_pk?: Maybe<Daily_Existence_Report>;
  /** fetch data from the table: "import_sales_order" */
  import_sales_order: Array<Import_Sales_Order>;
  /** fetch aggregated fields from the table: "import_sales_order" */
  import_sales_order_aggregate: Import_Sales_Order_Aggregate;
  /** fetch data from the table: "import_sales_order" using primary key columns */
  import_sales_order_by_pk?: Maybe<Import_Sales_Order>;
  /** fetch data from the table: "modules" */
  modules: Array<Modules>;
  /** fetch aggregated fields from the table: "modules" */
  modules_aggregate: Modules_Aggregate;
  /** fetch data from the table: "modules" using primary key columns */
  modules_by_pk?: Maybe<Modules>;
  /** execute function "opb_execute_daily_report" which returns "daily_existence_report" */
  opb_execute_daily_report: Array<Daily_Existence_Report>;
  /** execute function "opb_execute_daily_report" and query aggregates on result of table type "daily_existence_report" */
  opb_execute_daily_report_aggregate: Daily_Existence_Report_Aggregate;
  /** fetch data from the table: "opb_existence" */
  opb_existence: Array<Opb_Existence>;
  /** fetch aggregated fields from the table: "opb_existence" */
  opb_existence_aggregate: Opb_Existence_Aggregate;
  /** fetch data from the table: "opb_ipv_report" */
  opb_ipv_report: Array<Opb_Ipv_Report>;
  /** fetch aggregated fields from the table: "opb_ipv_report" */
  opb_ipv_report_aggregate: Opb_Ipv_Report_Aggregate;
  /** fetch data from the table: "opb_product_existence" */
  opb_product_existence: Array<Opb_Product_Existence>;
  /** fetch aggregated fields from the table: "opb_product_existence" */
  opb_product_existence_aggregate: Opb_Product_Existence_Aggregate;
  /** fetch data from the table: "opb_product_lot" */
  opb_product_lot: Array<Opb_Product_Lot>;
  /** fetch aggregated fields from the table: "opb_product_lot" */
  opb_product_lot_aggregate: Opb_Product_Lot_Aggregate;
  /** fetch data from the table: "opb_product_min_quantity_alert" */
  opb_product_min_quantity_alert: Array<Opb_Product_Min_Quantity_Alert>;
  /** fetch aggregated fields from the table: "opb_product_min_quantity_alert" */
  opb_product_min_quantity_alert_aggregate: Opb_Product_Min_Quantity_Alert_Aggregate;
  /** fetch data from the table: "opb_product_near_to_expire" */
  opb_product_near_to_expire: Array<Opb_Product_Near_To_Expire>;
  /** fetch aggregated fields from the table: "opb_product_near_to_expire" */
  opb_product_near_to_expire_aggregate: Opb_Product_Near_To_Expire_Aggregate;
  /** execute function "opb_profit_report" which returns "opb_profit_report_template" */
  opb_profit_report: Array<Opb_Profit_Report_Template>;
  /** execute function "opb_profit_report" and query aggregates on result of table type "opb_profit_report_template" */
  opb_profit_report_aggregate: Opb_Profit_Report_Template_Aggregate;
  /** fetch data from the table: "opb_profit_report_template" */
  opb_profit_report_template: Array<Opb_Profit_Report_Template>;
  /** fetch aggregated fields from the table: "opb_profit_report_template" */
  opb_profit_report_template_aggregate: Opb_Profit_Report_Template_Aggregate;
  /** fetch data from the table: "opb_purchases_view" */
  opb_purchases_view: Array<Opb_Purchases_View>;
  /** fetch aggregated fields from the table: "opb_purchases_view" */
  opb_purchases_view_aggregate: Opb_Purchases_View_Aggregate;
  /** fetch data from the table: "opb_sales_view" */
  opb_sales_view: Array<Opb_Sales_View>;
  /** fetch aggregated fields from the table: "opb_sales_view" */
  opb_sales_view_aggregate: Opb_Sales_View_Aggregate;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product_bill_of_material_items" */
  product_bill_of_material_items: Array<Product_Bill_Of_Material_Items>;
  /** fetch aggregated fields from the table: "product_bill_of_material_items" */
  product_bill_of_material_items_aggregate: Product_Bill_Of_Material_Items_Aggregate;
  /** fetch data from the table: "product_bill_of_material_items" using primary key columns */
  product_bill_of_material_items_by_pk?: Maybe<Product_Bill_Of_Material_Items>;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_category" */
  product_category: Array<Product_Category>;
  /** fetch aggregated fields from the table: "product_category" */
  product_category_aggregate: Product_Category_Aggregate;
  /** fetch data from the table: "product_category" using primary key columns */
  product_category_by_pk?: Maybe<Product_Category>;
  /** fetch data from the table: "quantity_on_hand" */
  quantity_on_hand: Array<Quantity_On_Hand>;
  /** fetch aggregated fields from the table: "quantity_on_hand" */
  quantity_on_hand_aggregate: Quantity_On_Hand_Aggregate;
  /** fetch data from the table: "stock_deliver_status" */
  stock_deliver_status: Array<Stock_Deliver_Status>;
  /** fetch aggregated fields from the table: "stock_deliver_status" */
  stock_deliver_status_aggregate: Stock_Deliver_Status_Aggregate;
  /** fetch data from the table: "stock_deliver_status" using primary key columns */
  stock_deliver_status_by_pk?: Maybe<Stock_Deliver_Status>;
  /** fetch data from the table: "stock_location" */
  stock_location: Array<Stock_Location>;
  /** fetch aggregated fields from the table: "stock_location" */
  stock_location_aggregate: Stock_Location_Aggregate;
  /** fetch data from the table: "stock_location" using primary key columns */
  stock_location_by_pk?: Maybe<Stock_Location>;
  /** fetch data from the table: "stock_location_type" */
  stock_location_type: Array<Stock_Location_Type>;
  /** fetch aggregated fields from the table: "stock_location_type" */
  stock_location_type_aggregate: Stock_Location_Type_Aggregate;
  /** fetch data from the table: "stock_location_type" using primary key columns */
  stock_location_type_by_pk?: Maybe<Stock_Location_Type>;
  /** fetch data from the table: "stock_manufacturing_order_item" */
  stock_manufacturing_order_item: Array<Stock_Manufacturing_Order_Item>;
  /** fetch aggregated fields from the table: "stock_manufacturing_order_item" */
  stock_manufacturing_order_item_aggregate: Stock_Manufacturing_Order_Item_Aggregate;
  /** fetch data from the table: "stock_manufacturing_order_item" using primary key columns */
  stock_manufacturing_order_item_by_pk?: Maybe<Stock_Manufacturing_Order_Item>;
  /** fetch data from the table: "stock_operation" */
  stock_operation: Array<Stock_Operation>;
  /** fetch aggregated fields from the table: "stock_operation" */
  stock_operation_aggregate: Stock_Operation_Aggregate;
  /** fetch data from the table: "stock_operation" using primary key columns */
  stock_operation_by_pk?: Maybe<Stock_Operation>;
  /** fetch data from the table: "stock_operation_type" */
  stock_operation_type: Array<Stock_Operation_Type>;
  /** fetch aggregated fields from the table: "stock_operation_type" */
  stock_operation_type_aggregate: Stock_Operation_Type_Aggregate;
  /** fetch data from the table: "stock_operation_type" using primary key columns */
  stock_operation_type_by_pk?: Maybe<Stock_Operation_Type>;
  /** fetch data from the table: "stock_order" */
  stock_order: Array<Stock_Order>;
  /** fetch aggregated fields from the table: "stock_order" */
  stock_order_aggregate: Stock_Order_Aggregate;
  /** fetch data from the table: "stock_order" using primary key columns */
  stock_order_by_pk?: Maybe<Stock_Order>;
  /** fetch data from the table: "stock_order_execution_errors" */
  stock_order_execution_errors: Array<Stock_Order_Execution_Errors>;
  /** fetch aggregated fields from the table: "stock_order_execution_errors" */
  stock_order_execution_errors_aggregate: Stock_Order_Execution_Errors_Aggregate;
  /** fetch data from the table: "stock_order_execution_errors" using primary key columns */
  stock_order_execution_errors_by_pk?: Maybe<Stock_Order_Execution_Errors>;
  /** fetch data from the table: "stock_order_status" */
  stock_order_status: Array<Stock_Order_Status>;
  /** fetch aggregated fields from the table: "stock_order_status" */
  stock_order_status_aggregate: Stock_Order_Status_Aggregate;
  /** fetch data from the table: "stock_order_status" using primary key columns */
  stock_order_status_by_pk?: Maybe<Stock_Order_Status>;
  /** fetch data from the table: "stock_order_type" */
  stock_order_type: Array<Stock_Order_Type>;
  /** fetch aggregated fields from the table: "stock_order_type" */
  stock_order_type_aggregate: Stock_Order_Type_Aggregate;
  /** fetch data from the table: "stock_order_type" using primary key columns */
  stock_order_type_by_pk?: Maybe<Stock_Order_Type>;
  /** fetch data from the table: "stock_partner" */
  stock_partner: Array<Stock_Partner>;
  /** fetch aggregated fields from the table: "stock_partner" */
  stock_partner_aggregate: Stock_Partner_Aggregate;
  /** fetch data from the table: "stock_partner" using primary key columns */
  stock_partner_by_pk?: Maybe<Stock_Partner>;
  /** fetch data from the table: "stock_production_lot" */
  stock_production_lot: Array<Stock_Production_Lot>;
  /** fetch aggregated fields from the table: "stock_production_lot" */
  stock_production_lot_aggregate: Stock_Production_Lot_Aggregate;
  /** fetch data from the table: "stock_production_lot" using primary key columns */
  stock_production_lot_by_pk?: Maybe<Stock_Production_Lot>;
  /** fetch data from the table: "stock_purchase_order_item" */
  stock_purchase_order_item: Array<Stock_Purchase_Order_Item>;
  /** fetch aggregated fields from the table: "stock_purchase_order_item" */
  stock_purchase_order_item_aggregate: Stock_Purchase_Order_Item_Aggregate;
  /** fetch data from the table: "stock_purchase_order_item" using primary key columns */
  stock_purchase_order_item_by_pk?: Maybe<Stock_Purchase_Order_Item>;
  /** fetch data from the table: "stock_quantity" */
  stock_quantity: Array<Stock_Quantity>;
  /** fetch aggregated fields from the table: "stock_quantity" */
  stock_quantity_aggregate: Stock_Quantity_Aggregate;
  /** fetch data from the table: "stock_quantity" using primary key columns */
  stock_quantity_by_pk?: Maybe<Stock_Quantity>;
  /** fetch data from the table: "stock_receipts_order_item" */
  stock_receipts_order_item: Array<Stock_Receipts_Order_Item>;
  /** fetch aggregated fields from the table: "stock_receipts_order_item" */
  stock_receipts_order_item_aggregate: Stock_Receipts_Order_Item_Aggregate;
  /** fetch data from the table: "stock_receipts_order_item" using primary key columns */
  stock_receipts_order_item_by_pk?: Maybe<Stock_Receipts_Order_Item>;
  /** fetch data from the table: "stock_sales_order_item" */
  stock_sales_order_item: Array<Stock_Sales_Order_Item>;
  /** fetch aggregated fields from the table: "stock_sales_order_item" */
  stock_sales_order_item_aggregate: Stock_Sales_Order_Item_Aggregate;
  /** fetch data from the table: "stock_sales_order_item" using primary key columns */
  stock_sales_order_item_by_pk?: Maybe<Stock_Sales_Order_Item>;
  /** fetch data from the table: "stock_transfer_order_item" */
  stock_transfer_order_item: Array<Stock_Transfer_Order_Item>;
  /** fetch aggregated fields from the table: "stock_transfer_order_item" */
  stock_transfer_order_item_aggregate: Stock_Transfer_Order_Item_Aggregate;
  /** fetch data from the table: "stock_transfer_order_item" using primary key columns */
  stock_transfer_order_item_by_pk?: Maybe<Stock_Transfer_Order_Item>;
  /** fetch data from the table: "unit_of_measure" */
  unit_of_measure: Array<Unit_Of_Measure>;
  /** fetch aggregated fields from the table: "unit_of_measure" */
  unit_of_measure_aggregate: Unit_Of_Measure_Aggregate;
  /** fetch data from the table: "unit_of_measure" using primary key columns */
  unit_of_measure_by_pk?: Maybe<Unit_Of_Measure>;
  /** fetch data from the table: "unit_of_measure_category" */
  unit_of_measure_category: Array<Unit_Of_Measure_Category>;
  /** fetch aggregated fields from the table: "unit_of_measure_category" */
  unit_of_measure_category_aggregate: Unit_Of_Measure_Category_Aggregate;
  /** fetch data from the table: "unit_of_measure_category" using primary key columns */
  unit_of_measure_category_by_pk?: Maybe<Unit_Of_Measure_Category>;
  /** fetch data from the table: "unit_of_measure_conversion" */
  unit_of_measure_conversion: Array<Unit_Of_Measure_Conversion>;
  /** fetch aggregated fields from the table: "unit_of_measure_conversion" */
  unit_of_measure_conversion_aggregate: Unit_Of_Measure_Conversion_Aggregate;
  /** fetch data from the table: "unit_of_measure_conversion" using primary key columns */
  unit_of_measure_conversion_by_pk?: Maybe<Unit_Of_Measure_Conversion>;
  /** fetch data from the table: "user_permission" */
  user_permission: Array<User_Permission>;
  /** fetch aggregated fields from the table: "user_permission" */
  user_permission_aggregate: User_Permission_Aggregate;
  /** fetch data from the table: "user_permission" using primary key columns */
  user_permission_by_pk?: Maybe<User_Permission>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


/** subscription root */
export type Subscription_RootAuth_Account_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Providers_Order_By>>;
  where?: InputMaybe<Auth_Account_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_Account_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Account_Roles_Order_By>>;
  where?: InputMaybe<Auth_Account_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_AccountsArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Accounts_Order_By>>;
  where?: InputMaybe<Auth_Accounts_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Providers_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Providers_Order_By>>;
  where?: InputMaybe<Auth_Providers_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** subscription root */
export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: InputMaybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootAuth_RolesArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Auth_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Auth_Roles_Order_By>>;
  where?: InputMaybe<Auth_Roles_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** subscription root */
export type Subscription_RootDaily_Existence_ReportArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDaily_Existence_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootDaily_Existence_Report_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootImport_Sales_OrderArgs = {
  distinct_on?: InputMaybe<Array<Import_Sales_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Import_Sales_Order_Order_By>>;
  where?: InputMaybe<Import_Sales_Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootImport_Sales_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Import_Sales_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Import_Sales_Order_Order_By>>;
  where?: InputMaybe<Import_Sales_Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootImport_Sales_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootModulesArgs = {
  distinct_on?: InputMaybe<Array<Modules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Modules_Order_By>>;
  where?: InputMaybe<Modules_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModules_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Modules_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Modules_Order_By>>;
  where?: InputMaybe<Modules_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootModules_By_PkArgs = {
  module_name: Scalars['String'];
};


/** subscription root */
export type Subscription_RootOpb_Execute_Daily_ReportArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Execute_Daily_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Daily_Existence_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Daily_Existence_Report_Order_By>>;
  where?: InputMaybe<Daily_Existence_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_ExistenceArgs = {
  distinct_on?: InputMaybe<Array<Opb_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Existence_Order_By>>;
  where?: InputMaybe<Opb_Existence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Existence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Existence_Order_By>>;
  where?: InputMaybe<Opb_Existence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Ipv_ReportArgs = {
  distinct_on?: InputMaybe<Array<Opb_Ipv_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Ipv_Report_Order_By>>;
  where?: InputMaybe<Opb_Ipv_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Ipv_Report_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Ipv_Report_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Ipv_Report_Order_By>>;
  where?: InputMaybe<Opb_Ipv_Report_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_ExistenceArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Existence_Order_By>>;
  where?: InputMaybe<Opb_Product_Existence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Existence_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Existence_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Existence_Order_By>>;
  where?: InputMaybe<Opb_Product_Existence_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_LotArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Lot_Order_By>>;
  where?: InputMaybe<Opb_Product_Lot_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Min_Quantity_AlertArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Order_By>>;
  where?: InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Min_Quantity_Alert_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Min_Quantity_Alert_Order_By>>;
  where?: InputMaybe<Opb_Product_Min_Quantity_Alert_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Near_To_ExpireArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Near_To_Expire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Near_To_Expire_Order_By>>;
  where?: InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Product_Near_To_Expire_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Product_Near_To_Expire_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Product_Near_To_Expire_Order_By>>;
  where?: InputMaybe<Opb_Product_Near_To_Expire_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Profit_ReportArgs = {
  args: Opb_Profit_Report_Args;
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Profit_Report_AggregateArgs = {
  args: Opb_Profit_Report_Args;
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Profit_Report_TemplateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Profit_Report_Template_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Profit_Report_Template_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Profit_Report_Template_Order_By>>;
  where?: InputMaybe<Opb_Profit_Report_Template_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Purchases_ViewArgs = {
  distinct_on?: InputMaybe<Array<Opb_Purchases_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Purchases_View_Order_By>>;
  where?: InputMaybe<Opb_Purchases_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Purchases_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Purchases_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Purchases_View_Order_By>>;
  where?: InputMaybe<Opb_Purchases_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Sales_ViewArgs = {
  distinct_on?: InputMaybe<Array<Opb_Sales_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Sales_View_Order_By>>;
  where?: InputMaybe<Opb_Sales_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootOpb_Sales_View_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Opb_Sales_View_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Opb_Sales_View_Order_By>>;
  where?: InputMaybe<Opb_Sales_View_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProductArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By>>;
  where?: InputMaybe<Product_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_Bill_Of_Material_ItemsArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_Bill_Of_Material_Items_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Bill_Of_Material_Items_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Bill_Of_Material_Items_Order_By>>;
  where?: InputMaybe<Product_Bill_Of_Material_Items_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_Bill_Of_Material_Items_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootProduct_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Category_Order_By>>;
  where?: InputMaybe<Product_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Product_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Category_Order_By>>;
  where?: InputMaybe<Product_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootProduct_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootQuantity_On_HandArgs = {
  distinct_on?: InputMaybe<Array<Quantity_On_Hand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quantity_On_Hand_Order_By>>;
  where?: InputMaybe<Quantity_On_Hand_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootQuantity_On_Hand_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Quantity_On_Hand_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Quantity_On_Hand_Order_By>>;
  where?: InputMaybe<Quantity_On_Hand_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Deliver_StatusArgs = {
  distinct_on?: InputMaybe<Array<Stock_Deliver_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Deliver_Status_Order_By>>;
  where?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Deliver_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Deliver_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Deliver_Status_Order_By>>;
  where?: InputMaybe<Stock_Deliver_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Deliver_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStock_LocationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Order_By>>;
  where?: InputMaybe<Stock_Location_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Location_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Order_By>>;
  where?: InputMaybe<Stock_Location_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Location_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Location_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Type_Order_By>>;
  where?: InputMaybe<Stock_Location_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Location_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Location_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Location_Type_Order_By>>;
  where?: InputMaybe<Stock_Location_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Location_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStock_Manufacturing_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Manufacturing_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Manufacturing_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Manufacturing_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Manufacturing_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStock_OperationArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Order_By>>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Operation_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Order_By>>;
  where?: InputMaybe<Stock_Operation_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Operation_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Operation_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Type_Order_By>>;
  where?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Operation_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Operation_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Operation_Type_Order_By>>;
  where?: InputMaybe<Stock_Operation_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Operation_Type_By_PkArgs = {
  value: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStock_OrderArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By>>;
  where?: InputMaybe<Stock_Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By>>;
  where?: InputMaybe<Stock_Order_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStock_Order_Execution_ErrorsArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Execution_Errors_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Execution_Errors_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Execution_Errors_Order_By>>;
  where?: InputMaybe<Stock_Order_Execution_Errors_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Execution_Errors_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Order_StatusArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Status_Order_By>>;
  where?: InputMaybe<Stock_Order_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Status_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Status_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Status_Order_By>>;
  where?: InputMaybe<Stock_Order_Status_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Status_By_PkArgs = {
  status: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStock_Order_TypeArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Type_Order_By>>;
  where?: InputMaybe<Stock_Order_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Type_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Order_Type_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Type_Order_By>>;
  where?: InputMaybe<Stock_Order_Type_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Order_Type_By_PkArgs = {
  code: Scalars['String'];
};


/** subscription root */
export type Subscription_RootStock_PartnerArgs = {
  distinct_on?: InputMaybe<Array<Stock_Partner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Partner_Order_By>>;
  where?: InputMaybe<Stock_Partner_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Partner_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Partner_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Partner_Order_By>>;
  where?: InputMaybe<Stock_Partner_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Partner_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Production_LotArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Production_Lot_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Production_Lot_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Production_Lot_Order_By>>;
  where?: InputMaybe<Stock_Production_Lot_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Production_Lot_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Purchase_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Purchase_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Purchase_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Purchase_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Purchase_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Purchase_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStock_QuantityArgs = {
  distinct_on?: InputMaybe<Array<Stock_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Quantity_Order_By>>;
  where?: InputMaybe<Stock_Quantity_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Quantity_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Quantity_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Quantity_Order_By>>;
  where?: InputMaybe<Stock_Quantity_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Quantity_By_PkArgs = {
  location_id: Scalars['Int'];
  lot_id: Scalars['Int'];
  product_id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootStock_Receipts_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Receipts_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Receipts_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Receipts_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Receipts_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Receipts_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStock_Sales_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Sales_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Sales_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Sales_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Sales_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Sales_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootStock_Transfer_Order_ItemArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Transfer_Order_Item_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stock_Transfer_Order_Item_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Transfer_Order_Item_Order_By>>;
  where?: InputMaybe<Stock_Transfer_Order_Item_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootStock_Transfer_Order_Item_By_PkArgs = {
  id: Scalars['bigint'];
};


/** subscription root */
export type Subscription_RootUnit_Of_MeasureArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Category_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Category_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_Category_By_PkArgs = {
  id: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_ConversionArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_Conversion_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUnit_Of_Measure_Conversion_By_PkArgs = {
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
};


/** subscription root */
export type Subscription_RootUser_PermissionArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Permission_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUser_Permission_By_PkArgs = {
  module_name: Modules_Enum;
  user_id: Scalars['uuid'];
};


/** subscription root */
export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


/** subscription root */
export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};

/** expression to compare columns of type timestamp. All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']>;
  _gt?: InputMaybe<Scalars['timestamp']>;
  _gte?: InputMaybe<Scalars['timestamp']>;
  _in?: InputMaybe<Array<Scalars['timestamp']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamp']>;
  _lte?: InputMaybe<Scalars['timestamp']>;
  _neq?: InputMaybe<Scalars['timestamp']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']>>;
};

/** expression to compare columns of type timestamptz. All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']>;
  _gt?: InputMaybe<Scalars['timestamptz']>;
  _gte?: InputMaybe<Scalars['timestamptz']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['timestamptz']>;
  _lte?: InputMaybe<Scalars['timestamptz']>;
  _neq?: InputMaybe<Scalars['timestamptz']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "unit_of_measure" */
export type Unit_Of_Measure = {
  __typename?: 'unit_of_measure';
  /** An object relationship */
  category: Unit_Of_Measure_Category;
  category_id: Scalars['Int'];
  code: Scalars['String'];
  /** An array relationship */
  conversions: Array<Unit_Of_Measure_Conversion>;
  /** An aggregated array relationship */
  conversions_aggregate: Unit_Of_Measure_Conversion_Aggregate;
  created_at: Scalars['timestamptz'];
  description: Scalars['String'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "unit_of_measure" */
export type Unit_Of_MeasureConversionsArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};


/** columns and relationships of "unit_of_measure" */
export type Unit_Of_MeasureConversions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Unit_Of_Measure_Conversion_Order_By>>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};

/** aggregated selection of "unit_of_measure" */
export type Unit_Of_Measure_Aggregate = {
  __typename?: 'unit_of_measure_aggregate';
  aggregate?: Maybe<Unit_Of_Measure_Aggregate_Fields>;
  nodes: Array<Unit_Of_Measure>;
};

/** aggregate fields of "unit_of_measure" */
export type Unit_Of_Measure_Aggregate_Fields = {
  __typename?: 'unit_of_measure_aggregate_fields';
  avg?: Maybe<Unit_Of_Measure_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Unit_Of_Measure_Max_Fields>;
  min?: Maybe<Unit_Of_Measure_Min_Fields>;
  stddev?: Maybe<Unit_Of_Measure_Stddev_Fields>;
  stddev_pop?: Maybe<Unit_Of_Measure_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unit_Of_Measure_Stddev_Samp_Fields>;
  sum?: Maybe<Unit_Of_Measure_Sum_Fields>;
  var_pop?: Maybe<Unit_Of_Measure_Var_Pop_Fields>;
  var_samp?: Maybe<Unit_Of_Measure_Var_Samp_Fields>;
  variance?: Maybe<Unit_Of_Measure_Variance_Fields>;
};


/** aggregate fields of "unit_of_measure" */
export type Unit_Of_Measure_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unit_Of_Measure_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unit_of_measure" */
export type Unit_Of_Measure_Aggregate_Order_By = {
  avg?: InputMaybe<Unit_Of_Measure_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unit_Of_Measure_Max_Order_By>;
  min?: InputMaybe<Unit_Of_Measure_Min_Order_By>;
  stddev?: InputMaybe<Unit_Of_Measure_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unit_Of_Measure_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unit_Of_Measure_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unit_Of_Measure_Sum_Order_By>;
  var_pop?: InputMaybe<Unit_Of_Measure_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unit_Of_Measure_Var_Samp_Order_By>;
  variance?: InputMaybe<Unit_Of_Measure_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unit_of_measure" */
export type Unit_Of_Measure_Arr_Rel_Insert_Input = {
  data: Array<Unit_Of_Measure_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_On_Conflict>;
};

/** aggregate avg on columns */
export type Unit_Of_Measure_Avg_Fields = {
  __typename?: 'unit_of_measure_avg_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unit_of_measure". All fields are combined with a logical 'AND'. */
export type Unit_Of_Measure_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Bool_Exp>>>;
  _not?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Bool_Exp>>>;
  category?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
  category_id?: InputMaybe<Int_Comparison_Exp>;
  code?: InputMaybe<String_Comparison_Exp>;
  conversions?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** columns and relationships of "unit_of_measure_category" */
export type Unit_Of_Measure_Category = {
  __typename?: 'unit_of_measure_category';
  description?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  value: Scalars['String'];
};

/** aggregated selection of "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Aggregate = {
  __typename?: 'unit_of_measure_category_aggregate';
  aggregate?: Maybe<Unit_Of_Measure_Category_Aggregate_Fields>;
  nodes: Array<Unit_Of_Measure_Category>;
};

/** aggregate fields of "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Aggregate_Fields = {
  __typename?: 'unit_of_measure_category_aggregate_fields';
  avg?: Maybe<Unit_Of_Measure_Category_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Unit_Of_Measure_Category_Max_Fields>;
  min?: Maybe<Unit_Of_Measure_Category_Min_Fields>;
  stddev?: Maybe<Unit_Of_Measure_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Unit_Of_Measure_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unit_Of_Measure_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Unit_Of_Measure_Category_Sum_Fields>;
  var_pop?: Maybe<Unit_Of_Measure_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Unit_Of_Measure_Category_Var_Samp_Fields>;
  variance?: Maybe<Unit_Of_Measure_Category_Variance_Fields>;
};


/** aggregate fields of "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unit_Of_Measure_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Unit_Of_Measure_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unit_Of_Measure_Category_Max_Order_By>;
  min?: InputMaybe<Unit_Of_Measure_Category_Min_Order_By>;
  stddev?: InputMaybe<Unit_Of_Measure_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unit_Of_Measure_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unit_Of_Measure_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unit_Of_Measure_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Unit_Of_Measure_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unit_Of_Measure_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Unit_Of_Measure_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Arr_Rel_Insert_Input = {
  data: Array<Unit_Of_Measure_Category_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Unit_Of_Measure_Category_Avg_Fields = {
  __typename?: 'unit_of_measure_category_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unit_of_measure_category". All fields are combined with a logical 'AND'. */
export type Unit_Of_Measure_Category_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Category_Bool_Exp>>>;
  _not?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Category_Bool_Exp>>>;
  description?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  value?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "unit_of_measure_category" */
export enum Unit_Of_Measure_Category_Constraint {
  /** unique or primary key constraint */
  UnitOfMeasureCategoryPkey = 'unit_of_measure_category_pkey'
}

/** input type for incrementing integer column in table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Inc_Input = {
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Insert_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Unit_Of_Measure_Category_Max_Fields = {
  __typename?: 'unit_of_measure_category_max_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Max_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unit_Of_Measure_Category_Min_Fields = {
  __typename?: 'unit_of_measure_category_min_fields';
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Min_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Mutation_Response = {
  __typename?: 'unit_of_measure_category_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Unit_Of_Measure_Category>;
};

/** input type for inserting object relation for remote table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Obj_Rel_Insert_Input = {
  data: Unit_Of_Measure_Category_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_Category_On_Conflict>;
};

/** on conflict condition type for table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_On_Conflict = {
  constraint: Unit_Of_Measure_Category_Constraint;
  update_columns: Array<Unit_Of_Measure_Category_Update_Column>;
  where?: InputMaybe<Unit_Of_Measure_Category_Bool_Exp>;
};

/** ordering options when selecting data from "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Order_By = {
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "unit_of_measure_category" */
export enum Unit_Of_Measure_Category_Select_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Set_Input = {
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Unit_Of_Measure_Category_Stddev_Fields = {
  __typename?: 'unit_of_measure_category_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unit_Of_Measure_Category_Stddev_Pop_Fields = {
  __typename?: 'unit_of_measure_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unit_Of_Measure_Category_Stddev_Samp_Fields = {
  __typename?: 'unit_of_measure_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Unit_Of_Measure_Category_Sum_Fields = {
  __typename?: 'unit_of_measure_category_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "unit_of_measure_category" */
export enum Unit_Of_Measure_Category_Update_Column {
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Unit_Of_Measure_Category_Var_Pop_Fields = {
  __typename?: 'unit_of_measure_category_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unit_Of_Measure_Category_Var_Samp_Fields = {
  __typename?: 'unit_of_measure_category_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unit_Of_Measure_Category_Variance_Fields = {
  __typename?: 'unit_of_measure_category_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unit_of_measure_category" */
export type Unit_Of_Measure_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** unique or primary key constraints on table "unit_of_measure" */
export enum Unit_Of_Measure_Constraint {
  /** unique or primary key constraint */
  UnitOfMeasureCodeKey = 'unit_of_measure_code_key',
  /** unique or primary key constraint */
  UnitOfMeasurePkey = 'unit_of_measure_pkey'
}

/** columns and relationships of "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion = {
  __typename?: 'unit_of_measure_conversion';
  created_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  from: Unit_Of_Measure;
  from_um: Scalars['Int'];
  /** An object relationship */
  to: Unit_Of_Measure;
  to_um: Scalars['Int'];
  updated_at?: Maybe<Scalars['timestamptz']>;
  value: Scalars['numeric'];
};

/** aggregated selection of "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Aggregate = {
  __typename?: 'unit_of_measure_conversion_aggregate';
  aggregate?: Maybe<Unit_Of_Measure_Conversion_Aggregate_Fields>;
  nodes: Array<Unit_Of_Measure_Conversion>;
};

/** aggregate fields of "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Aggregate_Fields = {
  __typename?: 'unit_of_measure_conversion_aggregate_fields';
  avg?: Maybe<Unit_Of_Measure_Conversion_Avg_Fields>;
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Unit_Of_Measure_Conversion_Max_Fields>;
  min?: Maybe<Unit_Of_Measure_Conversion_Min_Fields>;
  stddev?: Maybe<Unit_Of_Measure_Conversion_Stddev_Fields>;
  stddev_pop?: Maybe<Unit_Of_Measure_Conversion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Unit_Of_Measure_Conversion_Stddev_Samp_Fields>;
  sum?: Maybe<Unit_Of_Measure_Conversion_Sum_Fields>;
  var_pop?: Maybe<Unit_Of_Measure_Conversion_Var_Pop_Fields>;
  var_samp?: Maybe<Unit_Of_Measure_Conversion_Var_Samp_Fields>;
  variance?: Maybe<Unit_Of_Measure_Conversion_Variance_Fields>;
};


/** aggregate fields of "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Unit_Of_Measure_Conversion_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Aggregate_Order_By = {
  avg?: InputMaybe<Unit_Of_Measure_Conversion_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Unit_Of_Measure_Conversion_Max_Order_By>;
  min?: InputMaybe<Unit_Of_Measure_Conversion_Min_Order_By>;
  stddev?: InputMaybe<Unit_Of_Measure_Conversion_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Unit_Of_Measure_Conversion_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Unit_Of_Measure_Conversion_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Unit_Of_Measure_Conversion_Sum_Order_By>;
  var_pop?: InputMaybe<Unit_Of_Measure_Conversion_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Unit_Of_Measure_Conversion_Var_Samp_Order_By>;
  variance?: InputMaybe<Unit_Of_Measure_Conversion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Arr_Rel_Insert_Input = {
  data: Array<Unit_Of_Measure_Conversion_Insert_Input>;
  on_conflict?: InputMaybe<Unit_Of_Measure_Conversion_On_Conflict>;
};

/** aggregate avg on columns */
export type Unit_Of_Measure_Conversion_Avg_Fields = {
  __typename?: 'unit_of_measure_conversion_avg_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Avg_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "unit_of_measure_conversion". All fields are combined with a logical 'AND'. */
export type Unit_Of_Measure_Conversion_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>>>;
  _not?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  from?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
  from_um?: InputMaybe<Int_Comparison_Exp>;
  to?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
  to_um?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  value?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "unit_of_measure_conversion" */
export enum Unit_Of_Measure_Conversion_Constraint {
  /** unique or primary key constraint */
  UnitOfMeasureConversionPkey = 'unit_of_measure_conversion_pkey'
}

/** input type for incrementing integer column in table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Inc_Input = {
  from_um?: InputMaybe<Scalars['Int']>;
  to_um?: InputMaybe<Scalars['Int']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from?: InputMaybe<Unit_Of_Measure_Obj_Rel_Insert_Input>;
  from_um?: InputMaybe<Scalars['Int']>;
  to?: InputMaybe<Unit_Of_Measure_Obj_Rel_Insert_Input>;
  to_um?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Unit_Of_Measure_Conversion_Max_Fields = {
  __typename?: 'unit_of_measure_conversion_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_um?: Maybe<Scalars['Int']>;
  to_um?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unit_Of_Measure_Conversion_Min_Fields = {
  __typename?: 'unit_of_measure_conversion_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  from_um?: Maybe<Scalars['Int']>;
  to_um?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Mutation_Response = {
  __typename?: 'unit_of_measure_conversion_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Unit_Of_Measure_Conversion>;
};

/** input type for inserting object relation for remote table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Obj_Rel_Insert_Input = {
  data: Unit_Of_Measure_Conversion_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_Conversion_On_Conflict>;
};

/** on conflict condition type for table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_On_Conflict = {
  constraint: Unit_Of_Measure_Conversion_Constraint;
  update_columns: Array<Unit_Of_Measure_Conversion_Update_Column>;
  where?: InputMaybe<Unit_Of_Measure_Conversion_Bool_Exp>;
};

/** ordering options when selecting data from "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Order_By = {
  created_at?: InputMaybe<Order_By>;
  from?: InputMaybe<Unit_Of_Measure_Order_By>;
  from_um?: InputMaybe<Order_By>;
  to?: InputMaybe<Unit_Of_Measure_Order_By>;
  to_um?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Pk_Columns_Input = {
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
};

/** select columns of table "unit_of_measure_conversion" */
export enum Unit_Of_Measure_Conversion_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUm = 'from_um',
  /** column name */
  ToUm = 'to_um',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** input type for updating data in table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']>;
  from_um?: InputMaybe<Scalars['Int']>;
  to_um?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
  value?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Unit_Of_Measure_Conversion_Stddev_Fields = {
  __typename?: 'unit_of_measure_conversion_stddev_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Stddev_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unit_Of_Measure_Conversion_Stddev_Pop_Fields = {
  __typename?: 'unit_of_measure_conversion_stddev_pop_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Stddev_Pop_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unit_Of_Measure_Conversion_Stddev_Samp_Fields = {
  __typename?: 'unit_of_measure_conversion_stddev_samp_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Stddev_Samp_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Unit_Of_Measure_Conversion_Sum_Fields = {
  __typename?: 'unit_of_measure_conversion_sum_fields';
  from_um?: Maybe<Scalars['Int']>;
  to_um?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Sum_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** update columns of table "unit_of_measure_conversion" */
export enum Unit_Of_Measure_Conversion_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FromUm = 'from_um',
  /** column name */
  ToUm = 'to_um',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Value = 'value'
}

/** aggregate var_pop on columns */
export type Unit_Of_Measure_Conversion_Var_Pop_Fields = {
  __typename?: 'unit_of_measure_conversion_var_pop_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Var_Pop_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unit_Of_Measure_Conversion_Var_Samp_Fields = {
  __typename?: 'unit_of_measure_conversion_var_samp_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Var_Samp_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unit_Of_Measure_Conversion_Variance_Fields = {
  __typename?: 'unit_of_measure_conversion_variance_fields';
  from_um?: Maybe<Scalars['Float']>;
  to_um?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unit_of_measure_conversion" */
export type Unit_Of_Measure_Conversion_Variance_Order_By = {
  from_um?: InputMaybe<Order_By>;
  to_um?: InputMaybe<Order_By>;
  value?: InputMaybe<Order_By>;
};

/** input type for incrementing integer column in table "unit_of_measure" */
export type Unit_Of_Measure_Inc_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "unit_of_measure" */
export type Unit_Of_Measure_Insert_Input = {
  category?: InputMaybe<Unit_Of_Measure_Category_Obj_Rel_Insert_Input>;
  category_id?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  conversions?: InputMaybe<Unit_Of_Measure_Conversion_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Unit_Of_Measure_Max_Fields = {
  __typename?: 'unit_of_measure_max_fields';
  category_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Max_Order_By = {
  category_id?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Unit_Of_Measure_Min_Fields = {
  __typename?: 'unit_of_measure_min_fields';
  category_id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Min_Order_By = {
  category_id?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "unit_of_measure" */
export type Unit_Of_Measure_Mutation_Response = {
  __typename?: 'unit_of_measure_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Unit_Of_Measure>;
};

/** input type for inserting object relation for remote table "unit_of_measure" */
export type Unit_Of_Measure_Obj_Rel_Insert_Input = {
  data: Unit_Of_Measure_Insert_Input;
  on_conflict?: InputMaybe<Unit_Of_Measure_On_Conflict>;
};

/** on conflict condition type for table "unit_of_measure" */
export type Unit_Of_Measure_On_Conflict = {
  constraint: Unit_Of_Measure_Constraint;
  update_columns: Array<Unit_Of_Measure_Update_Column>;
  where?: InputMaybe<Unit_Of_Measure_Bool_Exp>;
};

/** ordering options when selecting data from "unit_of_measure" */
export type Unit_Of_Measure_Order_By = {
  category?: InputMaybe<Unit_Of_Measure_Category_Order_By>;
  category_id?: InputMaybe<Order_By>;
  code?: InputMaybe<Order_By>;
  conversions_aggregate?: InputMaybe<Unit_Of_Measure_Conversion_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "unit_of_measure" */
export type Unit_Of_Measure_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "unit_of_measure" */
export enum Unit_Of_Measure_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "unit_of_measure" */
export type Unit_Of_Measure_Set_Input = {
  category_id?: InputMaybe<Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['Int']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Unit_Of_Measure_Stddev_Fields = {
  __typename?: 'unit_of_measure_stddev_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Unit_Of_Measure_Stddev_Pop_Fields = {
  __typename?: 'unit_of_measure_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Unit_Of_Measure_Stddev_Samp_Fields = {
  __typename?: 'unit_of_measure_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate sum on columns */
export type Unit_Of_Measure_Sum_Fields = {
  __typename?: 'unit_of_measure_sum_fields';
  category_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** update columns of table "unit_of_measure" */
export enum Unit_Of_Measure_Update_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate var_pop on columns */
export type Unit_Of_Measure_Var_Pop_Fields = {
  __typename?: 'unit_of_measure_var_pop_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Unit_Of_Measure_Var_Samp_Fields = {
  __typename?: 'unit_of_measure_var_samp_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Unit_Of_Measure_Variance_Fields = {
  __typename?: 'unit_of_measure_variance_fields';
  category_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "unit_of_measure" */
export type Unit_Of_Measure_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_permission" */
export type User_Permission = {
  __typename?: 'user_permission';
  can_delete?: Maybe<Scalars['Boolean']>;
  can_read?: Maybe<Scalars['Boolean']>;
  can_write?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  module: Modules;
  module_name: Modules_Enum;
  /** An object relationship */
  user?: Maybe<Users>;
  user_id: Scalars['uuid'];
};

/** aggregated selection of "user_permission" */
export type User_Permission_Aggregate = {
  __typename?: 'user_permission_aggregate';
  aggregate?: Maybe<User_Permission_Aggregate_Fields>;
  nodes: Array<User_Permission>;
};

/** aggregate fields of "user_permission" */
export type User_Permission_Aggregate_Fields = {
  __typename?: 'user_permission_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<User_Permission_Max_Fields>;
  min?: Maybe<User_Permission_Min_Fields>;
};


/** aggregate fields of "user_permission" */
export type User_Permission_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Permission_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "user_permission" */
export type User_Permission_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Permission_Max_Order_By>;
  min?: InputMaybe<User_Permission_Min_Order_By>;
};

/** input type for inserting array relation for remote table "user_permission" */
export type User_Permission_Arr_Rel_Insert_Input = {
  data: Array<User_Permission_Insert_Input>;
  on_conflict?: InputMaybe<User_Permission_On_Conflict>;
};

/** Boolean expression to filter rows from the table "user_permission". All fields are combined with a logical 'AND'. */
export type User_Permission_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<User_Permission_Bool_Exp>>>;
  _not?: InputMaybe<User_Permission_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<User_Permission_Bool_Exp>>>;
  can_delete?: InputMaybe<Boolean_Comparison_Exp>;
  can_read?: InputMaybe<Boolean_Comparison_Exp>;
  can_write?: InputMaybe<Boolean_Comparison_Exp>;
  module?: InputMaybe<Modules_Bool_Exp>;
  module_name?: InputMaybe<Modules_Enum_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_permission" */
export enum User_Permission_Constraint {
  /** unique or primary key constraint */
  UserPermissionPkey = 'user_permission_pkey'
}

/** input type for inserting data into table "user_permission" */
export type User_Permission_Insert_Input = {
  can_delete?: InputMaybe<Scalars['Boolean']>;
  can_read?: InputMaybe<Scalars['Boolean']>;
  can_write?: InputMaybe<Scalars['Boolean']>;
  module?: InputMaybe<Modules_Obj_Rel_Insert_Input>;
  module_name?: InputMaybe<Modules_Enum>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type User_Permission_Max_Fields = {
  __typename?: 'user_permission_max_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "user_permission" */
export type User_Permission_Max_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Permission_Min_Fields = {
  __typename?: 'user_permission_min_fields';
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "user_permission" */
export type User_Permission_Min_Order_By = {
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_permission" */
export type User_Permission_Mutation_Response = {
  __typename?: 'user_permission_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<User_Permission>;
};

/** input type for inserting object relation for remote table "user_permission" */
export type User_Permission_Obj_Rel_Insert_Input = {
  data: User_Permission_Insert_Input;
  on_conflict?: InputMaybe<User_Permission_On_Conflict>;
};

/** on conflict condition type for table "user_permission" */
export type User_Permission_On_Conflict = {
  constraint: User_Permission_Constraint;
  update_columns: Array<User_Permission_Update_Column>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};

/** ordering options when selecting data from "user_permission" */
export type User_Permission_Order_By = {
  can_delete?: InputMaybe<Order_By>;
  can_read?: InputMaybe<Order_By>;
  can_write?: InputMaybe<Order_By>;
  module?: InputMaybe<Modules_Order_By>;
  module_name?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "user_permission" */
export type User_Permission_Pk_Columns_Input = {
  module_name: Modules_Enum;
  user_id: Scalars['uuid'];
};

/** select columns of table "user_permission" */
export enum User_Permission_Select_Column {
  /** column name */
  CanDelete = 'can_delete',
  /** column name */
  CanRead = 'can_read',
  /** column name */
  CanWrite = 'can_write',
  /** column name */
  ModuleName = 'module_name',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_permission" */
export type User_Permission_Set_Input = {
  can_delete?: InputMaybe<Scalars['Boolean']>;
  can_read?: InputMaybe<Scalars['Boolean']>;
  can_write?: InputMaybe<Scalars['Boolean']>;
  module_name?: InputMaybe<Modules_Enum>;
  user_id?: InputMaybe<Scalars['uuid']>;
};

/** update columns of table "user_permission" */
export enum User_Permission_Update_Column {
  /** column name */
  CanDelete = 'can_delete',
  /** column name */
  CanRead = 'can_read',
  /** column name */
  CanWrite = 'can_write',
  /** column name */
  ModuleName = 'module_name',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  active?: Maybe<Scalars['Boolean']>;
  /** An object relationship */
  authorized_by?: Maybe<Users>;
  authorized_by_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  /** An array relationship */
  permissions: Array<User_Permission>;
  /** An aggregated array relationship */
  permissions_aggregate: User_Permission_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "users" */
export type UsersPermissionsArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Permission_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<User_Permission_Order_By>>;
  where?: InputMaybe<User_Permission_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  count?: Maybe<Scalars['Int']>;
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "users" */
export type Users_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Users_Max_Order_By>;
  min?: InputMaybe<Users_Min_Order_By>;
};

/** input type for inserting array relation for remote table "users" */
export type Users_Arr_Rel_Insert_Input = {
  data: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<InputMaybe<Users_Bool_Exp>>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<InputMaybe<Users_Bool_Exp>>>;
  account?: InputMaybe<Auth_Accounts_Bool_Exp>;
  active?: InputMaybe<Boolean_Comparison_Exp>;
  authorized_by?: InputMaybe<Users_Bool_Exp>;
  authorized_by_id?: InputMaybe<Uuid_Comparison_Exp>;
  avatar_url?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  permissions?: InputMaybe<User_Permission_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account?: InputMaybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  active?: InputMaybe<Scalars['Boolean']>;
  authorized_by?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  authorized_by_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  permissions?: InputMaybe<User_Permission_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  authorized_by_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "users" */
export type Users_Max_Order_By = {
  authorized_by_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  authorized_by_id?: Maybe<Scalars['uuid']>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "users" */
export type Users_Min_Order_By = {
  authorized_by_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of affected rows by the mutation */
  affected_rows: Scalars['Int'];
  /** data of the affected rows by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** ordering options when selecting data from "users" */
export type Users_Order_By = {
  account?: InputMaybe<Auth_Accounts_Order_By>;
  active?: InputMaybe<Order_By>;
  authorized_by?: InputMaybe<Users_Order_By>;
  authorized_by_id?: InputMaybe<Order_By>;
  avatar_url?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  display_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  permissions_aggregate?: InputMaybe<User_Permission_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: "users" */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorizedById = 'authorized_by_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  active?: InputMaybe<Scalars['Boolean']>;
  authorized_by_id?: InputMaybe<Scalars['uuid']>;
  avatar_url?: InputMaybe<Scalars['String']>;
  created_at?: InputMaybe<Scalars['timestamptz']>;
  display_name?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['uuid']>;
  updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  AuthorizedById = 'authorized_by_id',
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** expression to compare columns of type uuid. All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']>;
  _gt?: InputMaybe<Scalars['uuid']>;
  _gte?: InputMaybe<Scalars['uuid']>;
  _in?: InputMaybe<Array<Scalars['uuid']>>;
  _is_null?: InputMaybe<Scalars['Boolean']>;
  _lt?: InputMaybe<Scalars['uuid']>;
  _lte?: InputMaybe<Scalars['uuid']>;
  _neq?: InputMaybe<Scalars['uuid']>;
  _nin?: InputMaybe<Array<Scalars['uuid']>>;
};

export type ICategoriesFragment = { __typename?: 'product_category', id: number, name: string, description?: string | null };

export type CategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CategoriesQuery = { __typename?: 'query_root', product_category: Array<{ __typename?: 'product_category', id: number, name: string, description?: string | null }> };

export type InsertCategoryMutationVariables = Exact<{
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type InsertCategoryMutation = { __typename?: 'mutation_root', insert_product_category?: { __typename?: 'product_category_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'product_category', id: number, name: string, description?: string | null }> } | null };

export type UpdateCategoryMutationVariables = Exact<{
  id: Scalars['Int'];
  name?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdateCategoryMutation = { __typename?: 'mutation_root', update_product_category_by_pk?: { __typename?: 'product_category', id: number, name: string, description?: string | null } | null };

export type DeleteCategoryMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteCategoryMutation = { __typename?: 'mutation_root', delete_product_category?: { __typename?: 'product_category_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'product_category', id: number, name: string, description?: string | null }> } | null };

export type PurchaseViewQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchaseViewQuery = { __typename?: 'query_root', opb_purchases_view: Array<{ __typename?: 'opb_purchases_view', cost?: any | null, purchases?: any | null }> };

export type SalesViewQueryVariables = Exact<{ [key: string]: never; }>;


export type SalesViewQuery = { __typename?: 'query_root', opb_sales_view: Array<{ __typename?: 'opb_sales_view', revenue?: any | null, sales?: any | null }> };

export type ILocationsFragment = { __typename?: 'stock_location', id: number, name: string, code: string, location_type: Stock_Location_Type_Enum, external_id?: string | null };

export type LocationsQueryVariables = Exact<{ [key: string]: never; }>;


export type LocationsQuery = { __typename?: 'query_root', stock_location: Array<{ __typename?: 'stock_location', id: number, name: string, code: string, location_type: Stock_Location_Type_Enum, external_id?: string | null }> };

export type InsertLocationMutationVariables = Exact<{
  name: Scalars['String'];
  code: Scalars['String'];
  location_type: Stock_Location_Type_Enum;
  external_id?: InputMaybe<Scalars['String']>;
}>;


export type InsertLocationMutation = { __typename?: 'mutation_root', insert_stock_location?: { __typename?: 'stock_location_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_location', id: number, name: string, code: string, location_type: Stock_Location_Type_Enum, external_id?: string | null }> } | null };

export type UpdateLocationMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  code: Scalars['String'];
  location_type: Stock_Location_Type_Enum;
  external_id?: InputMaybe<Scalars['String']>;
}>;


export type UpdateLocationMutation = { __typename?: 'mutation_root', update_stock_location_by_pk?: { __typename?: 'stock_location', id: number, name: string, code: string, location_type: Stock_Location_Type_Enum, external_id?: string | null } | null };

export type DeleteLocationMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteLocationMutation = { __typename?: 'mutation_root', delete_stock_location?: { __typename?: 'stock_location_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_location', id: number, name: string, code: string, location_type: Stock_Location_Type_Enum, external_id?: string | null }> } | null };

export type IManufactoryOrderFragment = { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, manufacturing_order_items: Array<{ __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null };

export type IManufactoryItemFragment = { __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } };

export type ManufactoryQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By> | Stock_Order_Order_By>;
  like?: InputMaybe<Scalars['String']>;
}>;


export type ManufactoryQuery = { __typename?: 'query_root', stock_order: Array<{ __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, manufacturing_order_items: Array<{ __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null }>, stock_order_aggregate: { __typename?: 'stock_order_aggregate', aggregate?: { __typename?: 'stock_order_aggregate_fields', count?: number | null } | null } };

export type ManufactoryFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type ManufactoryFormDataQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }>, stock_operation: Array<{ __typename?: 'stock_operation', name: string, default_src: number, default_dest: number, id: number }> };

export type InsertManufactoryOrderMutationVariables = Exact<{
  data: Array<Stock_Manufacturing_Order_Item_Insert_Input> | Stock_Manufacturing_Order_Item_Insert_Input;
  note?: InputMaybe<Scalars['String']>;
}>;


export type InsertManufactoryOrderMutation = { __typename?: 'mutation_root', insert_stock_order_one?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, manufacturing_order_items: Array<{ __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null } | null };

export type InsertManufactoryItemMutationVariables = Exact<{
  order_id: Scalars['bigint'];
  product_id?: InputMaybe<Scalars['Int']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  src_location: Scalars['Int'];
  dest_location: Scalars['Int'];
}>;


export type InsertManufactoryItemMutation = { __typename?: 'mutation_root', insert_stock_manufacturing_order_item_one?: { __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type UpdateManufactoryItemMutationVariables = Exact<{
  id: Scalars['bigint'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  src_location: Scalars['Int'];
  dest_location: Scalars['Int'];
  material_items?: InputMaybe<Scalars['jsonb']>;
  lot_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_lot?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  alert_date?: InputMaybe<Scalars['date']>;
}>;


export type UpdateManufactoryItemMutation = { __typename?: 'mutation_root', update_stock_manufacturing_order_item_by_pk?: { __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null, update_stock_production_lot?: { __typename?: 'stock_production_lot_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> } | null };

export type DeleteManufactoryOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteManufactoryOrderMutation = { __typename?: 'mutation_root', delete_stock_order_by_pk?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, manufacturing_order_items: Array<{ __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null } | null };

export type DeleteManufactoryItemMutationVariables = Exact<{
  id: Scalars['bigint'];
  lot: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeleteManufactoryItemMutation = { __typename?: 'mutation_root', delete_stock_manufacturing_order_item_by_pk?: { __typename?: 'stock_manufacturing_order_item', id: any, status?: Stock_Deliver_Status_Enum | null, product_id: number, product_uom: number, quantity: any, lot_id?: number | null, src_location: number, dest_location: number, material_items?: any | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, destiny_location: { __typename?: 'stock_location', name: string }, source_location: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null, delete_stock_production_lot?: { __typename?: 'stock_production_lot_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_production_lot', id: number }> } | null };

export type IOperationFragment = { __typename?: 'stock_operation', code: string, id: number, name: string, operation_type: Stock_Operation_Type_Enum, order_type: Stock_Order_Type_Enum, return_operation?: number | null, default_src: number, default_dest: number, destination: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number }, source: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number } };

export type OperationsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type OperationsQuery = { __typename?: 'query_root', stock_operation: Array<{ __typename?: 'stock_operation', code: string, id: number, name: string, operation_type: Stock_Operation_Type_Enum, order_type: Stock_Order_Type_Enum, return_operation?: number | null, default_src: number, default_dest: number, destination: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number }, source: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number } }>, stock_operation_aggregate: { __typename?: 'stock_operation_aggregate', aggregate?: { __typename?: 'stock_operation_aggregate_fields', count?: number | null } | null }, stock_location: Array<{ __typename?: 'stock_location', id: number, name: string, code: string }> };

export type OperationsTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type OperationsTypeQuery = { __typename?: 'query_root', stock_operation_type: Array<{ __typename?: 'stock_operation_type', value: string }> };

export type OrderTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type OrderTypeQuery = { __typename?: 'query_root', stock_order_type: Array<{ __typename?: 'stock_order_type', code: string }> };

export type InsertOperationMutationVariables = Exact<{
  default_dest: Scalars['Int'];
  default_src: Scalars['Int'];
  operation_type: Stock_Operation_Type_Enum;
  order_type: Stock_Order_Type_Enum;
  return_operation?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type InsertOperationMutation = { __typename?: 'mutation_root', insert_stock_operation_one?: { __typename?: 'stock_operation', code: string, id: number, name: string, operation_type: Stock_Operation_Type_Enum, order_type: Stock_Order_Type_Enum, return_operation?: number | null, default_src: number, default_dest: number, destination: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number }, source: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number } } | null };

export type UpdateOperationMutationVariables = Exact<{
  id: Scalars['Int'];
  default_dest: Scalars['Int'];
  default_src: Scalars['Int'];
  operation_type: Stock_Operation_Type_Enum;
  order_type: Stock_Order_Type_Enum;
  return_operation?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
}>;


export type UpdateOperationMutation = { __typename?: 'mutation_root', update_stock_operation_by_pk?: { __typename?: 'stock_operation', code: string, id: number, name: string, operation_type: Stock_Operation_Type_Enum, order_type: Stock_Order_Type_Enum, return_operation?: number | null, default_src: number, default_dest: number, destination: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number }, source: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number } } | null };

export type DeleteOperationMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteOperationMutation = { __typename?: 'mutation_root', delete_stock_operation?: { __typename?: 'stock_operation_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_operation', code: string, id: number, name: string, operation_type: Stock_Operation_Type_Enum, order_type: Stock_Order_Type_Enum, return_operation?: number | null, default_src: number, default_dest: number, destination: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number }, source: { __typename?: 'stock_location', code: string, name: string, location_type: Stock_Location_Type_Enum, id: number } }> } | null };

export type IPartnerFragment = { __typename?: 'stock_partner', id: number, name: string, description?: string | null };

export type PartnerQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}>;


export type PartnerQuery = { __typename?: 'query_root', stock_partner: Array<{ __typename?: 'stock_partner', id: number, name: string, description?: string | null }> };

export type PartnersQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnersQuery = { __typename?: 'query_root', stock_partner: Array<{ __typename?: 'stock_partner', id: number, name: string, description?: string | null }> };

export type FindPartnerQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FindPartnerQuery = { __typename?: 'query_root', stock_partner_by_pk?: { __typename?: 'stock_partner', id: number, name: string, description?: string | null } | null };

export type InsertPartnerMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type InsertPartnerMutation = { __typename?: 'mutation_root', insert_stock_partner_one?: { __typename?: 'stock_partner', id: number, name: string, description?: string | null } | null };

export type UpdatePartnerMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
}>;


export type UpdatePartnerMutation = { __typename?: 'mutation_root', update_stock_partner_by_pk?: { __typename?: 'stock_partner', id: number, name: string, description?: string | null } | null };

export type DeletePartnersMutationVariables = Exact<{
  id: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeletePartnersMutation = { __typename?: 'mutation_root', delete_stock_partner?: { __typename?: 'stock_partner_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_partner', id: number, name: string, description?: string | null }> } | null };

export type IProductMaterialsFragment = { __typename?: 'product_bill_of_material_items', id: number, product_id: number, product_uom: number, quantity: any, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } };

export type ProductMaterialsQueryVariables = Exact<{
  _eq: Scalars['Int'];
}>;


export type ProductMaterialsQuery = { __typename?: 'query_root', product_bill_of_material_items: Array<{ __typename?: 'product_bill_of_material_items', id: number, product_id: number, product_uom: number, quantity: any, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> };

export type DeleteProductMaterialMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteProductMaterialMutation = { __typename?: 'mutation_root', delete_product_bill_of_material_items_by_pk?: { __typename?: 'product_bill_of_material_items', id: number, product_id: number, product_uom: number, quantity: any, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type InsertProductMaterialMutationVariables = Exact<{
  bill_of_material_id: Scalars['Int'];
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
}>;


export type InsertProductMaterialMutation = { __typename?: 'mutation_root', insert_product_bill_of_material_items_one?: { __typename?: 'product_bill_of_material_items', id: number, product_id: number, product_uom: number, quantity: any, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type UpdateProductMaterialMutationVariables = Exact<{
  id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
}>;


export type UpdateProductMaterialMutation = { __typename?: 'mutation_root', update_product_bill_of_material_items_by_pk?: { __typename?: 'product_bill_of_material_items', id: number, product_id: number, product_uom: number, quantity: any, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type IProductFragment = { __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } };

export type IProductSearchFragment = { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } };

export type SearchProductQueryVariables = Exact<{
  _ilike?: InputMaybe<Scalars['String']>;
}>;


export type SearchProductQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> };

export type ProductsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Product_Order_By> | Product_Order_By>;
  product_category?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  like?: InputMaybe<Scalars['String']>;
}>;


export type ProductsQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }>, product_aggregate: { __typename?: 'product_aggregate', aggregate?: { __typename?: 'product_aggregate_fields', count?: number | null } | null }, unit_of_measure: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string, category: { __typename?: 'unit_of_measure_category', id: number, value: string } }> };

export type FindProductQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type FindProductQuery = { __typename?: 'query_root', product_by_pk?: { __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } | null };

export type InsertProductMutationVariables = Exact<{
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  product_category?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  sku?: InputMaybe<Scalars['String']>;
  external_product_id?: InputMaybe<Scalars['String']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  allowed_min_quantity?: InputMaybe<Scalars['numeric']>;
  sellable: Scalars['Boolean'];
  expirable?: InputMaybe<Scalars['Boolean']>;
  purchasable: Scalars['Boolean'];
  storable: Scalars['Boolean'];
  trackerable: Scalars['Boolean'];
  expire_on?: InputMaybe<Scalars['Int']>;
  alert_of_expiration_on?: InputMaybe<Scalars['Int']>;
}>;


export type InsertProductMutation = { __typename?: 'mutation_root', insert_product?: { __typename?: 'product_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type UpdateProductMutationVariables = Exact<{
  id: Scalars['Int'];
  name: Scalars['String'];
  description?: InputMaybe<Scalars['String']>;
  product_category?: InputMaybe<Scalars['Int']>;
  cost?: InputMaybe<Scalars['numeric']>;
  price?: InputMaybe<Scalars['numeric']>;
  sku?: InputMaybe<Scalars['String']>;
  allowed_min_quantity?: InputMaybe<Scalars['numeric']>;
  external_product_id?: InputMaybe<Scalars['String']>;
  product_uom?: InputMaybe<Scalars['Int']>;
  sellable: Scalars['Boolean'];
  purchasable: Scalars['Boolean'];
  storable: Scalars['Boolean'];
  expirable?: InputMaybe<Scalars['Boolean']>;
  trackerable: Scalars['Boolean'];
  expire_on?: InputMaybe<Scalars['Int']>;
  alert_of_expiration_on?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateProductMutation = { __typename?: 'mutation_root', update_product_by_pk?: { __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } | null };

export type DeleteProductMutationVariables = Exact<{
  _in?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
}>;


export type DeleteProductMutation = { __typename?: 'mutation_root', delete_product?: { __typename?: 'product_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'product', id: number, name: string, description?: string | null, cost?: any | null, price?: any | null, sku?: string | null, external_product_id?: string | null, sellable: boolean, expirable: boolean, purchasable: boolean, allowed_min_quantity?: any | null, storable: boolean, trackerable: boolean, expire_on?: number | null, alert_of_expiration_on?: number | null, manufacturable: boolean, quantity_on_hand?: any | null, product_category?: number | null, product_uom: number, category?: { __typename?: 'product_category', id: number, name: string } | null, product_lot: Array<{ __typename?: 'opb_product_lot', code?: string | null, lot_id?: number | null }>, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type IPurchaseOrderFragment = { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, purchase_order_items: Array<{ __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> };

export type ILotFragment = { __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null };

export type IPurchaseItemFragment = { __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } };

export type PurchasesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By> | Stock_Order_Order_By>;
  like?: InputMaybe<Scalars['String']>;
}>;


export type PurchasesQuery = { __typename?: 'query_root', stock_order: Array<{ __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, purchase_order_items: Array<{ __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> }>, stock_order_aggregate: { __typename?: 'stock_order_aggregate', aggregate?: { __typename?: 'stock_order_aggregate_fields', count?: number | null } | null } };

export type PurchasesFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type PurchasesFormDataQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }>, stock_operation: Array<{ __typename?: 'stock_operation', id: number, name: string }>, stock_partner: Array<{ __typename?: 'stock_partner', id: number, name: string }> };

export type InsertPurchaseOrderMutationVariables = Exact<{
  data: Array<Stock_Purchase_Order_Item_Insert_Input> | Stock_Purchase_Order_Item_Insert_Input;
  note?: InputMaybe<Scalars['String']>;
}>;


export type InsertPurchaseOrderMutation = { __typename?: 'mutation_root', insert_stock_order_one?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, purchase_order_items: Array<{ __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> } | null };

export type InsertLotMutationVariables = Exact<{
  code: Scalars['String'];
  product_id: Scalars['Int'];
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  alert_date?: InputMaybe<Scalars['date']>;
}>;


export type InsertLotMutation = { __typename?: 'mutation_root', insert_stock_production_lot_one?: { __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null } | null };

export type DeleteLotMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteLotMutation = { __typename?: 'mutation_root', delete_stock_production_lot_by_pk?: { __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null } | null };

export type RunOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type RunOrderMutation = { __typename?: 'mutation_root', update_stock_order_by_pk?: { __typename?: 'stock_order', id: any, status: Stock_Order_Status_Enum, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null } | null };

export type RevertOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type RevertOrderMutation = { __typename?: 'mutation_root', update_stock_order_by_pk?: { __typename?: 'stock_order', id: any, status: Stock_Order_Status_Enum } | null };

export type InsertPurchaseItemMutationVariables = Exact<{
  order_id: Scalars['bigint'];
  operation_id: Scalars['Int'];
  lot_id?: InputMaybe<Scalars['Int']>;
  partner_provider?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['numeric']>;
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
}>;


export type InsertPurchaseItemMutation = { __typename?: 'mutation_root', insert_stock_purchase_order_item_one?: { __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type UpdatePurchaseItemsMutationVariables = Exact<{
  id: Scalars['bigint'];
  operation_id: Scalars['Int'];
  partner_provider?: InputMaybe<Scalars['Int']>;
  product_uom: Scalars['Int'];
  price: Scalars['numeric'];
  quantity: Scalars['numeric'];
  lot_id?: InputMaybe<Scalars['Int']>;
  product_id?: InputMaybe<Scalars['Int']>;
  product_lot?: InputMaybe<Array<Scalars['Int']> | Scalars['Int']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  expiration_date?: InputMaybe<Scalars['date']>;
  alert_date?: InputMaybe<Scalars['date']>;
}>;


export type UpdatePurchaseItemsMutation = { __typename?: 'mutation_root', update_stock_purchase_order_item_by_pk?: { __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null, update_stock_production_lot?: { __typename?: 'stock_production_lot_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> } | null };

export type DeletePurchaseOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeletePurchaseOrderMutation = { __typename?: 'mutation_root', delete_stock_order_by_pk?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, purchase_order_items: Array<{ __typename?: 'stock_purchase_order_item', id: any, lot_id?: number | null, operation_id: number, product_id: number, status: Stock_Deliver_Status_Enum, quantity: any, price: any, product_uom: number, partner_provider?: number | null, lot?: Array<{ __typename?: 'stock_production_lot', id: number, code: string, product_id: number, description?: string | null, expiration_date?: any | null, alert_date?: any | null }> | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> } | null };

export type DeletePurchaseOrderItemMutationVariables = Exact<{
  id: Scalars['bigint'];
  lot: Array<Scalars['Int']> | Scalars['Int'];
}>;


export type DeletePurchaseOrderItemMutation = { __typename?: 'mutation_root', delete_stock_purchase_order_item_by_pk?: { __typename?: 'stock_purchase_order_item', id: any } | null, delete_stock_production_lot?: { __typename?: 'stock_production_lot_mutation_response', affected_rows: number, returning: Array<{ __typename?: 'stock_production_lot', id: number }> } | null };

export type UpdateOrderNoteMutationVariables = Exact<{
  id: Scalars['bigint'];
  note?: InputMaybe<Scalars['String']>;
}>;


export type UpdateOrderNoteMutation = { __typename?: 'mutation_root', update_stock_order_by_pk?: { __typename?: 'stock_order', id: any, note?: string | null, status: Stock_Order_Status_Enum } | null };

export type ExistenceReportLotQueryVariables = Exact<{ [key: string]: never; }>;


export type ExistenceReportLotQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_product_existence', location_name?: string | null, lot_code?: string | null, product_id?: number | null, product_name?: string | null, quantity_on_hand?: any | null, unit_of_measure?: string | null, unit_price?: any | null, expiration_date?: any | null, product_code?: string | null, product?: { __typename?: 'product', id: number, manufacturable: boolean, purchasable: boolean, sellable: boolean, storable: boolean } | null }> };

export type ExistenceReportProductQueryVariables = Exact<{ [key: string]: never; }>;


export type ExistenceReportProductQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_existence', location_name?: string | null, product_id?: number | null, product_name?: string | null, quantity_on_hand?: any | null, product_code?: string | null, unit_of_measure?: string | null, unit_price?: any | null, product?: { __typename?: 'product', id: number, purchasable: boolean, manufacturable: boolean, sellable: boolean, storable: boolean } | null }> };

export type ProductsNearToExpireQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsNearToExpireQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_product_near_to_expire', product_name?: string | null, quantity_on_hand?: any | null, lot_code?: string | null, expiration_date?: any | null, days_to_expire?: number | null, product?: { __typename?: 'product', id: number, purchasable: boolean, manufacturable: boolean, sellable: boolean, storable: boolean, unit_of_measure: { __typename?: 'unit_of_measure', code: string } } | null }> };

export type ProductsRunningOutQueryVariables = Exact<{ [key: string]: never; }>;


export type ProductsRunningOutQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_product_min_quantity_alert', product_id?: number | null, product_name?: string | null, quantity?: any | null, unit_of_measure?: string | null, product?: { __typename?: 'product', id: number, purchasable: boolean, manufacturable: boolean, sellable: boolean, storable: boolean } | null }> };

export type ProfitQueryVariables = Exact<{
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
}>;


export type ProfitQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_profit_report_template', product_id?: number | null, product_name?: string | null, quantity?: any | null, purchase_price?: any | null, sales_price?: any | null, profit?: any | null, margin?: any | null, product?: { __typename?: 'product', id: number, purchasable: boolean, manufacturable: boolean, sellable: boolean, storable: boolean, unit_of_measure: { __typename?: 'unit_of_measure', code: string } } | null }>, dataAgregate: { __typename?: 'opb_profit_report_template_aggregate', aggregate?: { __typename?: 'opb_profit_report_template_aggregate_fields', sum?: { __typename?: 'opb_profit_report_template_sum_fields', profit?: any | null } | null } | null } };

export type IpvQueryVariables = Exact<{
  start_date?: InputMaybe<Scalars['date']>;
  end_date?: InputMaybe<Scalars['date']>;
}>;


export type IpvQuery = { __typename?: 'query_root', data: Array<{ __typename?: 'opb_ipv_report', product_name?: string | null, unit_of_measure?: string | null, location_name?: string | null, start_quantity?: any | null, start_value?: any | null, in_quantity?: any | null, out_quantity?: any | null, end_quantity?: any | null, end_value?: any | null, date?: any | null, product?: { __typename?: 'product', id: number, purchasable: boolean, manufacturable: boolean, sellable: boolean, storable: boolean } | null }> };

export type RecalculateIpvQueryVariables = Exact<{ [key: string]: never; }>;


export type RecalculateIpvQuery = { __typename?: 'query_root', opb_execute_daily_report: Array<{ __typename?: 'daily_existence_report', id: number }> };

export type ISalesOrderFragment = { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, sales_order_items: Array<{ __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> };

export type ExecutionErrorsFragment = { __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null };

export type ISalesItemFragment = { __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } };

export type SalesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By> | Stock_Order_Order_By>;
  like?: InputMaybe<Scalars['String']>;
}>;


export type SalesQuery = { __typename?: 'query_root', stock_order: Array<{ __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, sales_order_items: Array<{ __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> }>, stock_order_aggregate: { __typename?: 'stock_order_aggregate', aggregate?: { __typename?: 'stock_order_aggregate_fields', count?: number | null } | null } };

export type SalesFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type SalesFormDataQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }>, stock_operation: Array<{ __typename?: 'stock_operation', id: number, name: string }>, stock_partner: Array<{ __typename?: 'stock_partner', id: number, name: string }> };

export type InsertSalesOrderMutationVariables = Exact<{
  data: Array<Stock_Sales_Order_Item_Insert_Input> | Stock_Sales_Order_Item_Insert_Input;
  note?: InputMaybe<Scalars['String']>;
}>;


export type InsertSalesOrderMutation = { __typename?: 'mutation_root', insert_stock_order_one?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, sales_order_items: Array<{ __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> } | null };

export type InsertSaleItemMutationVariables = Exact<{
  operation_id: Scalars['Int'];
  product_id?: InputMaybe<Scalars['Int']>;
  product_uom: Scalars['Int'];
  src_location?: InputMaybe<Scalars['Int']>;
  quantity: Scalars['numeric'];
  price?: InputMaybe<Scalars['numeric']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  order_id: Scalars['bigint'];
  partner_customer?: InputMaybe<Scalars['Int']>;
}>;


export type InsertSaleItemMutation = { __typename?: 'mutation_root', insert_stock_sales_order_item_one?: { __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type UpdateSaleItemMutationVariables = Exact<{
  id: Scalars['bigint'];
  operation_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  price: Scalars['numeric'];
  quantity: Scalars['numeric'];
  src_location?: InputMaybe<Scalars['Int']>;
  dest_location?: InputMaybe<Scalars['Int']>;
  partner_customer?: InputMaybe<Scalars['Int']>;
}>;


export type UpdateSaleItemMutation = { __typename?: 'mutation_root', update_stock_sales_order_item_by_pk?: { __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type DeleteSalesOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteSalesOrderMutation = { __typename?: 'mutation_root', delete_stock_order_by_pk?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, sales_order_items: Array<{ __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }> } | null };

export type DeleteSalesOrderItemMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteSalesOrderItemMutation = { __typename?: 'mutation_root', delete_stock_sales_order_item_by_pk?: { __typename?: 'stock_sales_order_item', id: any, price: any, status: Stock_Deliver_Status_Enum, quantity: any, product_id: number, product_uom: number, operation_id: number, src_location: number, dest_location: number, missing_amount?: any | null, partner_customer?: number | null, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type ImportSalesOrdersMutationVariables = Exact<{
  imported_order: Scalars['jsonb'];
}>;


export type ImportSalesOrdersMutation = { __typename?: 'mutation_root', insert_import_sales_order_one?: { __typename?: 'import_sales_order', id: number } | null };

export type ITransferOrderFragment = { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, transfer_order_items: Array<{ __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null };

export type ITransferItemFragment = { __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } };

export type TransfersQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  order_by?: InputMaybe<Array<Stock_Order_Order_By> | Stock_Order_Order_By>;
  like?: InputMaybe<Scalars['String']>;
}>;


export type TransfersQuery = { __typename?: 'query_root', stock_order: Array<{ __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, transfer_order_items: Array<{ __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null }>, stock_order_aggregate: { __typename?: 'stock_order_aggregate', aggregate?: { __typename?: 'stock_order_aggregate_fields', count?: number | null } | null } };

export type TransferFormDataQueryVariables = Exact<{ [key: string]: never; }>;


export type TransferFormDataQuery = { __typename?: 'query_root', product: Array<{ __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }>, stock_location: Array<{ __typename?: 'stock_location', id: number, name: string }> };

export type InsertTransferOrderMutationVariables = Exact<{
  data: Array<Stock_Transfer_Order_Item_Insert_Input> | Stock_Transfer_Order_Item_Insert_Input;
  note?: InputMaybe<Scalars['String']>;
}>;


export type InsertTransferOrderMutation = { __typename?: 'mutation_root', insert_stock_order_one?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, transfer_order_items: Array<{ __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null } | null };

export type InsertTransferItemMutationVariables = Exact<{
  order_id: Scalars['bigint'];
  product_id: Scalars['Int'];
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
  src_location: Scalars['Int'];
  dest_location: Scalars['Int'];
  lot_id?: InputMaybe<Scalars['Int']>;
}>;


export type InsertTransferItemMutation = { __typename?: 'mutation_root', insert_stock_transfer_order_item_one?: { __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type UpdateTranferItemMutationVariables = Exact<{
  id: Scalars['bigint'];
  src_location: Scalars['Int'];
  dest_location: Scalars['Int'];
  lot_id?: InputMaybe<Scalars['Int']>;
  product_uom: Scalars['Int'];
  quantity: Scalars['numeric'];
}>;


export type UpdateTranferItemMutation = { __typename?: 'mutation_root', update_stock_transfer_order_item_by_pk?: { __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type DeleteTransferOrderMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteTransferOrderMutation = { __typename?: 'mutation_root', delete_stock_order_by_pk?: { __typename?: 'stock_order', id: any, code: string, note?: string | null, order_date?: any | null, status: Stock_Order_Status_Enum, type: Stock_Order_Type_Enum, transfer_order_items: Array<{ __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } }>, execution_errors?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null, execution_errors_by_reference?: Array<{ __typename?: 'stock_order_execution_errors', id: number, message: string, errors?: string | null, order_id: any, order_item_id: any, reference_order_id?: any | null, reference_order_item_id?: any | null }> | null } | null };

export type DeleteTransferItemMutationVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type DeleteTransferItemMutation = { __typename?: 'mutation_root', delete_stock_transfer_order_item_by_pk?: { __typename?: 'stock_transfer_order_item', id: any, lot_id?: number | null, product_id: number, product_uom: number, status: Stock_Deliver_Status_Enum, quantity: any, src_location: number, dest_location: number, src: { __typename?: 'stock_location', name: string }, dest: { __typename?: 'stock_location', name: string }, product: { __typename?: 'product', id: number, name: string, trackerable: boolean, cost?: any | null, price?: any | null, expire_on?: number | null, alert_of_expiration_on?: number | null, product_lot: Array<{ __typename?: 'opb_product_lot', product_id?: number | null, location_id?: number | null, location_name?: string | null, lot_id?: number | null, lot_code?: string | null, quantity?: any | null }>, related_uom?: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string }> | null, unit_of_measure: { __typename?: 'unit_of_measure', id: number, code: string, description: string } } } | null };

export type IUnitFragment = { __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> };

export type IuConversionFragment = { __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } };

export type IuomCategoryFragment = { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null };

export type UnitsQueryVariables = Exact<{ [key: string]: never; }>;


export type UnitsQuery = { __typename?: 'query_root', unit_of_measure: Array<{ __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> }>, unit_of_measure_category: Array<{ __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }> };

export type UnitQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type UnitQuery = { __typename?: 'query_root', unit_of_measure_by_pk?: { __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type InsertUnitMutationVariables = Exact<{
  code: Scalars['String'];
  description: Scalars['String'];
  category_id: Scalars['Int'];
}>;


export type InsertUnitMutation = { __typename?: 'mutation_root', insert_unit_of_measure_one?: { __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type InsertConversionMutationVariables = Exact<{
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
  value: Scalars['numeric'];
}>;


export type InsertConversionMutation = { __typename?: 'mutation_root', insert_unit_of_measure_conversion_one?: { __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any } | null };

export type UpdateUnitMutationVariables = Exact<{
  id: Scalars['Int'];
  code: Scalars['String'];
  description: Scalars['String'];
  category_id: Scalars['Int'];
}>;


export type UpdateUnitMutation = { __typename?: 'mutation_root', update_unit_of_measure_by_pk?: { __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type DeleteUnitMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type DeleteUnitMutation = { __typename?: 'mutation_root', delete_unit_of_measure_by_pk?: { __typename?: 'unit_of_measure', id: number, code: string, description: string, category_id: number, category: { __typename?: 'unit_of_measure_category', id: number, value: string, description?: string | null }, conversions: Array<{ __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any, from: { __typename?: 'unit_of_measure', id: number, code: string, description: string }, to: { __typename?: 'unit_of_measure', id: number, code: string, description: string } }> } | null };

export type DeleteConversionMutationVariables = Exact<{
  from_um: Scalars['Int'];
  to_um: Scalars['Int'];
}>;


export type DeleteConversionMutation = { __typename?: 'mutation_root', delete_unit_of_measure_conversion_by_pk?: { __typename?: 'unit_of_measure_conversion', from_um: number, to_um: number, value: any } | null };

export type AccountFragment = { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } };

export type PermissionsFragment = { __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null };

export type UserFragment = { __typename?: 'users', id: any, active?: boolean | null, display_name?: string | null, avatar_url?: string | null, authorized_by?: { __typename?: 'users', account?: { __typename?: 'auth_accounts', email?: any | null } | null } | null, account?: { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } } | null, permissions: Array<{ __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null }> };

export type UsersQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type UsersQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, active?: boolean | null, display_name?: string | null, avatar_url?: string | null, authorized_by?: { __typename?: 'users', account?: { __typename?: 'auth_accounts', email?: any | null } | null } | null, account?: { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } } | null, permissions: Array<{ __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null }> }> };

export type FindUserByEmailQueryVariables = Exact<{
  email?: InputMaybe<Scalars['citext']>;
}>;


export type FindUserByEmailQuery = { __typename?: 'query_root', users: Array<{ __typename?: 'users', id: any, active?: boolean | null, display_name?: string | null, avatar_url?: string | null, authorized_by?: { __typename?: 'users', account?: { __typename?: 'auth_accounts', email?: any | null } | null } | null, account?: { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } } | null, permissions: Array<{ __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null }> }> };

export type SetActiveStatusMutationVariables = Exact<{
  id: Scalars['uuid'];
  active: Scalars['Boolean'];
}>;


export type SetActiveStatusMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: any, active?: boolean | null, display_name?: string | null, avatar_url?: string | null, authorized_by?: { __typename?: 'users', account?: { __typename?: 'auth_accounts', email?: any | null } | null } | null, account?: { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } } | null, permissions: Array<{ __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null }> } | null };

export type ChangeUserNameMutationVariables = Exact<{
  id: Scalars['uuid'];
  display_name: Scalars['String'];
}>;


export type ChangeUserNameMutation = { __typename?: 'mutation_root', update_users_by_pk?: { __typename?: 'users', id: any, active?: boolean | null, display_name?: string | null, avatar_url?: string | null, authorized_by?: { __typename?: 'users', account?: { __typename?: 'auth_accounts', email?: any | null } | null } | null, account?: { __typename?: 'auth_accounts', id: any, email?: any | null, default_role: string, active: boolean, user_id: any, role: { __typename?: 'auth_roles', role: string } } | null, permissions: Array<{ __typename?: 'user_permission', user_id: any, module_name: Modules_Enum, can_delete?: boolean | null, can_read?: boolean | null, can_write?: boolean | null }> } | null };

export type EditPermissionsMutationVariables = Exact<{
  objects: Array<User_Permission_Insert_Input> | User_Permission_Insert_Input;
}>;


export type EditPermissionsMutation = { __typename?: 'mutation_root', insert_user_permission?: { __typename?: 'user_permission_mutation_response', affected_rows: number } | null };

export const ICategoriesFragmentDoc = gql`
    fragment ICategories on product_category {
  id
  name
  description
}
    `;
export const ILocationsFragmentDoc = gql`
    fragment ILocations on stock_location {
  id
  name
  code
  location_type
  external_id
}
    `;
export const ILotFragmentDoc = gql`
    fragment ILot on stock_production_lot {
  id
  code
  product_id
  description
  expiration_date
  alert_date
}
    `;
export const IProductSearchFragmentDoc = gql`
    fragment IProductSearch on product {
  id
  name
  trackerable
  related_uom {
    id
    code
    description
  }
  unit_of_measure {
    id
    code
    description
  }
  cost
  price
  expire_on
  alert_of_expiration_on
}
    `;
export const IManufactoryItemFragmentDoc = gql`
    fragment IManufactoryItem on stock_manufacturing_order_item {
  id
  status
  product_id
  product_uom
  quantity
  lot_id
  lot {
    ...ILot
  }
  src_location
  dest_location
  material_items
  destiny_location {
    name
  }
  source_location {
    name
  }
  product {
    ...IProductSearch
  }
}
    ${ILotFragmentDoc}
${IProductSearchFragmentDoc}`;
export const ExecutionErrorsFragmentDoc = gql`
    fragment ExecutionErrors on stock_order_execution_errors {
  id
  message
  errors
  order_id
  order_item_id
  reference_order_id
  reference_order_item_id
}
    `;
export const IManufactoryOrderFragmentDoc = gql`
    fragment IManufactoryOrder on stock_order {
  id
  code
  note
  order_date
  status
  type
  manufacturing_order_items {
    ...IManufactoryItem
  }
  execution_errors {
    ...ExecutionErrors
  }
  execution_errors_by_reference {
    ...ExecutionErrors
  }
}
    ${IManufactoryItemFragmentDoc}
${ExecutionErrorsFragmentDoc}`;
export const IOperationFragmentDoc = gql`
    fragment IOperation on stock_operation {
  code
  id
  name
  operation_type
  order_type
  return_operation
  default_src
  default_dest
  destination {
    code
    name
    location_type
    id
  }
  source {
    code
    name
    location_type
    id
  }
}
    `;
export const IPartnerFragmentDoc = gql`
    fragment IPartner on stock_partner {
  id
  name
  description
}
    `;
export const IProductMaterialsFragmentDoc = gql`
    fragment IProductMaterials on product_bill_of_material_items {
  id
  product_id
  product_uom
  quantity
  product {
    ...IProductSearch
  }
}
    ${IProductSearchFragmentDoc}`;
export const IProductFragmentDoc = gql`
    fragment IProduct on product {
  id
  name
  description
  cost
  price
  sku
  external_product_id
  sellable
  expirable
  purchasable
  allowed_min_quantity
  storable
  trackerable
  expire_on
  alert_of_expiration_on
  manufacturable
  quantity_on_hand
  product_category
  category {
    id
    name
  }
  product_lot {
    code
    lot_id
  }
  product_uom
  unit_of_measure {
    id
    code
    description
  }
}
    `;
export const IPurchaseItemFragmentDoc = gql`
    fragment IPurchaseItem on stock_purchase_order_item {
  id
  lot_id
  lot {
    ...ILot
  }
  operation_id
  product_id
  status
  quantity
  price
  product_uom
  partner_provider
  product {
    ...IProductSearch
  }
}
    ${ILotFragmentDoc}
${IProductSearchFragmentDoc}`;
export const IPurchaseOrderFragmentDoc = gql`
    fragment IPurchaseOrder on stock_order {
  id
  code
  note
  order_date
  status
  type
  purchase_order_items {
    ...IPurchaseItem
  }
}
    ${IPurchaseItemFragmentDoc}`;
export const ISalesItemFragmentDoc = gql`
    fragment ISalesItem on stock_sales_order_item {
  id
  price
  status
  quantity
  product_id
  product_uom
  operation_id
  src_location
  dest_location
  missing_amount
  partner_customer
  product {
    ...IProductSearch
  }
}
    ${IProductSearchFragmentDoc}`;
export const ISalesOrderFragmentDoc = gql`
    fragment ISalesOrder on stock_order {
  id
  code
  note
  order_date
  status
  type
  execution_errors {
    ...ExecutionErrors
  }
  execution_errors_by_reference {
    ...ExecutionErrors
  }
  sales_order_items {
    ...ISalesItem
  }
}
    ${ExecutionErrorsFragmentDoc}
${ISalesItemFragmentDoc}`;
export const ITransferItemFragmentDoc = gql`
    fragment ITransferItem on stock_transfer_order_item {
  id
  lot_id
  product_id
  product_uom
  status
  quantity
  src_location
  src {
    name
  }
  dest_location
  dest {
    name
  }
  product {
    ...IProductSearch
    product_lot {
      product_id
      location_id
      location_name
      lot_id
      lot_code
      quantity
    }
  }
}
    ${IProductSearchFragmentDoc}`;
export const ITransferOrderFragmentDoc = gql`
    fragment ITransferOrder on stock_order {
  id
  code
  note
  order_date
  status
  type
  transfer_order_items {
    ...ITransferItem
  }
  execution_errors {
    ...ExecutionErrors
  }
  execution_errors_by_reference {
    ...ExecutionErrors
  }
}
    ${ITransferItemFragmentDoc}
${ExecutionErrorsFragmentDoc}`;
export const IuomCategoryFragmentDoc = gql`
    fragment IUOMCategory on unit_of_measure_category {
  id
  value
  description
}
    `;
export const IuConversionFragmentDoc = gql`
    fragment IUConversion on unit_of_measure_conversion {
  from_um
  from {
    id
    code
    description
  }
  to_um
  to {
    id
    code
    description
  }
  value
}
    `;
export const IUnitFragmentDoc = gql`
    fragment IUnit on unit_of_measure {
  id
  code
  description
  category_id
  category {
    ...IUOMCategory
  }
  conversions {
    ...IUConversion
  }
}
    ${IuomCategoryFragmentDoc}
${IuConversionFragmentDoc}`;
export const AccountFragmentDoc = gql`
    fragment Account on auth_accounts {
  id
  email
  default_role
  active
  user_id
  role {
    role
  }
}
    `;
export const PermissionsFragmentDoc = gql`
    fragment Permissions on user_permission {
  user_id
  module_name
  can_delete
  can_read
  can_write
}
    `;
export const UserFragmentDoc = gql`
    fragment User on users {
  id
  active
  authorized_by {
    account {
      email
    }
  }
  account {
    ...Account
  }
  permissions {
    ...Permissions
  }
  display_name
  avatar_url
}
    ${AccountFragmentDoc}
${PermissionsFragmentDoc}`;
export const CategoriesDocument = gql`
    query Categories {
  product_category {
    ...ICategories
  }
}
    ${ICategoriesFragmentDoc}`;

/**
 * __useCategoriesQuery__
 *
 * To run a query within a Vue component, call `useCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useCategoriesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useCategoriesQuery();
 */
export function useCategoriesQuery(options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, {}, options);
}
export function useCategoriesLazyQuery(options: VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<CategoriesQuery, CategoriesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<CategoriesQuery, CategoriesQueryVariables>(CategoriesDocument, {}, options);
}
export type CategoriesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<CategoriesQuery, CategoriesQueryVariables>;
export const InsertCategoryDocument = gql`
    mutation InsertCategory($name: String, $description: String) {
  insert_product_category(objects: {name: $name, description: $description}) {
    affected_rows
    returning {
      ...ICategories
    }
  }
}
    ${ICategoriesFragmentDoc}`;

/**
 * __useInsertCategoryMutation__
 *
 * To run a mutation, you first call `useInsertCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertCategoryMutation({
 *   variables: {
 *     name: // value for 'name'
 *     description: // value for 'description'
 *   },
 * });
 */
export function useInsertCategoryMutation(options: VueApolloComposable.UseMutationOptions<InsertCategoryMutation, InsertCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertCategoryMutation, InsertCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<InsertCategoryMutation, InsertCategoryMutationVariables>(InsertCategoryDocument, options);
}
export type InsertCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertCategoryMutation, InsertCategoryMutationVariables>;
export const UpdateCategoryDocument = gql`
    mutation UpdateCategory($id: Int!, $name: String, $description: String) {
  update_product_category_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    ...ICategories
  }
}
    ${ICategoriesFragmentDoc}`;

/**
 * __useUpdateCategoryMutation__
 *
 * To run a mutation, you first call `useUpdateCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateCategoryMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     description: // value for 'description'
 *   },
 * });
 */
export function useUpdateCategoryMutation(options: VueApolloComposable.UseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateCategoryMutation, UpdateCategoryMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateCategoryMutation, UpdateCategoryMutationVariables>(UpdateCategoryDocument, options);
}
export type UpdateCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateCategoryMutation, UpdateCategoryMutationVariables>;
export const DeleteCategoryDocument = gql`
    mutation DeleteCategory($_in: [Int!]) {
  delete_product_category(where: {id: {_in: $_in}}) {
    affected_rows
    returning {
      ...ICategories
    }
  }
}
    ${ICategoriesFragmentDoc}`;

/**
 * __useDeleteCategoryMutation__
 *
 * To run a mutation, you first call `useDeleteCategoryMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCategoryMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteCategoryMutation({
 *   variables: {
 *     _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteCategoryMutation(options: VueApolloComposable.UseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteCategoryMutation, DeleteCategoryMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteCategoryMutation, DeleteCategoryMutationVariables>(DeleteCategoryDocument, options);
}
export type DeleteCategoryMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteCategoryMutation, DeleteCategoryMutationVariables>;
export const PurchaseViewDocument = gql`
    query PurchaseView {
  opb_purchases_view {
    cost
    purchases
  }
}
    `;

/**
 * __usePurchaseViewQuery__
 *
 * To run a query within a Vue component, call `usePurchaseViewQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchaseViewQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchaseViewQuery();
 */
export function usePurchaseViewQuery(options: VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchaseViewQuery, PurchaseViewQueryVariables>(PurchaseViewDocument, {}, options);
}
export function usePurchaseViewLazyQuery(options: VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchaseViewQuery, PurchaseViewQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PurchaseViewQuery, PurchaseViewQueryVariables>(PurchaseViewDocument, {}, options);
}
export type PurchaseViewQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchaseViewQuery, PurchaseViewQueryVariables>;
export const SalesViewDocument = gql`
    query SalesView {
  opb_sales_view {
    revenue
    sales
  }
}
    `;

/**
 * __useSalesViewQuery__
 *
 * To run a query within a Vue component, call `useSalesViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesViewQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesViewQuery();
 */
export function useSalesViewQuery(options: VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesViewQuery, SalesViewQueryVariables>(SalesViewDocument, {}, options);
}
export function useSalesViewLazyQuery(options: VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesViewQuery, SalesViewQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SalesViewQuery, SalesViewQueryVariables>(SalesViewDocument, {}, options);
}
export type SalesViewQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesViewQuery, SalesViewQueryVariables>;
export const LocationsDocument = gql`
    query Locations {
  stock_location {
    ...ILocations
  }
}
    ${ILocationsFragmentDoc}`;

/**
 * __useLocationsQuery__
 *
 * To run a query within a Vue component, call `useLocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useLocationsQuery();
 */
export function useLocationsQuery(options: VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, {}, options);
}
export function useLocationsLazyQuery(options: VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<LocationsQuery, LocationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<LocationsQuery, LocationsQueryVariables>(LocationsDocument, {}, options);
}
export type LocationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<LocationsQuery, LocationsQueryVariables>;
export const InsertLocationDocument = gql`
    mutation InsertLocation($name: String!, $code: String!, $location_type: stock_location_type_enum!, $external_id: String) {
  insert_stock_location(
    objects: {name: $name, code: $code, location_type: $location_type, external_id: $external_id}
  ) {
    affected_rows
    returning {
      ...ILocations
    }
  }
}
    ${ILocationsFragmentDoc}`;

/**
 * __useInsertLocationMutation__
 *
 * To run a mutation, you first call `useInsertLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertLocationMutation({
 *   variables: {
 *     name: // value for 'name'
 *     code: // value for 'code'
 *     location_type: // value for 'location_type'
 *     external_id: // value for 'external_id'
 *   },
 * });
 */
export function useInsertLocationMutation(options: VueApolloComposable.UseMutationOptions<InsertLocationMutation, InsertLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertLocationMutation, InsertLocationMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertLocationMutation, InsertLocationMutationVariables>(InsertLocationDocument, options);
}
export type InsertLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertLocationMutation, InsertLocationMutationVariables>;
export const UpdateLocationDocument = gql`
    mutation UpdateLocation($id: Int!, $name: String!, $code: String!, $location_type: stock_location_type_enum!, $external_id: String) {
  update_stock_location_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, code: $code, location_type: $location_type, external_id: $external_id}
  ) {
    ...ILocations
  }
}
    ${ILocationsFragmentDoc}`;

/**
 * __useUpdateLocationMutation__
 *
 * To run a mutation, you first call `useUpdateLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateLocationMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     code: // value for 'code'
 *     location_type: // value for 'location_type'
 *     external_id: // value for 'external_id'
 *   },
 * });
 */
export function useUpdateLocationMutation(options: VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateLocationMutation, UpdateLocationMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateLocationMutation, UpdateLocationMutationVariables>(UpdateLocationDocument, options);
}
export type UpdateLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateLocationMutation, UpdateLocationMutationVariables>;
export const DeleteLocationDocument = gql`
    mutation DeleteLocation($_in: [Int!]) {
  delete_stock_location(where: {id: {_in: $_in}}) {
    affected_rows
    returning {
      ...ILocations
    }
  }
}
    ${ILocationsFragmentDoc}`;

/**
 * __useDeleteLocationMutation__
 *
 * To run a mutation, you first call `useDeleteLocationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLocationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteLocationMutation({
 *   variables: {
 *     _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteLocationMutation(options: VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteLocationMutation, DeleteLocationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteLocationMutation, DeleteLocationMutationVariables>(DeleteLocationDocument, options);
}
export type DeleteLocationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteLocationMutation, DeleteLocationMutationVariables>;
export const ManufactoryDocument = gql`
    query Manufactory($limit: Int, $offset: Int, $order_by: [stock_order_order_by!] = {order_date: desc}, $like: String) {
  stock_order(
    where: {type: {_eq: MFA}, _or: [{manufacturing_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...IManufactoryOrder
  }
  stock_order_aggregate(
    where: {type: {_eq: MFA}, _or: [{manufacturing_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${IManufactoryOrderFragmentDoc}`;

/**
 * __useManufactoryQuery__
 *
 * To run a query within a Vue component, call `useManufactoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufactoryQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useManufactoryQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   like: // value for 'like'
 * });
 */
export function useManufactoryQuery(variables: ManufactoryQueryVariables | VueCompositionApi.Ref<ManufactoryQueryVariables> | ReactiveFunction<ManufactoryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ManufactoryQuery, ManufactoryQueryVariables>(ManufactoryDocument, variables, options);
}
export function useManufactoryLazyQuery(variables: ManufactoryQueryVariables | VueCompositionApi.Ref<ManufactoryQueryVariables> | ReactiveFunction<ManufactoryQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManufactoryQuery, ManufactoryQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ManufactoryQuery, ManufactoryQueryVariables>(ManufactoryDocument, variables, options);
}
export type ManufactoryQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ManufactoryQuery, ManufactoryQueryVariables>;
export const ManufactoryFormDataDocument = gql`
    query ManufactoryFormData {
  product(where: {storable: {_eq: true}, manufacturable: {_eq: true}}) {
    ...IProductSearch
  }
  stock_operation(where: {order_type: {_eq: MFA}}) {
    name
    default_src
    default_dest
    id
  }
}
    ${IProductSearchFragmentDoc}`;

/**
 * __useManufactoryFormDataQuery__
 *
 * To run a query within a Vue component, call `useManufactoryFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useManufactoryFormDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useManufactoryFormDataQuery();
 */
export function useManufactoryFormDataQuery(options: VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>(ManufactoryFormDataDocument, {}, options);
}
export function useManufactoryFormDataLazyQuery(options: VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>(ManufactoryFormDataDocument, {}, options);
}
export type ManufactoryFormDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ManufactoryFormDataQuery, ManufactoryFormDataQueryVariables>;
export const InsertManufactoryOrderDocument = gql`
    mutation InsertManufactoryOrder($data: [stock_manufacturing_order_item_insert_input!]!, $note: String) {
  insert_stock_order_one(
    object: {type: MFA, manufacturing_order_items: {data: $data}, note: $note}
  ) {
    ...IManufactoryOrder
  }
}
    ${IManufactoryOrderFragmentDoc}`;

/**
 * __useInsertManufactoryOrderMutation__
 *
 * To run a mutation, you first call `useInsertManufactoryOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertManufactoryOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertManufactoryOrderMutation({
 *   variables: {
 *     data: // value for 'data'
 *     note: // value for 'note'
 *   },
 * });
 */
export function useInsertManufactoryOrderMutation(options: VueApolloComposable.UseMutationOptions<InsertManufactoryOrderMutation, InsertManufactoryOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertManufactoryOrderMutation, InsertManufactoryOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertManufactoryOrderMutation, InsertManufactoryOrderMutationVariables>(InsertManufactoryOrderDocument, options);
}
export type InsertManufactoryOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertManufactoryOrderMutation, InsertManufactoryOrderMutationVariables>;
export const InsertManufactoryItemDocument = gql`
    mutation InsertManufactoryItem($order_id: bigint!, $product_id: Int, $lot_id: Int, $product_uom: Int!, $quantity: numeric!, $src_location: Int!, $dest_location: Int!) {
  insert_stock_manufacturing_order_item_one(
    object: {order_id: $order_id, product_id: $product_id, lot_id: $lot_id, product_uom: $product_uom, quantity: $quantity, src_location: $src_location, dest_location: $dest_location}
  ) {
    ...IManufactoryItem
  }
}
    ${IManufactoryItemFragmentDoc}`;

/**
 * __useInsertManufactoryItemMutation__
 *
 * To run a mutation, you first call `useInsertManufactoryItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertManufactoryItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertManufactoryItemMutation({
 *   variables: {
 *     order_id: // value for 'order_id'
 *     product_id: // value for 'product_id'
 *     lot_id: // value for 'lot_id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *     src_location: // value for 'src_location'
 *     dest_location: // value for 'dest_location'
 *   },
 * });
 */
export function useInsertManufactoryItemMutation(options: VueApolloComposable.UseMutationOptions<InsertManufactoryItemMutation, InsertManufactoryItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertManufactoryItemMutation, InsertManufactoryItemMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertManufactoryItemMutation, InsertManufactoryItemMutationVariables>(InsertManufactoryItemDocument, options);
}
export type InsertManufactoryItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertManufactoryItemMutation, InsertManufactoryItemMutationVariables>;
export const UpdateManufactoryItemDocument = gql`
    mutation UpdateManufactoryItem($id: bigint!, $product_uom: Int!, $quantity: numeric!, $src_location: Int!, $dest_location: Int!, $material_items: jsonb, $lot_id: Int, $product_id: Int, $product_lot: [Int!], $code: String, $description: String, $expiration_date: date, $alert_date: date) {
  update_stock_manufacturing_order_item_by_pk(
    pk_columns: {id: $id}
    _set: {product_uom: $product_uom, quantity: $quantity, src_location: $src_location, dest_location: $dest_location, material_items: $material_items, lot_id: $lot_id}
  ) {
    ...IManufactoryItem
  }
  update_stock_production_lot(
    where: {id: {_in: $product_lot}}
    _set: {code: $code, product_id: $product_id, description: $description, expiration_date: $expiration_date, alert_date: $alert_date}
  ) {
    affected_rows
    returning {
      ...ILot
    }
  }
}
    ${IManufactoryItemFragmentDoc}
${ILotFragmentDoc}`;

/**
 * __useUpdateManufactoryItemMutation__
 *
 * To run a mutation, you first call `useUpdateManufactoryItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateManufactoryItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateManufactoryItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *     src_location: // value for 'src_location'
 *     dest_location: // value for 'dest_location'
 *     material_items: // value for 'material_items'
 *     lot_id: // value for 'lot_id'
 *     product_id: // value for 'product_id'
 *     product_lot: // value for 'product_lot'
 *     code: // value for 'code'
 *     description: // value for 'description'
 *     expiration_date: // value for 'expiration_date'
 *     alert_date: // value for 'alert_date'
 *   },
 * });
 */
export function useUpdateManufactoryItemMutation(options: VueApolloComposable.UseMutationOptions<UpdateManufactoryItemMutation, UpdateManufactoryItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateManufactoryItemMutation, UpdateManufactoryItemMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateManufactoryItemMutation, UpdateManufactoryItemMutationVariables>(UpdateManufactoryItemDocument, options);
}
export type UpdateManufactoryItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateManufactoryItemMutation, UpdateManufactoryItemMutationVariables>;
export const DeleteManufactoryOrderDocument = gql`
    mutation DeleteManufactoryOrder($id: bigint!) {
  delete_stock_order_by_pk(id: $id) {
    ...IManufactoryOrder
  }
}
    ${IManufactoryOrderFragmentDoc}`;

/**
 * __useDeleteManufactoryOrderMutation__
 *
 * To run a mutation, you first call `useDeleteManufactoryOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManufactoryOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManufactoryOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteManufactoryOrderMutation(options: VueApolloComposable.UseMutationOptions<DeleteManufactoryOrderMutation, DeleteManufactoryOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteManufactoryOrderMutation, DeleteManufactoryOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteManufactoryOrderMutation, DeleteManufactoryOrderMutationVariables>(DeleteManufactoryOrderDocument, options);
}
export type DeleteManufactoryOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteManufactoryOrderMutation, DeleteManufactoryOrderMutationVariables>;
export const DeleteManufactoryItemDocument = gql`
    mutation DeleteManufactoryItem($id: bigint!, $lot: [Int!]!) {
  delete_stock_manufacturing_order_item_by_pk(id: $id) {
    ...IManufactoryItem
  }
  delete_stock_production_lot(where: {id: {_in: $lot}}) {
    affected_rows
    returning {
      id
    }
  }
}
    ${IManufactoryItemFragmentDoc}`;

/**
 * __useDeleteManufactoryItemMutation__
 *
 * To run a mutation, you first call `useDeleteManufactoryItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteManufactoryItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteManufactoryItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     lot: // value for 'lot'
 *   },
 * });
 */
export function useDeleteManufactoryItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteManufactoryItemMutation, DeleteManufactoryItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteManufactoryItemMutation, DeleteManufactoryItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteManufactoryItemMutation, DeleteManufactoryItemMutationVariables>(DeleteManufactoryItemDocument, options);
}
export type DeleteManufactoryItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteManufactoryItemMutation, DeleteManufactoryItemMutationVariables>;
export const OperationsDocument = gql`
    query Operations($limit: Int, $offset: Int) {
  stock_operation(limit: $limit, offset: $offset) {
    ...IOperation
  }
  stock_operation_aggregate {
    aggregate {
      count
    }
  }
  stock_location {
    id
    name
    code
  }
}
    ${IOperationFragmentDoc}`;

/**
 * __useOperationsQuery__
 *
 * To run a query within a Vue component, call `useOperationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOperationsQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function useOperationsQuery(variables: OperationsQueryVariables | VueCompositionApi.Ref<OperationsQueryVariables> | ReactiveFunction<OperationsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OperationsQuery, OperationsQueryVariables>(OperationsDocument, variables, options);
}
export function useOperationsLazyQuery(variables: OperationsQueryVariables | VueCompositionApi.Ref<OperationsQueryVariables> | ReactiveFunction<OperationsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OperationsQuery, OperationsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OperationsQuery, OperationsQueryVariables>(OperationsDocument, variables, options);
}
export type OperationsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OperationsQuery, OperationsQueryVariables>;
export const OperationsTypeDocument = gql`
    query OperationsType {
  stock_operation_type(where: {value: {_neq: "TRAN"}}) {
    value
  }
}
    `;

/**
 * __useOperationsTypeQuery__
 *
 * To run a query within a Vue component, call `useOperationsTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOperationsTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOperationsTypeQuery();
 */
export function useOperationsTypeQuery(options: VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OperationsTypeQuery, OperationsTypeQueryVariables>(OperationsTypeDocument, {}, options);
}
export function useOperationsTypeLazyQuery(options: VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OperationsTypeQuery, OperationsTypeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OperationsTypeQuery, OperationsTypeQueryVariables>(OperationsTypeDocument, {}, options);
}
export type OperationsTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OperationsTypeQuery, OperationsTypeQueryVariables>;
export const OrderTypeDocument = gql`
    query OrderType {
  stock_order_type(where: {code: {_nin: ["TO", "RC", "OUT"]}}) {
    code
  }
}
    `;

/**
 * __useOrderTypeQuery__
 *
 * To run a query within a Vue component, call `useOrderTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrderTypeQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useOrderTypeQuery();
 */
export function useOrderTypeQuery(options: VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<OrderTypeQuery, OrderTypeQueryVariables>(OrderTypeDocument, {}, options);
}
export function useOrderTypeLazyQuery(options: VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<OrderTypeQuery, OrderTypeQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<OrderTypeQuery, OrderTypeQueryVariables>(OrderTypeDocument, {}, options);
}
export type OrderTypeQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<OrderTypeQuery, OrderTypeQueryVariables>;
export const InsertOperationDocument = gql`
    mutation InsertOperation($default_dest: Int!, $default_src: Int!, $operation_type: stock_operation_type_enum!, $order_type: stock_order_type_enum!, $return_operation: Int, $name: String) {
  insert_stock_operation_one(
    object: {default_dest: $default_dest, default_src: $default_src, name: $name, operation_type: $operation_type, order_type: $order_type, return_operation: $return_operation}
  ) {
    ...IOperation
  }
}
    ${IOperationFragmentDoc}`;

/**
 * __useInsertOperationMutation__
 *
 * To run a mutation, you first call `useInsertOperationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertOperationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertOperationMutation({
 *   variables: {
 *     default_dest: // value for 'default_dest'
 *     default_src: // value for 'default_src'
 *     operation_type: // value for 'operation_type'
 *     order_type: // value for 'order_type'
 *     return_operation: // value for 'return_operation'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useInsertOperationMutation(options: VueApolloComposable.UseMutationOptions<InsertOperationMutation, InsertOperationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertOperationMutation, InsertOperationMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertOperationMutation, InsertOperationMutationVariables>(InsertOperationDocument, options);
}
export type InsertOperationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertOperationMutation, InsertOperationMutationVariables>;
export const UpdateOperationDocument = gql`
    mutation UpdateOperation($id: Int!, $default_dest: Int!, $default_src: Int!, $operation_type: stock_operation_type_enum!, $order_type: stock_order_type_enum!, $return_operation: Int, $name: String) {
  update_stock_operation_by_pk(
    pk_columns: {id: $id}
    _set: {default_dest: $default_dest, default_src: $default_src, name: $name, operation_type: $operation_type, order_type: $order_type, return_operation: $return_operation}
  ) {
    ...IOperation
  }
}
    ${IOperationFragmentDoc}`;

/**
 * __useUpdateOperationMutation__
 *
 * To run a mutation, you first call `useUpdateOperationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOperationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOperationMutation({
 *   variables: {
 *     id: // value for 'id'
 *     default_dest: // value for 'default_dest'
 *     default_src: // value for 'default_src'
 *     operation_type: // value for 'operation_type'
 *     order_type: // value for 'order_type'
 *     return_operation: // value for 'return_operation'
 *     name: // value for 'name'
 *   },
 * });
 */
export function useUpdateOperationMutation(options: VueApolloComposable.UseMutationOptions<UpdateOperationMutation, UpdateOperationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateOperationMutation, UpdateOperationMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateOperationMutation, UpdateOperationMutationVariables>(UpdateOperationDocument, options);
}
export type UpdateOperationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateOperationMutation, UpdateOperationMutationVariables>;
export const DeleteOperationDocument = gql`
    mutation DeleteOperation($_in: [Int!]) {
  delete_stock_operation(where: {id: {_in: $_in}}) {
    affected_rows
    returning {
      ...IOperation
    }
  }
}
    ${IOperationFragmentDoc}`;

/**
 * __useDeleteOperationMutation__
 *
 * To run a mutation, you first call `useDeleteOperationMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOperationMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteOperationMutation({
 *   variables: {
 *     _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteOperationMutation(options: VueApolloComposable.UseMutationOptions<DeleteOperationMutation, DeleteOperationMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteOperationMutation, DeleteOperationMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteOperationMutation, DeleteOperationMutationVariables>(DeleteOperationDocument, options);
}
export type DeleteOperationMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteOperationMutation, DeleteOperationMutationVariables>;
export const PartnerDocument = gql`
    query Partner($limit: Int = 10, $offset: Int = 10) {
  stock_partner(limit: $limit, offset: $offset) {
    ...IPartner
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __usePartnerQuery__
 *
 * To run a query within a Vue component, call `usePartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePartnerQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 * });
 */
export function usePartnerQuery(variables: PartnerQueryVariables | VueCompositionApi.Ref<PartnerQueryVariables> | ReactiveFunction<PartnerQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, variables, options);
}
export function usePartnerLazyQuery(variables: PartnerQueryVariables | VueCompositionApi.Ref<PartnerQueryVariables> | ReactiveFunction<PartnerQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnerQuery, PartnerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PartnerQuery, PartnerQueryVariables>(PartnerDocument, variables, options);
}
export type PartnerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PartnerQuery, PartnerQueryVariables>;
export const PartnersDocument = gql`
    query Partners {
  stock_partner {
    ...IPartner
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __usePartnersQuery__
 *
 * To run a query within a Vue component, call `usePartnersQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePartnersQuery();
 */
export function usePartnersQuery(options: VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, {}, options);
}
export function usePartnersLazyQuery(options: VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PartnersQuery, PartnersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PartnersQuery, PartnersQueryVariables>(PartnersDocument, {}, options);
}
export type PartnersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PartnersQuery, PartnersQueryVariables>;
export const FindPartnerDocument = gql`
    query FindPartner($id: Int!) {
  stock_partner_by_pk(id: $id) {
    ...IPartner
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __useFindPartnerQuery__
 *
 * To run a query within a Vue component, call `useFindPartnerQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPartnerQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFindPartnerQuery({
 *   id: // value for 'id'
 * });
 */
export function useFindPartnerQuery(variables: FindPartnerQueryVariables | VueCompositionApi.Ref<FindPartnerQueryVariables> | ReactiveFunction<FindPartnerQueryVariables>, options: VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FindPartnerQuery, FindPartnerQueryVariables>(FindPartnerDocument, variables, options);
}
export function useFindPartnerLazyQuery(variables: FindPartnerQueryVariables | VueCompositionApi.Ref<FindPartnerQueryVariables> | ReactiveFunction<FindPartnerQueryVariables>, options: VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindPartnerQuery, FindPartnerQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FindPartnerQuery, FindPartnerQueryVariables>(FindPartnerDocument, variables, options);
}
export type FindPartnerQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FindPartnerQuery, FindPartnerQueryVariables>;
export const InsertPartnerDocument = gql`
    mutation InsertPartner($name: String!, $description: String) {
  insert_stock_partner_one(object: {name: $name, description: $description}) {
    ...IPartner
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __useInsertPartnerMutation__
 *
 * To run a mutation, you first call `useInsertPartnerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertPartnerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertPartnerMutation({
 *   variables: {
 *     name: // value for 'name'
 *     description: // value for 'description'
 *   },
 * });
 */
export function useInsertPartnerMutation(options: VueApolloComposable.UseMutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertPartnerMutation, InsertPartnerMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertPartnerMutation, InsertPartnerMutationVariables>(InsertPartnerDocument, options);
}
export type InsertPartnerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertPartnerMutation, InsertPartnerMutationVariables>;
export const UpdatePartnerDocument = gql`
    mutation UpdatePartner($id: Int!, $name: String!, $description: String) {
  update_stock_partner_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description}
  ) {
    ...IPartner
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __useUpdatePartnerMutation__
 *
 * To run a mutation, you first call `useUpdatePartnerMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePartnerMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePartnerMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     description: // value for 'description'
 *   },
 * });
 */
export function useUpdatePartnerMutation(options: VueApolloComposable.UseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePartnerMutation, UpdatePartnerMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePartnerMutation, UpdatePartnerMutationVariables>(UpdatePartnerDocument, options);
}
export type UpdatePartnerMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePartnerMutation, UpdatePartnerMutationVariables>;
export const DeletePartnersDocument = gql`
    mutation DeletePartners($id: [Int!]!) {
  delete_stock_partner(where: {id: {_in: $id}}) {
    affected_rows
    returning {
      ...IPartner
    }
  }
}
    ${IPartnerFragmentDoc}`;

/**
 * __useDeletePartnersMutation__
 *
 * To run a mutation, you first call `useDeletePartnersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePartnersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePartnersMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeletePartnersMutation(options: VueApolloComposable.UseMutationOptions<DeletePartnersMutation, DeletePartnersMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePartnersMutation, DeletePartnersMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePartnersMutation, DeletePartnersMutationVariables>(DeletePartnersDocument, options);
}
export type DeletePartnersMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePartnersMutation, DeletePartnersMutationVariables>;
export const ProductMaterialsDocument = gql`
    query ProductMaterials($_eq: Int!) {
  product_bill_of_material_items(
    where: {bill_of_material_id: {_eq: $_eq}}
    order_by: {product: {name: asc}}
  ) {
    ...IProductMaterials
  }
}
    ${IProductMaterialsFragmentDoc}`;

/**
 * __useProductMaterialsQuery__
 *
 * To run a query within a Vue component, call `useProductMaterialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductMaterialsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductMaterialsQuery({
 *   _eq: // value for '_eq'
 * });
 */
export function useProductMaterialsQuery(variables: ProductMaterialsQueryVariables | VueCompositionApi.Ref<ProductMaterialsQueryVariables> | ReactiveFunction<ProductMaterialsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductMaterialsQuery, ProductMaterialsQueryVariables>(ProductMaterialsDocument, variables, options);
}
export function useProductMaterialsLazyQuery(variables: ProductMaterialsQueryVariables | VueCompositionApi.Ref<ProductMaterialsQueryVariables> | ReactiveFunction<ProductMaterialsQueryVariables>, options: VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductMaterialsQuery, ProductMaterialsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductMaterialsQuery, ProductMaterialsQueryVariables>(ProductMaterialsDocument, variables, options);
}
export type ProductMaterialsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductMaterialsQuery, ProductMaterialsQueryVariables>;
export const DeleteProductMaterialDocument = gql`
    mutation DeleteProductMaterial($id: Int!) {
  delete_product_bill_of_material_items_by_pk(id: $id) {
    ...IProductMaterials
  }
}
    ${IProductMaterialsFragmentDoc}`;

/**
 * __useDeleteProductMaterialMutation__
 *
 * To run a mutation, you first call `useDeleteProductMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProductMaterialMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteProductMaterialMutation(options: VueApolloComposable.UseMutationOptions<DeleteProductMaterialMutation, DeleteProductMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProductMaterialMutation, DeleteProductMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteProductMaterialMutation, DeleteProductMaterialMutationVariables>(DeleteProductMaterialDocument, options);
}
export type DeleteProductMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteProductMaterialMutation, DeleteProductMaterialMutationVariables>;
export const InsertProductMaterialDocument = gql`
    mutation InsertProductMaterial($bill_of_material_id: Int!, $product_id: Int!, $product_uom: Int!, $quantity: numeric!) {
  insert_product_bill_of_material_items_one(
    object: {quantity: $quantity, product_id: $product_id, product_uom: $product_uom, bill_of_material_id: $bill_of_material_id}
  ) {
    ...IProductMaterials
  }
}
    ${IProductMaterialsFragmentDoc}`;

/**
 * __useInsertProductMaterialMutation__
 *
 * To run a mutation, you first call `useInsertProductMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertProductMaterialMutation({
 *   variables: {
 *     bill_of_material_id: // value for 'bill_of_material_id'
 *     product_id: // value for 'product_id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *   },
 * });
 */
export function useInsertProductMaterialMutation(options: VueApolloComposable.UseMutationOptions<InsertProductMaterialMutation, InsertProductMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertProductMaterialMutation, InsertProductMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertProductMaterialMutation, InsertProductMaterialMutationVariables>(InsertProductMaterialDocument, options);
}
export type InsertProductMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertProductMaterialMutation, InsertProductMaterialMutationVariables>;
export const UpdateProductMaterialDocument = gql`
    mutation UpdateProductMaterial($id: Int!, $product_uom: Int!, $quantity: numeric!) {
  update_product_bill_of_material_items_by_pk(
    pk_columns: {id: $id}
    _set: {product_uom: $product_uom, quantity: $quantity}
  ) {
    ...IProductMaterials
  }
}
    ${IProductMaterialsFragmentDoc}`;

/**
 * __useUpdateProductMaterialMutation__
 *
 * To run a mutation, you first call `useUpdateProductMaterialMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMaterialMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProductMaterialMutation({
 *   variables: {
 *     id: // value for 'id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateProductMaterialMutation(options: VueApolloComposable.UseMutationOptions<UpdateProductMaterialMutation, UpdateProductMaterialMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProductMaterialMutation, UpdateProductMaterialMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateProductMaterialMutation, UpdateProductMaterialMutationVariables>(UpdateProductMaterialDocument, options);
}
export type UpdateProductMaterialMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProductMaterialMutation, UpdateProductMaterialMutationVariables>;
export const SearchProductDocument = gql`
    query SearchProduct($_ilike: String) {
  product(where: {name: {_ilike: $_ilike}}) {
    ...IProductSearch
  }
}
    ${IProductSearchFragmentDoc}`;

/**
 * __useSearchProductQuery__
 *
 * To run a query within a Vue component, call `useSearchProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSearchProductQuery({
 *   _ilike: // value for '_ilike'
 * });
 */
export function useSearchProductQuery(variables: SearchProductQueryVariables | VueCompositionApi.Ref<SearchProductQueryVariables> | ReactiveFunction<SearchProductQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, variables, options);
}
export function useSearchProductLazyQuery(variables: SearchProductQueryVariables | VueCompositionApi.Ref<SearchProductQueryVariables> | ReactiveFunction<SearchProductQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SearchProductQuery, SearchProductQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SearchProductQuery, SearchProductQueryVariables>(SearchProductDocument, variables, options);
}
export type SearchProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SearchProductQuery, SearchProductQueryVariables>;
export const ProductsDocument = gql`
    query Products($limit: Int, $offset: Int, $order_by: [product_order_by!], $product_category: [Int!], $like: String) {
  product(
    limit: $limit
    offset: $offset
    order_by: $order_by
    where: {product_category: {_in: $product_category}, _or: [{name: {_ilike: $like}}, {category: {name: {_ilike: $like}}}]}
  ) {
    ...IProduct
  }
  product_aggregate(
    where: {product_category: {_in: $product_category}, _or: [{name: {_ilike: $like}}, {category: {name: {_ilike: $like}}}]}
  ) {
    aggregate {
      count
    }
  }
  unit_of_measure {
    id
    code
    description
    category {
      id
      value
    }
  }
}
    ${IProductFragmentDoc}`;

/**
 * __useProductsQuery__
 *
 * To run a query within a Vue component, call `useProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductsQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   product_category: // value for 'product_category'
 *   like: // value for 'like'
 * });
 */
export function useProductsQuery(variables: ProductsQueryVariables | VueCompositionApi.Ref<ProductsQueryVariables> | ReactiveFunction<ProductsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);
}
export function useProductsLazyQuery(variables: ProductsQueryVariables | VueCompositionApi.Ref<ProductsQueryVariables> | ReactiveFunction<ProductsQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsQuery, ProductsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductsQuery, ProductsQueryVariables>(ProductsDocument, variables, options);
}
export type ProductsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductsQuery, ProductsQueryVariables>;
export const FindProductDocument = gql`
    query FindProduct($id: Int!) {
  product_by_pk(id: $id) {
    ...IProduct
  }
}
    ${IProductFragmentDoc}`;

/**
 * __useFindProductQuery__
 *
 * To run a query within a Vue component, call `useFindProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFindProductQuery({
 *   id: // value for 'id'
 * });
 */
export function useFindProductQuery(variables: FindProductQueryVariables | VueCompositionApi.Ref<FindProductQueryVariables> | ReactiveFunction<FindProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, variables, options);
}
export function useFindProductLazyQuery(variables: FindProductQueryVariables | VueCompositionApi.Ref<FindProductQueryVariables> | ReactiveFunction<FindProductQueryVariables>, options: VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindProductQuery, FindProductQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FindProductQuery, FindProductQueryVariables>(FindProductDocument, variables, options);
}
export type FindProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FindProductQuery, FindProductQueryVariables>;
export const InsertProductDocument = gql`
    mutation InsertProduct($name: String!, $description: String, $product_category: Int, $cost: numeric, $price: numeric, $sku: String, $external_product_id: String, $product_uom: Int, $allowed_min_quantity: numeric, $sellable: Boolean!, $expirable: Boolean, $purchasable: Boolean!, $storable: Boolean!, $trackerable: Boolean!, $expire_on: Int, $alert_of_expiration_on: Int) {
  insert_product(
    objects: {name: $name, description: $description, product_category: $product_category, price: $price, sku: $sku, cost: $cost, allowed_min_quantity: $allowed_min_quantity, external_product_id: $external_product_id, product_uom: $product_uom, sellable: $sellable, purchasable: $purchasable, expirable: $expirable, storable: $storable, trackerable: $trackerable, expire_on: $expire_on, alert_of_expiration_on: $alert_of_expiration_on}
  ) {
    affected_rows
    returning {
      ...IProduct
    }
  }
}
    ${IProductFragmentDoc}`;

/**
 * __useInsertProductMutation__
 *
 * To run a mutation, you first call `useInsertProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertProductMutation({
 *   variables: {
 *     name: // value for 'name'
 *     description: // value for 'description'
 *     product_category: // value for 'product_category'
 *     cost: // value for 'cost'
 *     price: // value for 'price'
 *     sku: // value for 'sku'
 *     external_product_id: // value for 'external_product_id'
 *     product_uom: // value for 'product_uom'
 *     allowed_min_quantity: // value for 'allowed_min_quantity'
 *     sellable: // value for 'sellable'
 *     expirable: // value for 'expirable'
 *     purchasable: // value for 'purchasable'
 *     storable: // value for 'storable'
 *     trackerable: // value for 'trackerable'
 *     expire_on: // value for 'expire_on'
 *     alert_of_expiration_on: // value for 'alert_of_expiration_on'
 *   },
 * });
 */
export function useInsertProductMutation(options: VueApolloComposable.UseMutationOptions<InsertProductMutation, InsertProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertProductMutation, InsertProductMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertProductMutation, InsertProductMutationVariables>(InsertProductDocument, options);
}
export type InsertProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertProductMutation, InsertProductMutationVariables>;
export const UpdateProductDocument = gql`
    mutation UpdateProduct($id: Int!, $name: String!, $description: String, $product_category: Int, $cost: numeric, $price: numeric, $sku: String, $allowed_min_quantity: numeric, $external_product_id: String, $product_uom: Int, $sellable: Boolean!, $purchasable: Boolean!, $storable: Boolean!, $expirable: Boolean, $trackerable: Boolean!, $expire_on: Int, $alert_of_expiration_on: Int) {
  update_product_by_pk(
    pk_columns: {id: $id}
    _set: {name: $name, description: $description, product_category: $product_category, price: $price, sku: $sku, cost: $cost, allowed_min_quantity: $allowed_min_quantity, external_product_id: $external_product_id, product_uom: $product_uom, expirable: $expirable, sellable: $sellable, purchasable: $purchasable, storable: $storable, trackerable: $trackerable, expire_on: $expire_on, alert_of_expiration_on: $alert_of_expiration_on}
  ) {
    ...IProduct
  }
}
    ${IProductFragmentDoc}`;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateProductMutation({
 *   variables: {
 *     id: // value for 'id'
 *     name: // value for 'name'
 *     description: // value for 'description'
 *     product_category: // value for 'product_category'
 *     cost: // value for 'cost'
 *     price: // value for 'price'
 *     sku: // value for 'sku'
 *     allowed_min_quantity: // value for 'allowed_min_quantity'
 *     external_product_id: // value for 'external_product_id'
 *     product_uom: // value for 'product_uom'
 *     sellable: // value for 'sellable'
 *     purchasable: // value for 'purchasable'
 *     storable: // value for 'storable'
 *     expirable: // value for 'expirable'
 *     trackerable: // value for 'trackerable'
 *     expire_on: // value for 'expire_on'
 *     alert_of_expiration_on: // value for 'alert_of_expiration_on'
 *   },
 * });
 */
export function useUpdateProductMutation(options: VueApolloComposable.UseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
}
export type UpdateProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateProductMutation, UpdateProductMutationVariables>;
export const DeleteProductDocument = gql`
    mutation DeleteProduct($_in: [Int!]) {
  delete_product(where: {id: {_in: $_in}}) {
    affected_rows
    returning {
      ...IProduct
    }
  }
}
    ${IProductFragmentDoc}`;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteProductMutation({
 *   variables: {
 *     _in: // value for '_in'
 *   },
 * });
 */
export function useDeleteProductMutation(options: VueApolloComposable.UseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteProductMutation, DeleteProductMutationVariables>> = {}) {
  return VueApolloComposable.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
}
export type DeleteProductMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteProductMutation, DeleteProductMutationVariables>;
export const PurchasesDocument = gql`
    query Purchases($limit: Int, $offset: Int, $order_by: [stock_order_order_by!] = {created_at: desc}, $like: String) {
  stock_order(
    where: {type: {_eq: PO}, _or: [{purchase_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...IPurchaseOrder
  }
  stock_order_aggregate(
    where: {type: {_eq: PO}, _or: [{purchase_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${IPurchaseOrderFragmentDoc}`;

/**
 * __usePurchasesQuery__
 *
 * To run a query within a Vue component, call `usePurchasesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchasesQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   like: // value for 'like'
 * });
 */
export function usePurchasesQuery(variables: PurchasesQueryVariables | VueCompositionApi.Ref<PurchasesQueryVariables> | ReactiveFunction<PurchasesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, variables, options);
}
export function usePurchasesLazyQuery(variables: PurchasesQueryVariables | VueCompositionApi.Ref<PurchasesQueryVariables> | ReactiveFunction<PurchasesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchasesQuery, PurchasesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PurchasesQuery, PurchasesQueryVariables>(PurchasesDocument, variables, options);
}
export type PurchasesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchasesQuery, PurchasesQueryVariables>;
export const PurchasesFormDataDocument = gql`
    query PurchasesFormData {
  product(where: {purchasable: {_eq: true}, storable: {_eq: true}}) {
    ...IProductSearch
  }
  stock_operation(where: {order_type: {_eq: PO}}) {
    id
    name
  }
  stock_partner {
    id
    name
  }
}
    ${IProductSearchFragmentDoc}`;

/**
 * __usePurchasesFormDataQuery__
 *
 * To run a query within a Vue component, call `usePurchasesFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `usePurchasesFormDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = usePurchasesFormDataQuery();
 */
export function usePurchasesFormDataQuery(options: VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>(PurchasesFormDataDocument, {}, options);
}
export function usePurchasesFormDataLazyQuery(options: VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>(PurchasesFormDataDocument, {}, options);
}
export type PurchasesFormDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<PurchasesFormDataQuery, PurchasesFormDataQueryVariables>;
export const InsertPurchaseOrderDocument = gql`
    mutation InsertPurchaseOrder($data: [stock_purchase_order_item_insert_input!]!, $note: String) {
  insert_stock_order_one(
    object: {type: PO, purchase_order_items: {data: $data}, note: $note}
  ) {
    ...IPurchaseOrder
  }
}
    ${IPurchaseOrderFragmentDoc}`;

/**
 * __useInsertPurchaseOrderMutation__
 *
 * To run a mutation, you first call `useInsertPurchaseOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertPurchaseOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertPurchaseOrderMutation({
 *   variables: {
 *     data: // value for 'data'
 *     note: // value for 'note'
 *   },
 * });
 */
export function useInsertPurchaseOrderMutation(options: VueApolloComposable.UseMutationOptions<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>(InsertPurchaseOrderDocument, options);
}
export type InsertPurchaseOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertPurchaseOrderMutation, InsertPurchaseOrderMutationVariables>;
export const InsertLotDocument = gql`
    mutation InsertLot($code: String!, $product_id: Int!, $description: String, $expiration_date: date, $alert_date: date) {
  insert_stock_production_lot_one(
    object: {code: $code, product_id: $product_id, description: $description, expiration_date: $expiration_date, alert_date: $alert_date}
  ) {
    ...ILot
  }
}
    ${ILotFragmentDoc}`;

/**
 * __useInsertLotMutation__
 *
 * To run a mutation, you first call `useInsertLotMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertLotMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertLotMutation({
 *   variables: {
 *     code: // value for 'code'
 *     product_id: // value for 'product_id'
 *     description: // value for 'description'
 *     expiration_date: // value for 'expiration_date'
 *     alert_date: // value for 'alert_date'
 *   },
 * });
 */
export function useInsertLotMutation(options: VueApolloComposable.UseMutationOptions<InsertLotMutation, InsertLotMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertLotMutation, InsertLotMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertLotMutation, InsertLotMutationVariables>(InsertLotDocument, options);
}
export type InsertLotMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertLotMutation, InsertLotMutationVariables>;
export const DeleteLotDocument = gql`
    mutation DeleteLot($id: Int!) {
  delete_stock_production_lot_by_pk(id: $id) {
    ...ILot
  }
}
    ${ILotFragmentDoc}`;

/**
 * __useDeleteLotMutation__
 *
 * To run a mutation, you first call `useDeleteLotMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLotMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteLotMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLotMutation(options: VueApolloComposable.UseMutationOptions<DeleteLotMutation, DeleteLotMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteLotMutation, DeleteLotMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteLotMutation, DeleteLotMutationVariables>(DeleteLotDocument, options);
}
export type DeleteLotMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteLotMutation, DeleteLotMutationVariables>;
export const RunOrderDocument = gql`
    mutation RunOrder($id: bigint!) {
  update_stock_order_by_pk(pk_columns: {id: $id}, _set: {status: APPROVED}) {
    id
    status
    execution_errors {
      ...ExecutionErrors
    }
    execution_errors_by_reference {
      ...ExecutionErrors
    }
  }
}
    ${ExecutionErrorsFragmentDoc}`;

/**
 * __useRunOrderMutation__
 *
 * To run a mutation, you first call `useRunOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRunOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRunOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRunOrderMutation(options: VueApolloComposable.UseMutationOptions<RunOrderMutation, RunOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RunOrderMutation, RunOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<RunOrderMutation, RunOrderMutationVariables>(RunOrderDocument, options);
}
export type RunOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RunOrderMutation, RunOrderMutationVariables>;
export const RevertOrderDocument = gql`
    mutation RevertOrder($id: bigint!) {
  update_stock_order_by_pk(pk_columns: {id: $id}, _set: {status: REVERTED}) {
    id
    status
  }
}
    `;

/**
 * __useRevertOrderMutation__
 *
 * To run a mutation, you first call `useRevertOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useRevertOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useRevertOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useRevertOrderMutation(options: VueApolloComposable.UseMutationOptions<RevertOrderMutation, RevertOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<RevertOrderMutation, RevertOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<RevertOrderMutation, RevertOrderMutationVariables>(RevertOrderDocument, options);
}
export type RevertOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<RevertOrderMutation, RevertOrderMutationVariables>;
export const InsertPurchaseItemDocument = gql`
    mutation InsertPurchaseItem($order_id: bigint!, $operation_id: Int!, $lot_id: Int, $partner_provider: Int, $price: numeric, $product_id: Int!, $product_uom: Int!, $quantity: numeric!) {
  insert_stock_purchase_order_item_one(
    object: {product_id: $product_id, lot_id: $lot_id, operation_id: $operation_id, quantity: $quantity, product_uom: $product_uom, price: $price, partner_provider: $partner_provider, order_id: $order_id}
  ) {
    ...IPurchaseItem
  }
}
    ${IPurchaseItemFragmentDoc}`;

/**
 * __useInsertPurchaseItemMutation__
 *
 * To run a mutation, you first call `useInsertPurchaseItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertPurchaseItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertPurchaseItemMutation({
 *   variables: {
 *     order_id: // value for 'order_id'
 *     operation_id: // value for 'operation_id'
 *     lot_id: // value for 'lot_id'
 *     partner_provider: // value for 'partner_provider'
 *     price: // value for 'price'
 *     product_id: // value for 'product_id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *   },
 * });
 */
export function useInsertPurchaseItemMutation(options: VueApolloComposable.UseMutationOptions<InsertPurchaseItemMutation, InsertPurchaseItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertPurchaseItemMutation, InsertPurchaseItemMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertPurchaseItemMutation, InsertPurchaseItemMutationVariables>(InsertPurchaseItemDocument, options);
}
export type InsertPurchaseItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertPurchaseItemMutation, InsertPurchaseItemMutationVariables>;
export const UpdatePurchaseItemsDocument = gql`
    mutation UpdatePurchaseItems($id: bigint!, $operation_id: Int!, $partner_provider: Int, $product_uom: Int!, $price: numeric!, $quantity: numeric!, $lot_id: Int, $product_id: Int, $product_lot: [Int!], $code: String, $description: String, $expiration_date: date, $alert_date: date) {
  update_stock_purchase_order_item_by_pk(
    pk_columns: {id: $id}
    _set: {partner_provider: $partner_provider, quantity: $quantity, product_uom: $product_uom, price: $price, operation_id: $operation_id, lot_id: $lot_id}
  ) {
    ...IPurchaseItem
  }
  update_stock_production_lot(
    where: {id: {_in: $product_lot}}
    _set: {code: $code, product_id: $product_id, description: $description, expiration_date: $expiration_date, alert_date: $alert_date}
  ) {
    affected_rows
    returning {
      ...ILot
    }
  }
}
    ${IPurchaseItemFragmentDoc}
${ILotFragmentDoc}`;

/**
 * __useUpdatePurchaseItemsMutation__
 *
 * To run a mutation, you first call `useUpdatePurchaseItemsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePurchaseItemsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdatePurchaseItemsMutation({
 *   variables: {
 *     id: // value for 'id'
 *     operation_id: // value for 'operation_id'
 *     partner_provider: // value for 'partner_provider'
 *     product_uom: // value for 'product_uom'
 *     price: // value for 'price'
 *     quantity: // value for 'quantity'
 *     lot_id: // value for 'lot_id'
 *     product_id: // value for 'product_id'
 *     product_lot: // value for 'product_lot'
 *     code: // value for 'code'
 *     description: // value for 'description'
 *     expiration_date: // value for 'expiration_date'
 *     alert_date: // value for 'alert_date'
 *   },
 * });
 */
export function useUpdatePurchaseItemsMutation(options: VueApolloComposable.UseMutationOptions<UpdatePurchaseItemsMutation, UpdatePurchaseItemsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdatePurchaseItemsMutation, UpdatePurchaseItemsMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdatePurchaseItemsMutation, UpdatePurchaseItemsMutationVariables>(UpdatePurchaseItemsDocument, options);
}
export type UpdatePurchaseItemsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdatePurchaseItemsMutation, UpdatePurchaseItemsMutationVariables>;
export const DeletePurchaseOrderDocument = gql`
    mutation DeletePurchaseOrder($id: bigint!) {
  delete_stock_order_by_pk(id: $id) {
    ...IPurchaseOrder
  }
}
    ${IPurchaseOrderFragmentDoc}`;

/**
 * __useDeletePurchaseOrderMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePurchaseOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeletePurchaseOrderMutation(options: VueApolloComposable.UseMutationOptions<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>(DeletePurchaseOrderDocument, options);
}
export type DeletePurchaseOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePurchaseOrderMutation, DeletePurchaseOrderMutationVariables>;
export const DeletePurchaseOrderItemDocument = gql`
    mutation DeletePurchaseOrderItem($id: bigint!, $lot: [Int!]!) {
  delete_stock_purchase_order_item_by_pk(id: $id) {
    id
  }
  delete_stock_production_lot(where: {id: {_in: $lot}}) {
    affected_rows
    returning {
      id
    }
  }
}
    `;

/**
 * __useDeletePurchaseOrderItemMutation__
 *
 * To run a mutation, you first call `useDeletePurchaseOrderItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeletePurchaseOrderItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeletePurchaseOrderItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     lot: // value for 'lot'
 *   },
 * });
 */
export function useDeletePurchaseOrderItemMutation(options: VueApolloComposable.UseMutationOptions<DeletePurchaseOrderItemMutation, DeletePurchaseOrderItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeletePurchaseOrderItemMutation, DeletePurchaseOrderItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeletePurchaseOrderItemMutation, DeletePurchaseOrderItemMutationVariables>(DeletePurchaseOrderItemDocument, options);
}
export type DeletePurchaseOrderItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeletePurchaseOrderItemMutation, DeletePurchaseOrderItemMutationVariables>;
export const UpdateOrderNoteDocument = gql`
    mutation UpdateOrderNote($id: bigint!, $note: String) {
  update_stock_order_by_pk(pk_columns: {id: $id}, _set: {note: $note}) {
    id
    note
    status
  }
}
    `;

/**
 * __useUpdateOrderNoteMutation__
 *
 * To run a mutation, you first call `useUpdateOrderNoteMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrderNoteMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateOrderNoteMutation({
 *   variables: {
 *     id: // value for 'id'
 *     note: // value for 'note'
 *   },
 * });
 */
export function useUpdateOrderNoteMutation(options: VueApolloComposable.UseMutationOptions<UpdateOrderNoteMutation, UpdateOrderNoteMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateOrderNoteMutation, UpdateOrderNoteMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateOrderNoteMutation, UpdateOrderNoteMutationVariables>(UpdateOrderNoteDocument, options);
}
export type UpdateOrderNoteMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateOrderNoteMutation, UpdateOrderNoteMutationVariables>;
export const ExistenceReportLotDocument = gql`
    query ExistenceReportLot {
  data: opb_product_existence {
    location_name
    lot_code
    product_id
    product_name
    quantity_on_hand
    unit_of_measure
    unit_price
    expiration_date
    product_code
    product {
      id
      manufacturable
      purchasable
      sellable
      storable
    }
  }
}
    `;

/**
 * __useExistenceReportLotQuery__
 *
 * To run a query within a Vue component, call `useExistenceReportLotQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistenceReportLotQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useExistenceReportLotQuery();
 */
export function useExistenceReportLotQuery(options: VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>(ExistenceReportLotDocument, {}, options);
}
export function useExistenceReportLotLazyQuery(options: VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>(ExistenceReportLotDocument, {}, options);
}
export type ExistenceReportLotQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ExistenceReportLotQuery, ExistenceReportLotQueryVariables>;
export const ExistenceReportProductDocument = gql`
    query ExistenceReportProduct {
  data: opb_existence {
    location_name
    product_id
    product {
      id
      purchasable
      manufacturable
      sellable
      storable
    }
    product_name
    quantity_on_hand
    product_code
    unit_of_measure
    unit_price
  }
}
    `;

/**
 * __useExistenceReportProductQuery__
 *
 * To run a query within a Vue component, call `useExistenceReportProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useExistenceReportProductQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useExistenceReportProductQuery();
 */
export function useExistenceReportProductQuery(options: VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>(ExistenceReportProductDocument, {}, options);
}
export function useExistenceReportProductLazyQuery(options: VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>(ExistenceReportProductDocument, {}, options);
}
export type ExistenceReportProductQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ExistenceReportProductQuery, ExistenceReportProductQueryVariables>;
export const ProductsNearToExpireDocument = gql`
    query ProductsNearToExpire {
  data: opb_product_near_to_expire {
    product_name
    quantity_on_hand
    lot_code
    expiration_date
    days_to_expire
    product {
      id
      purchasable
      manufacturable
      sellable
      storable
      unit_of_measure {
        code
      }
    }
  }
}
    `;

/**
 * __useProductsNearToExpireQuery__
 *
 * To run a query within a Vue component, call `useProductsNearToExpireQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsNearToExpireQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductsNearToExpireQuery();
 */
export function useProductsNearToExpireQuery(options: VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>(ProductsNearToExpireDocument, {}, options);
}
export function useProductsNearToExpireLazyQuery(options: VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>(ProductsNearToExpireDocument, {}, options);
}
export type ProductsNearToExpireQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductsNearToExpireQuery, ProductsNearToExpireQueryVariables>;
export const ProductsRunningOutDocument = gql`
    query ProductsRunningOut {
  data: opb_product_min_quantity_alert {
    product_id
    product {
      id
      purchasable
      manufacturable
      sellable
      storable
    }
    product_name
    quantity
    unit_of_measure
  }
}
    `;

/**
 * __useProductsRunningOutQuery__
 *
 * To run a query within a Vue component, call `useProductsRunningOutQuery` and pass it any options that fit your needs.
 * When your component renders, `useProductsRunningOutQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProductsRunningOutQuery();
 */
export function useProductsRunningOutQuery(options: VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>(ProductsRunningOutDocument, {}, options);
}
export function useProductsRunningOutLazyQuery(options: VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>(ProductsRunningOutDocument, {}, options);
}
export type ProductsRunningOutQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProductsRunningOutQuery, ProductsRunningOutQueryVariables>;
export const ProfitDocument = gql`
    query Profit($start_date: date, $end_date: date) {
  data: opb_profit_report(args: {start_date: $start_date, end_date: $end_date}) {
    product_id
    product_name
    quantity
    purchase_price
    sales_price
    profit
    margin
    product {
      id
      purchasable
      manufacturable
      sellable
      storable
      unit_of_measure {
        code
      }
    }
  }
  dataAgregate: opb_profit_report_aggregate(
    args: {start_date: $start_date, end_date: $end_date}
  ) {
    aggregate {
      sum {
        profit
      }
    }
  }
}
    `;

/**
 * __useProfitQuery__
 *
 * To run a query within a Vue component, call `useProfitQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfitQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useProfitQuery({
 *   start_date: // value for 'start_date'
 *   end_date: // value for 'end_date'
 * });
 */
export function useProfitQuery(variables: ProfitQueryVariables | VueCompositionApi.Ref<ProfitQueryVariables> | ReactiveFunction<ProfitQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<ProfitQuery, ProfitQueryVariables>(ProfitDocument, variables, options);
}
export function useProfitLazyQuery(variables: ProfitQueryVariables | VueCompositionApi.Ref<ProfitQueryVariables> | ReactiveFunction<ProfitQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<ProfitQuery, ProfitQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<ProfitQuery, ProfitQueryVariables>(ProfitDocument, variables, options);
}
export type ProfitQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<ProfitQuery, ProfitQueryVariables>;
export const IpvDocument = gql`
    query Ipv($start_date: date, $end_date: date) {
  data: opb_ipv_report(where: {date: {_gte: $start_date, _lte: $end_date}}) {
    product_name
    unit_of_measure
    location_name
    start_quantity
    start_value
    in_quantity
    out_quantity
    end_quantity
    end_value
    date
    product {
      id
      purchasable
      manufacturable
      sellable
      storable
    }
  }
}
    `;

/**
 * __useIpvQuery__
 *
 * To run a query within a Vue component, call `useIpvQuery` and pass it any options that fit your needs.
 * When your component renders, `useIpvQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useIpvQuery({
 *   start_date: // value for 'start_date'
 *   end_date: // value for 'end_date'
 * });
 */
export function useIpvQuery(variables: IpvQueryVariables | VueCompositionApi.Ref<IpvQueryVariables> | ReactiveFunction<IpvQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<IpvQuery, IpvQueryVariables>(IpvDocument, variables, options);
}
export function useIpvLazyQuery(variables: IpvQueryVariables | VueCompositionApi.Ref<IpvQueryVariables> | ReactiveFunction<IpvQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<IpvQuery, IpvQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<IpvQuery, IpvQueryVariables>(IpvDocument, variables, options);
}
export type IpvQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<IpvQuery, IpvQueryVariables>;
export const RecalculateIpvDocument = gql`
    query RecalculateIpv {
  opb_execute_daily_report {
    id
  }
}
    `;

/**
 * __useRecalculateIpvQuery__
 *
 * To run a query within a Vue component, call `useRecalculateIpvQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecalculateIpvQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useRecalculateIpvQuery();
 */
export function useRecalculateIpvQuery(options: VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<RecalculateIpvQuery, RecalculateIpvQueryVariables>(RecalculateIpvDocument, {}, options);
}
export function useRecalculateIpvLazyQuery(options: VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<RecalculateIpvQuery, RecalculateIpvQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<RecalculateIpvQuery, RecalculateIpvQueryVariables>(RecalculateIpvDocument, {}, options);
}
export type RecalculateIpvQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<RecalculateIpvQuery, RecalculateIpvQueryVariables>;
export const SalesDocument = gql`
    query Sales($limit: Int, $offset: Int, $order_by: [stock_order_order_by!] = {order_date: desc}, $like: String) {
  stock_order(
    where: {type: {_eq: SO}, _or: [{sales_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ISalesOrder
  }
  stock_order_aggregate(
    where: {type: {_eq: SO}, _or: [{sales_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${ISalesOrderFragmentDoc}`;

/**
 * __useSalesQuery__
 *
 * To run a query within a Vue component, call `useSalesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   like: // value for 'like'
 * });
 */
export function useSalesQuery(variables: SalesQueryVariables | VueCompositionApi.Ref<SalesQueryVariables> | ReactiveFunction<SalesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesQuery, SalesQueryVariables>(SalesDocument, variables, options);
}
export function useSalesLazyQuery(variables: SalesQueryVariables | VueCompositionApi.Ref<SalesQueryVariables> | ReactiveFunction<SalesQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesQuery, SalesQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SalesQuery, SalesQueryVariables>(SalesDocument, variables, options);
}
export type SalesQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesQuery, SalesQueryVariables>;
export const SalesFormDataDocument = gql`
    query SalesFormData {
  product(where: {sellable: {_eq: true}}) {
    ...IProductSearch
  }
  stock_operation(where: {order_type: {_eq: SO}}) {
    id
    name
  }
  stock_partner {
    id
    name
  }
}
    ${IProductSearchFragmentDoc}`;

/**
 * __useSalesFormDataQuery__
 *
 * To run a query within a Vue component, call `useSalesFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesFormDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useSalesFormDataQuery();
 */
export function useSalesFormDataQuery(options: VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<SalesFormDataQuery, SalesFormDataQueryVariables>(SalesFormDataDocument, {}, options);
}
export function useSalesFormDataLazyQuery(options: VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<SalesFormDataQuery, SalesFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<SalesFormDataQuery, SalesFormDataQueryVariables>(SalesFormDataDocument, {}, options);
}
export type SalesFormDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<SalesFormDataQuery, SalesFormDataQueryVariables>;
export const InsertSalesOrderDocument = gql`
    mutation InsertSalesOrder($data: [stock_sales_order_item_insert_input!]!, $note: String) {
  insert_stock_order_one(
    object: {type: SO, sales_order_items: {data: $data}, note: $note}
  ) {
    ...ISalesOrder
  }
}
    ${ISalesOrderFragmentDoc}`;

/**
 * __useInsertSalesOrderMutation__
 *
 * To run a mutation, you first call `useInsertSalesOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertSalesOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertSalesOrderMutation({
 *   variables: {
 *     data: // value for 'data'
 *     note: // value for 'note'
 *   },
 * });
 */
export function useInsertSalesOrderMutation(options: VueApolloComposable.UseMutationOptions<InsertSalesOrderMutation, InsertSalesOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertSalesOrderMutation, InsertSalesOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertSalesOrderMutation, InsertSalesOrderMutationVariables>(InsertSalesOrderDocument, options);
}
export type InsertSalesOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertSalesOrderMutation, InsertSalesOrderMutationVariables>;
export const InsertSaleItemDocument = gql`
    mutation InsertSaleItem($operation_id: Int!, $product_id: Int, $product_uom: Int!, $src_location: Int, $quantity: numeric!, $price: numeric, $dest_location: Int, $order_id: bigint!, $partner_customer: Int) {
  insert_stock_sales_order_item_one(
    object: {dest_location: $dest_location, src_location: $src_location, partner_customer: $partner_customer, quantity: $quantity, price: $price, product_uom: $product_uom, operation_id: $operation_id, product_id: $product_id, order_id: $order_id}
  ) {
    ...ISalesItem
  }
}
    ${ISalesItemFragmentDoc}`;

/**
 * __useInsertSaleItemMutation__
 *
 * To run a mutation, you first call `useInsertSaleItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertSaleItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertSaleItemMutation({
 *   variables: {
 *     operation_id: // value for 'operation_id'
 *     product_id: // value for 'product_id'
 *     product_uom: // value for 'product_uom'
 *     src_location: // value for 'src_location'
 *     quantity: // value for 'quantity'
 *     price: // value for 'price'
 *     dest_location: // value for 'dest_location'
 *     order_id: // value for 'order_id'
 *     partner_customer: // value for 'partner_customer'
 *   },
 * });
 */
export function useInsertSaleItemMutation(options: VueApolloComposable.UseMutationOptions<InsertSaleItemMutation, InsertSaleItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertSaleItemMutation, InsertSaleItemMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertSaleItemMutation, InsertSaleItemMutationVariables>(InsertSaleItemDocument, options);
}
export type InsertSaleItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertSaleItemMutation, InsertSaleItemMutationVariables>;
export const UpdateSaleItemDocument = gql`
    mutation UpdateSaleItem($id: bigint!, $operation_id: Int!, $product_uom: Int!, $price: numeric!, $quantity: numeric!, $src_location: Int, $dest_location: Int, $partner_customer: Int) {
  update_stock_sales_order_item_by_pk(
    pk_columns: {id: $id}
    _set: {dest_location: $dest_location, operation_id: $operation_id, price: $price, product_uom: $product_uom, quantity: $quantity, src_location: $src_location, partner_customer: $partner_customer}
  ) {
    ...ISalesItem
  }
}
    ${ISalesItemFragmentDoc}`;

/**
 * __useUpdateSaleItemMutation__
 *
 * To run a mutation, you first call `useUpdateSaleItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSaleItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateSaleItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     operation_id: // value for 'operation_id'
 *     product_uom: // value for 'product_uom'
 *     price: // value for 'price'
 *     quantity: // value for 'quantity'
 *     src_location: // value for 'src_location'
 *     dest_location: // value for 'dest_location'
 *     partner_customer: // value for 'partner_customer'
 *   },
 * });
 */
export function useUpdateSaleItemMutation(options: VueApolloComposable.UseMutationOptions<UpdateSaleItemMutation, UpdateSaleItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateSaleItemMutation, UpdateSaleItemMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateSaleItemMutation, UpdateSaleItemMutationVariables>(UpdateSaleItemDocument, options);
}
export type UpdateSaleItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateSaleItemMutation, UpdateSaleItemMutationVariables>;
export const DeleteSalesOrderDocument = gql`
    mutation DeleteSalesOrder($id: bigint!) {
  delete_stock_order_by_pk(id: $id) {
    ...ISalesOrder
  }
}
    ${ISalesOrderFragmentDoc}`;

/**
 * __useDeleteSalesOrderMutation__
 *
 * To run a mutation, you first call `useDeleteSalesOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSalesOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSalesOrderMutation(options: VueApolloComposable.UseMutationOptions<DeleteSalesOrderMutation, DeleteSalesOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSalesOrderMutation, DeleteSalesOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteSalesOrderMutation, DeleteSalesOrderMutationVariables>(DeleteSalesOrderDocument, options);
}
export type DeleteSalesOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSalesOrderMutation, DeleteSalesOrderMutationVariables>;
export const DeleteSalesOrderItemDocument = gql`
    mutation DeleteSalesOrderItem($id: bigint!) {
  delete_stock_sales_order_item_by_pk(id: $id) {
    ...ISalesItem
  }
}
    ${ISalesItemFragmentDoc}`;

/**
 * __useDeleteSalesOrderItemMutation__
 *
 * To run a mutation, you first call `useDeleteSalesOrderItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSalesOrderItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteSalesOrderItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSalesOrderItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteSalesOrderItemMutation, DeleteSalesOrderItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteSalesOrderItemMutation, DeleteSalesOrderItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteSalesOrderItemMutation, DeleteSalesOrderItemMutationVariables>(DeleteSalesOrderItemDocument, options);
}
export type DeleteSalesOrderItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteSalesOrderItemMutation, DeleteSalesOrderItemMutationVariables>;
export const ImportSalesOrdersDocument = gql`
    mutation ImportSalesOrders($imported_order: jsonb!) {
  insert_import_sales_order_one(object: {imported_order: $imported_order}) {
    id
  }
}
    `;

/**
 * __useImportSalesOrdersMutation__
 *
 * To run a mutation, you first call `useImportSalesOrdersMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useImportSalesOrdersMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useImportSalesOrdersMutation({
 *   variables: {
 *     imported_order: // value for 'imported_order'
 *   },
 * });
 */
export function useImportSalesOrdersMutation(options: VueApolloComposable.UseMutationOptions<ImportSalesOrdersMutation, ImportSalesOrdersMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ImportSalesOrdersMutation, ImportSalesOrdersMutationVariables>>) {
  return VueApolloComposable.useMutation<ImportSalesOrdersMutation, ImportSalesOrdersMutationVariables>(ImportSalesOrdersDocument, options);
}
export type ImportSalesOrdersMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ImportSalesOrdersMutation, ImportSalesOrdersMutationVariables>;
export const TransfersDocument = gql`
    query Transfers($limit: Int, $offset: Int, $order_by: [stock_order_order_by!] = {order_date: desc}, $like: String) {
  stock_order(
    where: {type: {_eq: TO}, _or: [{transfer_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
    limit: $limit
    offset: $offset
    order_by: $order_by
  ) {
    ...ITransferOrder
  }
  stock_order_aggregate(
    where: {type: {_eq: TO}, _or: [{transfer_order_items: {product: {name: {_ilike: $like}}}}, {code: {_ilike: $like}}]}
  ) {
    aggregate {
      count
    }
  }
}
    ${ITransferOrderFragmentDoc}`;

/**
 * __useTransfersQuery__
 *
 * To run a query within a Vue component, call `useTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransfersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTransfersQuery({
 *   limit: // value for 'limit'
 *   offset: // value for 'offset'
 *   order_by: // value for 'order_by'
 *   like: // value for 'like'
 * });
 */
export function useTransfersQuery(variables: TransfersQueryVariables | VueCompositionApi.Ref<TransfersQueryVariables> | ReactiveFunction<TransfersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, variables, options);
}
export function useTransfersLazyQuery(variables: TransfersQueryVariables | VueCompositionApi.Ref<TransfersQueryVariables> | ReactiveFunction<TransfersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TransfersQuery, TransfersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TransfersQuery, TransfersQueryVariables>(TransfersDocument, variables, options);
}
export type TransfersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TransfersQuery, TransfersQueryVariables>;
export const TransferFormDataDocument = gql`
    query TransferFormData {
  product(
    where: {_and: {storable: {_eq: true}, product_lot: {quantity: {_gt: 0}}}}
  ) {
    product_lot {
      product_id
      location_id
      location_name
      lot_id
      lot_code
      quantity
    }
    ...IProductSearch
  }
  stock_location {
    id
    name
  }
}
    ${IProductSearchFragmentDoc}`;

/**
 * __useTransferFormDataQuery__
 *
 * To run a query within a Vue component, call `useTransferFormDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useTransferFormDataQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useTransferFormDataQuery();
 */
export function useTransferFormDataQuery(options: VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<TransferFormDataQuery, TransferFormDataQueryVariables>(TransferFormDataDocument, {}, options);
}
export function useTransferFormDataLazyQuery(options: VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<TransferFormDataQuery, TransferFormDataQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<TransferFormDataQuery, TransferFormDataQueryVariables>(TransferFormDataDocument, {}, options);
}
export type TransferFormDataQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<TransferFormDataQuery, TransferFormDataQueryVariables>;
export const InsertTransferOrderDocument = gql`
    mutation InsertTransferOrder($data: [stock_transfer_order_item_insert_input!]!, $note: String) {
  insert_stock_order_one(
    object: {type: TO, transfer_order_items: {data: $data}, note: $note}
  ) {
    ...ITransferOrder
  }
}
    ${ITransferOrderFragmentDoc}`;

/**
 * __useInsertTransferOrderMutation__
 *
 * To run a mutation, you first call `useInsertTransferOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertTransferOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertTransferOrderMutation({
 *   variables: {
 *     data: // value for 'data'
 *     note: // value for 'note'
 *   },
 * });
 */
export function useInsertTransferOrderMutation(options: VueApolloComposable.UseMutationOptions<InsertTransferOrderMutation, InsertTransferOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertTransferOrderMutation, InsertTransferOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertTransferOrderMutation, InsertTransferOrderMutationVariables>(InsertTransferOrderDocument, options);
}
export type InsertTransferOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertTransferOrderMutation, InsertTransferOrderMutationVariables>;
export const InsertTransferItemDocument = gql`
    mutation InsertTransferItem($order_id: bigint!, $product_id: Int!, $product_uom: Int!, $quantity: numeric!, $src_location: Int!, $dest_location: Int!, $lot_id: Int) {
  insert_stock_transfer_order_item_one(
    object: {order_id: $order_id, product_id: $product_id, product_uom: $product_uom, lot_id: $lot_id, quantity: $quantity, src_location: $src_location, dest_location: $dest_location}
  ) {
    ...ITransferItem
  }
}
    ${ITransferItemFragmentDoc}`;

/**
 * __useInsertTransferItemMutation__
 *
 * To run a mutation, you first call `useInsertTransferItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertTransferItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertTransferItemMutation({
 *   variables: {
 *     order_id: // value for 'order_id'
 *     product_id: // value for 'product_id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *     src_location: // value for 'src_location'
 *     dest_location: // value for 'dest_location'
 *     lot_id: // value for 'lot_id'
 *   },
 * });
 */
export function useInsertTransferItemMutation(options: VueApolloComposable.UseMutationOptions<InsertTransferItemMutation, InsertTransferItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertTransferItemMutation, InsertTransferItemMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertTransferItemMutation, InsertTransferItemMutationVariables>(InsertTransferItemDocument, options);
}
export type InsertTransferItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertTransferItemMutation, InsertTransferItemMutationVariables>;
export const UpdateTranferItemDocument = gql`
    mutation UpdateTranferItem($id: bigint!, $src_location: Int!, $dest_location: Int!, $lot_id: Int, $product_uom: Int!, $quantity: numeric!) {
  update_stock_transfer_order_item_by_pk(
    pk_columns: {id: $id}
    _set: {lot_id: $lot_id, product_uom: $product_uom, quantity: $quantity, src_location: $src_location, dest_location: $dest_location}
  ) {
    ...ITransferItem
  }
}
    ${ITransferItemFragmentDoc}`;

/**
 * __useUpdateTranferItemMutation__
 *
 * To run a mutation, you first call `useUpdateTranferItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTranferItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateTranferItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *     src_location: // value for 'src_location'
 *     dest_location: // value for 'dest_location'
 *     lot_id: // value for 'lot_id'
 *     product_uom: // value for 'product_uom'
 *     quantity: // value for 'quantity'
 *   },
 * });
 */
export function useUpdateTranferItemMutation(options: VueApolloComposable.UseMutationOptions<UpdateTranferItemMutation, UpdateTranferItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateTranferItemMutation, UpdateTranferItemMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateTranferItemMutation, UpdateTranferItemMutationVariables>(UpdateTranferItemDocument, options);
}
export type UpdateTranferItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateTranferItemMutation, UpdateTranferItemMutationVariables>;
export const DeleteTransferOrderDocument = gql`
    mutation DeleteTransferOrder($id: bigint!) {
  delete_stock_order_by_pk(id: $id) {
    ...ITransferOrder
  }
}
    ${ITransferOrderFragmentDoc}`;

/**
 * __useDeleteTransferOrderMutation__
 *
 * To run a mutation, you first call `useDeleteTransferOrderMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransferOrderMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTransferOrderMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransferOrderMutation(options: VueApolloComposable.UseMutationOptions<DeleteTransferOrderMutation, DeleteTransferOrderMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTransferOrderMutation, DeleteTransferOrderMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteTransferOrderMutation, DeleteTransferOrderMutationVariables>(DeleteTransferOrderDocument, options);
}
export type DeleteTransferOrderMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTransferOrderMutation, DeleteTransferOrderMutationVariables>;
export const DeleteTransferItemDocument = gql`
    mutation DeleteTransferItem($id: bigint!) {
  delete_stock_transfer_order_item_by_pk(id: $id) {
    ...ITransferItem
  }
}
    ${ITransferItemFragmentDoc}`;

/**
 * __useDeleteTransferItemMutation__
 *
 * To run a mutation, you first call `useDeleteTransferItemMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTransferItemMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteTransferItemMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTransferItemMutation(options: VueApolloComposable.UseMutationOptions<DeleteTransferItemMutation, DeleteTransferItemMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteTransferItemMutation, DeleteTransferItemMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteTransferItemMutation, DeleteTransferItemMutationVariables>(DeleteTransferItemDocument, options);
}
export type DeleteTransferItemMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteTransferItemMutation, DeleteTransferItemMutationVariables>;
export const UnitsDocument = gql`
    query Units {
  unit_of_measure {
    ...IUnit
  }
  unit_of_measure_category {
    ...IUOMCategory
  }
}
    ${IUnitFragmentDoc}
${IuomCategoryFragmentDoc}`;

/**
 * __useUnitsQuery__
 *
 * To run a query within a Vue component, call `useUnitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitsQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUnitsQuery();
 */
export function useUnitsQuery(options: VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, {}, options);
}
export function useUnitsLazyQuery(options: VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UnitsQuery, UnitsQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UnitsQuery, UnitsQueryVariables>(UnitsDocument, {}, options);
}
export type UnitsQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UnitsQuery, UnitsQueryVariables>;
export const UnitDocument = gql`
    query Unit($id: Int!) {
  unit_of_measure_by_pk(id: $id) {
    ...IUnit
  }
}
    ${IUnitFragmentDoc}`;

/**
 * __useUnitQuery__
 *
 * To run a query within a Vue component, call `useUnitQuery` and pass it any options that fit your needs.
 * When your component renders, `useUnitQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUnitQuery({
 *   id: // value for 'id'
 * });
 */
export function useUnitQuery(variables: UnitQueryVariables | VueCompositionApi.Ref<UnitQueryVariables> | ReactiveFunction<UnitQueryVariables>, options: VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UnitQuery, UnitQueryVariables>(UnitDocument, variables, options);
}
export function useUnitLazyQuery(variables: UnitQueryVariables | VueCompositionApi.Ref<UnitQueryVariables> | ReactiveFunction<UnitQueryVariables>, options: VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UnitQuery, UnitQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UnitQuery, UnitQueryVariables>(UnitDocument, variables, options);
}
export type UnitQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UnitQuery, UnitQueryVariables>;
export const InsertUnitDocument = gql`
    mutation InsertUnit($code: String!, $description: String!, $category_id: Int!) {
  insert_unit_of_measure_one(
    object: {code: $code, description: $description, category_id: $category_id}
  ) {
    ...IUnit
  }
}
    ${IUnitFragmentDoc}`;

/**
 * __useInsertUnitMutation__
 *
 * To run a mutation, you first call `useInsertUnitMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertUnitMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertUnitMutation({
 *   variables: {
 *     code: // value for 'code'
 *     description: // value for 'description'
 *     category_id: // value for 'category_id'
 *   },
 * });
 */
export function useInsertUnitMutation(options: VueApolloComposable.UseMutationOptions<InsertUnitMutation, InsertUnitMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertUnitMutation, InsertUnitMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertUnitMutation, InsertUnitMutationVariables>(InsertUnitDocument, options);
}
export type InsertUnitMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertUnitMutation, InsertUnitMutationVariables>;
export const InsertConversionDocument = gql`
    mutation InsertConversion($from_um: Int!, $to_um: Int!, $value: numeric!) {
  insert_unit_of_measure_conversion_one(
    object: {from_um: $from_um, to_um: $to_um, value: $value}
    on_conflict: {update_columns: value, constraint: unit_of_measure_conversion_pkey}
  ) {
    from_um
    to_um
    value
  }
}
    `;

/**
 * __useInsertConversionMutation__
 *
 * To run a mutation, you first call `useInsertConversionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useInsertConversionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useInsertConversionMutation({
 *   variables: {
 *     from_um: // value for 'from_um'
 *     to_um: // value for 'to_um'
 *     value: // value for 'value'
 *   },
 * });
 */
export function useInsertConversionMutation(options: VueApolloComposable.UseMutationOptions<InsertConversionMutation, InsertConversionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<InsertConversionMutation, InsertConversionMutationVariables>>) {
  return VueApolloComposable.useMutation<InsertConversionMutation, InsertConversionMutationVariables>(InsertConversionDocument, options);
}
export type InsertConversionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<InsertConversionMutation, InsertConversionMutationVariables>;
export const UpdateUnitDocument = gql`
    mutation UpdateUnit($id: Int!, $code: String!, $description: String!, $category_id: Int!) {
  update_unit_of_measure_by_pk(
    pk_columns: {id: $id}
    _set: {code: $code, description: $description, category_id: $category_id}
  ) {
    ...IUnit
  }
}
    ${IUnitFragmentDoc}`;

/**
 * __useUpdateUnitMutation__
 *
 * To run a mutation, you first call `useUpdateUnitMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUnitMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useUpdateUnitMutation({
 *   variables: {
 *     id: // value for 'id'
 *     code: // value for 'code'
 *     description: // value for 'description'
 *     category_id: // value for 'category_id'
 *   },
 * });
 */
export function useUpdateUnitMutation(options: VueApolloComposable.UseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<UpdateUnitMutation, UpdateUnitMutationVariables>>) {
  return VueApolloComposable.useMutation<UpdateUnitMutation, UpdateUnitMutationVariables>(UpdateUnitDocument, options);
}
export type UpdateUnitMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<UpdateUnitMutation, UpdateUnitMutationVariables>;
export const DeleteUnitDocument = gql`
    mutation DeleteUnit($id: Int!) {
  delete_unit_of_measure_by_pk(id: $id) {
    ...IUnit
  }
}
    ${IUnitFragmentDoc}`;

/**
 * __useDeleteUnitMutation__
 *
 * To run a mutation, you first call `useDeleteUnitMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUnitMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteUnitMutation({
 *   variables: {
 *     id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUnitMutation(options: VueApolloComposable.UseMutationOptions<DeleteUnitMutation, DeleteUnitMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteUnitMutation, DeleteUnitMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteUnitMutation, DeleteUnitMutationVariables>(DeleteUnitDocument, options);
}
export type DeleteUnitMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteUnitMutation, DeleteUnitMutationVariables>;
export const DeleteConversionDocument = gql`
    mutation DeleteConversion($from_um: Int!, $to_um: Int!) {
  delete_unit_of_measure_conversion_by_pk(from_um: $from_um, to_um: $to_um) {
    from_um
    to_um
    value
  }
}
    `;

/**
 * __useDeleteConversionMutation__
 *
 * To run a mutation, you first call `useDeleteConversionMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useDeleteConversionMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useDeleteConversionMutation({
 *   variables: {
 *     from_um: // value for 'from_um'
 *     to_um: // value for 'to_um'
 *   },
 * });
 */
export function useDeleteConversionMutation(options: VueApolloComposable.UseMutationOptions<DeleteConversionMutation, DeleteConversionMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<DeleteConversionMutation, DeleteConversionMutationVariables>>) {
  return VueApolloComposable.useMutation<DeleteConversionMutation, DeleteConversionMutationVariables>(DeleteConversionDocument, options);
}
export type DeleteConversionMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<DeleteConversionMutation, DeleteConversionMutationVariables>;
export const UsersDocument = gql`
    query Users($active: Boolean) {
  users(where: {active: {_eq: $active}}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useUsersQuery__
 *
 * To run a query within a Vue component, call `useUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsersQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useUsersQuery({
 *   active: // value for 'active'
 * });
 */
export function useUsersQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export function useUsersLazyQuery(variables: UsersQueryVariables | VueCompositionApi.Ref<UsersQueryVariables> | ReactiveFunction<UsersQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<UsersQuery, UsersQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<UsersQuery, UsersQueryVariables>(UsersDocument, variables, options);
}
export type UsersQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<UsersQuery, UsersQueryVariables>;
export const FindUserByEmailDocument = gql`
    query FindUserByEmail($email: citext) {
  users(where: {account: {email: {_eq: $email}}}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useFindUserByEmailQuery__
 *
 * To run a query within a Vue component, call `useFindUserByEmailQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUserByEmailQuery` returns an object from Apollo Client that contains result, loading and error properties
 * you can use to render your UI.
 *
 * @param variables that will be passed into the query
 * @param options that will be passed into the query, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/query.html#options;
 *
 * @example
 * const { result, loading, error } = useFindUserByEmailQuery({
 *   email: // value for 'email'
 * });
 */
export function useFindUserByEmailQuery(variables: FindUserByEmailQueryVariables | VueCompositionApi.Ref<FindUserByEmailQueryVariables> | ReactiveFunction<FindUserByEmailQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>> = {}) {
  return VueApolloComposable.useQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(FindUserByEmailDocument, variables, options);
}
export function useFindUserByEmailLazyQuery(variables: FindUserByEmailQueryVariables | VueCompositionApi.Ref<FindUserByEmailQueryVariables> | ReactiveFunction<FindUserByEmailQueryVariables> = {}, options: VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables> | VueCompositionApi.Ref<VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>> | ReactiveFunction<VueApolloComposable.UseQueryOptions<FindUserByEmailQuery, FindUserByEmailQueryVariables>> = {}) {
  return VueApolloComposable.useLazyQuery<FindUserByEmailQuery, FindUserByEmailQueryVariables>(FindUserByEmailDocument, variables, options);
}
export type FindUserByEmailQueryCompositionFunctionResult = VueApolloComposable.UseQueryReturn<FindUserByEmailQuery, FindUserByEmailQueryVariables>;
export const SetActiveStatusDocument = gql`
    mutation SetActiveStatus($id: uuid!, $active: Boolean!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {active: $active}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useSetActiveStatusMutation__
 *
 * To run a mutation, you first call `useSetActiveStatusMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useSetActiveStatusMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useSetActiveStatusMutation({
 *   variables: {
 *     id: // value for 'id'
 *     active: // value for 'active'
 *   },
 * });
 */
export function useSetActiveStatusMutation(options: VueApolloComposable.UseMutationOptions<SetActiveStatusMutation, SetActiveStatusMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<SetActiveStatusMutation, SetActiveStatusMutationVariables>>) {
  return VueApolloComposable.useMutation<SetActiveStatusMutation, SetActiveStatusMutationVariables>(SetActiveStatusDocument, options);
}
export type SetActiveStatusMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<SetActiveStatusMutation, SetActiveStatusMutationVariables>;
export const ChangeUserNameDocument = gql`
    mutation ChangeUserName($id: uuid!, $display_name: String!) {
  update_users_by_pk(pk_columns: {id: $id}, _set: {display_name: $display_name}) {
    ...User
  }
}
    ${UserFragmentDoc}`;

/**
 * __useChangeUserNameMutation__
 *
 * To run a mutation, you first call `useChangeUserNameMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useChangeUserNameMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useChangeUserNameMutation({
 *   variables: {
 *     id: // value for 'id'
 *     display_name: // value for 'display_name'
 *   },
 * });
 */
export function useChangeUserNameMutation(options: VueApolloComposable.UseMutationOptions<ChangeUserNameMutation, ChangeUserNameMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<ChangeUserNameMutation, ChangeUserNameMutationVariables>>) {
  return VueApolloComposable.useMutation<ChangeUserNameMutation, ChangeUserNameMutationVariables>(ChangeUserNameDocument, options);
}
export type ChangeUserNameMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<ChangeUserNameMutation, ChangeUserNameMutationVariables>;
export const EditPermissionsDocument = gql`
    mutation EditPermissions($objects: [user_permission_insert_input!]!) {
  insert_user_permission(
    objects: $objects
    on_conflict: {constraint: user_permission_pkey, update_columns: [can_read, can_write, can_delete]}
  ) {
    affected_rows
  }
}
    `;

/**
 * __useEditPermissionsMutation__
 *
 * To run a mutation, you first call `useEditPermissionsMutation` within a Vue component and pass it any options that fit your needs.
 * When your component renders, `useEditPermissionsMutation` returns an object that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - Several other properties: https://v4.apollo.vuejs.org/api/use-mutation.html#return
 *
 * @param options that will be passed into the mutation, supported options are listed on: https://v4.apollo.vuejs.org/guide-composable/mutation.html#options;
 *
 * @example
 * const { mutate, loading, error, onDone } = useEditPermissionsMutation({
 *   variables: {
 *     objects: // value for 'objects'
 *   },
 * });
 */
export function useEditPermissionsMutation(options: VueApolloComposable.UseMutationOptions<EditPermissionsMutation, EditPermissionsMutationVariables> | ReactiveFunction<VueApolloComposable.UseMutationOptions<EditPermissionsMutation, EditPermissionsMutationVariables>>) {
  return VueApolloComposable.useMutation<EditPermissionsMutation, EditPermissionsMutationVariables>(EditPermissionsDocument, options);
}
export type EditPermissionsMutationCompositionFunctionResult = VueApolloComposable.UseMutationReturn<EditPermissionsMutation, EditPermissionsMutationVariables>;