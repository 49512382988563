import { createApp } from "vue";
import App from "./App.vue";
// import "./registerServiceWorker";
import router from "./router";

import { primeVueSetup } from "@/plugins/primevue";
import auth from "@/plugins/auth";
import message from "@/plugins/message";
import { i18n } from "./plugins/i18n";

createApp(App)
  .use(auth)
  .use(i18n)
  .use(router)
  .use(message)
  .use(primeVueSetup)
  .mount("#app");
