import { ref, watch } from "vue";

export function useStoredRef<T>(value: T, key: string) {
  const innerRef = ref(value);
  const cache = localStorage.getItem(key);

  if (cache) {
    innerRef.value = JSON.parse(cache);
  }

  watch(
    innerRef,
    (value) => {
      localStorage.setItem(key, JSON.stringify(value));
    },
    { deep: true }
  );

  return innerRef;
}
