
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import {
  IProductMaterialsFragment,
  useProductMaterialsQuery,
  useInsertProductMaterialMutation,
  useUpdateProductMaterialMutation,
  useDeleteProductMaterialMutation,
  IProductSearchFragment,
  useSearchProductQuery,
} from "@/graphql";
import ShowConfirmation, {
  IShowConfirmation,
} from "@/components/common/ShowConfirmation.vue";
import { msg } from "@/plugins/message";
import { accessors } from "@/router";

interface NewProduct {
  product: IProductSearchFragment;
  bill_of_material_id: number;
  product_id: number;
  quantity: number;
  product_uom: number;
}

export type IMaterialList = {
  showForm(): Promise<boolean>;
};

const MConf = {
  refetchQueries: ["ProductMaterials", "Products"],
};

export default defineComponent({
  name: "MaterialList",
  components: { ShowConfirmation },
  props: {
    productId: {
      type: Number,
      required: true,
    },
    name: String,
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const editingRows = ref([]);
    const submitted = ref(false);
    const newItem = ref<NewProduct>({} as NewProduct);
    const materialBuf = ref<IProductMaterialsFragment[]>([]);
    const deleteDialog = ref<IShowConfirmation | null>(null);

    //<!-- Product Materials Query -->
    const {
      result: productResult,
      refetch: productRefetch,
      loading,
    } = useProductMaterialsQuery(
      { _eq: props.productId || -1 },
      { notifyOnNetworkStatusChange: true }
    );

    watch(
      () => props.productId,
      () => {
        productRefetch({ _eq: props.productId });
      }
    );

    const materials = computed(() => {
      const _materials =
        productResult.value?.product_bill_of_material_items || [];

      // eslint-disable-next-line
      materialBuf.value = JSON.parse(JSON.stringify(_materials));
      return _materials;
    });

    //<!-- Product List Query -->

    const { result: productListResult, refetch: productListRefetch } =
      useSearchProductQuery({ _ilike: null });

    const productList = computed(() => {
      const usedProducts = materials.value?.map((item) => item.product.id);
      usedProducts?.push(props.productId);
      return productListResult.value?.product.filter(
        (item) => !usedProducts?.includes(item.id)
      );
    });

    //<!-- Mutations -->

    const { mutate: insertMaterial } = useInsertProductMaterialMutation(MConf);

    const { mutate: updateMaterial } = useUpdateProductMaterialMutation(MConf);

    const { mutate: deleteMaterial } = useDeleteProductMaterialMutation(MConf);

    //<!-- Handlers -->

    function showForm() {
      open.value = true;
      productRefetch();
    }

    function hideForm() {
      open.value = false;
      clearInsert();
    }

    async function searchProduct(query: string) {
      if (query) {
        await productListRefetch({ _ilike: `%${query}%` });
      } else {
        await productListRefetch({ _ilike: null });
      }
    }

    function clearInsert() {
      newItem.value = {} as NewProduct;
    }

    async function handleInsert() {
      const item = JSON.parse(JSON.stringify(newItem.value));
      item.product_id = item.product.id;
      item.bill_of_material_id = props.productId;
      delete item.product;
      await insertMaterial(item);
      msg.showInsertSuccess();
      newItem.value = {} as NewProduct;
    }

    async function handleUpdate({
      newData: data,
    }: {
      newData: IProductMaterialsFragment;
    }) {
      const _data = JSON.parse(JSON.stringify(data));
      delete _data.product_id;
      delete _data.__typename;
      delete _data.product;
      await updateMaterial(_data);
      msg.showUpdateSuccess();
    }

    async function handleDelete({ id }: { id: number }) {
      const isOk = await deleteDialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!isOk) {
        return;
      }

      await deleteMaterial({ id });
    }

    function handleEditCancel(event: { index: number }) {
      materialBuf.value[event.index] = materials.value[event.index];
    }

    return {
      t,
      open,
      showForm,
      hideForm,
      newItem,
      loading,
      materialBuf,
      productList,
      deleteDialog,
      editingRows,
      submitted,
      searchProduct,
      clearInsert,
      handleInsert,
      handleUpdate,
      handleDelete,
      handleEditCancel,
      accessors,
    };
  },
});
