import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_ctx.view)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 0,
          icon: "pi pi-list",
          class: "p-button-rounded mr-2 p-button-text p-button-sm",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('view')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('view'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.edit && _ctx.accessors.route?.canWrite)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 1,
          icon: "pi pi-pencil",
          class: "p-button-rounded mr-2 p-button-text p-button-sm",
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('edit')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('edit'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.run && _ctx.accessors.route?.canDelete)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 2,
          icon: "pi pi-forward",
          class: "p-button-rounded mr-2 p-button-text product-badge status-approved p-button-sm",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('run')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('execute'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.rollback && _ctx.accessors.route?.canWrite)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 3,
          icon: "pi pi-replay",
          class: "p-button-rounded mr-2 p-button-text product-badge status-in_progress p-button-sm",
          onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('rollback')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('rollback'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.$props.delete && _ctx.accessors.route?.canDelete)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 4,
          icon: "pi pi-trash",
          class: "p-button-rounded p-button-text p-button-danger p-button-sm",
          onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('delete')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('delete'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.error)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 5,
          icon: "pi pi-comments",
          class: "p-button-rounded p-button-text p-button-danger mr-2 p-button-sm",
          onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.$emit('error')))
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.t('errors'),
            void 0,
            { top: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.note)
      ? _withDirectives((_openBlock(), _createBlock(_component_Button, {
          key: 6,
          icon: "pi pi-book",
          class: "p-button-rounded mr-2 p-button-text p-button-sm"
        }, null, 512)), [
          [
            _directive_tooltip,
            _ctx.note,
            void 0,
            { left: true }
          ]
        ])
      : _createCommentVNode("", true),
    (_ctx.config)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 7,
          icon: "pi pi-cog",
          class: "p-button-rounded mr-2 p-button-text p-button-sm",
          onClick: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('config')))
        }))
      : _createCommentVNode("", true)
  ], 64))
}