
import {
  IUnitFragment,
  IuomCategoryFragment,
  useInsertUnitMutation,
  useUpdateUnitMutation,
} from "@/graphql";
import { msg } from "@/plugins/message";
import { defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";

type FormMode = "insert" | "update";

export type IUnitsForm = {
  showForm(mode: FormMode, item?: IUnitFragment): void;
};

export default defineComponent({
  name: "UnitsForm",
  props: {
    categories: {
      type: Array as PropType<IuomCategoryFragment[]>,
      default: () => [],
    },
  },
  setup() {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("update");
    const submitted = ref(false);
    const item = ref({} as IUnitFragment);

    //<!-- Mutations -->
    const { mutate: insert } = useInsertUnitMutation({
      refetchQueries: ["Units"],
    });
    const { mutate: update } = useUpdateUnitMutation({});

    //<!-- Handlers -->
    function showForm(_mode: FormMode, _item?: IUnitFragment) {
      item.value = _item
        ? JSON.parse(JSON.stringify(_item))
        : ({} as IUnitFragment);
      mode.value = _mode;
      open.value = true;
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
    }

    async function insertUnit() {
      await insert(item.value);
    }

    async function updateUnit() {
      const _item = JSON.parse(JSON.stringify(item.value));
      delete _item.category;
      delete _item.conversions;
      delete _item.__typename;
      await update(_item);
    }

    function validateForm() {
      const i = item.value;

      if (i.code && i.category_id && i.description) {
        return true;
      }
      return false;
    }

    async function saveItem() {
      submitted.value = true;

      if (!validateForm()) {
        return;
      }

      try {
        if (mode.value === "insert") {
          await insertUnit();
          msg.showInsertSuccess();
        }

        if (mode.value === "update") {
          await updateUnit();
          msg.showUpdateSuccess();
        }
      } catch (_) {
        return;
      }

      hideForm();
    }

    return {
      t,
      open,
      mode,
      submitted,
      hideForm,
      item,
      showForm,
      saveItem,
    };
  },
});
