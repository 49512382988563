
import {
  IProductSearchFragment,
  ISalesItemFragment,
  ISalesOrderFragment,
  useDeleteSalesOrderItemMutation,
  useInsertSaleItemMutation,
  useInsertSalesOrderMutation,
  useSalesFormDataQuery,
  useUpdateOrderNoteMutation,
  useUpdateSaleItemMutation,
} from "@/graphql";
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useI18n } from "vue-i18n";
import ShowConfirmation, {
  IShowConfirmation,
} from "../common/ShowConfirmation.vue";
import FooterActions from "@/components/orders/FooterActions.vue";
import { msg } from "@/plugins/message";
import OrderNote from "@/components/orders/OrderNote.vue";

type FormMode = "" | "insert" | "update" | "select";

type OrderItem = {
  -readonly [K in keyof ISalesItemFragment]: ISalesItemFragment[K];
};

export type ISalesForm = {
  showForm(mode: FormMode): void;
};
export default defineComponent({
  name: "SalesForm",
  components: {
    ShowConfirmation,
    FooterActions,
    OrderNote,
  },
  props: {
    order: Object as PropType<ISalesOrderFragment>,
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const editingRows = ref<any[]>([]);
    const submitted = ref(false);
    const dialog = ref<IShowConfirmation | null>(null);
    const note = ref("");

    const items = ref<OrderItem[]>([]);
    const newItem = ref<OrderItem>({} as OrderItem);

    watch(
      () => props.order,
      () => {
        note.value = props.order?.note || "";
        items.value = JSON.parse(
          JSON.stringify(props.order?.sales_order_items || [])
        );
      }
    );

    //<!-- Form Data Query -->
    const { result: formData, refetch } = useSalesFormDataQuery();

    const operations = computed(() => formData.value?.stock_operation || []);
    const partners = computed(() => formData.value?.stock_partner || []);
    const productList = computed(() => formData.value?.product || []);

    onMounted(refetch);

    //<!-- Mutations -->
    const { mutate: insertOrder } = useInsertSalesOrderMutation({
      refetchQueries: ["Sales"],
    });

    const { mutate: insertItem } = useInsertSaleItemMutation({
      refetchQueries: ["Sales"],
    });

    const { mutate: updateItem } = useUpdateSaleItemMutation({
      refetchQueries: ["Sales"],
    });

    const { mutate: deleteItem } = useDeleteSalesOrderItemMutation({
      refetchQueries: ["Sales"],
    });

    const { mutate: updateNote } = useUpdateOrderNoteMutation({});

    //<!-- Handlers -->

    function showForm(_mode: FormMode) {
      open.value = true;
      mode.value = _mode;
    }

    async function hideForm(isOk?: boolean) {
      if (mode.value === "insert" && items.value.length) {
        if (isOk) {
          await handleSave();
        }
        items.value = [];
        newItem.value = {} as OrderItem;
      } else if (mode.value === "update") {
        if (note.value !== props.order?.note) {
          await updateNote({ id: props.order?.id, note: note.value });
        }
      }

      submitted.value = false;
      open.value = false;
      clearInsert();
    }

    async function handleSave() {
      if (mode.value === "insert") {
        const _items = JSON.parse(JSON.stringify(items.value));
        await insertOrder({
          note: note.value,
          data: _items.map((i: any) => {
            delete i.product;
            return i;
          }),
        });
      }
    }

    function validateInserForm() {
      const item = newItem.value;

      const { product, operation_id, quantity, price, product_uom } = item;

      if (product && operation_id && quantity && price >= 0 && product_uom) {
        return true;
      }
    }

    async function handleInsert() {
      submitted.value = true;

      if (!validateInserForm()) {
        return;
      }

      submitted.value = false;

      const item = newItem.value;

      item.product_id = item.product?.id || -1;

      if (mode.value === "insert") {
        items.value.push(item);
      }

      if (mode.value === "update") {
        const _item = JSON.parse(JSON.stringify(item));
        delete _item.product;
        await insertItem({ order_id: props.order?.id, ..._item });
      }

      newItem.value = {} as OrderItem;
    }

    function handleUpdate({ newData: data }: { newData: OrderItem }) {
      if (!(data.price >= 0) || !data.quantity) {
        editingRows.value.push(data);
        return;
      }

      if (mode.value === "update") {
        const _item = JSON.parse(JSON.stringify(data));
        delete _item.product;
        delete _item.status;
        delete _item.missing_amount;
        delete _item.product_id;
        delete _item.__typename;
        updateItem(_item)
          .then(() => msg.showUpdateSuccess())
          .catch();
      }
    }

    async function handleDelete({ id }: OrderItem) {
      const isOk = await dialog.value?.showConfirmation({
        message: t("msg_delete_object_confirmation"),
      });

      if (!isOk) {
        return;
      }

      if (mode.value === "update") {
        await deleteItem({ id });
      } else {
        const index = items.value.findIndex((i) => i.id === id);
        items.value.splice(index, 1);
      }
    }

    function handleEditCancel(event: { index: number }) {
      if (props.order) {
        items.value[event.index] = props.order.sales_order_items[
          event.index
        ] as OrderItem;
      }
    }

    function clearInsert() {
      newItem.value = {} as OrderItem;
      submitted.value = false;
    }

    function onProductSelect({ value: p }: { value: IProductSearchFragment }) {
      newItem.value = { product: newItem.value.product } as any;
      newItem.value.product_uom = p?.unit_of_measure.id;
      newItem.value.price = p.price;
      newItem.value.quantity = 1;
      if (operations.value.length === 1) {
        newItem.value.operation_id = operations.value[0].id;
      }
    }

    return {
      t,
      open,
      mode,
      note,
      editingRows,
      submitted,
      hideForm,
      handleSave,
      productList,
      partners,
      operations,
      items,
      newItem,
      showForm,
      clearInsert,
      dialog,
      handleInsert,
      handleUpdate,
      handleDelete,
      handleEditCancel,
      onProductSelect,
    };
  },
});
