
import { useSearchProductQuery } from "@/graphql";
import { computed, defineComponent, PropType, ref, watch } from "vue";
import { useI18n } from "vue-i18n";

interface MaterialItem {
  product_id: number;
  product_name: string;
  product_uom: number;
  code: string;
  quantity: number;
  quantity_on_product_uom: null;
  product?: {
    id: number;
    name: string;
    related_uom: {
      id: number;
      code: string;
    }[];
  };
}

type FormMode = "select" | "update";

export type IManufactoryProductList = {
  showForm(mode: FormMode): Promise<boolean>;
};

export default defineComponent({
  name: "ManufactoryProductList",
  props: {
    pid: {
      type: Number,
      required: true,
    },
    materialItems: {
      type: Array as PropType<MaterialItem[]>,
      default: () => [],
    },
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const editingRows = ref<any>([]);
    const submitted = ref(false);
    const newItem = ref<MaterialItem>({} as MaterialItem);
    const itemsBack = ref<MaterialItem[]>([]);
    const mode = ref<FormMode>("select");

    watch(
      () => props.materialItems,
      () => {
        itemsBack.value = JSON.parse(JSON.stringify(props.materialItems));
      }
    );

    //<!-- Product List Query -->
    const { result: productListResult } = useSearchProductQuery({
      _ilike: null,
    });

    const productList = computed(() => {
      const usedProducts = props.materialItems.map((item) => item.product_id);
      usedProducts?.push(props.pid);
      return productListResult.value?.product.filter(
        (item) => !usedProducts?.includes(item.id)
      );
    });

    //<!-- Handlers -->
    function showForm(_mode: FormMode) {
      mode.value = _mode;
      open.value = true;
    }

    function hideForm() {
      open.value = false;
      clearInsert();
    }

    function clearInsert() {
      newItem.value = {} as MaterialItem;
    }

    function handleEditCancel(event: { index: number }) {
      // eslint-disable-next-line
      props.materialItems[event.index] = itemsBack.value[event.index];
    }

    function validateInserForm() {
      const item = newItem.value;

      const { product, quantity, product_uom } = item;

      if (product && quantity && product_uom) {
        return true;
      }
    }

    function handleInsert() {
      submitted.value = true;
      if (!validateInserForm()) {
        return;
      }

      submitted.value = false;

      const item = newItem.value;

      //Set missing item properties
      item.product_id = item.product?.id || NaN;
      item.product_name = item.product?.name || "";
      item.code =
        item.product?.related_uom.find((i) => i.id === item.product_uom)
          ?.code || "";
      delete item.product;
      item.quantity_on_product_uom = null;
      //Done

      // eslint-disable-next-line
      props.materialItems.push(item);
      itemsBack.value.push(item);
      newItem.value = {} as MaterialItem;
    }

    function handleUpdate({ newData: data }: { newData: MaterialItem }) {
      if (!data.quantity) {
        editingRows.value.push(data);
        return;
      }

      const index = itemsBack.value.findIndex(
        (i) => i.product_id === data.product_id
      );
      itemsBack.value[index] = data;
    }

    function handleDelete({ product_id }: MaterialItem) {
      const index = props.materialItems.findIndex(
        (i) => i.product_id === product_id
      );
      // eslint-disable-next-line
      props.materialItems.splice(index, 1);
    }

    return {
      t,
      open,
      showForm,
      mode,
      hideForm,
      newItem,
      productList,
      handleUpdate,
      editingRows,
      submitted,
      handleInsert,
      clearInsert,
      handleDelete,
      handleEditCancel,
    };
  },
});
