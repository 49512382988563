import { App } from "vue";
import { TypeToast } from "@/plugins/primevue";

export const msg = {
  // 001 - 049 LOGIN
  // 050 - 099 PRODUCT
  // 100 - 149 CATEGORY
  // 150 - 199 CONVERSION
  // 200 - 249 LOCATION
  // 250 - 299 OPERATION
  // 300 - 349 ORDERS
  // 350 - 399 REPORT
  // 400 - 449 MEASURES
  // 450 - 499 USERS
  // 500 - 549 DASHBOARD
  // 550 - 599
  // 600 - 649
  // 650 - 699
  // 700 - 749
  // 750 - 799
  // 800 - 849
  // 850 - 899 NETWORK
  // 900 - 999 OTHER
  // plugin configuration
  registerToast(toast: TypeToast): void {
    msg.add = toast.add;
    msg.removeGroup = toast.removeGroup;
    msg.removeAllGroup = toast.removeAllGroup;
  },
  registerI18n(t: (p: unknown) => string): void {
    msg.t = t;
  },
  t(...args: unknown[]): string {
    console.info("Toast I18n called before instantiation", args);
    return "";
  },
  add(args: {
    severity?: "success" | "info" | "warn" | "error";
    summary?: string;
    detail?: string;
    life?: number;
    closable?: boolean;
    group?: string;
  }): void {
    console.info("Toast called before instantiation", args);
  },
  removeGroup(group: string): void {
    console.info("Toast called before instantiation", group);
  },
  removeAllGroup(): void {
    console.info("Toast called before instantiation");
  },
  showSuccess(
    detail?: string,
    title?: string,
    other?: { [key: string]: unknown }
  ): void {
    msg.add({
      severity: "success",
      life: 3000,
      summary: title || t("completed"),
      detail: detail || t("action_completed_succesfully"),
      ...other,
    });
  },
  showInsertSuccess(detail?: string, title?: string): void {
    msg.add({
      severity: "success",
      life: 3000,
      summary: title || t("completed"),
      detail: detail || t("insert_success"),
    });
  },
  showUpdateSuccess(detail?: string, title?: string): void {
    msg.add({
      severity: "success",
      life: 3000,
      summary: title || t("completed"),
      detail: detail || t("update_success"),
    });
  },
  showDeleteSuccess(detail?: string, title?: string): void {
    msg.add({
      severity: "success",
      life: 3000,
      summary: title || t("completed"),
      detail: detail || t("delete_success"),
    });
  },
  showError(detail?: string, title?: string, life?: number): void {
    msg.add({
      severity: "error",
      life: life ?? 10000,
      summary: title || t("error"),
      detail: detail || t("error_ocurred"),
    });
  },
  showInfo(detail: string, title?: string): void {
    msg.add({
      severity: "info",
      life: 3000,
      summary: title || t("information"),
      detail: detail,
    });
  },
  showWarn(detail: string, title?: string): void {
    msg.add({
      severity: "warn",
      life: 3000,
      summary: title || t("warning"),
      detail: detail,
    });
  },
};

export default {
  install(app: App): void {
    app.config.globalProperties.$msg = msg;
  },
};

export function t(...arg: unknown[]): string {
  return msg.t(...arg);
}
