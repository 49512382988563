import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createBlock(_component_FileUpload, {
    mode: "basic",
    auto: true,
    accept: ".json",
    maxFileSize: 1000000,
    chooseLabel: _ctx.t('import'),
    class: "mr-2",
    onError: _ctx.handleUpload
  }, null, 8, ["chooseLabel", "onError"]))
}