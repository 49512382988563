const emailPattern =
  // eslint-disable-next-line
  /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const validators = {
  required(value: unknown) {
    return !value && "required";
  },
  maxLenght(value: string, max: number) {
    return value?.length > max && "too_long";
  },
  positive(value: number) {
    return value < 0 && "required";
  },
  minLenght(value: string, min: number) {
    return value?.length < min && "too_short";
  },
  email(value: string) {
    return !emailPattern.test(value || "") && "invalid";
  },
};

type Validator = {
  [key in keyof typeof validators]: (v: any, p: any) => string;
};

export const validate =
  (
    t: (p: string) => string,
    name: string,
    validator: Array<[keyof typeof validators, any?]>
  ) =>
  (value: any) => {
    for (const v of validator) {
      const message = (validators as unknown as Validator)[v[0]](value, v[1]);

      if (message) {
        return `${t(message)}`;
      }
    }
  };
