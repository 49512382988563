import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-dialog-title" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { style: {"text-align":"right"} }
const _hoisted_10 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderNote = _resolveComponent("OrderNote")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ShowConfirmation = _resolveComponent("ShowConfirmation")!
  const _component_FooterActions = _resolveComponent("FooterActions")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"1280px"},
    modal: true,
    class: "w-full"
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.t("order_products") +
          (_ctx.order?.code ? `: ${_ctx.order?.code || _ctx.t("new")}` : "")) + " ", 1),
        _createVNode(_component_OrderNote, {
          modelValue: _ctx.note,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.note) = $event)),
          disabled: _ctx.mode === 'select'
        }, null, 8, ["modelValue", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm",
        value: _ctx.items,
        dataKey: "id",
        editMode: _ctx.mode === 'select' ? undefined : 'row',
        editingRows: _ctx.editingRows,
        "onUpdate:editingRows": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.editingRows) = $event)),
        onRowEditSave: _ctx.handleUpdate,
        onRowEditCancel: _ctx.handleEditCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.t('name'),
            sortable: true,
            headerStyle: "width:16rem"
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.name), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.name), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.product,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem.product) = $event)),
                options: _ctx.productList,
                filter: true,
                optionLabel: "name",
                appendTo: "body",
                placeholder: _ctx.t('product'),
                disabled: _ctx.mode === 'select',
                onChange: _ctx.onProductSelect
              }, null, 8, ["modelValue", "options", "placeholder", "disabled", "onChange"]),
              (_ctx.submitted && _ctx.productValidator(_ctx.newItem.product))
                ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.productValidator(_ctx.newItem.product)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "status",
            header: _ctx.t('status'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", {
                class: _normalizeClass('product-badge status-' + data.status?.toLowerCase())
              }, _toDisplayString(_ctx.t(data.status || "na")), 3)
            ]),
            editor: _withCtx(({ data }) => [
              _createElementVNode("span", {
                class: _normalizeClass('product-badge status-' + data.status?.toLowerCase())
              }, _toDisplayString(_ctx.t(data.status || "na")), 3)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "lot_id",
            header: _ctx.t('lot')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.product_lot?.find((i) => i.lot_id === data.lot_id)
              ?.lot_code || _ctx.t("na")), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.lot_id,
                "onUpdate:modelValue": ($event: any) => ((data.lot_id) = $event),
                options: 
              data.product?.product_lot?.reduce((items, lot) => {
                if (!items.some((_lot) => _lot.lot_id === lot.lot_id))
                  items.push({
                    lot_code: lot.lot_code || _ctx.t('na'),
                    lot_id: lot.lot_id,
                  });
                return items;
              }, []) || []
            ,
                optionLabel: "lot_code",
                optionValue: "lot_id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.lot_id,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newItem.lot_id) = $event)),
                options: 
              _ctx.newItem.product?.product_lot?.reduce((items, lot) => {
                if (!items.some((_lot) => _lot.lot_id === lot.lot_id))
                  items.push({
                    lot_code: lot.lot_code || _ctx.t('na'),
                    lot_id: lot.lot_id,
                  });
                return items;
              }, []) || []
            ,
                optionLabel: "lot_code",
                optionValue: "lot_id",
                appendTo: "body",
                disabled: _ctx.mode === 'select'
              }, null, 8, ["modelValue", "options", "disabled"]),
              (_ctx.submitted && _ctx.lotValidator(_ctx.newItem.lot_id))
                ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.lotValidator(_ctx.newItem.lot_id)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "src_location",
            header: _ctx.t('source')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.product_lot?.find(
              (i) => i.location_id === data.src_location
            )?.location_name || data.src.name), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.src_location,
                "onUpdate:modelValue": ($event: any) => ((data.src_location) = $event),
                options: 
              data.product?.product_lot?.filter(
                (lot) => data.lot_id === lot.lot_id
              )
            ,
                optionLabel: "location_name",
                optionValue: "location_id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.src_location,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.newItem.src_location) = $event)),
                options: 
              _ctx.newItem.product?.product_lot?.filter(
                (lot) => _ctx.newItem.lot_id === lot.lot_id
              )
            ,
                optionLabel: "location_name",
                optionValue: "location_id",
                appendTo: "body",
                disabled: _ctx.mode === 'select'
              }, null, 8, ["modelValue", "options", "disabled"]),
              (_ctx.submitted && _ctx.locationValidator(_ctx.newItem.src_location))
                ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.locationValidator(_ctx.newItem.src_location)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "dest_location",
            header: _ctx.t('destination')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.destLocation.find((i) => i.id === data.dest_location)?.name ||
            data.dest.name), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.dest_location,
                "onUpdate:modelValue": ($event: any) => ((data.dest_location) = $event),
                options: _ctx.destLocation.filter((i) => i.id !== data.src_location),
                optionLabel: "name",
                optionValue: "id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.dest_location,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newItem.dest_location) = $event)),
                options: _ctx.destLocation.filter((i) => i.id !== _ctx.newItem.src_location),
                optionLabel: "name",
                optionValue: "id",
                appendTo: "body",
                disabled: _ctx.mode === 'select'
              }, null, 8, ["modelValue", "options", "disabled"]),
              (_ctx.submitted && _ctx.locationValidator(_ctx.newItem.dest_location))
                ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.locationValidator(_ctx.newItem.dest_location)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "quantity",
            header: _ctx.t('quantity'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.quantity || _ctx.t("na")), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_InputNumber, {
                modelValue: data.quantity,
                "onUpdate:modelValue": ($event: any) => ((data.quantity) = $event),
                min: 0,
                minFractionDigits: 2,
                maxFractionDigits: 3
              }, null, 8, ["modelValue", "onUpdate:modelValue"]),
              (_ctx.quantityValidator(data.quantity))
                ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.quantityValidator(data.quantity)), 1))
                : _createCommentVNode("", true)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_InputNumber, {
                modelValue: _ctx.newItem.quantity,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newItem.quantity) = $event)),
                mode: "decimal",
                min: 0,
                disabled: _ctx.mode === 'select',
                minFractionDigits: 2,
                maxFractionDigits: 3
              }, null, 8, ["modelValue", "disabled"]),
              (_ctx.submitted && _ctx.quantityValidator(_ctx.newItem.quantity))
                ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.quantityValidator(_ctx.newItem.quantity)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "product_uom",
            header: _ctx.t('unit')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.related_uom?.find((i) => i.id === data.product_uom)
              ?.code), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.product_uom,
                "onUpdate:modelValue": ($event: any) => ((data.product_uom) = $event),
                options: 
              data.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.product_uom,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.newItem.product_uom) = $event)),
                options: 
              _ctx.newItem.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body",
                disabled: _ctx.mode === 'select'
              }, null, 8, ["modelValue", "options", "disabled"]),
              (_ctx.submitted && _ctx.unitValidator(_ctx.newItem.dest_location))
                ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.unitValidator(_ctx.newItem.product_uom)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            rowEditor: true,
            headerStyle: "width:7rem",
            bodyStyle: "text-align:right"
          }, {
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_9, [
                _createVNode(_component_Button, {
                  icon: "pi pi-check",
                  iconPos: "right",
                  class: "p-button-rounded",
                  disabled: _ctx.mode === 'select' || false,
                  onClick: _ctx.handleInsert
                }, null, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            headerStyle: "width:5rem",
            bodyStyle: "text-align:center",
            footerStyle: "text-align:center"
          }, {
            body: _withCtx((slotProps) => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                onClick: ($event: any) => (_ctx.handleDelete(slotProps.data)),
                disabled: _ctx.mode === 'select' || false
              }, null, 8, ["onClick", "disabled"])
            ]),
            editor: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                disabled: true
              })
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-times",
                iconPos: "right",
                class: "p-button-rounded p-button-danger",
                onClick: _ctx.clearInsert,
                disabled: _ctx.mode === 'select'
              }, null, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "editMode", "editingRows", "onRowEditSave", "onRowEditCancel"]),
      _createVNode(_component_ShowConfirmation, { ref: "dialog" }, null, 512),
      (_ctx.mode === 'insert')
        ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
            _createVNode(_component_FooterActions, {
              "disable-save": !_ctx.items.length || !!_ctx.newItem.product,
              onSave: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hideForm(true))),
              onCancel: _cache[9] || (_cache[9] = ($event: any) => (_ctx.hideForm(false)))
            }, null, 8, ["disable-save"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}