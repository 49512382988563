import { useAuth } from "@/plugins/auth";
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import * as PAGES from "@/views";
import LoginLayout from "@/layouts/LoginLayout.vue";
import { Modules_Enum } from "@/graphql";
import { computed } from "vue";

export enum ROUTES {
  Dashboard /*----------------*/ = "/",
  Products /*-----------------*/ = "/products",
  OrdersBuy /*----------------*/ = "/orders_buy",
  OrdersTransfer /*-----------*/ = "/orders_transfer",
  OrdersSell /*---------------*/ = "/orders_sell",
  OrdersManufactory /*--------*/ = "/orders_manufactor",
  ReportIPV /*----------------*/ = "/reports/ipv",
  ReportLot /*----------------*/ = "/reports/lot",
  ReportExistence /*----------*/ = "/reports/existence",
  ReportProductNearToExpire /**/ = "/reports/nearToExpire",
  ReportProductsRunningOut /*-*/ = "/reports/productsRunningOut",
  Locations /*----------------*/ = "/locations",
  Operations /*---------------*/ = "/operations",
  Partners /*-----------------*/ = "/partners",
  Units /*--------------------*/ = "/units",
  Users /*--------------------*/ = "/users",
  Login /*--------------------*/ = "/login",
  Profit /*-------------------*/ = "/profit",
}

export const PERMISSIONS: { [key in ROUTES]: string } = {
  [ROUTES.Dashboard]: "",
  [ROUTES.Login]: "",
  [ROUTES.Profit]: Modules_Enum.ProfitReport,
  [ROUTES.Locations]: Modules_Enum.Location,
  [ROUTES.Operations]: Modules_Enum.Operation,
  [ROUTES.OrdersBuy]: Modules_Enum.PurchaseOrder,
  [ROUTES.OrdersManufactory]: Modules_Enum.ManufacturingOrder,
  [ROUTES.OrdersSell]: Modules_Enum.SalesOrder,
  [ROUTES.OrdersTransfer]: Modules_Enum.TransferOrder,
  [ROUTES.Partners]: Modules_Enum.Partner,
  [ROUTES.Products]: Modules_Enum.Products,
  [ROUTES.ReportExistence]: Modules_Enum.ProductExistenceReport,
  [ROUTES.ReportIPV]: Modules_Enum.IpvReport,
  [ROUTES.ReportLot]: Modules_Enum.LotProductExistenceReport,
  [ROUTES.ReportProductNearToExpire]: Modules_Enum.ProductNearToExpireReport,
  [ROUTES.ReportProductsRunningOut]: Modules_Enum.ProductMinQuantityAlertReport,
  [ROUTES.Units]: Modules_Enum.UnitOfMeasure,
  [ROUTES.Users]: Modules_Enum.Users,
};

const routes: Array<RouteRecordRaw> = [
  {
    path: ROUTES.Dashboard,
    component: PAGES.Dashboard,
  },
  {
    path: ROUTES.Products,
    component: PAGES.Products,
    meta: {},
  },
  {
    path: ROUTES.OrdersBuy,
    component: PAGES.OrdersBuy,
  },
  {
    path: ROUTES.OrdersTransfer,
    component: PAGES.OrdersTransfer,
  },
  {
    path: ROUTES.OrdersManufactory,
    component: PAGES.OrdersManufactory,
  },
  {
    path: ROUTES.OrdersSell,
    component: PAGES.OrdersSell,
  },
  {
    path: ROUTES.ReportLot,
    component: PAGES.Lot,
  },
  {
    path: ROUTES.ReportExistence,
    component: PAGES.Existence,
  },
  {
    path: ROUTES.ReportProductNearToExpire,
    component: PAGES.ProductsNearToExpire,
  },
  {
    path: ROUTES.ReportProductsRunningOut,
    component: PAGES.ProductsRunningOut,
  },
  {
    path: ROUTES.Locations,
    component: PAGES.Locations,
  },
  {
    path: ROUTES.Operations,
    component: PAGES.Operations,
  },
  {
    path: ROUTES.Partners,
    component: PAGES.Partners,
  },
  {
    path: ROUTES.Units,
    component: PAGES.Units,
  },
  {
    path: ROUTES.Users,
    component: PAGES.Users,
  },
  {
    path: ROUTES.Login,
    component: PAGES.Login,
    meta: {
      anonimous: true,
      layout: LoginLayout,
    },
  },
  {
    path: ROUTES.Profit,
    component: PAGES.Profit,
  },
  {
    path: ROUTES.ReportIPV,
    component: PAGES.ReportIPV,
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

export default router;

const { user } = useAuth();

router.beforeEach((to) => {
  window.scrollTo(0, 0);

  if (to.meta.anonimous) {
    return;
  }

  const permissionName = PERMISSIONS[to.path as keyof typeof PERMISSIONS];
  const permissions = user.value?.userData?.permissions;

  if (
    permissionName &&
    permissions &&
    !permissions.find((p) => p.module_name === permissionName)?.can_read
  ) {
    return false;
  }

  if (!user.value) {
    return { path: ROUTES.Login, query: { redirect: to.fullPath } };
  }
});

export const accessors = computed(() => {
  const accessors: {
    [key: string]: {
      canRead: boolean;
      canWrite: boolean;
      canDelete: boolean;
    };
  } = {};

  user.value?.userData?.permissions.forEach((i) => {
    accessors[i.module_name] = {
      canDelete: i.can_delete || false,
      canRead: i.can_read || false,
      canWrite: i.can_write || false,
    };
  });

  accessors["route"] =
    accessors[
      PERMISSIONS[router.currentRoute.value.path as keyof typeof PERMISSIONS]
    ];

  return accessors;
});
