import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_Button, {
      icon: "pi pi-book",
      class: "p-button-link p-button-rounded",
      style: {"margin":"-6px 0"},
      onClick: _ctx.toggle
    }, null, 8, ["onClick"]),
    _createVNode(_component_OverlayPanel, {
      ref: "note",
      "append-to": "body",
      showCloseIcon: true,
      dismissable: true
    }, {
      default: _withCtx(() => [
        _createVNode(_component_Textarea, {
          modelValue: _ctx.modelValue,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event))),
          autoResize: true,
          rows: "5",
          cols: "30",
          disabled: _ctx.disabled
        }, null, 8, ["modelValue", "disabled"])
      ]),
      _: 1
    }, 512)
  ], 64))
}