import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "p-dialog-title" }
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = {
  class: "white-space-nowrap overflow-hidden white-space-nowrap text-overflow-ellipsis",
  style: {"width":"100%"}
}
const _hoisted_4 = {
  class: "white-space-nowrap overflow-hidden white-space-nowrap text-overflow-ellipsis",
  style: {"width":"100%"}
}
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = {
  class: "white-space-nowrap overflow-hidden white-space-nowrap text-overflow-ellipsis",
  style: {"width":"100%"}
}
const _hoisted_7 = {
  key: 0,
  class: "p-error"
}
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = {
  key: 0,
  class: "p-error"
}
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = {
  key: 0,
  class: "p-error"
}
const _hoisted_12 = { style: {"text-align":"right"} }
const _hoisted_13 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderNote = _resolveComponent("OrderNote")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_Button = _resolveComponent("Button")!
  const _component_lot_form = _resolveComponent("lot-form")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ManufactoryProductList = _resolveComponent("ManufactoryProductList")!
  const _component_ShowConfirmation = _resolveComponent("ShowConfirmation")!
  const _component_FooterActions = _resolveComponent("FooterActions")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"1280px"},
    modal: true,
    class: "w-full"
  }, {
    header: _withCtx(() => [
      _createElementVNode("span", _hoisted_1, [
        _createTextVNode(_toDisplayString(_ctx.t("order_products") +
          (_ctx.order?.code ? `: ${_ctx.order?.code || _ctx.t("new")}` : "")) + " ", 1),
        _createVNode(_component_OrderNote, {
          modelValue: _ctx.note,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.note) = $event)),
          disabled: _ctx.mode === 'select'
        }, null, 8, ["modelValue", "disabled"])
      ])
    ]),
    default: _withCtx(() => [
      _createVNode(_component_DataTable, {
        class: "p-datatable-sm",
        value: _ctx.items,
        dataKey: "id",
        editMode: _ctx.mode === 'select' ? undefined : 'row',
        editingRows: _ctx.editingRows,
        "onUpdate:editingRows": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.editingRows) = $event)),
        onRowEditSave: _ctx.handleUpdate,
        onRowEditCancel: _ctx.handleEditCancel
      }, {
        default: _withCtx(() => [
          _createVNode(_component_Column, {
            field: "name",
            header: _ctx.t('name'),
            sortable: true,
            headerStyle: "width:16rem"
          }, {
            body: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.product?.name), 1)
            ]),
            editor: _withCtx((slotProps) => [
              _createTextVNode(_toDisplayString(slotProps.data.product?.name), 1)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.product,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem.product) = $event)),
                options: _ctx.productList,
                filter: true,
                optionLabel: "name",
                appendTo: "body",
                placeholder: _ctx.t('product'),
                disabled: _ctx.mode === 'select',
                onChange: _ctx.onProductSelect,
                class: _normalizeClass({
              'p-invalid': _ctx.submitted && _ctx.productValidator(_ctx.newItem.product),
            })
              }, null, 8, ["modelValue", "options", "placeholder", "disabled", "onChange", "class"]),
              (_ctx.submitted && _ctx.productValidator(_ctx.newItem.product))
                ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.productValidator(_ctx.newItem.product)), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "status",
            header: _ctx.t('status'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createElementVNode("span", {
                class: _normalizeClass('product-badge status-' + data.status?.toLowerCase())
              }, _toDisplayString(_ctx.t(data.status || "na")), 3)
            ]),
            editor: _withCtx(({ data }) => [
              _createElementVNode("span", {
                class: _normalizeClass('product-badge status-' + data.status?.toLowerCase())
              }, _toDisplayString(_ctx.t(data.status || "na")), 3)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "lot_id",
            header: _ctx.t('lot'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_lot_form, null, {
                default: _withCtx(({ view }) => [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined p-button-secondary",
                    style: {"width":"100%"},
                    disabled: !data.product?.trackerable,
                    onClick: ($event: any) => (view($event, data))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_3, _toDisplayString(data?.lot?.[0]?.code || _ctx.t("na")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ]),
                _: 2
              }, 1024)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_lot_form, null, {
                default: _withCtx(({ edit }) => [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined",
                    disabled: !data.product?.trackerable,
                    style: {"width":"100%"},
                    onClick: ($event: any) => (edit($event, data))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_4, _toDisplayString(data?.lot?.[0]?.code || _ctx.t("na")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"])
                ]),
                _: 2
              }, 1024),
              (data.product?.trackerable && !data?.lot?.[0]?.code)
                ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.t("required")), 1))
                : _createCommentVNode("", true)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_lot_form, null, {
                default: _withCtx(({ edit }) => [
                  _createVNode(_component_Button, {
                    class: "p-button-outlined",
                    disabled: !_ctx.newItem.product?.trackerable,
                    style: {"width":"100%"},
                    onClick: ($event: any) => (edit($event, _ctx.newItem))
                  }, {
                    default: _withCtx(() => [
                      _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.newItem?.lot?.[0]?.code || _ctx.t("na")), 1)
                    ]),
                    _: 2
                  }, 1032, ["disabled", "onClick"]),
                  (
                _ctx.submitted &&
                _ctx.newItem.product?.trackerable &&
                !_ctx.newItem?.lot?.[0]?.code
              )
                    ? (_openBlock(), _createElementBlock("small", _hoisted_7, _toDisplayString(_ctx.t("required")), 1))
                    : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "operation",
            header: _ctx.t('operation')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(_ctx.operations.find(
              (i) =>
                i.default_src === data.src_location &&
                i.default_dest === data.dest_location
            )?.name), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: 
              _ctx.operations.find(
                (i) =>
                  data.src_location === i.default_src &&
                  data.dest_location === i.default_dest
              )?.id
            ,
                options: _ctx.operations,
                optionLabel: "name",
                optionValue: "id",
                appendTo: "body",
                onChange: ($event: any) => (_ctx.handleOpChange($event, data)),
                disabled: _ctx.mode === 'select',
                class: _normalizeClass({
              'p-invalid': _ctx.submitted && !_ctx.newItem.operation,
            })
              }, null, 8, ["modelValue", "options", "onChange", "disabled", "class"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.newItem.operation,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newItem.operation) = $event)),
                options: _ctx.operations,
                optionLabel: "name",
                appendTo: "body",
                optionValue: "id",
                onChange: _cache[3] || (_cache[3] = ($event: any) => (_ctx.handleOpChange($event, _ctx.newItem))),
                disabled: _ctx.mode === 'select',
                class: _normalizeClass({
              'p-invalid': _ctx.submitted && !_ctx.newItem.operation,
            })
              }, null, 8, ["modelValue", "options", "disabled", "class"]),
              (_ctx.submitted && !_ctx.newItem.operation)
                ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.t("required")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "quantity",
            header: _ctx.t('quantity'),
            sortable: true
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.quantity || "N/A"), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_InputNumber, {
                modelValue: data['quantity'],
                "onUpdate:modelValue": ($event: any) => ((data['quantity']) = $event),
                minFractionDigits: 2,
                maxFractionDigits: 3,
                class: _normalizeClass({
              'p-invalid': !data.quantity,
            })
              }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
              (!data.quantity)
                ? (_openBlock(), _createElementBlock("small", _hoisted_9, _toDisplayString(_ctx.t("required")), 1))
                : _createCommentVNode("", true)
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_InputNumber, {
                modelValue: _ctx.newItem.quantity,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.newItem.quantity) = $event)),
                mode: "decimal",
                disabled: _ctx.mode === 'select',
                minFractionDigits: 2,
                maxFractionDigits: 3,
                class: _normalizeClass({
              'p-invalid': _ctx.submitted && !_ctx.newItem.quantity,
            })
              }, null, 8, ["modelValue", "disabled", "class"]),
              (_ctx.submitted && !_ctx.newItem.quantity)
                ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.t("required")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "product_uom",
            header: _ctx.t('unit')
          }, {
            body: _withCtx(({ data }) => [
              _createTextVNode(_toDisplayString(data.product?.related_uom?.find((i) => i.id === data.product_uom)
              ?.code), 1)
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Dropdown, {
                modelValue: data.product_uom,
                "onUpdate:modelValue": ($event: any) => ((data.product_uom) = $event),
                options: 
              data.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body"
              }, null, 8, ["modelValue", "onUpdate:modelValue", "options"])
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Dropdown, {
                class: _normalizeClass(["units-selector", {
              'p-invalid': _ctx.submitted && !_ctx.newItem.product_uom,
            }]),
                modelValue: _ctx.newItem.product_uom,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.newItem.product_uom) = $event)),
                options: 
              _ctx.newItem.product?.related_uom.map((i) => ({
                id: i.id,
                label: i.description ? `${i.code} (${i.description})` : i.code,
              }))
            ,
                optionLabel: "label",
                optionValue: "id",
                appendTo: "body",
                disabled: _ctx.mode === 'select'
              }, null, 8, ["modelValue", "options", "disabled", "class"]),
              (_ctx.submitted && !_ctx.newItem.product_uom)
                ? (_openBlock(), _createElementBlock("small", _hoisted_11, _toDisplayString(_ctx.t("required")), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            field: "material_items",
            header: _ctx.t('materials'),
            headerStyle: "width: 7rem",
            bodyStyle: "text-align: center"
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_Button, {
                icon: `pi pi-${_ctx.mode === 'insert' ? 'ban' : 'list'}`,
                class: "p-button-outlined",
                disabled: _ctx.mode === 'insert',
                onClick: ($event: any) => (_ctx.editMaterials(data, 'select'))
              }, null, 8, ["icon", "disabled", "onClick"])
            ]),
            editor: _withCtx(({ data }) => [
              _createVNode(_component_Button, {
                icon: `pi pi-${_ctx.mode === 'insert' ? 'ban' : 'cog'}`,
                class: "p-button-outlined",
                disabled: _ctx.mode === 'insert',
                onClick: ($event: any) => (_ctx.editMaterials(data, 'update'))
              }, null, 8, ["icon", "disabled", "onClick"])
            ]),
            _: 1
          }, 8, ["header"]),
          _createVNode(_component_Column, {
            rowEditor: true,
            headerStyle: "width:7rem",
            bodyStyle: "text-align:right"
          }, {
            footer: _withCtx(() => [
              _createElementVNode("div", _hoisted_12, [
                _createVNode(_component_Button, {
                  icon: "pi pi-check",
                  iconPos: "right",
                  class: "p-button-rounded",
                  disabled: _ctx.mode === 'select' || false,
                  onClick: _ctx.handleInsert
                }, null, 8, ["disabled", "onClick"])
              ])
            ]),
            _: 1
          }),
          _createVNode(_component_Column, {
            headerStyle: "width:5rem",
            bodyStyle: "text-align:center",
            footerStyle: "text-align:center"
          }, {
            body: _withCtx(({ data }) => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                onClick: ($event: any) => (_ctx.handleDelete(data)),
                disabled: _ctx.mode === 'select' || false
              }, null, 8, ["onClick", "disabled"])
            ]),
            editor: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-trash",
                class: "p-button-rounded p-button-secondary p-button-text",
                disabled: true
              })
            ]),
            footer: _withCtx(() => [
              _createVNode(_component_Button, {
                icon: "pi pi-times",
                iconPos: "right",
                class: "p-button-rounded p-button-danger",
                onClick: _ctx.clearInsert,
                disabled: _ctx.mode === 'select'
              }, null, 8, ["onClick", "disabled"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["value", "editMode", "editingRows", "onRowEditSave", "onRowEditCancel"]),
      _createVNode(_component_ManufactoryProductList, {
        ref: "list",
        materialItems: _ctx.items[_ctx.activeIndex]?.material_items || [],
        pid: _ctx.items[_ctx.activeIndex]?.id || -1
      }, null, 8, ["materialItems", "pid"]),
      _createVNode(_component_ShowConfirmation, { ref: "dialog" }, null, 512),
      (_ctx.mode === 'insert')
        ? (_openBlock(), _createElementBlock("div", _hoisted_13, [
            _createVNode(_component_FooterActions, {
              "disable-save": !_ctx.items.length || !!_ctx.newItem.product,
              onSave: _cache[7] || (_cache[7] = ($event: any) => (_ctx.hideForm(true))),
              onCancel: _cache[8] || (_cache[8] = ($event: any) => (_ctx.hideForm(false)))
            }, null, 8, ["disable-save"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible"]))
}