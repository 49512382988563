
import {
  IOperationFragment as Operation,
  useInsertOperationMutation,
  InsertOperationMutationVariables,
  useUpdateOperationMutation,
  useOperationsTypeQuery,
  useOrderTypeQuery,
  useLocationsQuery,
} from "@/graphql";
import { defineComponent, ref, computed } from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";

type FormMode = "" | "insert" | "update";

export type IOperationForm = {
  showForm(mode: FormMode, item: Operation): void;
};

export default defineComponent({
  name: "OperationForm",

  setup() {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const submitted = ref(false);
    const item = ref({} as Operation);

    const { result: operationResult } = useOperationsTypeQuery();
    const operations_type = computed(
      () =>
        operationResult.value?.stock_operation_type.map((i) => {
          return {
            value: i.value,
            label: `${t(i.value)} (${i.value})`,
          };
        }) || []
    );

    const { result: orderTypeResult } = useOrderTypeQuery();
    const order_types = computed(
      () =>
        orderTypeResult.value?.stock_order_type.map((i) => {
          return {
            code: i.code,
            label: `${t(i.code)} (${i.code})`,
          };
        }) || []
    );

    const { result: locationResult } = useLocationsQuery();
    const locations = computed(
      () => locationResult.value?.stock_location || []
    );

    const { mutate: inserOperation } = useInsertOperationMutation({
      refetchQueries: ["Operations"],
    });

    const { mutate: updateOperation } = useUpdateOperationMutation({
      refetchQueries: ["Operations"],
    });

    function showForm(_mode: FormMode, _item: Operation) {
      open.value = true;
      mode.value = _mode;
      item.value = JSON.parse(JSON.stringify(_item));
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
    }

    function validateForm() {
      submitted.value = true;
      const { name, default_src, default_dest, order_type, operation_type } =
        item.value;

      if (name && default_src && default_dest && order_type && operation_type) {
        return true;
      }
    }

    async function saveItem() {
      submitted.value = true;

      if (validateForm()) {
        try {
          if (mode.value === "insert") {
            await inserOperation(
              item.value as InsertOperationMutationVariables
            );
            msg.showInsertSuccess();
          }
          if (mode.value === "update") {
            const _item = JSON.parse(JSON.stringify(item.value));
            delete _item.destination;
            delete _item.__typename;
            delete _item.code;
            delete _item.source;
            await updateOperation(_item);
            msg.showUpdateSuccess();
          }
        } catch (_) {
          return;
        }

        hideForm();
      }
    }

    return {
      t,
      open,
      mode,
      submitted,
      item,
      showForm,
      hideForm,
      saveItem,
      operations_type,
      order_types,
      locations,
    };
  },
});
