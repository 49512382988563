
import { accessors } from "@/router";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ActionButtons",
  props: {
    view: Boolean,
    edit: Boolean,
    run: Boolean,
    rollback: Boolean,
    delete: Boolean,
    error: Boolean,
    note: String,
    config: Boolean,
  },
  emits: ["view", "edit", "run", "delete", "error", "rollback", "config"],
  setup() {
    const { t } = useI18n();

    return { t, accessors };
  },
});
