import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "field col" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { for: "name" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "formgrid grid" }
const _hoisted_7 = { class: "field col" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "field col" }
const _hoisted_10 = {
  key: 0,
  class: "p-error"
}
const _hoisted_11 = { class: "formgrid grid" }
const _hoisted_12 = { class: "field col" }
const _hoisted_13 = {
  key: 0,
  class: "p-error"
}
const _hoisted_14 = { class: "field col" }
const _hoisted_15 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"500px"},
    header: _ctx.t('operation'),
    modal: true,
    class: "w-full"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.t('cancel'),
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.t('save'),
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.t("name")), 1),
            _createVNode(_component_InputText, {
              id: "name",
              class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.name }]),
              modelValue: _ctx.item.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              autofocus: ""
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted && !_ctx.item.name)
              ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.t("required")), 1))
              : _createCommentVNode("", true)
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("order")), 1),
          _createVNode(_component_Dropdown, {
            modelValue: _ctx.item.order_type,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.order_type) = $event)),
            options: _ctx.order_types,
            class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.order_type }]),
            optionLabel: "label",
            optionValue: "code",
            appendTo: "body"
          }, null, 8, ["modelValue", "options", "class"]),
          (_ctx.submitted && !_ctx.item.order_type)
            ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("operation")), 1),
          _createVNode(_component_Dropdown, {
            class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.operation_type }]),
            modelValue: _ctx.item.operation_type,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.operation_type) = $event)),
            options: _ctx.operations_type,
            optionLabel: "label",
            optionValue: "value",
            appendTo: "body"
          }, null, 8, ["modelValue", "options", "class"]),
          (_ctx.submitted && !_ctx.item.operation_type)
            ? (_openBlock(), _createElementBlock("small", _hoisted_10, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      _createElementVNode("div", _hoisted_11, [
        _createElementVNode("div", _hoisted_12, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("source")), 1),
          _createVNode(_component_Dropdown, {
            class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.default_src }]),
            modelValue: _ctx.item.default_src,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.default_src) = $event)),
            options: _ctx.locations,
            optionLabel: "name",
            optionValue: "id",
            appendTo: "body"
          }, null, 8, ["modelValue", "options", "class"]),
          (_ctx.submitted && !_ctx.item.default_src)
            ? (_openBlock(), _createElementBlock("small", _hoisted_13, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_14, [
          _createElementVNode("label", null, _toDisplayString(_ctx.t("destination")), 1),
          _createVNode(_component_Dropdown, {
            class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.default_dest }]),
            modelValue: _ctx.item.default_dest,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.default_dest) = $event)),
            options: _ctx.locations,
            optionLabel: "name",
            optionValue: "id",
            appendTo: "body"
          }, null, 8, ["modelValue", "options", "class"]),
          (_ctx.submitted && !_ctx.item.default_dest)
            ? (_openBlock(), _createElementBlock("small", _hoisted_15, _toDisplayString(_ctx.t("required")), 1))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}