import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "layout-menu-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AppSubmenu = _resolveComponent("AppSubmenu")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_AppSubmenu, {
      items: _ctx.menu,
      class: "layout-menu",
      root: true,
      onMenuitemClick: _ctx.onMenuItemClick
    }, null, 8, ["items", "onMenuitemClick"])
  ]))
}