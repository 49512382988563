import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, Teleport as _Teleport, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "p-error"
}
const _hoisted_2 = {
  key: 0,
  class: "p-error"
}
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = {
  key: 0,
  class: "p-error"
}
const _hoisted_5 = { style: {"text-align":"right"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Column = _resolveComponent("Column")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
    _createVNode(_component_Dialog, {
      header: _ctx.t('materials'),
      visible: _ctx.open,
      "onUpdate:visible": _ctx.hideForm,
      modal: true,
      class: "w-full",
      style: {"width":"100%","max-width":"700px"},
      appendTo: "body"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          class: "p-datatable-sm",
          value: _ctx.materialItems,
          dataKey: "product_id",
          editMode: ((_ctx.mode === 'select' ? null : 'row') as any),
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRows) = $event)),
          onRowEditSave: _ctx.handleUpdate,
          onRowEditCancel: _ctx.handleEditCancel
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              field: "product_name",
              header: _ctx.t('name'),
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.product_name), 1)
              ]),
              editor: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.product_name), 1)
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  modelValue: _ctx.newItem.product,
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newItem.product) = $event)),
                  options: _ctx.productList,
                  filter: true,
                  optionLabel: "name",
                  appendTo: "body",
                  placeholder: _ctx.t('product'),
                  disabled: _ctx.mode === 'select',
                  class: _normalizeClass({
                'p-invalid': _ctx.submitted && !_ctx.newItem.product,
              })
                }, null, 8, ["modelValue", "options", "placeholder", "disabled", "class"]),
                (_ctx.submitted && !_ctx.newItem.product)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_1, _toDisplayString(_ctx.t("required")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "product_uom",
              header: _ctx.t('unit')
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.code), 1)
              ]),
              editor: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.code), 1)
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_Dropdown, {
                  class: _normalizeClass(["units-selector", {
                'p-invalid': _ctx.submitted && !_ctx.newItem.product_uom,
              }]),
                  modelValue: _ctx.newItem.product_uom,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newItem.product_uom) = $event)),
                  options: 
                _ctx.newItem.product?.related_uom.map((i) => ({
                  id: i.id,
                  label: i.description
                    ? `${i.code} (${i.description})`
                    : i.code,
                }))
              ,
                  optionLabel: "label",
                  optionValue: "id",
                  disabled: _ctx.mode === 'select',
                  appendTo: "body"
                }, null, 8, ["modelValue", "options", "disabled", "class"]),
                (_ctx.submitted && !_ctx.newItem.product_uom)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_2, _toDisplayString(_ctx.t("required")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "quantity",
              header: _ctx.t('quantity'),
              sortable: true
            }, {
              body: _withCtx(({ data }) => [
                _createTextVNode(_toDisplayString(data.quantity || "N/A"), 1)
              ]),
              editor: _withCtx(({ data }) => [
                _createVNode(_component_InputNumber, {
                  modelValue: data.quantity,
                  "onUpdate:modelValue": ($event: any) => ((data.quantity) = $event),
                  minFractionDigits: 1,
                  maxFractionDigits: 3,
                  class: _normalizeClass({
                'p-invalid': !data.quantity,
              } as any)
                }, null, 8, ["modelValue", "onUpdate:modelValue", "class"]),
                (!data.quantity)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.t("required")), 1))
                  : _createCommentVNode("", true)
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_InputNumber, {
                  disabled: _ctx.mode === 'select',
                  modelValue: _ctx.newItem.quantity,
                  "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.newItem.quantity) = $event)),
                  minFractionDigits: 1,
                  maxFractionDigits: 3,
                  mode: "decimal",
                  class: _normalizeClass({
                'p-invalid': _ctx.submitted && !_ctx.newItem.quantity,
              } as any)
                }, null, 8, ["disabled", "modelValue", "class"]),
                (_ctx.submitted && !_ctx.newItem.quantity)
                  ? (_openBlock(), _createElementBlock("small", _hoisted_4, _toDisplayString(_ctx.t("required")), 1))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              rowEditor: true,
              headerStyle: "width:7rem",
              bodyStyle: "text-align:right"
            }, {
              footer: _withCtx(() => [
                _createElementVNode("div", _hoisted_5, [
                  _createVNode(_component_Button, {
                    icon: "pi pi-check",
                    iconPos: "right",
                    class: "p-button-rounded",
                    disabled: _ctx.mode === 'select',
                    onClick: _ctx.handleInsert
                  }, null, 8, ["disabled", "onClick"])
                ])
              ]),
              _: 1
            }),
            _createVNode(_component_Column, {
              headerStyle: "width:5rem",
              bodyStyle: "text-align:center",
              footerStyle: "text-align:center"
            }, {
              body: _withCtx((slotProps) => [
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  class: "p-button-rounded p-button-secondary p-button-text",
                  disabled: _ctx.mode === 'select',
                  onClick: ($event: any) => (_ctx.handleDelete(slotProps.data))
                }, null, 8, ["disabled", "onClick"])
              ]),
              editor: _withCtx(() => [
                _createVNode(_component_Button, {
                  icon: "pi pi-trash",
                  class: "p-button-rounded p-button-secondary p-button-text",
                  disabled: true
                })
              ]),
              footer: _withCtx(() => [
                _createVNode(_component_Button, {
                  icon: "pi pi-times",
                  iconPos: "right",
                  class: "p-button-rounded p-button-danger",
                  disabled: _ctx.mode === 'select',
                  onClick: _ctx.clearInsert
                }, null, 8, ["disabled", "onClick"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["value", "editMode", "editingRows", "onRowEditSave", "onRowEditCancel"])
      ]),
      _: 1
    }, 8, ["header", "visible", "onUpdate:visible"])
  ]))
}