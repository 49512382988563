
import { defineComponent, ref, watch } from "vue";
import { useAuth } from "@/plugins/auth";
import { useI18n } from "vue-i18n";
import { ROUTES } from "@/router";
import { useRoute, useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    const { user, login } = useAuth();
    const route = useRoute();
    const router = useRouter();
    const email = ref("");
    const password = ref("");
    const loading = ref(false);

    watch(user, (v) => {
      if (v) {
        router.push((route.query?.redirect as string) || ROUTES.Dashboard);
      }
    });

    async function handleClick() {
      loading.value = true;
      try {
        await login(email.value, password.value);
      } catch (_) {
        // noop
      }
      loading.value = false;
    }

    return { t, email, password, loading, handleClick, user };
  },
});
