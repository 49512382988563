
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";

interface ConfirmationParams {
  title?: string;
  message?: string;
}

export type IShowConfirmation = {
  showConfirmation(params?: ConfirmationParams): Promise<boolean>;
};

export default defineComponent({
  name: "ShowConfirmation",
  props: {
    title: {
      type: String,
    },
    width: {
      type: String,
      default: "450px",
    },
  },
  setup() {
    const { t } = useI18n();
    const resolver = ref<((p: boolean) => void) | null>(null);
    const open = ref(false);
    const dynTitle = ref("");
    const dynMessage = ref("");

    function showConfirmation(params: ConfirmationParams) {
      dynTitle.value = params?.title || t("confirmation");
      dynMessage.value = params?.message || "";

      open.value = true;

      return new Promise((res) => {
        resolver.value = res;
      });
    }

    function close() {
      resolver.value = null;
      open.value = false;
    }

    function handleConfirm() {
      resolver.value?.(true);
      close();
    }

    function handleCancel() {
      resolver.value?.(false);
      close();
    }

    return {
      t,
      open,
      dynTitle,
      dynMessage,
      handleCancel,
      handleConfirm,
      showConfirmation,
    };
  },
});
