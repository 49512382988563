
import {
  IuConversionFragment,
  IUnitFragment,
  useInsertConversionMutation,
} from "@/graphql";
import { msg } from "@/plugins/message";
import { computed, defineComponent, PropType, ref } from "vue";
import { useI18n } from "vue-i18n";

type FormMode = "insert" | "update";

export type IConversionsForm = {
  showForm(mode: FormMode, conversion?: IuConversionFragment): void;
};

type UConversion = {
  -readonly [k in keyof IuConversionFragment]: IuConversionFragment[k];
};

export default defineComponent({
  name: "ConversionForm",
  props: {
    units: {
      type: Array as PropType<IUnitFragment[]>,
      default: () => [],
    },
    from: {
      type: Object as PropType<IUnitFragment>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("update");
    const submitted = ref(false);
    const inverse = ref(true);
    const conversion = ref({} as UConversion);

    const unitsList = computed(() => {
      const usedUnits = props.from.conversions.map((i) => i.to_um);
      return props.units.filter(
        (i) => !usedUnits.includes(i.id) && i.id !== props.from.id
      );
    });

    //<!-- Mutations -->
    const { mutate: insert } = useInsertConversionMutation({
      refetchQueries: ["Units"],
    });

    //<!-- Handlers -->
    function showForm(_mode: FormMode, _conversion?: IUnitFragment) {
      conversion.value = _conversion
        ? JSON.parse(JSON.stringify(_conversion))
        : ({} as UConversion);

      if (_mode === "insert") {
        conversion.value.from_um = props.from?.id;
      }

      mode.value = _mode;
      open.value = true;
    }

    function hideForm() {
      submitted.value = false;
      inverse.value = true;
      open.value = false;
    }

    function validateForm() {
      const { value, to_um } = conversion.value;

      if (value && to_um) {
        return true;
      }
      return false;
    }

    async function saveItem() {
      submitted.value = true;

      if (!validateForm()) {
        return;
      }

      try {
        const i = conversion.value;
        const _i = JSON.parse(JSON.stringify(i));
        delete _i.to;
        delete _i.from;
        delete _i.__typename;
        delete _i.title;
        await insert(_i);
        if (inverse.value) {
          await insert({
            value: 1 / i.value,
            to_um: i.from_um,
            from_um: i.to_um,
          });
        }
        msg.showInsertSuccess();
      } catch (_) {
        return;
      }

      hideForm();
    }

    return {
      t,
      open,
      mode,
      inverse,
      submitted,
      unitsList,
      hideForm,
      conversion,
      showForm,
      saveItem,
    };
  },
});
