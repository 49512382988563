import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "table-header" }
const _hoisted_2 = { class: "m-0" }
const _hoisted_3 = { class: "formgrid grid create-col" }
const _hoisted_4 = { class: "field col" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "field col" }
const _hoisted_7 = { class: "field col-fixed" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Column = _resolveComponent("Column")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_ShowConfirmation = _resolveComponent("ShowConfirmation")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _renderSlot(_ctx.$slots, "default", { toggle: _ctx.toggle }),
    _createVNode(_component_OverlayPanel, {
      appendTo: "body",
      ref: "catForm",
      style: {"max-width":"530px"}
    }, {
      default: _withCtx(() => [
        _createVNode(_component_DataTable, {
          value: _ctx.categoriesBuf,
          selection: _ctx.selectedItems,
          "onUpdate:selection": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.selectedItems) = $event)),
          editMode: "row",
          dataKey: "id",
          class: "p-datatable-sm",
          editingRows: _ctx.editingRows,
          "onUpdate:editingRows": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.editingRows) = $event)),
          onRowEditSave: _ctx.handleSave
        }, {
          header: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("h5", _hoisted_2, _toDisplayString(_ctx.t("categories")), 1),
              _createElementVNode("div", null, [
                _createVNode(_component_Button, {
                  label: _ctx.t('new'),
                  icon: "pi pi-plus",
                  class: "p-button-success mr-2 p-button-sm",
                  onClick: _ctx.toggleCreate
                }, null, 8, ["label", "onClick"]),
                _createVNode(_component_Button, {
                  label: _ctx.t('delete'),
                  icon: "pi pi-trash",
                  class: "p-button-danger mr-2 p-button-sm",
                  disabled: !_ctx.selectedItems?.length,
                  onClick: _ctx.handleDelete
                }, null, 8, ["label", "disabled", "onClick"])
              ])
            ]),
            _createVNode(_Transition, { name: "layout-submenu-wrapper" }, {
              default: _withCtx(() => [
                _withDirectives(_createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_3, [
                    _createElementVNode("div", _hoisted_4, [
                      _createVNode(_component_InputText, {
                        style: {"width":"100%"},
                        modelValue: _ctx.newCategory.name,
                        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newCategory.name) = $event)),
                        modelModifiers: { trim: true },
                        class: _normalizeClass(["p-inputtext-sm", { 'p-invalid': _ctx.submitted && !_ctx.newCategory.name }]),
                        placeholder: _ctx.t('name')
                      }, null, 8, ["modelValue", "placeholder", "class"]),
                      (_ctx.submitted && !_ctx.newCategory.name)
                        ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.t("name") + _ctx.t("is_required")) + " . ", 1))
                        : _createCommentVNode("", true)
                    ]),
                    _createElementVNode("div", _hoisted_6, [
                      _createVNode(_component_InputText, {
                        style: {"width":"100%"},
                        class: "p-inputtext-sm",
                        placeholder: _ctx.t('description'),
                        modelValue: _ctx.newCategory.description,
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.newCategory.description) = $event)),
                        modelModifiers: { trim: true }
                      }, null, 8, ["placeholder", "modelValue"])
                    ]),
                    _createElementVNode("div", _hoisted_7, [
                      _createVNode(_component_Button, {
                        icon: "pi pi-check",
                        class: "p-button-sm",
                        onClick: _ctx.submit
                      }, null, 8, ["onClick"])
                    ])
                  ])
                ], 512), [
                  [_vShow, _ctx.newExpanded]
                ])
              ]),
              _: 1
            })
          ]),
          default: _withCtx(() => [
            _createVNode(_component_Column, {
              selectionMode: "multiple",
              headerStyle: "width: 3rem"
            }),
            _createVNode(_component_Column, {
              field: "name",
              header: _ctx.t('name')
            }, {
              editor: _withCtx((slotProps) => [
                _createVNode(_component_InputText, {
                  style: {"width":"100%"},
                  modelValue: slotProps.data[slotProps.column.props.field],
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.column.props.field]) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              field: "description",
              header: _ctx.t('description')
            }, {
              editor: _withCtx((slotProps) => [
                _createVNode(_component_InputText, {
                  style: {"width":"100%"},
                  modelValue: slotProps.data[slotProps.column.props.field],
                  "onUpdate:modelValue": ($event: any) => ((slotProps.data[slotProps.column.props.field]) = $event)
                }, null, 8, ["modelValue", "onUpdate:modelValue"])
              ]),
              _: 1
            }, 8, ["header"]),
            _createVNode(_component_Column, {
              rowEditor: true,
              headerStyle: "width:7rem",
              bodyStyle: "text-align:center"
            })
          ]),
          _: 1
        }, 8, ["value", "selection", "editingRows", "onRowEditSave"]),
        _createVNode(_component_ShowConfirmation, { ref: "deleteDialog" }, null, 512)
      ]),
      _: 1
    }, 512)
  ], 64))
}