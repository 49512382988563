
import "../assets/layout/layout.scss";

import AppTopBar from "@/components/layout/AppTopbar.vue";
import AppProfile from "@/components/layout/AppProfile.vue";
import AppMenu from "@/components/layout/AppMenu.vue";
import { computed, defineComponent, onBeforeUpdate, ref } from "vue";

export default defineComponent({
  name: "DefaultLayout",
  setup() {
    let layoutMode = ref("static");
    let layoutColorMode = ref("dark");
    let staticMenuInactive = ref(false);
    let overlayMenuActive = ref(false);
    let mobileMenuActive = ref(false);
    let menuActive = ref(false);
    let menuClick = ref(false);

    function onWrapperClick() {
      if (!menuClick.value) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
      }

      menuClick.value = false;
    }

    function onMenuToggle(event: any) {
      menuClick.value = true;

      if (isDesktop()) {
        if (layoutMode.value === "overlay") {
          if (mobileMenuActive.value === true) {
            overlayMenuActive.value = true;
          }

          overlayMenuActive.value = !overlayMenuActive.value;
          mobileMenuActive.value = false;
        } else if (layoutMode.value === "static") {
          staticMenuInactive.value = !staticMenuInactive.value;
        }
      } else {
        mobileMenuActive.value = !mobileMenuActive.value;
      }

      event.preventDefault();
    }

    function isDesktop() {
      return window.innerWidth > 1200;
    }

    function onSidebarClick() {
      menuClick.value = true;
    }

    function onMenuItemClick(event: any) {
      if (event.item && !event.item.items) {
        overlayMenuActive.value = false;
        mobileMenuActive.value = false;
      }
    }

    function addClass(element: any, className: any) {
      if (element.classList) element.classList.add(className);
      else element.className += " " + className;
    }

    function removeClass(element: any, className: any) {
      if (element.classList) element.classList.remove(className);
      else
        element.className = element.className.replace(
          new RegExp(
            "(^|\\b)" + className.split(" ").join("|") + "(\\b|$)",
            "gi"
          ),
          " "
        );
    }

    function isSidebarVisible() {
      if (isDesktop()) {
        if (layoutMode.value === "static") return !staticMenuInactive.value;
        else if (layoutMode.value === "overlay") return overlayMenuActive.value;
        else return true;
      } else {
        return true;
      }
    }

    const containerClass = computed(() => {
      return [
        "layout-wrapper",
        {
          "layout-overlay": layoutMode.value === "overlay",
          "layout-static": layoutMode.value === "static",
          "layout-static-sidebar-inactive":
            staticMenuInactive.value && layoutMode.value === "static",
          "layout-overlay-sidebar-active":
            overlayMenuActive.value && layoutMode.value === "overlay",
          "layout-mobile-sidebar-active": mobileMenuActive.value,
        },
      ];
    });

    const sidebarClass = () => {
      return [
        "layout-sidebar",
        {
          "layout-sidebar-dark": layoutColorMode.value === "dark",
          "layout-sidebar-light": layoutColorMode.value === "light",
          "z-5": !isDesktop(),
        },
      ];
    };

    onBeforeUpdate(() => {
      if (mobileMenuActive.value) {
        addClass(document.body, "body-overflow-hidden");
      } else {
        removeClass(document.body, "body-overflow-hidden");
      }
    });

    return {
      layoutMode,
      layoutColorMode,
      staticMenuInactive,
      overlayMenuActive,
      mobileMenuActive,
      menuActive,
      menuClick,

      onWrapperClick,
      onMenuToggle,
      isDesktop,
      onMenuItemClick,
      onSidebarClick,
      addClass,
      removeClass,
      isSidebarVisible,

      containerClass,
      sidebarClass,
    };
  },
  watch: {
    $route() {
      (this as any).menuActive = false;
      (this as any).$toast.removeAllGroups();
    },
  },

  components: {
    AppTopBar,
    AppProfile,
    AppMenu,
  },
});
