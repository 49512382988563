import { Ref, watch } from "vue";

export function useLoadingTimeout(
  loading: Ref<boolean>,
  reset: any,
  timer = 5000
) {
  let timeout: any = null;

  watch(loading, (v) => {
    clearTimeout(timeout);
    if (v) timeout = setTimeout(reset, timer);
  });
}
