
import { useImportSalesOrdersMutation } from "@/graphql";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "ImportOrdersButton",
  setup() {
    const { t } = useI18n();

    const { mutate: importSalesOrders } = useImportSalesOrdersMutation({});

    function handleUpload(e: Event) {
      const fr = new FileReader();

      fr.onload = async (e) => {
        const data = JSON.parse(e.target?.result as string);

        importSalesOrders({
          imported_order: data.variables.imported_order,
        });
      };

      fr.readAsText((e as unknown as { files: [Blob] }).files[0]);
    }

    return {
      t,
      handleUpload,
    };
  },
});
