
import {
  IPartnerFragment as IParter,
  UpdatePartnerMutationVariables,
  useInsertPartnerMutation,
  useUpdatePartnerMutation,
} from "@/graphql";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";

type FormMode = "insert" | "update";

export type IPartnerForm = {
  showForm(mode: FormMode, item: IParter): void;
};

export default defineComponent({
  name: "LocationForm",
  setup() {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("insert");
    const submitted = ref(false);
    const item = ref({} as IParter);

    const { mutate: insert } = useInsertPartnerMutation({
      refetchQueries: ["Partners"],
    });
    const { mutate: update } = useUpdatePartnerMutation({
      refetchQueries: ["Partners"],
    });

    function showForm(_mode: FormMode, _item: IParter) {
      open.value = true;
      mode.value = _mode;
      item.value = JSON.parse(JSON.stringify(_item));
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
    }

    function validateForm() {
      const { name } = item.value;

      if (name) {
        return item.value as UpdatePartnerMutationVariables;
      }

      return null;
    }

    async function saveItem() {
      submitted.value = true;

      const item = validateForm();

      if (item) {
        try {
          if (mode.value === "insert") {
            await insert(item);
            msg.showInsertSuccess();
          }
          if (mode.value === "update") {
            const _item = JSON.parse(JSON.stringify(item));
            delete _item.__typename;
            await update(_item);
            msg.showUpdateSuccess();
          }
        } catch (_) {
          return;
        }

        hideForm();
      }
    }

    return {
      t,
      open,
      mode,
      submitted,
      hideForm,
      saveItem,
      item,
      showForm,
    };
  },
});
