// import "primevue/resources/themes/lara-light-blue/theme.css";
import "primevue/resources/themes/saga-blue/theme.css";
import "primevue/resources/primevue.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";

import { App } from "vue";

import PrimeVue from "primevue/config";

import Card from "primevue/card";
import Tree from "primevue/tree";
import Chart from "primevue/chart";
import Toast from "primevue/toast";
import Panel from "primevue/panel";
import Chip from "primevue/chip";
import Chips from "primevue/chips";
import Ripple from "primevue/ripple";
import Column from "primevue/column";
import Button from "primevue/button";
import Dialog from "primevue/dialog";
import Message from "primevue/message";
import Toolbar from "primevue/toolbar";
import Listbox from "primevue/listbox";
import Inplace from "primevue/inplace";
import Tooltip from "primevue/tooltip";
import Password from "primevue/password";
import Checkbox from "primevue/checkbox";
import Calendar from "primevue/calendar";
import Dropdown from "primevue/dropdown";
import Textarea from "primevue/textarea";
import InputText from "primevue/inputtext";
import DataTable from "primevue/datatable";
import FileUpload from "primevue/fileupload";
import ProgressBar from "primevue/progressbar";
import MultiSelect from "primevue/multiselect";
import InputSwitch from "primevue/inputswitch";
import RadioButton from "primevue/radiobutton";
import InputNumber from "primevue/inputnumber";
import AutoComplete from "primevue/autocomplete";
import ToastService from "primevue/toastservice";
import OverlayPanel from "primevue/overlaypanel";
import ToggleButton from "primevue/togglebutton";
import SelectButton from "primevue/selectbutton";
import ConfirmDialog from "primevue/confirmdialog";
import ConfirmationService from "primevue/confirmationservice";

export const primeVueSetup = {
  // eslint-disable-next-line
  install: (app: App) => {
    app.use(PrimeVue, {
      ripple: true,
      zIndex: {
        modal: 1100, //dialog, sidebar
        overlay: 1000, //dropdown, overlaypanel
        menu: 1000, //overlay menus
        tooltip: 11000, //tooltip
      },
    });
    app.use(ToastService);
    app.use(ConfirmationService);

    app.directive("tooltip", Tooltip);
    app.directive("ripple", Ripple);

    app.component("Card", Card);
    app.component("Chip", Chip);
    app.component("Tree", Tree);
    app.component("Chips", Chips);
    app.component("Panel", Panel);
    app.component("Toast", Toast);
    app.component("Chart", Chart);
    app.component("Button", Button);
    app.component("Column", Column);
    app.component("Dialog", Dialog);
    app.component("Toolbar", Toolbar);
    app.component("Listbox", Listbox);
    app.component("Inplace", Inplace);
    app.component("Message", Message);
    app.component("Calendar", Calendar);
    app.component("Password", Password);
    app.component("Textarea", Textarea);
    app.component("Dropdown", Dropdown);
    app.component("Checkbox", Checkbox);
    app.component("DataTable", DataTable);
    app.component("InputText", InputText);
    app.component("FileUpload", FileUpload);
    app.component("RadioButton", RadioButton);
    app.component("InputNumber", InputNumber);
    app.component("InputSwitch", InputSwitch);
    app.component("ProgressBar", ProgressBar);
    app.component("MultiSelect", MultiSelect);
    app.component("AutoComplete", AutoComplete);
    app.component("OverlayPanel", OverlayPanel);
    app.component("ToggleButton", ToggleButton);
    app.component("SelectButton", SelectButton);
    app.component("ConfirmDialog", ConfirmDialog);
  },
};

export type TypeToast = {
  add(args: {
    severity?: "success" | "info" | "warn" | "error";
    summary?: string;
    detail?: string;
    life?: number;
    closable?: boolean;
    group?: string;
  }): void;
  removeGroup(group: string): void;
  removeAllGroup(): void;
};

export type TypeDataTable = {
  exportCSV(): void;
};
