import {
  ApolloClient,
  InMemoryCache,
  ApolloLink,
  from,
} from "@apollo/client/core";
import { errorLink } from "@/plugins/errors";
import { setContext } from "@apollo/client/link/context";
import { RetryLink } from "@apollo/client/link/retry";
import { useAuth } from "./plugins/auth";
import { BatchHttpLink } from "apollo-link-batch-http";

const { getHeader } = useAuth();

const httpLink = new BatchHttpLink({
  uri: "/v1/graphql",
  batchInterval: 40,
});

const authLink = setContext(async (request, { headers }) => {
  return {
    headers: {
      ...headers,
      ...(await getHeader()),
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 100,
    max: 2000,
    jitter: true,
  },
  attempts: {
    max: 5,
    retryIf(error /*, { getContext, setContext }*/) {
      // <!-- EXPIRED TOKEN -->
      // const headers = getContext().headers;
      // setContext({
      //   headers: {
      //     ...headers,
      //   }
      // });

      const doNotRetryCodes = [500, 400];
      const shouldRetry =
        !!error && !doNotRetryCodes.includes(error.statusCode);

      console.info(
        `[retry]: Error detected: ${
          shouldRetry ? "retrying..." : "ignoring..."
        }`
      );

      return shouldRetry;
    },
  },
});

const cache = new InMemoryCache();

export const apolloClient = new ApolloClient({
  link: from([
    errorLink,
    retryLink,
    authLink,
    httpLink as unknown as ApolloLink,
  ]),
  cache,
});
