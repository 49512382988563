
import {
  ILocationsFragment as ILocation,
  useInsertLocationMutation,
  useUpdateLocationMutation,
  UpdateLocationMutationVariables,
} from "@/graphql";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { msg } from "@/plugins/message";

type FormMode = "" | "insert" | "update";

export type ILocationForm = {
  showForm(mode: FormMode, item: ILocation): void;
};

export default defineComponent({
  name: "LocationForm",
  setup() {
    const { t } = useI18n();
    const open = ref(false);
    const mode = ref<FormMode>("");
    const submitted = ref(false);
    const item = ref({} as ILocation);

    const { mutate: insertLocation } = useInsertLocationMutation({
      refetchQueries: ["Locations"],
    });
    const { mutate: updateLocation } = useUpdateLocationMutation({
      refetchQueries: ["Locations"],
    });

    function showForm(_mode: FormMode, _item: ILocation) {
      open.value = true;
      mode.value = _mode;
      item.value = JSON.parse(JSON.stringify(_item));
    }

    function hideForm() {
      submitted.value = false;
      open.value = false;
    }

    function validateForm(): UpdateLocationMutationVariables | null {
      const { location_type, name, code } = item.value;

      if (code && name && location_type) {
        return item.value as UpdateLocationMutationVariables;
      }
      return null;
    }

    async function saveItem() {
      submitted.value = true;

      const item = validateForm();

      if (item) {
        try {
          if (mode.value === "insert") {
            await insertLocation(item);
            msg.showInsertSuccess();
          }
          if (mode.value === "update") {
            const _item = JSON.parse(JSON.stringify(item));
            delete _item.__typename;
            await updateLocation(_item);
            msg.showUpdateSuccess();
          }
        } catch (_) {
          return;
        }

        hideForm();
      }
    }

    return {
      t,
      open,
      mode,
      submitted,
      hideForm,
      saveItem,
      item,
      showForm,
    };
  },
});
