import axios from "axios";

export type LoginResponse = {
  jwt_token: string;
  jwt_expires_in: number;
};

export class AuthService {
  // Registration
  register(data: { email: string; password: string }): Promise<void> {
    return axios.post("/auth/register", data).then((res) => res.data);
  }

  // Login
  login(data: { email: string; password: string }): Promise<LoginResponse> {
    return axios.post("/auth/login", data).then((res) => res.data);
  }

  // Logout
  logout(): Promise<void> {
    return axios.post("/auth/logout").then((res) => res.data);
  }

  //Refresh token
  refresh(): Promise<LoginResponse> {
    return axios.get("/auth/token/refresh").then((res) => res.data);
  }

  //Change password
  changePassword(data: {
    old_password: string;
    new_password: string;
  }): Promise<void> {
    return axios.post("/auth/change-password", data).then((res) => res.data);
  }
}
