
import OverlayPanel from "primevue/overlaypanel";
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "OrderNote",
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
  emits: ["update:modelValue"],
  setup() {
    const note = ref<OverlayPanel>();

    return {
      note,
    };
  },
  methods: {
    toggle(e: Event) {
      this.note?.toggle(e);
    },
  },
});
