
import { computed, defineComponent, reactive, ref, watch } from "vue";
import OverlayPanel from "primevue/overlaypanel";
import { useI18n } from "vue-i18n";
import { useAuth } from "@/plugins/auth";
import { useChangeUserNameMutation } from "@/graphql";
import { AuthService } from "@/plugins/authService";
import { msg } from "@/plugins/message";

const authService = new AuthService();

const { user: authUser } = useAuth();

export default defineComponent({
  name: "ProfilePanel",
  setup() {
    const { t } = useI18n();
    const panel = ref<any>();
    const name = ref("");
    const password = reactive({
      open: false,
      submitted: false,
      loading: false,
      old: "",
      new: "",
      rnew: "",
    });

    const formStatus = computed(() => {
      const { old, new: newPass, rnew, submitted } = password;

      if (!submitted) {
        return null;
      }

      if (old && newPass?.length > 7 && newPass === rnew) {
        return true;
      }

      return false;
    });

    const user = computed(() => authUser.value?.userData);

    watch(
      () => [panel.value?.$data.visible, authUser.value?.email],
      ([value], [oldValue]) => {
        if (!value && oldValue) {
          clearPassword();
        } else {
          name.value = user.value?.display_name || "";
        }
      }
    );

    //<!-- Mutations -->
    const { mutate: mutateName, loading: savingName } =
      useChangeUserNameMutation({});

    function changeName() {
      if (savingName.value) {
        return;
      }
      mutateName({
        id: user.value?.id,
        display_name: name.value,
      });
    }

    async function changePassword() {
      const { old: old_password, new: new_password } = password;

      password.submitted = true;

      if (!formStatus.value) {
        return;
      }

      try {
        password.loading = true;
        await authService.changePassword({ old_password, new_password });
        password.loading = false;
        msg.showSuccess(t("password_updated"));
        clearPassword();
      } catch (e: any) {
        msg.showError(e.response.data.message || e.message);
        return;
      }
    }

    function clearPassword() {
      password.open = false;
      password.submitted = false;
      password.new = "";
      password.rnew = "";
    }

    return {
      t,
      panel,
      user,
      name,
      password,
      savingName,
      formStatus,
      changeName,
      clearPassword,
      changePassword,
    };
  },
  methods: {
    toggle(event: Event) {
      this.panel?.toggle(event);
    },
  },
});
