import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "field" }
const _hoisted_2 = { for: "code" }
const _hoisted_3 = {
  key: 0,
  class: "p-error"
}
const _hoisted_4 = { class: "field" }
const _hoisted_5 = { for: "description" }
const _hoisted_6 = {
  key: 0,
  class: "p-error"
}
const _hoisted_7 = { class: "field" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"300px","margin-right":"-3%"},
    header: _ctx.t('unit'),
    modal: true,
    class: "w-full product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: "Cancelar",
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["onClick"]),
      _createVNode(_component_Button, {
        label: "Guardar",
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.t("code")), 1),
        _createVNode(_component_InputText, {
          modelValue: _ctx.item.code,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.code) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.code }]),
          autofocus: ""
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && !_ctx.item.code)
          ? (_openBlock(), _createElementBlock("small", _hoisted_3, _toDisplayString(_ctx.t("required")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("label", _hoisted_5, _toDisplayString(_ctx.t("description")), 1),
        _createVNode(_component_InputText, {
          modelValue: _ctx.item.description,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.description) = $event)),
          modelModifiers: { trim: true },
          required: "true",
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.description }]),
          autofocus: ""
        }, null, 8, ["modelValue", "class"]),
        (_ctx.submitted && !_ctx.item.description)
          ? (_openBlock(), _createElementBlock("small", _hoisted_6, _toDisplayString(_ctx.t("required")), 1))
          : _createCommentVNode("", true)
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("label", null, _toDisplayString(_ctx.t("category")), 1),
        _createVNode(_component_Dropdown, {
          appendTo: "body",
          class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && !_ctx.item.category_id }]),
          modelValue: _ctx.item.category_id,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.item.category_id) = $event)),
          options: _ctx.categories,
          optionLabel: "value",
          optionValue: "id"
        }, null, 8, ["modelValue", "options", "class"]),
        (_ctx.submitted && !_ctx.item.category_id)
          ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.t("required")), 1))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}