import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "formgrid grid" }
const _hoisted_2 = { class: "field col-6" }
const _hoisted_3 = { class: "field" }
const _hoisted_4 = { for: "name" }
const _hoisted_5 = {
  key: 0,
  class: "p-error"
}
const _hoisted_6 = { class: "field" }
const _hoisted_7 = { for: "sku" }
const _hoisted_8 = {
  key: 0,
  class: "p-error"
}
const _hoisted_9 = { class: "field" }
const _hoisted_10 = { for: "external_product_id" }
const _hoisted_11 = { class: "field" }
const _hoisted_12 = { for: "description" }
const _hoisted_13 = { class: "field col-6" }
const _hoisted_14 = { class: "formgrid grid" }
const _hoisted_15 = { class: "field col" }
const _hoisted_16 = { class: "field col-fixed" }
const _hoisted_17 = { style: {"opacity":"0"} }
const _hoisted_18 = { class: "formgrid grid" }
const _hoisted_19 = { class: "field col-4" }
const _hoisted_20 = {
  key: 0,
  class: "p-error"
}
const _hoisted_21 = { class: "field col-4" }
const _hoisted_22 = { for: "cost" }
const _hoisted_23 = { class: "field col-4" }
const _hoisted_24 = { for: "price" }
const _hoisted_25 = { class: "formgrid grid" }
const _hoisted_26 = { class: "field col-4" }
const _hoisted_27 = { for: "price" }
const _hoisted_28 = { class: "field col-4" }
const _hoisted_29 = { for: "price" }
const _hoisted_30 = { class: "field col-4" }
const _hoisted_31 = { for: "cost" }
const _hoisted_32 = { class: "field" }
const _hoisted_33 = { class: "formgrid grid" }
const _hoisted_34 = { class: "field-checkbox col-4" }
const _hoisted_35 = { for: "trackerable cursor-pointer" }
const _hoisted_36 = { class: "field-checkbox col-4" }
const _hoisted_37 = { for: "storable cursor-pointer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_Button = _resolveComponent("Button")!
  const _component_CategoriesForm = _resolveComponent("CategoriesForm")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Checkbox = _resolveComponent("Checkbox")!
  const _component_Dialog = _resolveComponent("Dialog")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createBlock(_component_Dialog, {
    visible: _ctx.open,
    "onUpdate:visible": _ctx.hideForm,
    style: {"width":"100%","max-width":"800px"},
    header: _ctx.mode === 'insert' ? 'Nuevo Producto' : 'Detalles del Producto',
    modal: true,
    class: "product-form"
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_Button, {
        label: _ctx.t('cancel'),
        icon: "pi pi-times",
        class: "p-button-text",
        onClick: _ctx.hideForm
      }, null, 8, ["label", "onClick"]),
      _createVNode(_component_Button, {
        label: _ctx.t('save'),
        icon: "pi pi-check",
        class: "p-button-text",
        onClick: _ctx.saveItem
      }, null, 8, ["label", "onClick"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("label", _hoisted_4, _toDisplayString(_ctx.t("name")), 1),
            _createVNode(_component_InputText, {
              id: "name",
              modelValue: _ctx.item.name,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.item.name) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              autofocus: "",
              class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && _ctx.nameValidator(_ctx.item.name) }])
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted && _ctx.nameValidator(_ctx.item.name))
              ? (_openBlock(), _createElementBlock("small", _hoisted_5, _toDisplayString(_ctx.nameValidator(_ctx.item.name)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_6, [
            _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.t("sku")), 1),
            _createVNode(_component_InputText, {
              id: "sku",
              modelValue: _ctx.item.sku,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.item.sku) = $event)),
              modelModifiers: { trim: true },
              required: "true",
              class: _normalizeClass(["w-full", { 'p-invalid': _ctx.submitted && _ctx.skuValidator(_ctx.item.sku) }]),
              onInput: _cache[2] || (_cache[2] = 
              (value) => {
                if (!value) {
                  _ctx.item.sku = null;
                }
              }
            )
            }, null, 8, ["modelValue", "class"]),
            (_ctx.submitted && _ctx.skuValidator(_ctx.item.sku))
              ? (_openBlock(), _createElementBlock("small", _hoisted_8, _toDisplayString(_ctx.skuValidator(_ctx.item.sku)), 1))
              : _createCommentVNode("", true)
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("label", _hoisted_10, _toDisplayString(_ctx.t("external_product_id")), 1),
            _createVNode(_component_InputText, {
              id: "external_product_id",
              class: "w-full",
              modelValue: _ctx.item.external_product_id,
              "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.item.external_product_id) = $event)),
              modelModifiers: { trim: true },
              required: "true"
            }, null, 8, ["modelValue"])
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("label", _hoisted_12, _toDisplayString(_ctx.t("description")), 1),
            _createVNode(_component_Textarea, {
              id: "description",
              class: "w-full",
              modelValue: _ctx.item.description,
              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.item.description) = $event)),
              rows: "3",
              cols: "20"
            }, null, 8, ["modelValue"])
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          _createElementVNode("div", _hoisted_14, [
            _createElementVNode("div", _hoisted_15, [
              _createElementVNode("label", null, _toDisplayString(_ctx.t("categories")), 1),
              _createVNode(_component_Dropdown, {
                modelValue: _ctx.item.product_category,
                "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.item.product_category) = $event)),
                options: _ctx.categories,
                class: "w-full",
                optionLabel: "name",
                optionValue: "id",
                showClear: true
              }, null, 8, ["modelValue", "options"])
            ]),
            _createElementVNode("div", _hoisted_16, [
              _createElementVNode("label", _hoisted_17, _toDisplayString(_ctx.t("edit")), 1),
              _createVNode(_component_CategoriesForm, { categories: _ctx.categories }, {
                default: _withCtx(({ toggle }) => [
                  _createVNode(_component_Button, {
                    class: "p-button-warning block",
                    icon: "pi pi-pencil",
                    onClick: toggle
                  }, null, 8, ["onClick"])
                ]),
                _: 1
              }, 8, ["categories"])
            ])
          ]),
          _createElementVNode("div", _hoisted_18, [
            _createElementVNode("div", _hoisted_19, [
              _createElementVNode("label", null, _toDisplayString(_ctx.t("unit")), 1),
              _createVNode(_component_Dropdown, {
                class: _normalizeClass(["units-selector w-full", {
                'p-invalid': _ctx.submitted && _ctx.unitValidator(_ctx.item.product_uom),
              }]),
                modelValue: _ctx.item.product_uom,
                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.item.product_uom) = $event)),
                options: 
                _ctx.units.map((i) => ({
                  id: i.id,
                  label: i.description
                    ? `${i.code} (${i.description})`
                    : i.code,
                }))
              ,
                optionLabel: "label",
                optionValue: "id",
                placeholder: _ctx.t('select_one')
              }, null, 8, ["modelValue", "options", "placeholder", "class"]),
              (_ctx.submitted && _ctx.unitValidator(_ctx.item.product_uom))
                ? (_openBlock(), _createElementBlock("small", _hoisted_20, _toDisplayString(_ctx.unitValidator(_ctx.item.product_uom)), 1))
                : _createCommentVNode("", true)
            ]),
            _createElementVNode("div", _hoisted_21, [
              _createElementVNode("label", _hoisted_22, _toDisplayString(_ctx.t("price")) + "(" + _toDisplayString(_ctx.t("purchase")) + ")", 1),
              _createVNode(_component_InputNumber, {
                id: "cost",
                modelValue: _ctx.item.cost,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.item.cost) = $event)),
                mode: "currency",
                inputClass: "w-full",
                currency: "USD",
                locale: "en-US",
                onInput: _cache[8] || (_cache[8] = 
                ({ value }) => {
                  if (value) {
                    _ctx.item.purchasable = true;
                    _ctx.item.storable = true;
                  } else {
                    _ctx.item.purchasable = false;
                    _ctx.item.cost = null;
                  }
                }
              )
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_23, [
              _createElementVNode("label", _hoisted_24, _toDisplayString(_ctx.t("price")) + "(" + _toDisplayString(_ctx.t("sell")) + ")", 1),
              _createVNode(_component_InputNumber, {
                id: "price",
                modelValue: _ctx.item.price,
                "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.item.price) = $event)),
                mode: "currency",
                inputClass: "w-full",
                currency: "USD",
                locale: "en-US",
                onInput: _cache[10] || (_cache[10] = 
                ({ value }) => {
                  if (value) {
                    _ctx.item.sellable = true;
                  } else {
                    _ctx.item.sellable = false;
                    _ctx.item.price = null;
                  }
                }
              )
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("div", _hoisted_25, [
            _createElementVNode("div", _hoisted_26, [
              _createElementVNode("label", _hoisted_27, _toDisplayString(_ctx.t("min_stock")), 1),
              _createVNode(_component_InputNumber, {
                id: "allowed_min_quantity",
                modelValue: _ctx.item.allowed_min_quantity,
                "onUpdate:modelValue": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.item.allowed_min_quantity) = $event)),
                placeholder: "Min",
                inputClass: "w-full",
                onInput: _cache[12] || (_cache[12] = 
                ({ value }: any) => {
                  if (!value) {
                    _ctx.item.expirable || (_ctx.item.alert_of_expiration_on = null);
                  }
                }
              )
              }, null, 8, ["modelValue"])
            ]),
            _createElementVNode("div", _hoisted_28, [
              _createElementVNode("label", _hoisted_29, _toDisplayString(_ctx.t("expiration")), 1),
              _createVNode(_component_InputNumber, {
                id: "expire_on",
                modelValue: _ctx.item.expire_on,
                "onUpdate:modelValue": _cache[13] || (_cache[13] = ($event: any) => ((_ctx.item.expire_on) = $event)),
                inputClass: "w-full",
                placeholder: 'X ' + _ctx.t('days'),
                suffix: ' ' + _ctx.t('days'),
                onInput: _cache[14] || (_cache[14] = 
                ({ value }: any) => {
                  if (value) {
                    _ctx.item.expirable = true;
                    _ctx.item.trackerable = true;
                  } else {
                    _ctx.item.expirable = false;
                    _ctx.item.alert_of_expiration_on = null;
                    _ctx.item.expire_on = null;
                  }
                }
              )
              }, null, 8, ["modelValue", "placeholder", "suffix"])
            ]),
            _createElementVNode("div", _hoisted_30, [
              _createElementVNode("label", _hoisted_31, _toDisplayString(_ctx.t("alert")), 1),
              _createVNode(_component_InputNumber, {
                id: "alert_of_expiration_on",
                inputClass: "w-full",
                placeholder: 'X ' + _ctx.t('days') + ' ' + _ctx.t('before'),
                modelValue: _ctx.item.alert_of_expiration_on,
                "onUpdate:modelValue": _cache[15] || (_cache[15] = ($event: any) => ((_ctx.item.alert_of_expiration_on) = $event)),
                suffix: ` ${_ctx.t('days')} ${_ctx.t('before')}`,
                disabled: !_ctx.item.expirable
              }, null, 8, ["placeholder", "modelValue", "suffix", "disabled"])
            ])
          ]),
          _createElementVNode("div", _hoisted_32, [
            _createElementVNode("label", null, _toDisplayString(_ctx.t("options")), 1),
            _createElementVNode("div", _hoisted_33, [
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_34, [
                _createVNode(_component_Checkbox, {
                  id: "trackerable",
                  binary: true,
                  name: "trackerable",
                  modelValue: _ctx.item.trackerable,
                  "onUpdate:modelValue": _cache[16] || (_cache[16] = ($event: any) => ((_ctx.item.trackerable) = $event)),
                  onChange: _cache[17] || (_cache[17] = 
                  () => {
                    if (!_ctx.item.trackerable) {
                      _ctx.item.expirable = false;
                      _ctx.item.alert_of_expiration_on = null;
                      _ctx.item.expire_on = null;
                    } else {
                      _ctx.item.storable = true;
                    }
                  }
                ),
                  disabled: 
                  _ctx.item.product_lot?.length > 0 &&
                  !(
                    _ctx.item.product_lot.length === 1 &&
                    _ctx.item.product_lot[0].lot_id === -1
                  )
                
                }, null, 8, ["modelValue", "disabled"]),
                _createElementVNode("label", _hoisted_35, _toDisplayString(_ctx.t("lot")), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.t('msg_lot_info'),
                  void 0,
                  { top: true }
                ]
              ]),
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_36, [
                _createVNode(_component_Checkbox, {
                  id: "storable",
                  name: "storable",
                  binary: true,
                  class: "w-full",
                  modelValue: _ctx.item.storable,
                  "onUpdate:modelValue": _cache[18] || (_cache[18] = ($event: any) => ((_ctx.item.storable) = $event)),
                  onChange: _cache[19] || (_cache[19] = 
                  () => {
                    if (!_ctx.item.storable) {
                      _ctx.item.trackerable = false;
                      _ctx.item.cost = null;
                      _ctx.item.purchasable = false;
                    }
                  }
                )
                }, null, 8, ["modelValue"]),
                _createElementVNode("label", _hoisted_37, _toDisplayString(_ctx.t("storable")), 1)
              ])), [
                [
                  _directive_tooltip,
                  _ctx.t('msg_storable_info'),
                  void 0,
                  { top: true }
                ]
              ])
            ])
          ])
        ])
      ])
    ]),
    _: 1
  }, 8, ["visible", "onUpdate:visible", "header"]))
}