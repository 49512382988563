import { ref, App } from "vue";
import { AuthService, LoginResponse } from "./authService";
import { msg } from "./message";
import { UserFragment } from "@/graphql";

interface User {
  email: string;
  exp_stamp: number;
  token: string | null;
  userData?: UserFragment;
}

type RefreshPool =
  | {
      reject: (value?: string) => void;
      resolve: (value?: LoginResponse) => void;
    }[]
  | null;

let refreshPool: RefreshPool = null;

function authSetup() {
  const user = ref<null | undefined | User>();

  const authService = new AuthService();

  function init() {
    // Load from store
    const storedUser = localStorage.getItem("user");

    // If no user stored
    if (storedUser === null) {
      user.value = null;
      return;
    }

    // If user stored
    try {
      const parsedUser: User = JSON.parse(storedUser);
      refreshToken(parsedUser);
    } catch (_) {
      // Stored user is Broken, deleting...
      localStorage.removeItem("user");
    }
  }

  function saveUser() {
    localStorage.setItem("user", JSON.stringify(user.value));
  }

  async function login(email: string, password: string) {
    try {
      const data = await authService.login({ email, password });

      user.value = {
        token: data.jwt_token,
        exp_stamp: Date.now() + data.jwt_expires_in - 30000,
        email,
      };

      saveUser();
    } catch (e: any) {
      msg.showError(msg.t(e.response?.data?.message || e.message));
    }
  }

  async function refreshToken(
    _user?: User
  ): Promise<LoginResponse | undefined> {
    //If there is an active pool, save the promise.
    if (refreshPool) {
      return new Promise((resolve, reject) => {
        refreshPool?.push({ resolve, reject });
      });
    }

    //Initialize a pool
    refreshPool = [];

    try {
      const data = await authService.refresh();

      if (!data.jwt_token) throw new Error("500");

      if (_user) {
        user.value = _user;
      }

      if (user.value) {
        user.value.exp_stamp = Date.now() + data.jwt_expires_in - 30000;
        user.value.token = data.jwt_token;
      }

      saveUser();

      //Resolve the promises in the pool with the data
      refreshPool.forEach((i) => i.resolve(data));

      //Clear the pool
      refreshPool = null;

      return data;
    } catch (e) {
      console.warn(
        "[auth]:  Error injected, review to proper handle: <refreshError>"
      );
      logout();
    }
  }

  async function logout() {
    try {
      user.value = null;
      localStorage.removeItem("user");
      await authService.logout();
    } catch (_) {
      //maybe we should retry this, but is not realy needed now.
    }
  }

  async function getToken(): Promise<string | undefined> {
    if (!user.value) {
      return undefined;
    }

    const { token, exp_stamp } = user.value;

    if (exp_stamp - Date.now() > 0 && token !== null) {
      return token;
    }

    return (await refreshToken())?.jwt_token;
  }

  function invalidateToken() {
    if (user.value) user.value.token = null;
  }

  async function getHeader() {
    const token = await getToken();

    return token ? { Authorization: `Bearer ${token}` } : {};
  }

  init();

  return {
    user,
    login,
    logout,
    refreshToken,
    getHeader,
    authService,
    invalidateToken,
  };
}

//<!-- Auth initialization -->
const Auth = authSetup();

//<!-- For component use -->
export const useAuth = () => Auth;

//<!-- Vue plugin installer -->
export default {
  install(app: App) {
    app.config.globalProperties.$auth = Auth;
  },
};

//<!-- Register Auth for console debugin -->
declare global {
  interface Window {
    auth: typeof Auth;
  }
}

window.auth = Auth;
