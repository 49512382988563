
import { defineComponent, provide } from "vue";
import AppLayout from "@/layouts/AppLayout.vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { apolloClient } from "@/apollo";
import { useI18n } from "vue-i18n";
import { msg } from "./plugins/message";

export default defineComponent({
  components: { AppLayout },
  setup() {
    const { t } = useI18n();
    provide(DefaultApolloClient, apolloClient);

    return { t };
  },
  created() {
    msg.registerToast(this.$toast as any);
    msg.registerI18n(this.t as any);
  },
});
